import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import { checkreceivedData } from "../../hooks/checkReceivedData";

//import MyButton from "../Cards/WrapperComponents/myCard.js/myButton";
import classes from "./addpaymentmethod.module.css";
//import { Link } from "react-router-dom";

const AddEpoch = (props) => {
 
  function closeme() {
    props.getbackclose();
  }

  const [consent, setConsent] = useState(false);

  const authCtx = useContext(AuthContext);
  //   function submitHandler(event) {
  //     event.preventDefault();
  //     senddata(true);
  //   }

  const { t } = useTranslation();

  function togglewiderspruch() {
    setConsent(!consent);
  }
  function relocate(destination) {
    window.location.replace(destination);
  }
  const curPath=window.location.pathname;
  function weitergehts() {
    localStorage.setItem("lastSite", curPath);
    fetch(
      `https://api.deine.fans/api/payment/methods/epoch/authorize?authToken=${encodeURIComponent(
        props.authToken
      )}&userID=${encodeURIComponent(props.userID)}&referer=${encodeURIComponent(curPath)}`
    )
      .then((response) => response.json())
      .then((data) => {
        checkreceivedData(data, authCtx, "addepoch");

        if (!data.success) {
          console.log(data.error);
        } else {
          if (data.authUrl !== null) {
            relocate(data.authUrl);
          }
        }
      });
  }
  return (
    <>
      <div className={classes.darkBG} onClick={closeme} />
      <div className={classes.centered}>
        <div className={classes.modal}>
          <div className={classes.modalHeader}>
            <h3>{t("enterbankdata")}</h3>
          </div>
          <button className={classes.closeBtn} onClick={closeme}>
            <h3>X</h3>
          </button>

          <h5 className={classes.consent}>
            <label>
              <input
                className={classes.checkbox}
                type="checkbox"
                onClick={togglewiderspruch}
              />
              {t("consentepoch")}
            </label>
          </h5>
          <p></p>
          {consent && (
            <button className={classes.myButton} onClick={() => weitergehts()}>
              {t("agreed")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default AddEpoch;
