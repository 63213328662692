import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import RedButton from "../WrapperComponents/redbutton";
import classes from "./cookie.module.css";

const FSK18 = (props) => {
  function setAllFSK() {
    localStorage.setItem("fsk", "FSK");
    props.sendBack();
  }
 
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={classes.heads}>{t("adultcontent")}</div>
      <p
       
        className={classes.text}
      >
        {t("youseeadult")}
      </p>
     
      <div className={classes.buttons}>
        <div onClick={setAllFSK}>
          <RedButton>{t("allesklar")}</RedButton>
        </div>
       
      </div>
    </Fragment>
  );
};

export default FSK18;
