import React, { useContext, useState } from "react";
import RedButton from "../../WrapperComponents/redbutton";
import AuthContext from "../../../../context/authcontext";
import SuccessMessage from "../../../sonstige/specialcomponents/successmessage";
import PortalModal from "../../WrapperComponents/portalmodal";
import { useTranslation } from "react-i18next";
import classes from "./uploadpicture.module.css";
import LoadingSpinner from "../loadingspinner";

function UploadVideos(props) {
  const { width, height } = props;
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState(true);
  const inputRef = React.useRef();
const [isSuccess,setIsSuccess]=useState(false);
  const [source, setSource] = React.useState();
  const [finalID,setFinalID]=useState(null);

  const handleFileChange = (event) => {
    setIsSuccess(false)
    console.log(event);
    let file = event.target.files[0];
    if(!file.type.includes("video")){setError(t("novideo"));return}
    console.log(file)
    if(file.size>1500000000){setError(t("filetoobig"));return}
    let url = URL.createObjectURL(file);
    setSource(url);
    event.value=null;
  };

  const handleChoose = (event) => {
    if (document.querySelector("[type=file]").files[0]) {
      const input = document.querySelector("[type=file]");
      const fileListArr = Array.from(input.files);
      fileListArr.splice(0, 1);
    }
    console.log("event", document.querySelector("[type=file]").files[0]);
    inputRef.current.click();
  };

  const url = "https://api.deine.fans/api/userupload?";
  const authCtx = useContext(AuthContext);
  async function videoupload(source) {
    setLoading(true);
    const files = document.querySelector("[type=file]").files;

    if (!files) {
      return;
    }
    if(!authCtx.isLoggedIn){return}
    const formData = new FormData();

    let file = files[0];
    formData.append("file", file);
    formData.append("name", file);

    const backdata = await fetch(
      `${url}userID=${encodeURIComponent(authCtx.userID)}`,
      {
        method: "POST",
        body: formData,
      }
    );
    const backdatareadable = await backdata.json();
    console.log(backdatareadable.uploadkey);
    if (backdatareadable.uploadkey) {
      const newinfo = await fetch(
        `${url}uploadkey=${encodeURIComponent(
          backdatareadable.uploadkey
        )}&userID=${encodeURIComponent(authCtx.userID)}&authToken=${
          authCtx.jwtAuthToken
        }`
      );
      const myInfo = await newinfo.json();
      console.log(myInfo);
      setLoading(false);
      if (myInfo.error) {
        setError(myInfo.error);
      }
      if (myInfo.message === "Video Found") {
        console.log("myInfo",myInfo);
        setIsSuccess(true);
        setSource(false);
        setUpload(true);
        setFinalID(myInfo.uploadkey)
        if(props.onFinal){props.onFinal(myInfo.uploadkey);}
      }
    }
  }
  const { t } = useTranslation();
  return (
    <div className="VideoInput">
      {isSuccess ? <p>{t("uploadsuccess")}</p> : null}
      {upload && !source ? (
        <div>
          <h5>{t("uploadavideonowtitle")}</h5>
          <p>{t("uploadavideonow")}</p>
          <label
            alt="UploadPicture"
            className={classes.button}
            htmlFor="uploadbtn"
            //style={{ backgroundImage: `url("${imageUrl}"` }}
            onChange={handleFileChange}
            onSubmit={handleFileChange}
            onClick={(event) => {
              event.target.value = null;
            }}
          ></label>
        </div>
      ) : (
        false
      )}
      <input
        id="uploadbtn"
        hidden
        ref={inputRef}
        className="VideoInput_input"
        type="file"
        onChange={handleFileChange}
        onSubmit={handleFileChange}
        onClick={(event) => {
          event.target.value = null;
        }}
        accept=".mov,.mp4"
      />
      {!source && (
        <RedButton id="videoupload" onClick={handleChoose}>
          {t("videoauswählen")}
        </RedButton>
      )}
      {source && (
        <div className={classes.uploadedVideo}>
          <video
            className="VideoInput_video"
            width="100%"
            height={height}
            max-height="300px"
            controls
            src={source}
          />
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
            className={classes.igs}
            onClick={() => {
              setSource(null);
              console.log(document.querySelector("[type=file]").files[0]);
              document.querySelector("[type=file]").files[0].value = "";
              document.querySelector("[type=file]").files[0].value = "";
              document.querySelector("[type=file]").files[0].file = null;
            }}
          />
          {loading ? (
            <div className={classes.loadingspinner}>
              <LoadingSpinner />
            </div>
          ) : null}
        </div>
      )}
      {/* <div className="VideoInput_footer">{source || null}</div> */}
      {source ? (
        <RedButton
          onClick={() => {
            videoupload(source);
          }}
        >
          {t("videohochladen")}
        </RedButton>
      ) : null}

      {error ? (
        <PortalModal
          onclose={() => {
            setError(false);
          }}
        >
          <SuccessMessage
            type="error"
            error={error}
            onclose={() => {
              setError(false);
            }}
          />
        </PortalModal>
      ) : null}
    </div>
  );
}

export default UploadVideos;
