import { logallout } from "./logallout";
import { refreshtoken } from "./refreshtoken";
import { useMetric } from "./useMetric";
import uuid from "react-uuid";


export async function checkreceivedData(data, context,extra) {
   
    
   
  //if (context&&context.pseudo==="apitest"){console.log(data)}
  if (data.error === "Invalid AuthToken") {
  console.log("it'shappening", context);
  if(context&&context.isLoggedIn){logallout(context)};
    // window.location.replace("/");
    // return "logitout";

  }
 
  if (data.need_refresh) {
    if (context) {
      refreshtoken(context);
    }
  } else if (data.maybeLoggedOut === true) {
    if (context) {
      refreshtoken(context);
    }
  }
   if (data.error === "AuthToken expired") {
     if (context) {
       logallout(context);
     }
     window.location.replace("/");
   }
   if (data.error === "AuthToken mismatch") {
     if (context) {
       logallout(context);
     }
     window.location.replace("/");
   }

  if (data.error) {
  
    if(context.isLoggedIn && extra){
      
     
     context.hitLog.push({id:new uuid(), metricSubKey:data.error, metricID:"api-error",time:new Date().toLocaleTimeString(),metricKey:extra,userID:context.userID});
     useMetric(
       "pageHit",
       [{
         id: uuid(),
         metricSubKey: data.error,
         metricID: "api-error",
         time: new Date().toLocaleTimeString(),
         metricKey: extra,
         userID: context.userID,
       }],
       context
     );
     
    }
  }
  if (data.maybeLoggedOut === true) {
    console.log(data, context);
  } 

  return data;
}
