import React, { Fragment, useState, useContext, useEffect } from "react";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import classes from "./displayMessages.module.css";
import AuthContext from "../../../context/authcontext";
import LoadingSpinner from "../Extras/loadingspinner";
import MyImage from "../../sonstige/specialcomponents/images";
import { Line } from "rc-progress";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMessages } from "../../../hooks/usePreflight";

const DisplayMessageMedia = (props) => {
  const authCtx = useContext(AuthContext);
  const userToken = authCtx.token;
  //const userID= authCtx.userID;
  //const isMobile = useCheckMobileScreen();

  var [imgUrl, setImgUrl] = useState("/images/icons/loading.png");
  var [videoUrl, setVideoUrl] = useState("");
 
  var [debugmsg, setDebugmsg] = useState("Init...");

  const [loading, setLoading] = useState(false);
  const [showError,setShowError] = useState(false);

  const [zoomed, setZoomed] = useState(false);
  // function toggleZoom() {
  //     setZoomed(!zoomed);
  // }

  useEffect(() => {
    if (props.type === "image") {
      getImageUrl(props.mediaID, "480x480xinside", ".jpg");
    } else if (props.type === "video") {
      setImgUrl("/images/icons/video.png");
      getVideoUrl(props.mediaID, "mp4");
    } else if (props.type === "audio") {
      setImgUrl("/images/icons/audio.png");
      getVideoUrl(props.mediaID, "ogg");
    }
  }, []);


  // const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
  // useEffect(() => {
  //     setDarkMode(authCtx.darkMode);
  // }, [authCtx]);

  async function getImageUrl(mediaID, dimension, clientExtension) {
    if(mediaID.indexOf("e.")===0) {
      setImgUrl("/images/icons/bild.png");
      return;
    }

    let url = `https://api.deine.fans/api/image/get/${encodeURIComponent(
      mediaID
    )}?role=user&producerOrUserID=${encodeURIComponent(
      authCtx.userID
    )}&authToken=${encodeURIComponent(
      userToken
    )}&dimension=${encodeURIComponent(dimension)}`;

    setDebugmsg("Loading image...");
    setLoading(true);

    const response = await fetch(url);

    const result = await response.json();

    setDebugmsg("Attached image:");
    setLoading(false);

    setImgUrl(result.image.imageURL);
  }

  async function getVideoUrl(mediaID, playerType) {
  
    let url = `https://api.deine.fans/api/videoplay/${encodeURIComponent(
      mediaID
    )}?userOrProducerID=${encodeURIComponent(
      authCtx.userID
    )}&authToken=${encodeURIComponent(
      userToken
    )}&viewContextType=message&viewContextID=${encodeURIComponent(props.msgID)}&role=user&playerType=${encodeURIComponent(playerType)}`;

    setDebugmsg("Loading " + props.type + "...");
    setLoading(true);

    const response = await fetch(url);

    const result = await response.json();

    setDebugmsg("Attached " + props.type + ":");
    setLoading(false);

    // result.playerInfo gibt es auch noch, aber das ist nur für den Player relevant
console.log(result)
if(result.error){setShowError(
  <div className={classes.errormodal}>
    <h5>{t("anerroroccured")}</h5>
    <p>
      {t("pleasetryagainlater")}
      {t("ifpersistscontact")}
      <Link to="/Support">Support</Link>
    </p>
    <p>{result.error}</p>
  </div>
);}
else{setVideoUrl(result.url)}
    return result.url;
  }
const {t}=useTranslation();
  return (
    <Fragment>
      <div className={classes.mediaattachment}>
        {/* {loading ? <p>Loading...<img src="/images/icons/loading.png" className={classes.spinning} alt="Loading..." /></p> : null} */}
        {loading ? <LoadingSpinner isSmall={true} /> : null}
        <p className={classes.debugmsg}>{debugmsg}</p>
        {props.type === "image" ? (
          <MyImage
            src={imgUrl}
            alt="Attached image"
            className={classes.msgattachmentimg}
          />
        ) : null}
        {props.type === "video"&& videoUrl ? showError?showError:(
          <video
            src={videoUrl}
            controls
            playsInline
            alt="Video"
            key={videoUrl}
            controlsList="nodownload"
            className={classes.msgattachmentvideo}
          />
        ) : null}
        {props.type === "audio" ? (
          <audio
            src={videoUrl}
            controls
            playsInline
            alt="Audio"
            className={classes.msgattachmentaudio}
          />
        ) : null}
      </div>
    </Fragment>
  );
};
export default DisplayMessageMedia;
