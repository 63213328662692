import React, { Fragment, memo, useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Modeladvertorial from "../components/Cards/CreatorDetails/modeladvertorial";
import AuthContext from "../context/authcontext";
import useMyHttp from "../hooks/myhttp";
import { Link } from "react-router-dom";
import classes from "./detail.module.css";
import { useTranslation } from "react-i18next";
import AllApiGirls from "../components/lists/allapigirls";
import RedButton from "../components/Cards/WrapperComponents/redbutton";
import useCheckMobileScreen from "../hooks/useCheckMobile";
import PleaseLoginShort from "../components/sonstige/pleaseloginshort";
import DisplaySub from "../components/Cards/subscriptions/subs_2ed/displaySub";
import PortalFeedbackModal from "../components/Cards/WrapperComponents/portalfeedbackmodal";
import IsLiveGirl from "../components/Cards/Extras/isLiveGirl";
import TimeLineTest from "../components/lists/Timeline/timelintest";
import LoadingSpinner from "../components/Cards/Extras/loadingspinner";
import Timelinefilterbar from "../components/filterbars/timelinefilterbar";
import SearchForTags from "../components/sonstige/searchfortags";
import TimelinefilterbarAllways from "../components/filterbars/timelinefilterbarallways";
import ChoosepaymentOption from "../components/sonstige/profileElements/choosepaymentOption";
import Login from "./login";
import Aktionswrapper from "../components/Cards/userUIElements/aktionswrapper";
import SendVoucherCode from "../components/sonstige/formulare/sendVoucherCode";

const Detailsite = (props) => {
  const [showimagesPosts, setshowimages] = useState(false);
  const [showvideosposts, setShowVideos] = useState(false);
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const isPremium = authCtx.isPremium;
  let mygirl = params.girlId;
  const location = useLocation();
  const test = location.state;
  const [canSee,setCanSee]=useState(false);
  const [detectchange,setDetectChange]=useState(false);

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  let { isLoading, data, sendRequest } = useMyHttp();
  function fetchGirlsHandler() {
  
    data = [];
    sendRequest(
      `https://api.deine.fans/api/girls/${encodeURIComponent(mygirl)}/${encodeURIComponent(authCtx.userID)}`,
      "GET",
      null,
      null,
      null,
      authCtx
    );
  }
  const [isItClicked, setIsItClicked] = useState(false);

  const getclick = () => {
    setIsItClicked(!isItClicked);
  };
  useEffect(()=>{
      window.scrollTo(0, 0);
  },[])
useEffect(()=>{
  if(authCtx.isPremium){setCanSee(true)}
  if(authCtx.fanDollar>0){setCanSee(true)}
  if(authCtx.country==="CH"){setCanSee(true)}
},[authCtx])
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   console.log("dis")
  //   fetchGirlsHandler();
  // }, [params]);
  useEffect(() => {
    if (mygirl.toLocaleLowerCase() === "micaelaschaefer") {
      mygirl = "micaelaschäfer";
    }
    fetchGirlsHandler();
  }, [test, setIsItClicked,authCtx.isLoggedIn]);
  function setMyContent(type) {
    if (type === "all") {
      setShowVideos(false);
      setshowimages(false);
    } else if (type == "video") {
      setShowVideos(!showvideosposts);
      setshowimages(false);
    } else {
      setshowimages(!showimagesPosts);
      setShowVideos(false);
    }
  }
  const [sortbylike, setsortbylike] = useState(false);
  const [payVideo,setPayVideo]=useState(false);
   const [freeVideo, setFreeVideo] = useState(false);
  function setMyContentProps(props) {
   
setShowVideos(false);
setshowimages(false);
setsortbylike(false);
setPayVideo(false);
setFreeVideo(false);

    if (props === "all") {
    } else if (props === "video") {
      setShowVideos(true);
    } else if (props === "sortbylike") {
      setsortbylike(true);
    } else if (props === "image") {
      setshowimages(true);
    } else if (props === "payvideo") {
      setPayVideo(true);
    } else if (props === "freevideo") {
      setFreeVideo(true);
    }
    setDetectChange(true);
  }
  const [backmessage, setMessage] = useState(false);
  function checkchange(backinfo) {
  
    fetchGirlsHandler();
    if (backinfo && backinfo.paid === true) {
      setMessage(
        <div>
          <h5>{t("yohaveboughtsubscription")}</h5>
          <p>{t("boughtsubscriptiontext")}</p>
        </div>
      );
    }
  }
  const currentUrl=window.location.pathname;

  const [sortType, setSortType] = useState(null);
  function getback(info) {
    setSortType(info);
  }
  const isMobile=useCheckMobileScreen();
  function compareNumbers(a, b) {
    return a - b;
  }
  return (
    <div>
      {isLoading && <LoadingSpinner />}

      {/* {!isLoading && data && data.girl && !authCtx.isLoggedIn && !isMobile && (
        <Fragment>
          <PleaseLoginShort myUrl={currentUrl} />
          <Modeladvertorial
            sendclick={getclick}
            producerID={data.girl.producerID}
            name={data.girl.pseudo}
            imagesoft={data.girl.softSmallImageIDURLs.urlSmall}
            secondimagesoft={data.girl.softBigImageIDURLs.urlBig}
            image={
              data.girl.smallImageIDURLs.urlSmall.length > 0
                ? data.girl.smallImageIDURLs.urlSmall
                : data.girl.softSmallImageIDURLs.urlSmall
            }
            secondimage={
              data.girl.bigImageIDURLs.urlBig.length > 0
                ? data.girl.bigImageIDURLs.urlBig
                : data.girl.softBigImageIDURLs.urlBig
            }
            age={data.girl.age}
            numberofitems="2"
            key={data.girl.pseudo}
            isFav={data.isFav}
            hasSubscription={data.hasSubscription}
            id={data.girl.producerID}
            mymotto={data.girl.steckbriefText}
            hashtag={data.girl.hashTags}
            tiers={data.girl.tiers}
            extra={data.girl}
            filterlist={setMyContentProps}
            dontsnap={true}
            isCurOnline={data.girl.isOnline || data.girl.isOnlineInLivecam}
          />
          <div className={classes.bottombouncer}></div>
        </Fragment>
      )} */}
      {!isLoading && data && data.girl && !authCtx.isLoggedIn && (
        <Fragment>
          <div className={classes.isnapstart}></div>
          <Modeladvertorial
            sendclick={getclick}
            producerID={data.girl.producerID}
            name={data.girl.pseudo}
            imagesoft={data.girl.softSmallImageIDURLs.urlSmall}
            secondimagesoft={data.girl.softBigImageIDURLs.urlBig}
            image={
              data.girl.bigImageIDURLs &&
              data.girl.smallImageIDURLs.urlSmall.search("/.") < 0
                ? data.girl.smallImageIDURLs.urlSmall
                : data.girl.softSmallImageIDURLs.urlSmall
            }
            secondimage={
              data.girl.bigImageIDURLs &&
              data.girl.bigImageIDURLs.urlBig.search("/.") < 0
                ? data.girl.bigImageIDURLs.urlBig
                : data.girl.softBigImageIDURLs.urlBig
            }
            age={data.girl.age}
            numberofitems="2"
            key={data.girl.pseudo}
            isFav={data.isFav}
            hasSubscription={data.hasSubscription}
            id={data.girl.producerID}
            mymotto={data.girl.steckbriefText}
            hashtag={data.girl.hashTags}
            tiers={data.girl.tiers}
            extra={data.girl}
            filterlist={setMyContentProps}
            dontsnap={true}
            isCurOnline={data.girl.isOnline || data.girl.isOnlineInLivecam}
            makeLogin={isMobile}
            curerentUrl={currentUrl}
            data={data.girl}
            detail={true}
          />
          {isMobile ? (
            <DisplaySub
              tiers={Array.from(data.girl.tiers).sort(
                (a, b) => a.price - b.price
              )}
              name={data.girl.pseudo}
              producerID={data.girl.producerID}
              context={authCtx}
              sendchange={checkchange}
              secondimage={
                data.girl.bigImageIDURLs &&
                data.girl.bigImageIDURLs.urlBig.search("/.") < 0
                  ? data.girl.bigImageIDURLs.urlBig
                  : data.girl.softBigImageIDURLs.urlBig
              }
              imagesoft={data.girl.softSmallImageIDURLs.urlSmall}
              secondimagesoft={data.girl.softBigImageIDURLs.urlBig}
            />
          ) : null}

          <TimeLineTest
            sort={sortType}
            girlid={data.girl.producerID}
            showimages={showimagesPosts}
            showvideos={showvideosposts}
            key={detectchange}
            sortbylike={sortbylike}
            freeVideo={freeVideo}
            payVideo={payVideo}
            hasSub={data.hasSubscription}
            filter="promo"
            messengerPrice={data.girl.messengerPrice}
          />
          <div className={classes.bottombouncer}></div>
        </Fragment>
      )}
      {!isLoading && data && data.girl && authCtx.isLoggedIn && (
        <div>
          {authCtx.isLoggedIn && canSee && (
            <Timelinefilterbar checkback={getback} sort={sortType} />
            //<TimelinefilterbarAllways checkback={getback} sort={sortType} />
          )}
          {/* {!isMobile && <SearchForTags />} */}

          {/* {data && (
              <div>
                <button onClick={() => setMyContent("all")}>alle</button>
                <button onClick={() => setMyContent("video")}>videos</button>
                <button onClick={() => setMyContent("image")}>images</button>
              </div>
            )} */}
          <Modeladvertorial
            sendclick={getclick}
            producerID={data.girl.producerID}
            name={data.girl.pseudo}
            image={
              data.girl.smallImageIDURLs &&
              data.girl.smallImageIDURLs.urlSmall.search("/.") < 0
                ? data.girl.smallImageIDURLs.urlSmall
                : data.girl.softSmallImageIDURLs.urlSmall
            }
            secondimage={
              data.girl.bigImageIDURLs &&
              data.girl.bigImageIDURLs.urlBig.search("/.") < 0
                ? data.girl.bigImageIDURLs.urlBig
                : data.girl.softBigImageIDURLs.urlBig
            }
            imagesoft={data.girl.softSmallImageIDURLs.urlSmall}
            secondimagesoft={data.girl.softBigImageIDURLs.urlBig}
            age={data.girl.age}
            numberofitems="2"
            key={data.girl.pseudo}
            isFav={data.isFav}
            hasSubscription={data.hasSubscription}
            id={data.girl.producerID}
            mymotto={
              currentLanguage === "de"
                ? data.girl.descriptionDE || data.girl.steckbriefText
                : data.girl.descriptionEN || data.girl.steckbriefText
            }
            mymottosoft={
              currentLanguage === "de"
                ? data.girl.descriptionSoftDE || data.girl.steckbriefSoftText
                : data.girl.descriptionSoftEN || data.girl.steckbriefSoftText
            }
            hashtag={data.girl.hashTags}
            tiers={data.girl.tiers}
            extra={data.girl}
            filterlist={setMyContentProps}
            isCurOnline={data.girl.isOnline || data.girl.isOnlineInLivecam}
            data={data.girl}
          />
          <Aktionswrapper className={classes.wrapme}>
            <SendVoucherCode phone={true} />
          </Aktionswrapper>
          {data.girl.isLivegirl && data.girl.showLivecam ? (
            <IsLiveGirl
              image={
                data.girl.smallImageIDURLs &&
                data.girl.smallImageIDURLs.urlBig.search("/.") < 0
                  ? data.girl.smallImageIDURLs.urlBig
                  : data.girl.softBigImageIDURLs.urlBig
              }
              targetType={data.girl.showLivecamLinkType}
              targetUrl={data.girl.customLivecamLink}
              targetName={data.girl.websiteTitle}
              isOnline={data.girl.isOnline}
              isOnlineInLivecam={data.girl.isOnlineInLivecam}
              girlname={data.girl.pseudo}
              website={data.girl.website}
            />
          ) : null}
          {!data.hasSubscription && isMobile && (
            <Fragment>
              {/* <ManageTiers
                  tiers={data.girl.tiers}
                  name={data.girl.pseudo}
                  producerID={data.girl.producerID}
                  context={authCtx}
                  sendchange={checkchange}
                /> */}
              <DisplaySub
                tiers={data.girl.tiers}
                name={data.girl.pseudo}
                producerID={data.girl.producerID}
                context={authCtx}
                sendchange={checkchange}
                secondimage={
                  data.girl.bigImageIDURLs &&
                  data.girl.bigImageIDURLs.urlBig.search("/.") < 0
                    ? data.girl.bigImageIDURLs.urlBig
                    : data.girl.softBigImageIDURLs.urlBig
                }
                imagesoft={data.girl.softSmallImageIDURLs.urlSmall}
                secondimagesoft={data.girl.softBigImageIDURLs.urlBig}
              />
            </Fragment>
          )}
          {/* {data.hasSubscription && (
              <SingleGirlSubscriptions
                girlinfo={data.girl.producerID}
                girl={data.girl}
              />
            )} */}

          {data.hasSubscription &&
            data.girl.tiers.length > 0 &&
            authCtx.isLoggedIn &&
            authCtx.isPremium && (
              <div className={`${classes.centerme} ${classes.isnapstart}`}>
                <Link to="/subscriptions">
                  <RedButton>{t("manageactivesubscription")}</RedButton>
                </Link>
              </div>
            )}
          {authCtx.isAVS ? (
            <Fragment key={data.hasSubscription}>
              <TimeLineTest
                sort={sortType}
                girlid={data.girl.producerID}
                showimages={showimagesPosts}
                showvideos={showvideosposts}
                key={detectchange}
                sortbylike={sortbylike}
                freeVideo={freeVideo}
                payVideo={payVideo}
                hasSub={data.hasSubscription}
                messengerPrice={data.girl.messengerPrice}
              />
            </Fragment>
          ) : (
            <TimeLineTest
              sort={sortType}
              girlid={data.girl.producerID}
              showimages={showimagesPosts}
              showvideos={showvideosposts}
              key={detectchange}
              sortbylike={sortbylike}
              freeVideo={freeVideo}
              payVideo={payVideo}
              hasSub={data.hasSubscription}
              filter={!authCtx.isLoggedIn ? "promo" : ""}
              messengerPrice={data.girl.messengerPrice}
            />
          )}
        </div>
      )}
      
      {!isPremium && data && !data.girl && (
        <div>
          <h5>
            {t("notfound")} {t("tryoneofthese")}
          </h5>
          <AllApiGirls />
        </div>
      )}
      <div className={classes.bottombouncer}></div>
      {backmessage && (
        <PortalFeedbackModal
          onclose={() => {
            setMessage(false);
          }}
        >
          {backmessage}
        </PortalFeedbackModal>
      )}
    </div>
  );
};

export default Detailsite;
