import React, { useContext, useEffect, useState } from "react";
import classes from "./commentlist.module.css";
import Comment from "../../Cards/comments/comment";
import RedButton from "../../Cards/WrapperComponents/redbutton";
import { useTranslation } from "react-i18next";
import useMyHttp from "../../../hooks/myhttp";
import AuthContext from "../../../context/authcontext";
import WriteComment from "../../Cards/comments/writecomment";

const CommentList = (props)=>{

  const authCtx = useContext(AuthContext);
  const userToken= authCtx.jwtAuthToken;
  const userID= authCtx.userID;

// const testarray = [
//   {
//     username: "Caro",
//     userimage:
//       "https://d2cq08zcv5hf9g.cloudfront.net/720x540/gl3115fb66db374e0494314e5d1c99f50d.webp",
//     message: "Demo für Kommentarfunktion",
//   },
//   {
//     username: "Hugo",
//     userimage:
//       "https://d2cq08zcv5hf9g.cloudfront.net/720x540/gl3115fb66db374e0494314e5d1c99f50d.webp",
//     message: "interessante Dinge",
//   },
// ];


const { isLoading, data, error, sendRequest } = useMyHttp();
function fetchnews() {
  sendRequest(
    `https://api.deine.fans/api/comments?guid=${encodeURIComponent(
      props.post
    )}&guidType=${encodeURIComponent("post")}&userID=${encodeURIComponent(userID)}&authToken=${encodeURIComponent(userToken)}`,
    "GET",
    null,
    null,
    null,
    null,
    authCtx
  );
}
useEffect(()=>{
  fetchnews()
},[])
const [writeComment,setWriteComment] = useState()
function commentit() {
  setWriteComment(!writeComment);
}
const {t}=useTranslation();

function sentit(){
commentit();
fetchnews();
}
    return (
      <div
        className={
          !props.dm ? classes.allthecomments : classes.allthecommentsdm
        }
      >
        {data &&
          data.comments &&
          data.comments &&
          data.comments.map((comment, index) => (
            <Comment key={`Kommentarliste${index}`} com={comment} />
          ))}
        {isLoading && <p>{t("loading")}</p>}
        {error && <p>{error}</p>}
     
        <div onClick={commentit} className={classes.wrapbuttondiv}>
        <button type="button" className={classes.commentbtn}>{t("addcomment")}</button>
        </div>
        {writeComment&&<WriteComment guid={props.post} onclose={sentit} />}
      </div>
    );
}

export default CommentList;