import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PortalModal from "../Cards/WrapperComponents/portalmodal";
import GetNumber from "./simplepayment/components/getNumber";
import classes from "./addpaymentmethod.module.css";
import RedButton from "../Cards/WrapperComponents/redbutton";
import AuthContext from "../../context/authcontext";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import LoadingSpinner from "../Cards/Extras/loadingspinner";
import { Analytics } from "aws-amplify";

const AddTwint = (props) => {

  const [initialPayment, setInitialPayment] = useState(15);
  const [isConsent,setIsConsent]=useState(false);
  const [isLoading,setIsLoading]=useState(false);
  const authCtx=useContext(AuthContext);

  function toggleconsent(){
    setIsConsent(true);
  }

  function unnamed() {
    props.getbackclose();
  }
  
  function getbackNumber(value) {
    if (value) {
      setInitialPayment(value);
    }
  }

  function startPayment() {
    localStorage.setItem("lastSite", curPath);

    fetch(
      `https://api.deine.fans/api/payment/methods/twint/authorize/${encodeURIComponent(
        initialPayment * 100
      )}?authToken=${encodeURIComponent(
        authCtx.token
      )}&userID=${encodeURIComponent(
        authCtx.userID
      )}&referer=${encodeURIComponent(curPath)}`
    )
      .then((response) => response.json())
      .then((data) => {
        checkreceivedData(data, authCtx, "addTwint");
        if (!data.success) {
          console.log(data.error);
        } else {
          Analytics.record({
            name: "authorizePaymentTwint",
            attributes: {
              userID: authCtx.userID,
            },
          });

          window.location.replace(data.authUrl);
        }
      });
  }

  const curPath=window.location.pathname;

  const {t}=useTranslation();

  return (
    <PortalModal onclose={unnamed} >
      
      <div className={classes.topdown}>
        <div className={classes.flexit}>
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo_twint.png"
            className={classes.smalllogo}
            alt="Twint Payment"
          />
          <h5>{t("addtwintpayment")}</h5>
        </div>
        <p>{t("BuyWithTwint")}</p>
        <p>{t("addvalue2")}</p>
        {isLoading ? <LoadingSpinner /> :
          (<div>
           
                <div>
                  <span className={classes.getNumber}>
                    <GetNumber
                      sendbackNumber={getbackNumber}
                      value={initialPayment}
                      currency={"CHF"}
                      isMinimumtwint={true}
                      increment={5}
                    />
                  </span></div>{isConsent?
                  <div className={classes.consent}>
                    <label>
                      <input
                        className={classes.checkbox}
                        type="checkbox"
                        onClick={toggleconsent}
                      />
                      {t("consenttwint")}
                    </label>
                  </div>
               
  :
              
            <RedButton onClick={startPayment}>
                {t("kostenplichtigbestellen")}
              </RedButton>}
          </div>
          
          )
        }
        </div>
    </PortalModal>
  );
};
export default AddTwint;
