import React, { Fragment, useEffect, useRef, useState,memo } from "react";
import classes from "./simplepaymentclasses.module.css";


const GetNumber=memo((props)=>{
  const spendeinputref = useRef();
  const [payPrice,setPayPrice]=useState(10);

  let myIncrement = 1;
  if(typeof(props.increment) != "undefined") {
    myIncrement = props.increment;
  }
  const [increment,setIncrement]=useState(myIncrement);

  useEffect(()=>{
      if(props.value){setPayPrice(props.value)}
  },[])

  function changevalue(){
      setPayPrice(spendeinputref.current.value);
      props.sendbackNumber(spendeinputref.current.value);
  }

  function increase() {
    setPayPrice(Number(payPrice + increment));
    props.sendbackNumber(Number(payPrice + increment));
  }
  function decrease() {
    if (payPrice === 0) {
      return;
    }
    if(props.isMinimum && payPrice < 11) {
      return;
    }
    setPayPrice(Number(payPrice - increment));
    props.sendbackNumber(Number(payPrice - increment));
  }
 useEffect(()=>{
if (props.isMinimum && spendeinputref.current.value < 11) {
  spendeinputref.current.value=10;
}
if (props.isMinimumtwint && spendeinputref.current.value < 16) {
  spendeinputref.current.value = 15;
}
 },[payPrice])

  return (
    <Fragment>
      <div className={classes.firstelementbox}>
        <div className={classes.firstelement}>
          {props.isMinimumtwint ? null : props.isMinimum &&
            payPrice < 11 ? null : (
            <button onClick={decrease} className={classes.increasbutton}>
              -
            </button>
          )}
          {props.isMinimumtwint ? (
            payPrice > 16 ? (
              <button onClick={decrease} className={classes.increasbutton}>
                -
              </button>
            ) : null
          ) : null}
          <span className={classes.setapart}>
            <input
              type="number"
              max="100"
              min={props.isMinimum ? "10" : "1"}
              id="text"
              onChange={changevalue}
              ref={spendeinputref}
              value={payPrice}
              className={
                payPrice < 100 ? classes.entermoney : classes.entermoneylong
              }
            />
            {!props.currency ? (
              <img
                src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/f_dollar_02.png"
                alt="FanDollar"
                className={classes.fandollarelement}
              />
            ) : (
              <h2
                className={
                  props.currency && props.currency === "CHF"
                    ? payPrice < 100
                      ? classes.currencychf
                      : classes.currencychflong
                    : payPrice < 100
                    ? classes.currency
                    : classes.currencylong
                }
              >
                {props.currency}
              </h2>
            )}
          </span>
          <button onClick={increase} className={classes.increasbutton}>
            +
          </button>
        </div>
      </div>
    </Fragment>
  );
});
export default GetNumber;