import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import AllApiShort from "../components/lists/allapishort";
import DetailApiShort from "../components/lists/detailapishort";
import Dynafilters from "../components/lists/dynafilters";
import AuthContext from "../context/authcontext";
import DesktopHeader from "./desktopheader";
import Footer from "./footer";
import classes from "./smallmainpagedesktop.module.css";

const SmallMainPageDesktop = (props) =>{
      const params = useParams();
      const isDetailTrue = props.detail;
      const [isDarkmode, setIsDarkMode] = useState(false);
      const [setChange,setsetChange]=useState(0)
    const authCtx=useContext(AuthContext);
      useEffect(() => {
        setIsDarkMode(authCtx.darkMode);
      }, [authCtx]);
      function isbought(){
setsetChange(setChange + 1);
      }
const {t}=useTranslation();
    return (
      <div className={!isDarkmode ? classes.smaller : classes.smallerdm}>
        <div className={classes.header}>
          <DesktopHeader />
        </div>
        <Row>
          <Col
            xs="4"
            md="2"
            className={
              !isDarkmode ? classes.linkerwrapper : classes.linkerwrapperdm
            }
          >
            <div className={classes.linkeSpalte}>
              <Dynafilters />
            </div>
          </Col>
          <Col
            xs="8"
            md="7"
            className={
              !isDarkmode ? classes.middleElement : classes.middleElementdm
            }
            key={setChange}
          >
            {props.children}
          </Col>
          <Col xs="none" md="3" className={classes.rightelement}>
            <div
              className={
                !isDarkmode ? classes.rechteSpalte : classes.rechteSpaltedm
              }
            >
              {isDetailTrue ? (
                <Fragment>
                  <DetailApiShort
                    girl={params.girlId}
                    extrahead={true}
                    isbought={isbought}
                  />
                </Fragment>
              ) : (
                <AllApiShort />
              )}
              
            </div>
          </Col>
        </Row>
        <Footer />
      </div>
    );
};

export default SmallMainPageDesktop;