import React, {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../context/authcontext";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import PortalFeedbackModal from "../../Cards/WrapperComponents/portalfeedbackmodal";
import PortalModal from "../../Cards/WrapperComponents/portalmodal";
import RedButton from "../../Cards/WrapperComponents/redbutton";
import ConsentElement from "./components/consentelement";
import GetNumber from "./components/getNumber";
import WantFanDollar from "./components/wantFanDollar";
import classes from "./simplePaymentBaseModal.module.css";
import MyImage from "../../sonstige/specialcomponents/images";
import GetPaymentAuthorization from "../getPaymentAuthorization/getpaymentauthorization";
import InfoText from "../getPaymentAuthorization/infotext";
import uuid from "react-uuid";
import { useMetric } from "../../../hooks/useMetric";
import ChoosepaymentOption from "../../sonstige/profileElements/choosepaymentOption";
import SubInfoText from "../../Cards/subscriptions/subs_2ed/SubInfoText";
import GetFanDollarNumber from "./components/getFanDollarNumber";
import ChooseTwintorOther from "../../sonstige/profileElements/chooseTwintorOther";
import PayWithTwint from "../payWithTwint";
import { Link } from "react-router-dom";
import AddSofort from "../addSofort";

const SimplePaymentBaseModal = memo((props) => {
  const [chosenMethod, setChosenmethod] = useState("");
  const [methodName, setmethodName] = useState(false);
  const authContext = useContext(AuthContext);
  const [payPrice, setPayPrice] = useState(
    props.fixpreis ? props.fixpreis / 100 : 15
  );
  const [mainModal, setMainModal] = useState(true);
  const [errorModal, setErrorModal] = useState(false);
  const [epochModal, setEpochModal] = useState(false);
  const [redirectTarget, setRedirectTarget] = useState("");
  const { t } = useTranslation();
  const [epochEnd, setEpochEnd] = useState(false);
  const [thetransid, setthetransid] = useState(false);
  const [canUseFandollar, setCanUseFanDollar] = useState(false);
  const [useFanDollar, setUseFanDollar] = useState(false);
  const [fixpreis, setFixpreis] = useState(
    props.fixpreis ? props.fixpreis / 100 : null
  );
  const girlid = props.girlid || "";
  const girlname = props.girlname || "";
  const postid = props.postid || "";
  const msgid = props.msgid || "";
  const girlimage = props.girlimg || null;
  const [payments, setPayments] = useState("");
  const [canUseiFrame, setCanUseFrame] = useState(false);
  const [isConsent, setIsConsent] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isAboBuy, setIsAboBuy] = useState(false);
  const [canPay, setCanPay] = useState(true);
  const [vat, setVat] = useState(0.19);
  const [gotPrice,setGotPrice]=useState(false);
  const [offerTwint,setOfferTwint]=useState(authContext.country==="CH");
  const [usesTwintForTheFirstTime,setUsesTwintForTheFirstTime]=useState(false);
const currency=authContext.country==="CH"?"CHF":"EUR";
const [noOptions,setNoOptions]=useState(false);
  useEffect(() => {
    if (authContext.pseudo === "e3c71f1117ba4af0b07e676713691afb") {
      setIsSuperUser(true);
      setPayPrice(0.01);
    }
    if (props.type === "abobuy") {
      setIsAboBuy(true);
    }
    // if (authContext.pseudo === "apitest") {
    //   setIsSuperUser(true);
    //   setPayPrice(0.01);
    // }
    if (!authContext.isPremium && authContext.fanDollar < 1&& authContext.country!=="CH") {
      setCanPay(false);
    }
    
    if (props.fixpreis) {
      setFixpreis(props.fixpreis / 100);
      setPayPrice(props.fixpreis / 100);
    }
    if (props.canfandollar) {
      setCanUseFanDollar(true);
    }
    useMetric(
      "pageHit",
      [
        {
          id: uuid(),
          metricID: "page-hit",
          metricSubKey: "",
          metricKey: "paymentaufegrufen",
          userID: authContext.userID,
          time: new Date().toLocaleString(),
        },
      ],
      authContext
    );

    if (msgid !== "") {
      if (payPrice === 0) {
        fetch("https://api.deine.fans/api/messagebuy", {
          method: "POST",
          body: JSON.stringify({
            authToken: authContext.token,
            userID: authContext.userID,
            msgID: props.itemID,
            authorizationID: -1,
            useFanBalance: true,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          res.json().then((data) => {
            checkreceivedData(data, authContext, "simplepayment,messagebuy");
            if (data.alreadyBought) {
            }
            if (data.success === true) {
              console.log("data",data)
              const payments = {
                preis: payPrice,
                payed: payPrice,

              };
              authContext.checkFanDollar(data.newBalance / 100);
              props.onclose(payments);
            } else {
              setErrorModal(data.error);
            }
          });
        });
      }
    }

    if (postid !== "") {
      if (payPrice === 0) {
        fetch("https://api.deine.fans/api/timelinebuy", {
          method: "POST",
          body: JSON.stringify({
            authToken: authContext.token,
            userID: authContext.userID,
            postID: props.itemID,
            authorizationID: -1,
            useFanBalance: true,
            page: window.location.href,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          res.json().then((data) => {
            checkreceivedData(data, authContext, "simplepaymenttimelinebuy");
            console.log(data)
            if (data.alreadyBought) {
            }
            if (data.success === true) {
              const payments = {
                preis: payPrice,
                payed: payPrice,
              };
              authContext.checkFanDollar(data.newBalance / 100);
              props.onclose(payments);
            } else {
              setErrorModal(data.error);
            }
          });
        });
      }
    }
  }, []);

  function getbackfandollar(state) {
    setUseFanDollar(state);
  }
  function gotinfo(id, name, newvat) {
  
    if(id==="noneAvailable"){setNoOptions(true)}
    if (newvat) {
      setVat(newvat);

    }else if(authContext.country==="CH"){setVat(0.077)}
    setChosenmethod(id);
    if (name) {
      setmethodName(name.toLowerCase());
    }
    if (id === -1) {
      setUseFanDollar(true);
    }

    if (name && name.toLowerCase() === "epoch") {
      if (props.tellmeifepoch) {
        props.tellmeifepoch(true);
      }
    }
  }

  function getbackNumber(number) {
    setFirstTime(false);
        setPayPrice(number);
        setGotPrice(number);
  }

  function closemodal() {
    props.onclose();
  }

  function sendmoney() {
    var auth = chosenMethod;
    var method = "POST";
    let interfaceBody = JSON.stringify({
      userID: authContext.userID,
      authToken: authContext.token,
      amount: Number(payPrice * 100),
      authorizationID: auth,
      redirectTransID: thetransid ? thetransid : 0,
    });

    let url = "https://api.deine.fans/api/balance";

    if (girlid !== "") {
      url = `https://api.deine.fans/api/girls/buy/tip/${girlid}`;
      method = "PUT";
      interfaceBody = JSON.stringify({
        authToken: authContext.token,
        useFanBalance: useFanDollar,
        userID: authContext.userID,
        amount: Number(payPrice * 100),
        authorizationID: auth,
        redirectTransID: thetransid ? thetransid : 0,
        page: window.location.href,
      });
    }

    if (msgid !== "") {
      url = "https://api.deine.fans/api/messagebuy";
      method = "POST";
      interfaceBody = JSON.stringify({
        authToken: authContext.token,
        useFanBalance: useFanDollar,
        userID: authContext.userID,
        amount: Number(payPrice * 100),
        authorizationID: auth,
        redirectTransID: thetransid ? thetransid : 0,
        msgID: msgid,
        page: window.location.href,
      });
    }

    if (postid !== "") {
      url = "https://api.deine.fans/api/timelinebuy";
      method = "POST";
      interfaceBody = JSON.stringify({
        authToken: authContext.token,
        useFanBalance: useFanDollar,
        userID: authContext.userID,
        amount: Number(payPrice * 100),
        authorizationID: auth,
        redirectTransID: thetransid ? thetransid : 0,
        postID: postid,
        page: window.location.href,
      });
    }

    if (useFanDollar && authContext.fanDollar - payPrice >= 0) {
      auth = -1;
    }

    fetch(`${url}`, {
      method: method,
      body: interfaceBody,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, authContext, "simplepayment,fandollarbuy");

        if (data.success === true) {
          setPayments({ preis: payPrice });
          //console.log("checking newBalance", data)
          authContext.checkFanDollar(data.newBalance / 100);
          if (!girlid && !postid) {
            setErrorModal(
              "Du hast Dir erfolgreich " + payPrice + " FanDollar gekauft."
            );
          }
          if (girlid && !postid && props.girlimage) {
            setErrorModal(
              <div>
                <h5>{t("thankyou")}</h5>
                <div className={classes.flexme}>
                  {props.girlimage && (
                    <MyImage
                      src={props.girlimage}
                      className={classes.fanimage}
                    />
                  )}
                  <p>
                    "Du hast {girlname} {payPrice.toFixed(2)} EUR gezahlt. Du
                    bist ein echter Fan!"
                  </p>
                </div>
              </div>
            );
          }
          if (postid) {
            setErrorModal("Du hast den Post erfolgreich gekauft.");
          }
          if (msgid){props.onclose("bought")}
          setMainModal(false);
        } else {
          if (data.error === "Redirect needed") {
            setEpochModal(data.error);
            setMainModal(false);
            setRedirectTarget(data.redirectinfo.redirectURI);
            CheckApi(data.redirectinfo.transID);
            setCanUseFrame(data.redirectinfo.iframeSupported);

            if (!data.redirectinfo.iframeSupported) {
              window.open(
                data.redirectinfo.redirectURI,
                "popup",
                "location=0,width=750,height=650,left=500,top=55"
              );
            }
          } else if (data.error === "IBAN locked!") {
            setErrorModal(
              <div>
                <h5>{t("anerroroccured")}</h5>
                <div className={classes.flexme}>
                  {props.girlimage && (
                    <MyImage
                      src={props.girlimage}
                      className={classes.fanimage}
                    />
                  )}
                  <p>
                    {t("yourbankaccountislocked")}
                    {t("pleaseconsiderusing")}
                  </p>
                  {!canuseSofort ? (
                    <RedButton
                      className={`${classes.flexit} ${classes.addapayment}`}
                      onClick={() => setAddSofort(true)}
                    >
                      <img
                        src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/x70/logo_sofort.png"
                        className={classes.image}
                      />
                      <p className={classes.text}>{t("addsofort")}</p>
                    </RedButton>
                  ) : (
                    <div>
                      <RedButton onClick={()=>{setChosenmethod(false);setErrorModal(false);}}>{t("useotherpaymentoption")}</RedButton>
                    </div>
                  )}
                  <p>
                    {t("ifpersistscontact")}
                    <Link to="/support"></Link>
                  </p>
                  <p>{data.error}</p>
                </div>
              </div>
            );
          } else {
            setErrorModal(
              <div>
                <h5>{t("anerroroccured")}</h5>
                <div className={classes.flexme}>
                  {props.girlimage && (
                    <MyImage
                      src={props.girlimage}
                      className={classes.fanimage}
                    />
                  )}
                  <p>
                    {t("somethingwentwrong")}
                    {t("pleasetryagainlater")}
                    {t("ifpersistscontact")}
                    <Link to="/support"></Link>
                  </p>
                  <p>{data.error}</p>
                </div>
              </div>
            );
            setMainModal(false);
          }
        }
      });
    });
  }
const [addSofort,setAddSofort]=useState(false);
  function callApi(transid) {
    if (!thetransid) {
      return;
    }
    fetch(
      `https://api.deine.fans/api/payment/transcheck/${transid}?authToken=${authContext.token}&userID=${authContext.userID}`
    ).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, authContext, "simplePaymentCallApi");
        if (data.success) {
        }
        if (data.returned) {
          setEpochEnd(true);
          setEpochModal(false);
          sendmoney();
          setErrorModal("Das hat geklappt");
        }
      });
    });
  }
  function CheckApi(transid) {
    setthetransid(transid);
  }
  useEffect(() => {
    if (thetransid && !epochEnd) {
      let interval = setInterval(() => {
        callApi(thetransid);
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [thetransid, epochEnd]);

  // const child = useCallback(
  //   () => <GetPaymentAuthorization sendback={gotinfo} />,
  //   []
  // );
  const [roundedup, setroundedup] = useState(false);
  function roundup() {
    setroundedup(true);
    if (payPrice < 15) {
      setPayPrice(15);
    }
  }
  const [itsTwint,setItsTwint]=useState(false);
  const [canuseSofort,setCanUseSofort]=useState(false);
  function getbacksofort(){
    setCanUseSofort(true);
  }
  function sendUst(value){
    
    setVat(value-1);
  }
  const [firstTime,setFirstTime] =useState(true)
  return (
    <Fragment>
      {mainModal && (
        <PortalModal
          id="SimplePaymentModal"
          onclose={closemodal}
          className={props.secondgirlimg ? classes.damodal : ""}
        >
          {props.secondgirlimg ? (
            <img
              src={props.secondgirlimg}
              className={classes.girlimagebig}
              alt="headerimage"
              id={`SimplePaymentModal ${girlname}`}
            />
          ) : null}
          {canPay && !noOptions ? (
            !isAboBuy ? (
              <InfoText
                id={`simplepaymentmodal infotext`}
                girlid={girlid}
                postid={postid}
                msgid={msgid}
                girlname={girlname}
                payPrice={payPrice}
                girlimage={girlimage}
                secondgirlimage={props.secondgirlimg}
                title={props.title ? props.title : null}
              />
            ) : (
              <SubInfoText content={props.payload} />
            )
          ) : null}
          {!fixpreis ? (
            canUseFandollar ? (
              <div className={classes.allwidth}>
                <GetNumber
                  sendbackNumber={getbackNumber}
                  value={payPrice}
                  currency={props.currency ? props.currency : null}
                  context={authContext}
                  isMinimum={props.isMinimum ? true : false}
                />
              </div>
            ) : (
              <GetFanDollarNumber
                firtTime={firstTime}
                sendback={getbackNumber}
                value={gotPrice}
              />
            )
          ) : null}

          {!chosenMethod ? (
            <GetPaymentAuthorization
              sendback={gotinfo}
              fixprice={
                props.fixpreis ? props.fixpreis : girlid ? payPrice : null
              }
              getbacksofort={getbacksofort}
              sendUst={sendUst}
              sendbacknone={() => {
                console.log("gotbacknothing");
              }}
            />
          ) : chosenMethod !== "noneAvailable" ? (
            <div className={classes.flexit}>
              <img
                src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/logo_${methodName}.png`}
                alt="mymethod"
                className={`${classes.icon} ${classes.glow}`}
              />
              <img
                src={`/images/icons/paymentback.png`}
                alt={"back"}
                className={classes.icon}
                onClick={() => {
                  setChosenmethod(false);
                }}
              />
            </div>
          ) : (
            <div className={classes.flexittop}>
              {/* <p>{t("youhavenopaymentoption")}</p>
              <RedButton
                onClick={() => {
                  window.location.replace("/profile");
                }}
              >
                {t("AddPaymentOption")}
              </RedButton> */}
              {!useFanDollar && !usesTwintForTheFirstTime ? (
                offerTwint ? (
                  <ChooseTwintorOther
                    sendback={() => {
                      setOfferTwint(false);
                    }}
                    sendtwint={() => {
                      setmethodName("twint");
                      setChosenmethod(-3);
                      setItsTwint(true);
                    }}
                  />
                ) : (
                  <ChoosepaymentOption
                  pricefrombuyfandollar={!fixpreis?payPrice?payPrice:1500:null}
                    initialPay={
                      props.fixpreis && props.postid ? props.fixpreis : null
                    }
                    postid={
                      props.postid && props.fixpreis ? props.postid : null
                    }
                  />
                )
              ) : null}

              {canUseFandollar && authContext.fanDollar >= payPrice ? (
                <p>{t("oder")}</p>
              ) : null}
              {canUseFandollar && authContext.fanDollar >= payPrice ? (
                <img
                  id="buywithfandollar"
                  className={classes.icon}
                  src="/images/icons/fan_dollar.png"
                  alt="usefandollarinstead"
                  onClick={() => {
                    document
                      .getElementById("buywithfandollar")
                      .classList.add(classes.glow);
                    setUseFanDollar(true);
                    gotinfo(-1, "fandollar");
                  }}
                />
              ) : null}
            </div>
          )}

          {!isConsent && canPay && (
            <ConsentElement
              clickedagb={() => {
                closemodal();
              }}
              setok={() => setIsConsent(true)}
            />
          )}
          {/* {canUseFandollar && authContext.fanDollar > 0 && (
            <WantFanDollar
              price={payPrice}
              context={authContext}
              doyouwant={getbackfandollar}
              initial={useFanDollar}
            />
          )} */}
          {addSofort ? (
            <PortalModal
              onclose={() => {
                setAddSofort(false);
              }}
            >
              <AddSofort
                userID={authContext.userID}
                authToken={authContext.token}
                method={"Sofort"}
                getbackclose={() => setAddSofort(false)}
              />
            </PortalModal>
          ) : null}
          {isConsent &&
          chosenMethod &&
          chosenMethod !== "noneAvailable" &&
          chosenMethod !== "Twint" &&
          !itsTwint ? (
            <Fragment>
              {console.log("chosenMethod", props)}
              {chosenMethod !== -1 ? (
                <p key={vat}>
                  {t("vattext1")}
                  {payPrice.toFixed(2)} {currency}
                  {t("vattext2")}
                  {(payPrice * vat).toFixed(2)} {currency}
                  {t("vattext3")}
                </p>
              ) : null}
              <div onClick={sendmoney} className={classes.flexit}>
                <RedButton id={`bestellbutton1-chosenMethod:${chosenMethod}`}>
                  {t("kostenpflichtigbestellen")}
                </RedButton>
              </div>
            </Fragment>
          ) : null}
          {isConsent && chosenMethod && chosenMethod === "Twint" ? (
            payPrice < 15 && !roundedup ? (
              <Fragment>
                <p>{t("duetotechnicaltwint15")}</p>
                <div onClick={roundup} className={classes.flexit}>
                  <RedButton id={"simplemayment - aufrunden"}>
                    {t("Aufrunden")}
                  </RedButton>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <p key={`${vat}2`}>
                  {t("vattext1")}
                  {payPrice.toFixed(2)} {currency}
                  {t("vattext2")}
                  {(payPrice * vat).toFixed(2)} {currency}
                  {t("vattext3")}
                </p>
                <div onClick={sendmoney} className={classes.flexit}>
                  <RedButton id={`bestellbutton-Chosenmethod: ${chosenMethod}`}>
                    {t("kostenpflichtigbestellen")}
                  </RedButton>
                </div>
              </Fragment>
            )
          ) : null}
          {itsTwint ? (
            payPrice < 15 && !roundedup ? (
              <Fragment>
                <p>{t("duetotechnicaltwint15")}</p>
                <div
                  id={"simplemayment - aufrunden2"}
                  onClick={roundup}
                  className={classes.flexit}
                >
                  <RedButton>{t("Aufrunden")}</RedButton>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <p>
                  {t("vattext1")}
                  {payPrice.toFixed(2)} {currency}
                  {t("vattext2")}
                  {(payPrice * vat).toFixed(2)}
                  {currency}
                  {t("vattext3")}
                </p>
                <div onClick={sendmoney} className={classes.flexit}>
                  <RedButton id={`bestellbutton2-chosenMethod:${chosenMethod}`}>
                    {t("kostenpflichtigbestellen")}
                  </RedButton>
                </div>
              </Fragment>
            )
          ) : null}
          {isConsent && !chosenMethod && (
            <div className={classes.flexit}>
              <RedButton>{t("pleasechooseoption")}</RedButton>
            </div>
          )}
        </PortalModal>
      )}
      {errorModal && (
        <PortalFeedbackModal
          onclose={() => {
            setErrorModal(false);
            props.onclose(payments);
          }}
        >
          {errorModal}
        </PortalFeedbackModal>
      )}
      {epochModal && canUseiFrame && (
        <PortalModal
          onclose={() => {
            setEpochModal(false);
            props.onclose();
          }}
          big={true}
        >
          <div className={classes.iframe}>
            <iframe src={redirectTarget} className={classes.iframeinner} />
          </div>
        </PortalModal>
      )}
    </Fragment>
  );
});

export default SimplePaymentBaseModal;
