import React, {
  Fragment,

  useRef,
  useState,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// import MyButton from "../WrapperComponents/myButton";
import RedButton from "../WrapperComponents/redbutton";
import AuthContext from "../../../context/authcontext";
import classes from "./writecomment.module.css";

const WriteComment = (props) => {
  const messageref = useRef();
  const [theMessage, setTheMessage] = useState(null);
  const authCtx = useContext(AuthContext);
  const userToken = authCtx.token;
  const userID = authCtx.userID;

  async function submitMessage(e) {
    e.preventDefault();
    setTheMessage(messageref.current.value);




    let url = `https://api.deine.fans/api/comments?userID=${encodeURIComponent(
      userID
    )}&authToken=${encodeURIComponent(userToken)}&comment=${encodeURIComponent(
      messageref.current.value
    )}&guid=${encodeURIComponent(props.guid)}&guidType=post`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

    });

    const result = await response.json();

    if (typeof result.title !== "undefined") {
      let errormsg = result.title;
      for (let ei = 0; ei < result.errors.length; ei++) {
        errormsg += " " + result.errors[ei].error;
      }

    } else {
      if (result.error !== "") {
        console.log(result.error);

      }
    }

    onclose();
  }

  function onclose() {
    props.onclose();
  }


  const { t } = useTranslation();
  return (
    <Fragment>
      {/* <Link to="/messages">
        <MyButton>Alle Messages</MyButton>
      </Link> */}
      <div className={classes.size}>
        <h5>

          <div>
            {t("yourcomment")}
          </div>

        </h5>
        <form onSubmit={submitMessage} className={classes.commentformstyle}>
          <textarea
            className={classes.textforGirl}
            type="text"
            placeholder={t("wannasendmessage")}
            ref={messageref}
          />
        </form>
        <div onClick={submitMessage}>
          <button type="button" className={classes.commentbtn}>{t("send")}</button>
        </div>
      </div>
    </Fragment>
  );
};

export default WriteComment;
