import React, { useLayoutEffect, useRef, useState } from "react";
import MyImage from "../../../sonstige/specialcomponents/images";
import classes from "./scrollimage.module.css";


export const ScrollImage = (props) => {
  const preScroll = useRef(null);
  const elemRef = useRef(null);
  const [scale, setScale] = useState(1.05);
  const [moveit,setmoveit]=useState(0);
    
  useLayoutEffect(() => {
    const botPos = (elem) => elem.getBoundingClientRect().bottom;
    const topPos = (elem) => elem.getBoundingClientRect().top;
    const onScroll = () => {
      const divBotPos = botPos(elemRef.current);
      const divTopPos = topPos(elemRef.current);
      //const scrollPos = preScroll.current > window.scrollY;
      preScroll.current = window.scrollY;
      var oben = window.innerHeight * .2;
      var unten = window.innerHeight * 0.9;
      var maxval=(unten-oben)/2;
      var myval = maxval - Math.abs((divTopPos+(divBotPos - divTopPos)/2) - maxval);
    
      if (myval < 0) {
        setmoveit(0);
        setScale(1);
      } else {
        setmoveit(myval / 100);
        setScale(1 + myval / 2000);
      }
    
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
function action(){
  if(props.sendback){props.sendback()}
}

  return (
    <>
      <div ref={elemRef} className={classes.wrapper} id={props.id}>
        <MyImage
          alt={props.id}
          src={props.src}
          style={{
            transition: "transform 1000ms ease-out",
            //transform: `translateY(${moveit}%)`,
            transform: `scale(${scale}) translateY(${moveit}%)`,
            maxWidth: "100%",
          }}
          onClick={action}
          className={props.extraclass}
         
        />
      </div>
    </>
  );
};
export default ScrollImage;