import { useEffect, useState } from "react";
import axios from "axios";
import { logallout } from "../../../hooks/logallout";

export default function useGetList(isGirl, pageNumber, context, query, sort,isFav,hasSub) {
   const [needsPaymentAuth, setNeedsPaymentAuth] = useState(false);
console.log(query)
  if(!context&&!query){return}
//console.log("doihaveasub,", hasSub);
  let url = `https://api.deine.fans/api/timeline/user/${context.userID?context.userID:""}?authToken=${context.token?context.token:""}&role=user&roleID=${context.userID}&startindex=${pageNumber}`;
  if (isGirl) {
    url = `https://api.deine.fans/api/timeline/producer/${isGirl}?authToken=${
      context.token
    }&role=user&roleID=${
      context.userID ? context.userID : ""
    }&startindex=${pageNumber}`;
  }
  if (isFav){url = `https://api.deine.fans/api/timelinefavs/${
    context.userID ? context.userID : ""
  }?authToken=${
    context.token ? context.token : ""
  }&startTime=2012-09-21T11%3A22%3A27`;}
  if (query === "promo") {
    url = `${url}&filter=promo`;
  }   
  if (query === "video") {
    url = `${url}&filter=video`;
  }
  if (query === "image") {
    url = `${url}&filter=!video`;
  }
  if (query === "freevideo") {
    url = `${url}&filter=free_video`;
  }
  if (query === "payvideo") {
    url = `${url}&filter=pay_video`;
  }
  if (query === "freeimage") {
    url = `${url}&filter=!free_video`;
  }
  if (query === "payimage") {
    url = `${url}&filter=!pay_video`;
  }
  
  if (sort) {

    url = `${url}&sort=${sort}`;
  }
   
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [favList, setFavList] = useState([]);
  const [starred, setStarred] = useState([]);
  const [extra, setExtra] = useState("[]");
  const [needsAbo,setNeedsAbo] =useState(false);
  const [pinnedPosts,setPinnedPosts]=useState([])

  useEffect(() => {
    setList([]);
  },[]);
  useEffect(() => {
    setList([]);
  }, [query, sort]);
  useEffect(() => {

    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
//if(isGirl&&res.data.needsAbo&&!hasSub){setList(res.data);setLoading(false);  return { loading, error, list, favList, starred, hasMore,extra };}
if (res.data.needsAbo){setNeedsAbo(true);}
console.log(res.data)
if (res.data === "AuthToken expired"){logallout()}
  if (list&&list.length < 1) {
    //setNeedsPaymentAuth(res.data.needsPaymentAuth);

    setList(res.data.posts);
  } else {
    setList((prevlist) => {
      return [...new Set([...prevlist, ...res.data.posts])];
    });
  }
        setFavList(res.data.favs);
        setStarred(res.data.starred);
        setHasMore(res.data.total_posts > pageNumber + 10);
        setLoading(false);
        setPinnedPosts(res.data.pinnedPosts)
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [pageNumber, query, sort]);
  return {
    loading,
    error,
    list,
    favList,
    starred,
    hasMore,
    needsAbo,
    needsPaymentAuth,
    pinnedPosts
  };
}
