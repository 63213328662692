import React, { useContext, useState } from "react";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import useMyHttp from "../../hooks/myhttp";
import Add from "../Cards/Extras/Commercials/add";
import LoadingSpinner from "../Cards/Extras/loadingspinner";

import Shortadvertorial from "../Cards/CreatorDetails/shortadvertorial";
import classes from "./allapishort.module.css";
import { useGirlStore } from "../../hooks/useStoreData";

const AllApiShort = () => {
  const authCtx=useContext(AuthContext);
   const thegirls = useGirlStore((state) => state.thegirls);
   const setTheGirls = useGirlStore((state) => state.setGirlsList);
  const { t } = useTranslation();
  const { isLoading, data, error, sendRequest } = useMyHttp();
  function fetchGirlsHandler() {
   
    sendRequest(
      "https://api.deine.fans/api/girls?sort=lastactivity",
      "GET",
      null,
      null,
      null,
      authCtx
    );
 
  }
  useEffect(() => {
    fetchGirlsHandler();
    fetchlivegirls();
  },[]);

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
  const [render,setRender]=useState([]);
const [isShuffle,setIsShuffle]=useState(false);

useEffect(() => {
    if(data&&data.girls)
    if(isShuffle)
    {
      var zw = shuffle(data.girls);
      var zw=zw.filter(el=>el.bigImageID!==null)
      setRender(zw)
    
    }
    else{var a=data.girls.filter(el=>el.bigImageID!==null); setRender(a)}
     
     },[data]);

const [extraGirls,setExtraGirls]=useState([]);
function fetchlivegirls(){
fetch(
  "https://guppy.site/camsinfo?systemid=pussyposer&apikey=B956528A36CBC27624B047A167B6980C"
)
  .then((response) => response.json())
  .then((data) => {
  
 var z=(Array.from(data.online));

    setExtraGirls(z);

  });

}
  useEffect(() => {
    if (data && data.girls > 1) {
      setTheGirls(Array.from(data.girls));
    }
  }, [data]);
 const { i18n } = useTranslation();
 const currentLanguage = i18n.language;
   
  return (
    <div className={classes.contain}>
      <div className={classes.shortadd}>
        {render &&
          render.map((girl, index) => (
            <div key={`a${girl.pseudo}`} className={classes.shortadd}>
              <div key={`b${girl.pseudo}`}>
                {/* {index % 3 === 0 && index !== 0 && extraGirls.length > 0 && (
                  <Add
                    header={`Jetzt live im Chat: ${
                      extraGirls[index - 3].pseudo
                    }`}
                    image={extraGirls[index - 3].previewimageurlsoft}
                    addcontent={extraGirls[index - 3].vcardsoft}
                    link={extraGirls[index - 3].sedcardurl}
                  />
                )} */}
              </div>

              {index < 8 && girl.softSmallImageID ? (
                <div className={classes.shortadd}>
                  <Shortadvertorial
                    name={girl.pseudo}
                    image={
                      girl.smallImageID
                        ? girl.smallImageIDURLs.urlSmall
                        : girl.softSmallImageIDURLs.urlSmall
                    }
                    secondimage={
                      girl.smallImageID
                        ? girl.bigImageIDURLs.urlMedium
                        : girl.softBigImageIDURLs.urlBig
                    }
                    imagesoft={girl.softBigImageIDURLs.urlSmall}
                    secondimagesoft={girl.softSmallImageIDURLs.urlBig}
                    age={girl.age}
                    numberofitems="2"
                    key={girl.pseudo}
                    isFav="false"
                    id={girl.producerID}
                    // mymotto={`${t("iam")} ${girl.pseudo}. ${t("myhairis")} ${
                    //   girl.hairColor
                    // } ${t("andihave")} ${girl.cupSize} ${t("cupsize")}`}
                    mymotto={
                      currentLanguage === "de"
                        ? girl.descriptionDE || girl.steckbriefText
                        : girl.descriptionEN || girl.steckbriefText
                    }
                    mymottosoft={
                      currentLanguage === "de"
                        ? girl.descriptionSoftDE || girl.steckbriefSoftText
                        : girl.descriptionSoftEN || girl.steckbriefSoftText
                    }
                    isCurOnline={girl.isOnline}
                  />
                </div>
              ) : null}
            </div>
          ))}
        {isLoading && <LoadingSpinner />}

        {error && <p>{error}</p>}
        <div className={classes.footerelement}></div>
      </div>
    </div>
  );
};

export default AllApiShort;
