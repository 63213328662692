import { useState } from "react";
import { checkreceivedData } from "./checkReceivedData";

export async function useAddProfile(context, body) {
  
  fetch(`https://api.deine.fans/api/useraccount/profile`, {
    method: "POST",
    body: body,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    res.json().then((data) => {
      checkreceivedData(data, context);
    });
  });
}
