import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RedButton from "../Cards/WrapperComponents/redbutton";
import classes from "./firsttime.module.css";
import { Col, Row } from "reactstrap";

const PleaseLogin = () => {
  const { t } = useTranslation();

  const [width, setWidth] = useState(Number(window.innerWidth));
  const [isitMobile, setisItMobile] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    if (width <= 800) {
      setisItMobile(true);
    }
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <Row className={classes.box}>
      {!isitMobile && (
        <>
          <Col xs="6">
            <img
              src="/images/kommreingirl.png"
              className={classes.avsimage}
              alt="avsimage"
            />
          </Col>
          <Col xs="6">
            <Link to="/login">
              <h2 className={classes.header}>{t("login1")}</h2>
            </Link>
            <p className={classes.text}>{t("login2")}</p>
            <p className={classes.text}>{t("login3")}</p>
            <Link to="/login">
              <RedButton>{t("loginnow")}</RedButton>
            </Link>
          </Col>
        </>
      )}
      {isitMobile && (
        <div>
          {/* <Link to="/login">
            <h2 className={classes.header}>{t("login1")}</h2>
          </Link>
          <img
            src="/images/kommreingirl.png"
            className={classes.avsimage}
            alt="avsimagemobile"
          /> */}

          <p className={classes.text}>{t("welcome3")}</p>
          <Link to="/login">
            <RedButton>{t("loginnow")}</RedButton>
          </Link>
        </div>
      )}
    </Row>
  );
};

export default PleaseLogin;
