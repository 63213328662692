import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AuthContext from "../../../context/authcontext";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import classes from "./timelinetest.classes.css";
import TestPostCard from "../../Cards/MainPostCard/testPostCard";
import { useFullOnScreen } from "../../../hooks/useCheckFullinViewPort";
import RedButton from "../../Cards/WrapperComponents/redbutton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ShowInfoNoFollows from "./showInfoNoFollows";
import useGetList from "./useGetList";
import LoadingSpinner from "../../Cards/Extras/loadingspinner";
import MainPostCard from "../../Cards/MainPostCard/mainPostCard";
import ShowInfoAbos from "./showInfoAbos";
import ChoosepaymentOption from "../../sonstige/profileElements/choosepaymentOption";
import AddAvs from "../../Cards/Extras/ErsterPost/AddAvs";
import AVSGetData from "../avs/avsgetdata";
import AVSOverview from "../avs/avsoverview";
import PortalModal from "../../Cards/WrapperComponents/portalmodal";

const TimeLineTest = memo((props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showvideos = props.showvideos;
  const showimages = props.showimages;
  const showbylikes = props.sortbylike;
  const isFreeVideo = props.freeVideo;
  const isPayVideo = props.payVideo;
  const isFavList = props.isFavList;
  const [extraquery, setExtraQuery] = useState(false);
  const [sort, setSort] = useState(props.sort);
  const hasActiveSub = props.hasSub | false;
  const extrafilter = props.filter;
  useEffect(() => {
    if (props.filter) {
      setExtraQuery(props.filter);
    } else if (showvideos) {
      setExtraQuery("video");
    } else if (showimages) {
      setExtraQuery("image");
    } else if (isFreeVideo) {
      setExtraQuery("freevideo");
    } else if (isPayVideo) {
      setExtraQuery("payvideo");
    } else {
      setExtraQuery(false);
    }
    setSort(props.sort);
    setIndex(0);
    window.scrollTo(0, 0);
  }, [props]);
  const [isLivegirl, setIsLivegirl] = useState(props.girlid);
  const authCtx = useContext(AuthContext);
  const [index, setIndex] = useState(0);
  const {
    loading,
    error,
    list,
    favList,
    starred,
    hasMore,
    needsAbo,
    needsPaymentAuth,
    pinnedPosts,
  } = useGetList(
    isLivegirl,
    index,
    authCtx,
    extraquery,
    sort,
    isFavList,
    hasActiveSub
  );

  const observer = useRef();

  const lastListEmlementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setIndex((previndex) => previndex + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function gotbackbuy() {
    console.log("some");
  }
  const { t } = useTranslation();

  function loadmore() {
    if (hasMore) {
      setIndex((previndex) => previndex + 10);
    }
  }
  const [showTimeLine, setShowTimeLine] = useState(true);
  const [onAVS, setOnAVS] = useState(true);
  function toggleAVS() {
    setOnAVS(!onAVS);
  }
  return (
    <div className={classes.wrapper} key={props}>
      {pinnedPosts && isLivegirl
        ? pinnedPosts.map((elem) => (
            <div key={elem.index}>
              <MainPostCard
                post={elem}
                id={elem.index}
                context={authCtx}
                sendbackby={gotbackbuy}
                hasSub={props.hasSub}
                messengerPrice={props.messengerPrice}
                isPinned={true}
              />
            </div>
          ))
        : null}
      {list && list.length < 1 && !isLivegirl ? <ShowInfoNoFollows /> : null}
      {needsPaymentAuth ? <ChoosepaymentOption timeline={list.pseudo} /> : null}
      {needsAbo === true && !props.hasSub ? (
        <ShowInfoAbos girlinfo={list.pseudo} />
      ) : null}
      {!props.dontshow && authCtx.isLoggedIn && !authCtx.isAVS && onAVS ? (
        <AVSOverview sendback={toggleAVS} />
      ) : null}
      {authCtx.isLoggedIn && !authCtx.isAVS && !onAVS ? (
        <div onClick={toggleAVS}>
          <AddAvs />
        </div>
      ) : null}
      {
        list &&
        list.length > 0 &&
        showTimeLine &&
        list.map((elem) =>
          elem.pinned ? null : (
            <div key={elem.index}>
              <MainPostCard
                post={elem}
                id={elem.index}
                context={authCtx}
                sendbackby={gotbackbuy}
                hasSub={props.hasSub}
                messengerPrice={props.messengerPrice}
              />
            </div>
          )
        )}
      {authCtx.isAVS && loading && <LoadingSpinner />}
      {authCtx.isAVS && list && list.length > 0 && hasMore && (
        <div onClick={loadmore} ref={lastListEmlementRef}>
          <RedButton className={classes.loadbutton}>{t("loadmore")}</RedButton>
        </div>
      )}
    </div>
  );
});

export default TimeLineTest;
