import React, { Fragment, memo, useContext, useEffect, useState } from 'react';
import classes from "./simplepaymentclasses.module.css";
import AuthContext from '../../../../context/authcontext';

const GetFanDollarNumber = memo((props) =>{
const [hasChosen,setHasChosen]=useState(props.value);
let vat=0.19;
function sendback(value){
setHasChosen(value);
props.sendback(value);
  console.log("numbergetter", value);
}
 useEffect(() => {
   if (props.value) {
     setHasChosen(props.value);
   }
 }, [props]);
 const authCtx=useContext(AuthContext)
  return (
    <Fragment>
      {!hasChosen ? (
        <div className={classes.flexcontainer}>
          {props.includezero ? (
            <div
              className={`${classes.buyelement} ${
                props.includezero ? classes.highlight : ""
              }`}
              onClick={() => {
                sendback(0);
              }}
            >
              <h5 className={classes.num}>0</h5>
              <img
                src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/f_dollar_02.png"
                alt="FanDollar"
                className={classes.fandollarimg}
              />
            </div>
          ) : (
            null
          )}
          <div
            className={`${classes.buyelement} ${
              props.firtTime ? classes.highlight : ""
            }`}
            onClick={() => {
              sendback(15);
            }}
          >
            <h5 className={classes.num}>15</h5>
            <img
              src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/f_dollar_02.png"
              alt="FanDollar"
              className={classes.fandollarimg}
            />
          </div>
          <div
            className={classes.buyelement}
            onClick={() => {
              sendback(30);
            }}
          >
            <h5 className={classes.num}>30</h5>
            <img
              src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/f_dollar_02.png"
              alt="FanDollar"
              className={classes.fandollarimg}
            />
          </div>
          <div
            className={classes.buyelement}
            onClick={() => {
              sendback(50);
            }}
          >
            <h5 className={classes.num}>50</h5>
            <img
              src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/f_dollar_02.png"
              alt="FanDollar"
              className={classes.fandollarimg}
            />
          </div>
          <div
            className={classes.buyelement}
            onClick={() => {
              sendback(100);
            }}
          >
            <h5 className={classes.num}>100</h5>
            <img
              src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/f_dollar_02.png"
              alt="FanDollar"
              className={classes.fandollarimg}
            />
          </div>
        </div>
      ) : (
        <div
          className={classes.flexcon}
          onClick={() => {
            sendback(false);
          }}
        >
          <div className={classes.buyelementbig}>
            <h5 className={classes.numbig}>{hasChosen}</h5>
            <img
              src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/f_dollar_02.png"
              alt="FanDollar"
              className={classes.fandollarimgbig}
            />
          </div>
          {hasChosen} FanDollar zum Preis von {hasChosen.toFixed(2)}
          {props.currency
            ? props.currency
            : authCtx.country === "CHF"
            ? " CHF"
            : " €"}{" "}
          inklusive {(hasChosen * vat).toFixed(2)}{" "}
          {props.currency
            ? props.currency
            : authCtx.country === "CHF"
            ? "CHF"
            : "€"}{" "}
          Mehrwertsteuer kaufen.
        </div>
      )}
      <hr />
    </Fragment>
  );
})

export default GetFanDollarNumber
