import { checkreceivedData } from "./checkReceivedData";

export async function useSessionCount(context,landingPage) {
  const params = new URLSearchParams(window.location.search);

  let adr = "";
  let token = "";
  let extraID = "";

  for (const param of params) {
    if(param[0] !== null) {
      let myParamName = new String(param[0]).toLowerCase();
      if (myParamName === "adr") {
        adr = param[1];
      }
      if (myParamName === "token") {
        token = param[1];
      }
      if (myParamName === "extraid") {
        extraID = param[1];
      }
    }
  }

  let sessionGUID = "";
  if(localStorage.getItem("sessionGUID") !== null) {
    sessionGUID = localStorage.getItem("sessionGUID");
  }

  fetch(`https://api.deine.fans/api/sessioncount`, {
  method: "PUT",
  body: JSON.stringify({
    token: adr,
    adr: token,
    extraID: extraID,
    landingPage: landingPage,
    userID: context.userID,
    sessionGUID: sessionGUID,
  }),
  headers: {
    "Content-Type": "application/json",
  },
  }).then((res) => {
    res.json().then((data) => {
      if(data.sessionGUID) {
        localStorage.setItem("sessionGUID", data.sessionGUID);
      }
    });
  });
}
