import React, { useState } from "react";
import classes from "./loadingspinner.module.css";
import { useIsApple } from "../../../hooks/useIsApple";

const LoadingSpinner=(props)=>{

const [isBig]=useState(!props.isSmall)
const isApple=useIsApple();
    return (
      <div
        fetchpriority="high"
        className={`${classes.wrapper} ${
          isBig ? classes.wrapbig : classes.wrapsmall
        }`}
      >
        <div
          className={`${isApple ? classes.myobjectApple : classes.myobject} ${
            isBig ? classes.sizebig : classes.sizesmall
          }`}
        ></div>
        
          <div
            className={`${
              isApple ? classes.myobjectApple3 : classes.myobject3
            } ${isBig ? classes.sizebig : classes.sizesmall}`}
          ></div>
     
      </div>
    );
}

export default LoadingSpinner;