import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import classes from "./addpaymentmethod.module.css";

const Mandat = (props) => {
const { mandatID, mandatDate, glaeubigerID, paymentReceiver, bankName } =
  props.mandat;
const [isConsent,setIsConsent] =useState(false);

function toggleconsent(){
    setIsConsent(!isConsent)
}

function weitergehts(){
    props.getconsent(isConsent);
}
  
const {t}=useTranslation();
    return (
      <div
        className={
          props.context.darkMode
            ? classes.mandatskastendm
            : classes.mandatskasten
        }
      >
        <div>
          {props.extra ? (
            <img
              src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo_${props.extra}.png`}
              alt="icon"
              className={classes.icon}
            />
          ) : null}
          <h5>{t("SEPA_Lastschriftsmandat")}</h5>
        </div>
        <p>
          {t("Mandatsreferenz")}: {mandatID}
        </p>
        <p>
          {t("date")}: {mandatDate}
        </p>
        <p></p>
        <p>
          {t("Gläubiger-Identifikationsnummer")}: {glaeubigerID}
        </p>
        <p>
          {t("Zahlungsempfänger")}: {paymentReceiver}
        </p>
        <h5 className={classes.consent}>
          <label>
            <input
              className={classes.checkbox}
              type="checkbox"
              onClick={toggleconsent}
            />
            {!isConsent ? (
              <>
                {t("ermächtigung1")} {bankName}
                {t("ermächtigung2")}
                <br />
                {t("ermächtigung3")}
              </>
            ) : (
              <>{t("zustimmunngerteilt")}</>
            )}
          </label>
        </h5>

        <br />
        <br />
        <button
          className={classes.myButton}
          onClick={() => weitergehts(mandatID)}
        >
          {t("agreed")}
        </button>
      </div>
    );
}
export default Mandat;
