import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Report from '../Extras/reporting/report';
import PortalModal from '../WrapperComponents/portalmodal';
import classes from "./testpostcard.module.css"
const PostCardReport = memo((props) => {

   const [showReport, setShowReport] = useState(false);
   const toggleReport = () => setShowReport((prevState) => !prevState);
   const {t}=useTranslation();

    return (
      <div>
        <div onClick={toggleReport}>
          <button className={classes.reportbtn}>{t("report")}</button>
        </div>
        {showReport && (
          <PortalModal onclose={toggleReport}>
            <Report
              type="POST"
              guid={props.guid}
              context={props.context}
              closewindow={toggleReport}
            />
          </PortalModal>
        )}
      </div>
    );
})
export default PostCardReport;
