import { checkreceivedData } from "./checkReceivedData";

export async function endabo (subscriptionID,context){


   fetch("https://api.deine.fans/api/subscriptions/user/cancel", {
     method: "DELETE",
     body: JSON.stringify({
       userID: context.userID,
       subscriptionID: subscriptionID,
       authToken: context.token,
     }),
     headers: {
       "Content-Type": "application/json",
     },
   }).then((res) => {
     res.json().then((data) => {
      checkreceivedData(data,context,"endabo,cancelabo");
      
     });
   });
}

export async function removeabo(producerID, context) {
  fetch("https://api.deine.fans/api/subscriptions/user/delete", {
    method: "DELETE",
    body: JSON.stringify({
      userID: context.userID,
      producerID: producerID,
      authToken: context.token,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    res.json().then((data) => {
      checkreceivedData(data, context, "endabo,deleteabo");
      console.log(data)
    });
  });
}



export async function redoabo(context, subscriptionID, newPaymentID) {
 
         fetch("https://api.deine.fans/api/subscriptions/user/changeauth", {
           method: "POST",
           body: JSON.stringify({
             userID: context.userID,
             newPaymentAuthorizationID: newPaymentID,
             subscriptionID: subscriptionID,
             authToken: context.token,
           }),
           headers: {
             "Content-Type": "application/json",
           },
         }).then((res) => {
           res.json().then((data) => {
             checkreceivedData(data, context,"endabo,changeauth");
             console.log(data);
           });
         });
       }