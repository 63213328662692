import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../context/authcontext";
import PostCardWrapper from "../../MainPostCard/postCardWrapper";
import { PostCardHeader } from "../../MainPostCard/postCardHeader";
import AddAvs from "./AddAvs";
import AddPremium from "./AddPremium";
import classes from "./ersterPost.module.css";
import AddFavs from "./AddFavs";
import { checkreceivedData } from "../../../../hooks/checkReceivedData";
import SendVoucherCode from "../../../sonstige/formulare/sendVoucherCode";
import Aktionswrapper from "../../userUIElements/aktionswrapper";

function ErsterPost(props) {
  const { t } = useTranslation();
  const image =
    "https://d39j9qa9t0x6xj.cloudfront.net/dekor/allgirls.jpg";

  const firstPost = [
    {
      publishTime: "CurDate",
      freeMedia: [],
      msgTitle: t("welcome1"),
      msgText: `${t("welcome2")} ${t("welcomeadd")}`,
      payMedia: [],
      smallImageIDURLs: {
        urlMedium:
          "/https://d39j9qa9t0x6xj.cloudfront.net/dekor/girlsgirlsgirls.jpg",
        urlSmall:
          "https://d39j9qa9t0x6xj.cloudfront.net/dekor/kreisgirl.jpg",
      },
    },
  ];
  const authCtx = useContext(AuthContext);
  const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
  useEffect(() => {
    setDarkMode(authCtx.darkMode);
  }, [authCtx]);
  function loadData() {
    fetch(
      `https://api.deine.fans/api/favs/${encodeURIComponent(
        authCtx.userID
      )}?authToken=${encodeURIComponent(authCtx.jwtAuthToken)}`
    ).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          checkreceivedData(data, authCtx, "favgirls");
          if (data.favorites) {
            if (data.favorites.length > 0) {
             
              setHasFavs(true);
            }
          }
        });
      }
    });
  }
  const [hasFavs,setHasFavs]=useState(false);
  useEffect(()=>{loadData()},[]);
  const uppercaseWords = (str) =>
    str.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());
  return (
    <Fragment>
      <div className={classes.bouncebottom}>
        <PostCardWrapper
          noextra={true}
          image={image}
          newborder={true}
          darkMode={isDarkmode}
        >
          <div className={classes.higherup}>
            <PostCardHeader
              pseudo={uppercaseWords(window.location.hostname)}
              liked="0"
              image={image}
              post={firstPost[0]}
              noextra={true}
              isDarkmode={isDarkmode}
            />
          </div>
          <div className={classes.wrapper}>
            <h5 className={classes.headline}>
              {t("welcome1")}
              <span className={classes.hostname}>
                {window.location.hostname}
              </span>
            </h5>
            <p>
              {t("welcome2")} {t("welcomeadd")}
            </p>
            {authCtx.country === "DE" ? (
              <AddAvs isdone={authCtx.isAVS} context={authCtx} />
            ) : authCtx.country === "undefined" ? (
              <AddAvs isdone={authCtx.isAVS} context={authCtx} />
            ) : typeof authCtx.country === "undefined" ? (
              <AddAvs isdone={authCtx.isAVS} context={authCtx} />
            ) : null}
            {authCtx.country === "CH" ? null : (
              <AddPremium isdone={authCtx.isPremium} />
            )}
            <AddFavs isdone={hasFavs} />
          </div>
          {props.isAnon ? <SendVoucherCode /> : null}
        </PostCardWrapper>
      </div>
      <Aktionswrapper>
        <SendVoucherCode phone={true} />
      </Aktionswrapper>
    </Fragment>
  );
}

export default ErsterPost;
