import React, { Fragment, memo, useContext, useEffect, useState } from "react";
import classes from "./modeladvertorial.module.css";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/authcontext";
import { Col, Row } from "reactstrap";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import { t } from "i18next";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import WriteMessage from "../messenger/writeMessage";
import PortalModal from "../WrapperComponents/portalmodal";
import SimplePaymentBaseModal from "../../payment/simplepayment/simplepaymentBaseModal";
import MyImage from "../../sonstige/specialcomponents/images";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { checkstatus } from "../../../hooks/checkstatus";
import PostCounter from "../Extras/postcounter";
import PortalFeedbackModal from "../WrapperComponents/portalfeedbackmodal";
import RedButton from "../WrapperComponents/redbutton";
import { Analytics } from "aws-amplify";
import PleaseLoginShort from "../../sonstige/pleaseloginshort";
import BlueButton from "../WrapperComponents/bluebutton";
import { useTranslation } from "react-i18next";
import ChoosepaymentOption from "../../sonstige/profileElements/choosepaymentOption";

function NewlineText(props) {

 
  const text = props.text;
  let newText;
  if (text && text.length > 0) {
    newText = text.split("\n").map((str, index) => (
      <span key={index}>
        <br />
        {str}
      </span>
    ));
  }

  return newText;
}

const Modeladvertorial = memo((props) => {
    const date = new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const [isBirthday, setIsBirthday] = useState(false);
  useEffect(() => {
    var a = day + "." + month + ".";

    if (props.extra && props.extra.birthday === a) {
      setIsBirthday(true);
    }
  }, []);

  const history = useNavigate();
  const authCtx = useContext(AuthContext);
  const [currentFav, setCurrentFav] = useState(props.isFav);
  const modelname = props.name;
  const firstName = findUppercase(modelname).first;
  const secondName = findUppercase(modelname).second;
  const [showAll, setShowAll] = useState(!authCtx.isLoggedIn);
  function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }
  let useSoft=props.image.search("/.")
if(useSoft===-1){useSoft=false}
  function findUppercase(word) {
    var str = word;
    var sL = str.length;
    var i = 0;
    var myword;
    for (; i < sL; i++) {
      if (str.charAt(i) === str.charAt(i).toUpperCase()) {
        myword = str.charAt(i);
      }
    }
    var checkPos1 = word.search(myword);
    var checkPos2 = getPosition(word, myword, 2);
    if (word&&checkPos1 === 0 && checkPos2 !== word.length) {
      checkPos1 = checkPos2;
    }
    const splitOn = (slicable, ...indices) =>
      [0, ...indices].map((n, i, m) => slicable.slice(n, m[i + 1]));

    var newEl = splitOn(word, checkPos1);

    return { first: newEl[0], second: newEl[1] };
  }
  const [showmoretags, setshowmoretags] = useState(false);
  const toggleFavHandler = () => {
    if (authCtx.isLoggedIn === false) {
      history("/login");
    } else {
      fetchfavs();
    }
    Analytics.record({
      name: "clickedFav",
      attributes: {
        userID: authCtx.userID,
        producerID: props.id,
        modelname: modelname,
      },
    });
    setCurrentFav(!currentFav);
    //props.sendclick();
  };

  function fetchfavs() {
    fetch(
      `https://api.deine.fans/api/favs/?userID=${encodeURIComponent(
        authCtx.userID
      )}&producerID=${encodeURIComponent(
        props.id
      )}&authToken=${encodeURIComponent(authCtx.jwtAuthToken)}`
    ).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          checkreceivedData(data, authCtx, "ModelAdvertorial");
          if (data.hasFavorite === false) {
            fetch(`https://api.deine.fans/api/favs`, {
              method: "PUT",
              body: JSON.stringify({
                userID: authCtx.userID,
                producerID: props.id,
                authToken: authCtx.jwtAuthToken,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }).then((res) => {
              res.json().then((data) => {});
            });
          } else {
            fetch(`https://api.deine.fans/api/favs`, {
              method: "DELETE",
              body: JSON.stringify({
                userID: authCtx.userID,
                producerID: props.id,
                authToken: authCtx.jwtAuthToken,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            });
          }
        });
      }
    });
  }
  function showmemoretags() {
    setshowmoretags(true);
  }
  function showmelesstags() {
    setshowmoretags(false);
  }

  const [openotp, setopenotp] = useState(false);
  const [message, setMessage] = useState(null);
  function showotp() {
    Analytics.record({
      name: "clickedTrinkgeld",
      attributes: {
        userID: authCtx.userID,
        producerID: props.id,
        modelname: modelname,
      },
    });
    setopenotp(!openotp);
  }
  const [writeMail, setWriteMail] = useState(false);
  function showmail() {
    Analytics.record({
      name: "clickedWriteMail",
      attributes: {
        userID: authCtx.userID,
        producerID: props.id,
        modelname: modelname,
      },
    });
    setWriteMail(!writeMail);
  }
  const [onmyheart, setonheart] = useState(false);

  function generateHeart() {
    setTimeout(() => setMessage(null), 1500);
    setTimeout(() => setonheart(true), 1500);

    setTimeout(() => setonheart(false), 2500);
  }
  function gotbackdata(props) {
    showotp();
    if (!props) {
      return;
    }
    if (props && props.preis) {
      generateHeart();
      checkstatus(authCtx);
    }
  }
  const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
  useEffect(() => {
    setDarkMode(authCtx.darkMode);
  }, [authCtx]);

  function setMyContent(value) {
    if (props.filterlist) {
      props.filterlist(value);
    }
  }
  const { i18n } = useTranslation();
  
  const isMobile = useCheckMobileScreen();
  const [showdreiStriche, setShowDreiStriche] = useState(false);
  function togglemenu() {
    setTimeout(() => {
      setShowDreiStriche(!showdreiStriche);
    }, 50);
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    setTimeout(() => {
      setDropdownOpen(!dropdownOpen);
    }, 50);
  };
  const [makeAVS, setMakeAVS] = useState(false);
  function showAVS() {
    setMakeAVS(!makeAVS);
  }
  const [makePremium, setMakePremium] = useState(false);
  function showAddMoney() {
    setMakePremium(!makePremium);
  }

  const navigate = useNavigate();
  const [loginmodal,setloginmodal]=useState(false);
function openlogin(){
setloginmodal(!loginmodal);
}
  return (
    <>
      <div className={props.dontsnap ? classes.nosnappad : classes.pad}>
        <div className={isDarkmode ? classes.userinfodm : classes.userinfo}>
          {!isMobile && (
            <div
              className={classes.user}
              onClick={
                authCtx.isLoggedIn
                  ? () => {}
                  : props.detail
                  ? () => {}
                  : openlogin
              }
            >
              <Link to={`/${modelname}`}>
                <MyImage
                  src={
                    authCtx.isAVS
                      ? useSoft
                        ? props.imagesoft
                        : props.image
                      : props.imagesoft
                  }
                  alt={modelname}
                  id={`modelcard ${modelname}`}
                />
              </Link>
              {props.isCurOnline ? (
                <div className={classes.online}></div>
              ) : null}
            </div>
          )}

          {/* {isMobile && props.isFav !== "unimportant" ? (
            <div className={classes.likeheart} onClick={toggleFavHandler}> 
              <img src="/images/icons/like.png" alt="herz" className={currentFav ?null:classes.grayscale}/>
            </div>
          ):null} */}
          <div
            id={`userinfoheader ${modelname}`}
            className={
              !isMobile ? classes.userinfoheader : classes.userinfoheadermobile
            }
            onClick={
              authCtx.isLoggedIn
                ? () => {}
                : !props.detail
                ? () => {}
                : openlogin
            }
          >
            <Link to={`/${modelname}`}>
              <MyImage
                src={
                  authCtx.isAVS
                    ? useSoft
                      ? props.secondimagesoft
                      : props.secondimage
                    : props.secondimagesoft
                }
                alt={modelname}
                id={`modelcard_zweitesBild ${modelname}`}
              />
            </Link>
          </div>

          <div
            className={
              !isMobile ? classes.userinfobody : classes.userinfobodymobile
            }
            id={`modelcard userinfobody ${modelname}`}
          >
            {authCtx.isAVS ? (
              <div className={!isMobile ? classes.tag : classes.tagmobile}>
                {props.hashtag &&
                  props.hashtag.map((hashtag, index) =>
                    !showmoretags && index < 3 ? (
                      <div key={`${hashtag}${index}`}>
                        <Link to={`/models/${hashtag.replace("#", "")}`}>
                          <span
                            id={`hashtag ${hashtag}`}
                            className={
                              index % 3 === 0
                                ? classes.tagcolor3
                                : index % 2 === 0
                                ? classes.tagcolor2
                                : classes.tagcolor1
                            }
                          >
                            {hashtag}
                          </span>
                        </Link>
                        <span> </span>
                      </div>
                    ) : showmoretags ? (
                      <div key={`${hashtag}${index}`}>
                        <Link to={`/models/${hashtag.replace("#", "")}`}>
                          <span
                            id={`hashtag ${hashtag}`}
                            className={
                              index % 3 === 0
                                ? classes.tagcolor3
                                : index % 2 === 0
                                ? classes.tagcolor2
                                : classes.tagcolor1
                            }
                          >
                            {hashtag}
                          </span>
                        </Link>
                        <span> </span>
                      </div>
                    ) : null
                  )}
                {props.hashtag && props.hashtag.length > 3 ? (
                  <div
                    key="extrabtn"
                    onClick={!showmoretags ? showmemoretags : showmelesstags}
                    id={"hashtags-more"}
                  >
                    <span className={classes.tagcolor3}>
                      {!showmoretags ? (
                        <span>&#x21d2;</span>
                      ) : (
                        <span>&#x21d0;</span>
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
            ) : (
              <div className={!isMobile ? classes.tag : classes.tagmobile}>
                {props.hashtag &&
                  props.hashtag.map((hashtag, index) =>
                    !showmoretags && index < 3 ? (
                      <div key={`${hashtag}${index}`}>
                        <Link to={`/models/${hashtag.replace("#", "")}`}>
                          <span
                            id={`hashtag ${hashtag}`}
                            className={
                              index % 3 === 0
                                ? classes.tagcolor3
                                : index % 2 === 0
                                ? classes.tagcolor2
                                : classes.tagcolor1
                            }
                          >
                            {hashtag}
                          </span>
                        </Link>
                        <span> </span>
                      </div>
                    ) : showmoretags ? (
                      <div key={`${hashtag}${index}`}>
                        <Link to={`/models/${hashtag.replace("#", "")}`}>
                          <span
                            id={`hashtag ${hashtag}`}
                            className={
                              index % 3 === 0
                                ? classes.tagcolor3
                                : index % 2 === 0
                                ? classes.tagcolor2
                                : classes.tagcolor1
                            }
                          >
                            {hashtag}
                          </span>
                        </Link>
                        <span> </span>
                      </div>
                    ) : null
                  )}
                {props.hashtag && props.hashtag.length > 3 ? (
                  <div
                    key="extrabtn"
                    onClick={!showmoretags ? showmemoretags : showmelesstags}
                    id={`more hashtags`}
                  >
                    <span className={classes.tagcolor3}>
                      {!showmoretags ? (
                        <span>&#x21d2;</span>
                      ) : (
                        <span>&#x21d0;</span>
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
            )}
            {/* {!showmoretags && (
              <div className={!isMobile ? classes.tag : classes.tagmobile}>
                {props.hashtag && props.hashtag[0] && (
                  <Link to={`/models/${props.hashtag[0].replace("#", "")}`}>
                    <span className={classes.tagcolor1}>
                      {props.hashtag[0]}
                    </span>
                  </Link>
                )}

                {props.hashtag && props.hashtag[1] && (
                  <Link to={`/models/${props.hashtag[1].replace("#", "")}`}>
                    <span className={classes.tagcolor2}>
                      {props.hashtag[1]}
                    </span>
                  </Link>
                )}

                {props.hashtag && props.hashtag[2] && (
                  <Link to={`/models/${props.hashtag[2].replace("#", "")}`}>
                    <span className={classes.tagcolor3}>
                      {props.hashtag[2]}
                    </span>
                  </Link>
                )}
                {props.hashtag && props.hashtag[3] && (
                  <h5
                    onClick={showmemoretags}
                    className={!isMobile ? classes.plus : classes.plusmobile}
                  >
                    +
                  </h5>
                )}
              </div>
            )} */}
            {/* {showmoretags && (
              <div className={!isMobile ? classes.tag : classes.tagmobile}>
                {props.hashtag &&
                  props.hashtag.map((hashtag) => (
                    <div key={hashtag}>
                      <Link to={`/models/${hashtag.replace("#", "")}`}>
                        <span className={classes.tagcolor1}>{hashtag}</span>
                      </Link>
                      <span> </span>
                    </div>
                  ))}
                <h5
                  onClick={showmelesstags}
                  className={!isMobile ? classes.plus : classes.plusmobile}
                >
                  -
                </h5>
              </div>
            )} */}
            <Row className={classes.fullwidth}>
              <Col xs="6" md="6">
                {isMobile && (
                  <div className={classes.mobileName}>
                    {props.isCurOnline ? (
                      <div
                        className={
                          isMobile ? classes.online2mob : classes.online2
                        }
                      ></div>
                    ) : null}
                    <h4
                      className={
                        isDarkmode
                          ? classes.mobileNameLinkdm
                          : classes.mobileNameLink
                      }
                    >
                      <Link to={`/${modelname}`}>
                        <span
                          className={
                            props.isCurOnline
                              ? classes.firstNameright
                              : classes.firstName
                          }
                        >
                          {firstName}
                        </span>
                        <span className={classes.secondName}>{secondName}</span>
                      </Link>{" "}
                      {!props.age && props.isCurOnline ? (
                        <p className={classes.isCurOnlineMobBig}>
                          {t("isCurOnline")}
                        </p>
                      ) : null}
                    </h4>
                    {isBirthday && <span>{t("hatgeburtstag")}</span>}
                    {props.age && (
                      <small
                        className={
                          isDarkmode ? classes.agemobidm : classes.agemobi
                        }
                      >
                        {" "}
                        {props.age} Jahre{" "}
                        {props.age && props.isCurOnline ? (
                          <span className={classes.isCurOnlineMob}>
                            {t("isCurOnline")}
                          </span>
                        ) : null}
                      </small>
                    )}
                  </div>
                )}

                {!isMobile && ( // Ältere Variante der Mobilen Ansicht
                  <h4 className={classes.hideiftoobig}>
                    <Link to={`/${modelname}`}>
                      <span className={classes.firstNamebig}>{firstName}</span>
                      <span className={classes.secondNamebig}>
                        {secondName}
                      </span>
                    </Link>
                    {isBirthday && (
                      <small
                        className={isDarkmode ? classes.jahredm : classes.jahre}
                      >
                        <br />
                        {t("hatgeburtstag")}
                        {props.age ? (
                          <span>
                            {t("undis")} {props.age} {t("jahre")}
                          </span>
                        ) : null}
                      </small>
                    )}
                    {props.age && !isBirthday && (
                      <small
                        className={isDarkmode ? classes.jahredm : classes.jahre}
                      >
                        {" "}
                        / {props.age} Jahre
                      </small>
                    )}
                    {props.isCurOnline ? (
                      <Fragment>
                        <br />
                        <span className={classes.isCurOnline}>
                          {t("iscuronline")}
                        </span>
                      </Fragment>
                    ) : null}
                  </h4>
                )}
              </Col>
              <Col
                xs="6"
                md="6"
                className={
                  isMobile ? classes.itemsrightmobi : classes.itemsright
                }
              >
                <div className={classes.wrapthings}>
                  {authCtx.isLoggedIn && (
                    <div
                      onClick={
                        authCtx.isPremium
                          ? showmail
                          : authCtx.fanDollar > props.data
                          ? props.data.messengerPrice
                          : 1
                          ? showmail
                          : props.data && props.data.messengerPrice > 0
                          ? showAddMoney
                          : showmail
                      }
                    >
                      <img
                        className={
                          isDarkmode ? classes.faviconwhite : classes.favicon
                        }
                        alt="Mail"
                        //src="/images/icons/profilpost_mail.png"
                        src="/images/icons_m/messenger.png"
                      />
                    </div>
                  )}

                  {onmyheart && (
                    <div className={classes.heart} id="heart2">
                      <span className={classes.heart}></span>
                      <span className={classes.heart}></span>
                    </div>
                  )}

                  <div onClick={authCtx.isLoggedIn ? showotp : openlogin}>
                    <img
                      className={
                        isDarkmode ? classes.faviconwhite : classes.favicon
                      }
                      alt="trinkgeld"
                      //src="/images/icons/profilpost_payment.png"
                      src="/images/icons_m/bezahlen_trinkgeld_rot.png"
                    />
                  </div>

                  {authCtx.isLoggedIn && props.isFav !== "unimportant" ? (
                    <div onClick={toggleFavHandler}>
                      {currentFav ? (
                        <img
                          title={t("liketofollow")}
                          className={classes.faviconnorm}
                          alt="unvaf"
                          //src="images/icons/profilpost_like.png"
                          src="/images/icons_m/like.png"
                        />
                      ) : (
                        <img
                          title={t("liketofollow")}
                          className={`${
                            isDarkmode
                              ? classes.faviconwhite
                              : classes.faviconnorm
                          } ${classes.grayscale}`}
                          alt="fav"
                          //src="/images/icons/profilpost_like_off.png"
                          src="/images/icons_m/like.png"
                        />
                      )}
                    </div>
                  ) : authCtx.isLoggedIn ? null : (
                    <img
                      title={t("liketofollow")}
                      className={classes.faviconnorm}
                      alt="unvaf"
                      //src="images/icons/profilpost_like.png"
                      src="/images/icons_m/like.png"
                      onClick={openlogin}
                    />
                  )}
                  {props.isFav !== "unimportant" && (
                    <div className={classes.dropdownelement}>
                      {props.isFav !== "unimportant" && authCtx.isLoggedIn && (
                        <Dropdown
                          isOpen={dropdownOpen}
                          toggle={toggle}
                          direction="left"
                          className={classes.dropdownmenu}
                        >
                          <DropdownToggle
                            data-toggle="dropdown"
                            tag="span"
                            direction="down"
                            className={classes.mymenu}
                          >
                            <img
                              src="/images/icons_m/optionen.png"
                              alt="dreiStriche"
                              className={
                                isDarkmode
                                  ? classes.faviconwhite
                                  : classes.favicon
                              }
                            />
                          </DropdownToggle>
                          <DropdownMenu>
                            <div
                              onClick={() => {
                                toggle();
                                setMyContent("all");
                              }}
                              className={classes.dropdown}
                            >
                              <img
                                src="/images/icons/beides.png"
                                alt="Nur Videos"
                              />
                              {t("allposts")}
                            </div>
                            <div
                              onClick={() => {
                                toggle();
                                setMyContent("image");
                              }}
                              className={classes.dropdown}
                            >
                              <img
                                src="/images/icons/nur_bilder.png"
                                alt="Nur Bilder"
                              />
                              {t("postswithimages")}
                            </div>
                            <div
                              onClick={() => {
                                toggle();
                                setMyContent("video");
                              }}
                              className={classes.dropdown}
                            >
                              <img
                                src="/images/icons/nur_video.png"
                                alt="Nur Videos"
                              />
                              {t("postswithvideos")}
                            </div>
                            {/* <div
                              onClick={() => {
                                toggle();
                                setMyContent("sortbylike");
                              }}
                              className={classes.dropdown}
                            >
                              <img
                                src="/images/icons/like.png"
                                alt="Sortiert nach Likes"
                              />
                              {t("orderedbylikes")}
                            </div> */}
                            <div
                              onClick={() => {
                                toggle();
                                setMyContent("freevideo");
                              }}
                              className={classes.dropdown}
                            >
                              <img
                                src="/images/icons/nur_video.png"
                                alt="Nur Videos"
                              />
                              {t("postswithfreevideos")}
                            </div>
                            <div
                              onClick={() => {
                                toggle();
                                setMyContent("payvideo");
                              }}
                              className={classes.dropdown}
                            >
                              <img
                                src="/images/icons/nur_video.png"
                                alt="Nur Videos"
                              />
                              {t("postswithpayvideos")}
                            </div>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Col xs="12" className={classes.makehigher}>
              <p
                className={
                  props.isFav === "unimportant"
                    ? classes.desctextshort
                    : !showAll
                    ? classes.desctextshort
                    : classes.desctext
                }
              >
                <NewlineText
                  text={
                    authCtx.isAVS
                      ? props.mymotto
                      : i18n.language === "de"
                      ? props.data
                        ? props.data.descriptionSoftDE
                        : ""
                      : props.data
                      ? props.data.descriptionSoftDE
                      : ""
                  }
                />
              </p>
              {showAll ? <hr /> : null}

              {props && props.extra && props.extra.location && showAll ? (
                <p className={classes.linkelement}>
                  {t("location")}: {props.extra.location}
                </p>
              ) : null}
            </Col>
            {props && props.isFav !== "unimportant" && showAll ? (
              <Col xs="12" className={classes.linkelements}>
                {props && props.extra && props.extra.website ? (
                  <p className={classes.linkelement2}>
                    <a
                      className={
                        props.extra.websiteTitle.length > 20
                          ? classes.smaller
                          : classes.longer
                      }
                      href={
                        props.extra.website.search("ttp") > 0
                          ? props.extra.website
                          : `https://${props.extra.website}`
                      }
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      {props.extra.websiteTitle
                        ? props.extra.websiteTitle
                        : `${t("website")}: ${props.extra.website}`}
                    </a>
                  </p>
                ) : null}

                {props && props.extra && props.extra.additionalLink && (
                  <p className={classes.linkelement2}>
                    <a
                      href={
                        props.extra.additionalLink.search("ttp") > 0
                          ? props.extra.additionalLink
                          : `https://${props.extra.additionalLink}`
                      }
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      className={
                        props.extra.additionalLinkTitle.length > 20
                          ? classes.smaller
                          : classes.longer
                      }
                    >
                      {props.extra.additionalLinkTitle &&
                      props.extra.additionalLinkTitle.length > 0
                        ? props.extra.additionalLinkTitle
                        : props.extra.additionalLink}
                    </a>
                  </p>
                )}

                {props && props.extra && props.extra.additionalLink2 ? (
                  <p className={classes.linkelement2}>
                    <a
                      className={
                        props.extra.additionalLinkTitle2.length > 20
                          ? classes.smaller
                          : classes.longer
                      }
                      href={
                        props.extra.additionalLink2.search("ttp") > 0
                          ? props.extra.additionalLink2
                          : `https://${props.extra.additionalLink2}`
                      }
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      {props.extra.additionalLinkTitle2
                        ? props.extra.additionalLinkTitle2
                        : props.extra.additionalLink2}
                    </a>
                  </p>
                ) : null}
                <hr />
              </Col>
            ) : null}
            {props && props.extra ? (
              props.extra.location ||
              props.extra.additionalLink ||
              props.extra.additionalLink2 ||
              props.extra.website ? (
                !showAll && props && props.isFav !== "unimportant" ? (
                  <span className={classes.hideText}>
                    <RedButton onClick={() => setShowAll(!showAll)}>
                      {t("mehr...")}
                    </RedButton>
                  </span>
                ) : null
              ) : null
            ) : null}
            {showAll && props && props.isFav !== "unimportant" ? (
              <span className={classes.hideText}>
                <RedButton
                  className={classes.hideText}
                  onClick={() => setShowAll(!showAll)}
                >
                  {t("weniger...")}
                </RedButton>
              </span>
            ) : null}
            {props && props.makeLogin ? (
              <div onClick={openlogin} className={classes.loginButton}>
                <BlueButton>{t("loginnow")}</BlueButton>
              </div>
            ) : null}

            {props && props.extra ? (
              <Col
                xs="12"
                onClick={() => {
                  navigate(`/${modelname}`);
                }}
                className={
                  props.dontsnap ? classes.distancmenosnap : classes.distanceme
                }
              >
                <PostCounter girl={props.extra} />
              </Col>
            ) : null}
          </div>
        </div>
      </div>

      {openotp && (
        <SimplePaymentBaseModal
          girlname={modelname}
          girlid={props.id}
          onclose={gotbackdata}
          canfandollar={true}
          secondgirlimg={
            authCtx.isAVS ? props.secondimage : props.secondimagesoft
          }
          girlimg={authCtx.isAVS ? props.image : props.imagesoft}
          currency={authCtx.country !== "CH" ? "EUR" : "CHF"}
        />
      )}
      {writeMail && (
        <PortalModal onclose={showmail}>
          <WriteMessage
            onclose={showmail}
            fromgirl={props.producerID}
            fromname={modelname}
          />
        </PortalModal>
      )}
      {message && (
        <PortalModal
          onclose={() => {
            setMessage(false);
          }}
        >
          <div className={classes.thankyoudiv}>
            <h5 className={classes.thankyouheader}>{t("thankyou")}</h5>
            <div className={classes.thankyouimagetext}>
              <img
                src={authCtx.isAVS ? props.secondimage : props.secondimagesoft}
                onContextMenu="return false;"
                alt="thank you"
                className={classes.thankyouimage}
              />
              <p className={classes.thankyoutext}>{message}</p>
            </div>
          </div>
        </PortalModal>
      )}
      {makeAVS && (
        <PortalFeedbackModal onclose={showAVS} time="infinite">
          <div>
            <h5>{t("verifyyouragehead")}</h5>
            <p>{t("verifyagetext")}</p>
            <Link to="/profile">
              <RedButton>{t("verifyagebutton")}</RedButton>
            </Link>
          </div>
        </PortalFeedbackModal>
      )}
      {makePremium && (
        <PortalModal onclose={showAddMoney}>
          <div>
            <h5>{t("makepremiumhead")}</h5>
            <ChoosepaymentOption />
          </div>
        </PortalModal>
      )}
      {loginmodal ? (
        <PortalModal onclose={openlogin}>
          <PleaseLoginShort />
        </PortalModal>
      ) : null}
    </>
  );
});

export default Modeladvertorial;
