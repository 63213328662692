import { logallout } from "./logallout";

export function refreshtoken(context,token) {
 if(!token){var checkIfRunning =localStorage.getItem("refreshing");

 if(checkIfRunning!==null){
  var checkIfRefreshing = localStorage.getItem("reloading");
  if (checkIfRefreshing){return}
    setTimeout(() => {
      window.location.reload(false);
      localStorage.removeItem("reloading");
   
      return;
    }, "600");}
     localStorage.setItem("reloading", true);

   localStorage.setItem("refreshing",true);
    setTimeout(() => {
      localStorage.removeItem("refreshing")
    }, "500");
}
    fetch("https://api.deine.fans/api/token/refresh", {
      method: "POST",
      body: JSON.stringify({
        role: "user",
        userID: context.userID,
        authToken: token?token:context.refreshToken?context.refreshToken:context.token,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
     res.json().then((data) => {
       if(!data){return}

      if(!data.success){logallout(context);}
      
      if (
        data.success && data.refreshMessage !==
        "Refresh not needed! Returning old Token."
      ) {
        context.setAFreshToken(data.refreshToken);
        context.newToken(data.authToken);
        context.setJwtAuthToken(data.jwtAuthToken);
      }
       
    //    if(!token){ setTimeout(() => {
    //        window.location.reload(true);
    //     }, "500");
    //  }
  });
   });
  
}
