import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../context/authcontext";
import classes from "./postCounter.module.css";

const PostCounter = (props) => {
  const { t } = useTranslation();

  const [posts, setPosts] = useState(props.girl.posts);
  const [videos, setVideos] = useState(props.girl.videos);
  const [images, setImages] = useState(props.girl.images);

  const [isDarkmode, setIsDarkMode] = useState(false);
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    setIsDarkMode(authCtx.darkMode);
  }, [authCtx]);


  // useEffect(() => {
  //   if (posts > 6) {
  //     setPosts("5+");
  //   }
  //   if (videos > 6) {
  //     setVideos("5+");
  //   }
  //   if (images > 6) {
  //     setImages("5+");
  //   }
  //   if (posts > 12) {
  //     setPosts("10+");
  //   }
  //   if (videos > 12) {
  //     setVideos("10+");
  //   }
  //   if (images > 12) {
  //     setImages("10+");
  //   }
  //   if (posts > 22) {
  //     setPosts("20+");
  //   }
  //   if (videos > 22) {
  //     setVideos("20+");
  //   }
  //   if (images > 22) {
  //     setImages("20+");
  //   }
  //   if (posts > 32) {
  //     setPosts("30+");
  //   }
  //   if (videos > 32) {
  //     setVideos("30+");
  //   }
  //   if (images > 32) {
  //     setImages("30+");
  //   }
  //    if (posts > 42) {
  //      setPosts("40+");
  //    }
  //    if (videos > 42) {
  //      setVideos("40+");
  //    }
  //    if (images > 42) {
  //      setImages("40+");
  //    }
  //    if (posts > 50) {
  //      setPosts(posts);
  //    }
  //    if (videos > 50) {
  //      setVideos(videos);
  //    }
  //    if (images > 50) {
  //      setImages(images);
  //    }
  // }, []);

  return (
    <div
      className={classes.flexitouter}
    >
      <div className={classes.flexitinner}>
        <img src={isDarkmode ? "/images/icons/post_icon_dm.png" : "/images/icons_m/postsg.png"} className={classes.icon} />
        <p className={classes.bolder}>
          {" "}
          {posts} {props.girl.posts === 1 ? "Post" : "Posts"}
        </p>
      </div>
      {props && props.girl && props.girl.videos > 0 && (
        <div className={classes.flexitinner}>
          <img src={isDarkmode? "/images/icons/video_dm.png" : "/images/icons_m/videosg.png"}  className={classes.icon} />
          <p className={classes.bolder}>
            {" "}
            {videos} {props.girl.videos === 1 ? t("video") : t("videos")}
          </p>
        </div>
      )}
      {props && props.girl && props.girl.images > 0 && (
        <div className={classes.flexitinner}>
          <img src={isDarkmode? "/images/icons/bild_dm.png" : "/images/icons_m/bilderg.png"}  className={classes.icon} />
          <p className={classes.bolder}>
            {" "}
            {images} {props.girl.images === 1 ? t("image") : t("images")}
          </p>
        </div>
      )}
    </div>
  );
};

export default PostCounter;
