import React, { useContext, useState } from "react";
import { checkreceivedData } from "../hooks/checkReceivedData";

import AuthContext from "./authcontext";

const PremiumContext = React.createContext({
    isPremium: false,
    isAVS:false,
    setPremium: ()=>{}
});

export const PremiumContextProvider = (props) =>{
const authCtx = useContext(AuthContext);
const [isPremium, setIsPremium]=useState(false)




async function setNewPremium () {
   
   fetch(
      `https://api.deine.fans/api/userstatus?userID=${encodeURIComponent(authCtx.pseudo)}&authToken=${encodeURIComponent(authCtx.token)} `
    )
      .then((response) => response.json())
      .then((data) => {
        checkreceivedData(data);
        setIsPremium(data.hasPaymentData)
    });
  
    
}
const contextValue = {
  isPremium: isPremium,
  isAVS: false,
  setPremium: setNewPremium,
};
    return(
    <PremiumContext.Provider value={contextValue}>
      {props.children}
    </PremiumContext.Provider>
    );
}

export default PremiumContext;