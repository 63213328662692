import React, { Fragment, useContext, useEffect, useState } from 'react';
//import AuthContext from './context/authcontext';
import App from './App';
//import LoadingSpinner from './components/Cards/Extras/loadingspinner';
//import { checkstatus } from './hooks/checkstatus';

function PreApp() {

    
// const authCtx = useContext(AuthContext);
// const [isLoaded, setIsLoaded] = useState(false);
// useEffect(()=>{
//     checkstatus(authCtx).then(setIsLoaded(true));
//     console.log("it's")
// },[])
  return (
    <Fragment>
        <App />
    </Fragment>
  )
}

export default PreApp
