import React, { Fragment, useContext, useEffect, useState } from 'react'
import MyImage from '../../sonstige/specialcomponents/images';

import PortalModal from '../WrapperComponents/portalmodal';
import { useTranslation } from 'react-i18next';
import classes from "./aktionswrapper.module.css";
import AuthContext from '../../../context/authcontext';
import { useHasUsedFreeCoins } from '../../../hooks/usePreflight';
import SuccessMessage from '../../sonstige/specialcomponents/successmessage';
import { useNavigate } from 'react-router-dom';

function Aktionswrapper(props) {

  const [showIt,setShowIt]=useState(false);
  const [showOffer,setShowOffer]=useState(false);
  const authCtx=useContext(AuthContext);
  const hasUsed=useHasUsedFreeCoins((state)=>state.hasUsed);
 const changehasUsed = useHasUsedFreeCoins((state) => state.changehasUsed);
 const [closeSuccess,setCloseSuccess]=useState(true)
  function toggleoffer(){
    setShowOffer(!showOffer);
  }
  const {t}=useTranslation();
  useEffect(()=>{
if(!authCtx.isPremium&&!hasUsed){console.log("it'srunning");testforfree();}
  },[])
  
  async function testforfree(){
const data=await fetch(`https://api.deine.fans/api/freeaccount?userID=${authCtx.userID}&authToken=${authCtx.jwtAuthToken}`)
const readabledate= await data.json();
if (readabledate.hasUsed) {
  changehasUsed(true);
}
if (!readabledate.hatschon && !readabledate.phonenumber && !authCtx.isPremium && authCtx.isLoggedIn){setShowIt(true)}
  };
  const relocate=useNavigate();
  const [success,setSuccess]=useState(false);
  const mySuccess = useHasUsedFreeCoins((state) => state.hasSuccess);
  const changeSuccess = useHasUsedFreeCoins((state) => state.changeSuccess);
    return (
      <Fragment>
        {showIt && !hasUsed ? (
          <div
            className={`${classes.wrapper} ${props.className}`}
            onClick={!showOffer ? toggleoffer : () => {}}
          >
            <MyImage
              src="https://d39j9qa9t0x6xj.cloudfront.net/dekor/geschenke.jpg"
              alt="doyouwant"
              className={classes.image}
            />
            <h5>{t("specialpromotion")}</h5>
            <p>{t("specialpromotiontext")}</p>

            {showOffer ? (
              <PortalModal onclose={toggleoffer}>{props.children}</PortalModal>
            ) : null}
          </div>
        ) : null}
        {mySuccess && closeSuccess ? (
          <PortalModal
            onclose={() => {
              changeSuccess(false);
              setCloseSuccess(false);
              relocate("/");
            }}
          >
            <SuccessMessage
              type="message"
              image="success"
              body={success}
              head="Das hat geklappt"
              onclose={() => {
                changeSuccess(false);
                console.log("zumachen");
                setCloseSuccess(false)
                relocate("/");
              }}
            />
          </PortalModal>
        ) : null}
      </Fragment>
    );
}

export default Aktionswrapper
