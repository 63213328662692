import React from 'react';
import classes from "./testpostcard.module.css"

function PostCardNumofContent({post,isDarkmode}) {
const freeVideos = post.freeMedia?post.freeMedia.filter(
  (el) => el.type === "video"
).length:[];
const payVideos = post.payMedia?post.payMedia.filter(
  (el) => el.type === "video"
).length:[];
const freeImages = post.freeMedia?post.freeMedia.filter(
  (el) => el.type === "image"
).length:[];
const payImages = post.payMedia?post.payMedia.filter(
  (el) => el.type === "image"
).length:[];
const videos = payVideos + freeVideos;
const images = freeImages + payImages;
const content=videos+images
  return (
    <div
      className={classes.flexitright}
    
    >
      {content > 0 && (
              <div className={classes.flexitsm}>
                <img
                  src={
                    isDarkmode
                      ? "/images/icons_m/postsg.png"
                      : "/images/icons_m/postsg.png"
                  }
                  className={classes.iconsm}
                  alt="mediaitem"
                />
                <p className={classes.marker}> : {content}</p>
              </div>
            )}
            {images > 0 && (
              <div className={classes.flexitsm}>
                <img
                  src={
                    isDarkmode
                      ? "/images/icons_m/bilderg.png"
                      : "/images/icons_m/bilderg.png"
                  }
                  className={classes.iconsm}
                  alt="mediaitem"
                />
                <p className={classes.marker}> : {images}</p>
              </div>
            )}
            {videos > 0 && (
              <div className={classes.flexitsm}>
                <img
                  src={
                    isDarkmode
                      ? "/images/icons_m/videosg.png"
                      : "/images/icons_m/videosg.png"
                  }
                  className={classes.iconsm}
                  alt="mediaitem"
                />
                <p className={classes.marker}> : {videos}</p>
              </div>
            )}
    </div>
  );
}

export default PostCardNumofContent
