import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/authcontext";
import { checkforpremium } from "../../hooks/checkforpremium";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import LoadingSpinner from "../Cards/Extras/loadingspinner";
import Allapigirls from "../lists/allapigirls";
import classes from "./authresult.module.css";
import SuccessMessage from "../sonstige/specialcomponents/successmessage";
import PortalModal from "../Cards/WrapperComponents/portalmodal";

const AuthResult = (props) => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  const params = useParams();
  const posTarget=localStorage.getItem("lastSite")||null;

  let myurl = window.location.href;
  let weiterleitungsurl = myurl.split("?proccessingURL=")[1];
  let answer = t("payAuthorizeOk");
  if (props.result === "failed") {
    answer = t("payAuthorizeFail");
  }
  if (props.result === "already_authorized") {
    answer = t("payAuthorizeAlready");
  }
  const navigate = useNavigate();

  if (props.result === "success") {
    checkforpremium(authCtx.userID, authCtx.token, authCtx);
  }

  // setTimeout(() => {
  //   if (props.result === "failed") {
  //     if (posTarget && posTarget.length > 0) {navigate(posTarget);localStorage.removeItem("lastSite");}else{navigate("/profile");}
  //   } else if (props.result === "already_authorized") {
  //     navigate("/profile");
  //   } else if (props.result === "success" && posTarget &&posTarget.length>0) {
  //     navigate(posTarget);
  //     localStorage.removeItem("lastSite");
  //   } else {
  //     // navigate("/models");
  //   }
  // }, 5000);

  function callApi(transid) {
    fetch(
      `https://api.deine.fans/api/payment/transcheck/${encodeURIComponent(transid)}?authToken=${encodeURIComponent(authCtx.token)}&userID=${encodeURIComponent(authCtx.userID)}`
    ).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, authCtx, "authResult");

        if (data.success) {
        }
        if (data.returned) {
          setIsProcessing(false);
          navigate(weiterleitungsurl);
        }
      });
    });
  }
  const [isProcessing, setIsProcessing] = useState(false);
  useEffect(() => {
    if (props.result === "processing") {
      setIsProcessing(true);
    }
  }, []);

  useEffect(() => {
    if (isProcessing) {
      console.log("payment/authresult",params);
      let interval = setInterval(() => {
        if(typeof(params.key) === "undefined") {
          callApi(params);
        } else {
          callApi(params.key);
        }
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isProcessing]);
const [showModal,setShowModal]=useState(true);


function proceedtotarget(){
  setShowModal(false);
 if(props.result ==="success"){
  localStorage.removeItem("lastSite");
    navigate(posTarget?posTarget: "/");
    
 } else if(props.result ==="failed"){
 
  localStorage.removeItem("lastSite");
   navigate(posTarget ? posTarget : "/profile");
   
 }
 else if(props.result==="already_authorized"){
  localStorage.removeItem("lastSite");
  navigate(posTarget ? posTarget : "/profile");
 }
}
setTimeout(() => {
if (props.result === "success") {proceedtotarget();}
}, 8000)

  return (
    <div>
      {isProcessing ? (
        <LoadingSpinner />
      ) : props.transuccess ? (
        <div className={classes.resultdiv}>
          <h5 className={classes.resulttext}>
            {t("thatworkedyoumayclosenow")}
          </h5>
        </div>
      ) : (
        <div className={classes.resultdiv}>
          {props.result === "success" && showModal ? (
            <PortalModal onclose={proceedtotarget}>
              <SuccessMessage type="success" onclose={proceedtotarget} />
            </PortalModal>
          ) : props.result === "failed" && showModal ? (
            <PortalModal onclose={proceedtotarget}>
              <SuccessMessage
                type="failed"
                onclose={proceedtotarget}
                transid={params}
              />
            </PortalModal>
          ) : props.result === "already_authorized" && showModal ? (
            <PortalModal onclose={proceedtotarget}>
              <SuccessMessage
                type="already_authorized"
                onclose={proceedtotarget}
                transid={params}
              />
            </PortalModal>
          ) : null}
          {/* <h5 className={classes.resulttext}>{answer}</h5> */}
          <Allapigirls />
        </div>
      )}
    </div>
  );
};

export default AuthResult;
