import React from 'react';
import classes from "./boughtItemsTimeline.module.css";

function ShortBuyItem({item}) {

    var a=new Date(item.timestamp)
   var  b = a.toDateString("de-DE");

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerWrapper}>
        <h5 className={classes.header}>{item.buyType==="Timeline"?"Post":"Nachricht"} von {item.girlPseudo}</h5>
        <p>{b}</p>
      </div>
      <hr />

      <h5 className={classes.content}>{item.buyType==="Timeline"?item.buyDescription:"Klicken um zu lesen"}</h5>
    </div>
  );
}

export default ShortBuyItem
