import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import NewMailPW from "../../components/auth/newMailPW";
import RedButton from "../../components/Cards/WrapperComponents/redbutton";
import { useNavigate } from "react-router-dom";
import AVSOverview from "../../components/lists/avs/avsoverview";
import PaymentMethods from "../../components/payment/paymentmethods";
import LanguageSwitcher from "../../components/sonstige/languageswitch";
import AuthContext from "../../context/authcontext";
import { checkstatus } from "../../hooks/checkstatus";
import classes from "./profile.module.css";
import PleaseLogin from "../../components/sonstige/pleaselogin";
import ImageUploader from "../../components/sonstige/imageUploader/imageUploader";
import PortalModal from "../../components/Cards/WrapperComponents/portalmodal";
import { useAddProfile } from "../../hooks/useProfile";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import { Col, Row } from "reactstrap";
import DropDownButton from "../../components/Cards/WrapperComponents/dropdowbutton";
import ChoosepaymentOption from "../../components/sonstige/profileElements/choosepaymentOption";
import useCheckMobileScreen from "../../hooks/useCheckMobile";



const Profile = () => {
  const authCtx = useContext(AuthContext);
  const [showAVS, showsetHasAVS] = useState(false);
  const [isAVS, setIsAvs] = useState(false);
  const [profilepic, setProfilePic] = useState(
    "https://media.discordapp.net/attachments/1016060878443008050/1075447730102612070/ThalionmelAlgerin_generic_demo_profile_pic_blank_face_96bd798e-bcb4-4b19-b386-c9fbaa21165e.png?width=682&height=682"
  );
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [profileText, setProfileText] = useState("test");
  const [profileName, setProfileName] = useState("test");
  const [profileBirthdate, setProfileBirthdate] = useState("test");
  const [profilePref, setProfilePref] = useState("test");
  const [profiletags, setProfileTags] = useState([]);
  const [profileImage, setProfileImage] = useState("test");
  const [profileLang, setProfileLang] = useState(authCtx.myLanguage);
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();
  const [mailpw, setMailpw] = useState();
  const [showpayments, setShowPayments] = useState(false);
  const [isDarkmode, setIsDarkMode] = useState(authCtx.darkMode);
  const [isDefaultImage, setisDefaultImage] = useState();
  const [addATag, setAddATag] = useState(false);
  const [wantdeleteTag, setWantDeleteTag] = useState(false);
  const [memorizeTag, setMemorizeTag] = useState(null);
  const [isDecEnter, setisDecEnter] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
   const { i18n } = useTranslation();


  useEffect(() => {
   
    getUserData();
    window.scrollTo(0, 0);
    if (!authCtx.isPremium) {
      setShowPayments(true);
    }
    if (authCtx.profilePic) {
   
      setProfilePic(authCtx.profilePic);
    }else{
      setProfilePic(
        "https://media.discordapp.net/attachments/1016060878443008050/1075447730102612070/ThalionmelAlgerin_generic_demo_profile_pic_blank_face_96bd798e-bcb4-4b19-b386-c9fbaa21165e.png?width=682&height=682"
      );
    }
    
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    checkstatus(authCtx);
    if (!authCtx.isLoggedIn) {
      navigate("/");
    }
    if (!authCtx.isAVS) {
      showsetHasAVS(false);
    } else {
      setIsAvs(true);
    }
    console.log("auth",authCtx)
  }, [authCtx]);

  useEffect(() => {
    if (userData&&userData.profile) {
      setProfileText(userData.profile.profileText?userData.profile.profileText:"");
      setProfileName(userData.profile.profileName?userData.profile.profileName:"");
      setProfilePref(userData.profile.sexualPreferences?userData.profile.sexualPreferences:"");
      setProfileTags(userData.profile.tagsList?userData.profile.tagsList:"");
      setProfileBirthdate(userData.profile.birthdate?userData.profile.birthdate:"");
    }
  }, [userData]);

  function switchImageUploader() {
    setShowImageUploader(!showImageUploader);
  }
  function getUserData() {
    fetch(
      `https://api.deine.fans/api/useraccount/profile?userID=${encodeURIComponent(authCtx.userID)}&authToken=${encodeURIComponent(authCtx.jwtAuthToken)}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, authCtx,"detailIndex");
        setUserData(data);
      });
    });
  }
  function tellme(info) {
    setProfilePic(info.imageURL);
    setShowImageUploader(false);
    authCtx.saveProfilePic(info.imageURL);
    setProfileImage(info.imageID);
    postinfo("image", info.imageID);
  }
  const togglemailpw = () => {
    setMailpw(!mailpw);
  };
  function getback() {
    togglemailpw();
  }
  function toggleAVSModal() {
    showsetHasAVS(!showAVS);
  }
  function togglepaymenton() {
    setShowPayments(!showpayments);
  
  }
  function switchdm() {
    setIsDarkMode(!isDarkmode);
    authCtx.switchDarkMode();
  }
  function sendDefault(image) {
    setisDefaultImage(image);
  }
  function checkdefault() {
    if (isDefaultImage) {
      tellme(isDefaultImage);
    } else {
      switchImageUploader();
    }
    setisDefaultImage(false);
  }
  function postinfo(type, payload) {
    var thebody = JSON.stringify({
      userID: authCtx.userID,
      authToken: authCtx.jwtAuthToken,
    });
    if (type === "image") {
      var thebody = JSON.stringify({
        userID: authCtx.userID,
        authToken: authCtx.jwtAuthToken,
        image: payload,
      });
    } else if (type === "lang") {
      var thebody = JSON.stringify({
        userID: authCtx.userID,
        authToken: authCtx.jwtAuthToken,
        preferredLanguage: payload,
      });
     
    } else if (type === "tags") {
      var thebody = JSON.stringify({
        userID: authCtx.userID,
        authToken: authCtx.jwtAuthToken,
        tags: payload,
      });
    } else if (type === "prefs") {
      var thebody = JSON.stringify({
        userID: authCtx.userID,
        authToken: authCtx.jwtAuthToken,
        sexualPreferences: payload,
      });
    } else if (type === "infoText") {
     
      var thebody = JSON.stringify({
        userID: authCtx.userID,
        authToken: authCtx.jwtAuthToken,
        profileText: payload,
      });
    }
    useAddProfile(authCtx, thebody);
    setTimeout(() => {
      getUserData();
    }, 500);
  }
  function langback(lang) {
   
    postinfo("lang", lang);
  }
  function removetag(name) {
    setMemorizeTag(name);
   
    toggleWantDelete();
  }
  const myref = useRef();
  function addTag(e) {
    e.preventDefault();
    var a
    if (userData.profile.tags!=="null"){
      a = userData.profile.tags + ` #${myref.current.value}`;
    }else{
      a = ` #${myref.current.value}`;
    }

a=a.replace("null","")
    postinfo("tags", a);
    toggleAddTag();
  }
  function toggleAddTag() {
    setAddATag(!addATag);
  }
  function toggleWantDelete() {
    setWantDeleteTag(!wantdeleteTag);
  }
  function deletemyMemorizedTag() {
    var tags = userData.profile.tags;
 
    tags=tags.toLowerCase();
   
    tags = tags.replace(memorizeTag, "");
  
    tags=tags.replace("#null","")
    if(!tags){
   
      tags=" "
    }
    postinfo("tags", tags);
    toggleWantDelete();
  }
  const textref = useRef();
  function submitInfoText(e) {
    e.preventDefault();
    postinfo("infoText", textref.current.value);
    toggleDesc();
  }
  function toggleDesc() {
    setisDecEnter(!isDecEnter);
  }
  const isMobile=useCheckMobileScreen();

  return (
    <Fragment>
      {!authCtx.isLoggedIn && <PleaseLogin />}
      {authCtx.isLoggedIn && (
        <div className={classes.wrapper}>
          <Row className={classes.therows}>
            <Col xs="8" md="12">
              <h4 className={classes.header}>
                {t("greeting")} {authCtx.pseudo}
              </h4>
            </Col>
            <Col xs="4" md="12">
              {isMobile ? (
                <div className={classes.language}>
                  <LanguageSwitcher onChange={langback} />

                  <a className={classes.distancer}>
                    <input
                      className={classes.toggle}
                      type="checkbox"
                      onChange={switchdm}
                      checked={authCtx.darkMode}
                    />
                  </a>
                </div>
              ) : null}
            </Col>
            <Col xs="12">
              <p className={classes.welcome}>{t("welcometoLive-Strip-Fans")}</p>
            </Col>
          </Row>
          <Row >
            <Col xs="12">
              {authCtx.isLoggedIn && (
                <DropDownButton
                  className={classes.payments}
                  onClick={togglepaymenton}
                >
                  {t("managepayment")}
                </DropDownButton>
              )}
              {showpayments ? (
                authCtx.isPremium ? (
                  <PaymentMethods />
                ) : (
                  <ChoosepaymentOption />
                )
              ) : null}

              <DropDownButton onClick={togglemailpw}>
                {t("changepw")}
              </DropDownButton>

              {mailpw && <NewMailPW sendback={getback} />}

              {!showAVS && !isAVS && authCtx.isLoggedIn && (
                <DropDownButton onClick={toggleAVSModal}>
                  {t("startavs")}
                </DropDownButton>
              )}
              {showAVS && authCtx.isLoggedIn && (
                <AVSOverview sendback={toggleAVSModal} />
              )}
            </Col>
            <Col>
              <DropDownButton
                onClick={() => {
                  setIsPersonal(!isPersonal);
                }}
              >
                {t("personalstuff")}
              </DropDownButton>
            </Col>
          </Row>
          {isPersonal ? (
            <Row className={classes.personalbox}>
              <Col xs="6" md="4">
                <div className={classes.profpicwrapper}>
                  <img
                    onClick={switchImageUploader}
                    src={profilepic}
                    alt="profile"
                    className={classes.profpic}
                  />
                  <div
                    onClick={switchImageUploader}
                    className={classes.distancer}
                  >
                    <RedButton>{t("changeprofile")}</RedButton>
                  </div>
                </div>
              </Col>
              <Col xs="6" md="8">
                {userData && userData.profile && (
                  <div>
                    {!isDecEnter ? (
                      <div className={classes.infotext}>
                        <h5>{t("description")}</h5>
                        <p className={classes.desctext}>
                          {userData.profile.profileText !== null
                            ? userData.profile.profileText
                            : t("tellusaboutyou")}
                        </p>
                        <div onClick={toggleDesc} className={classes.toright}>
                          <RedButton>{t("edit")}</RedButton>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h5>{t("description")}</h5>
                        <form
                          onSubmit={submitInfoText}
                          className={classes.inputElement}
                        >
                          <input
                            type="textarea"
                            ref={textref}
                            placeholder={userData.profile.profileText}
                            className={classes.area}
                            rows="4"
                          />
                          <div
                            onClick={submitInfoText}
                            className={classes.toright}
                          >
                            <RedButton>{t("save")}</RedButton>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                )}
              </Col>
              <Col xs="12">
                <div className={classes.tagbox}>
                  <h5>{t("yourtags")}</h5>
                  <ul className={classes.taglist}>
                    {userData &&
                      userData.profile &&
                      userData.profile.tags !== "null" &&
                      profiletags.map((tag) => (
                        <li
                          onClick={() => {
                            removetag(tag);
                          }}
                          className={classes.tag}
                          key={tag}
                        >
                          {tag}
                        </li>
                      ))}
                    <li className={classes.tagplus} onClick={toggleAddTag}>
                      + {t("addtag")}
                    </li>
                  </ul>
                  {addATag && (
                    <span className={classes.inputElement}>
                      <form onSubmit={addTag}>
                        <input
                          ref={myref}
                          placeholder={t("addtaghere")}
                          type="text"
                        />
                        <span onClick={addTag}>
                          <RedButton>{t("send")}</RedButton>
                        </span>
                      </form>
                    </span>
                  )}
                </div>

                {userData && userData.profile && (
                  <div className={classes.tagbox}>
                    {" "}
                    <div className={classes.flexit}>
                      <h5>{t("preferences")}</h5>

                      <img
                        src="/images/icons/icon_frau.png"
                        className={
                          userData.profile.sexualPreferences === "0"
                            ? classes.iconactive
                            : classes.iconinactive
                        }
                        onClick={() => {
                          postinfo("prefs", "0");
                        }}
                      />
                      <img
                        src="/images/icons/icon_mann.png"
                        className={
                          userData.profile.sexualPreferences === "1"
                            ? classes.iconactive
                            : classes.iconinactive
                        }
                        onClick={() => {
                          postinfo("prefs", "1");
                        }}
                      />
                      <img
                        src="/images/icons/icon_divers.png"
                        className={
                          userData.profile.sexualPreferences === "2"
                            ? classes.iconactive
                            : classes.iconinactive
                        }
                        onClick={() => {
                          postinfo("prefs", "2");
                        }}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          ) : null}
        </div>
      )}
      {wantdeleteTag && (
        <PortalModal onClose={toggleWantDelete}>
          <h5>{t("wanttodeleteatag")}</h5>
          <p>{t("wanttodeleteatagtext")}</p>
          <div className={classes.flexit}>
            <div onClick={deletemyMemorizedTag}>
              <RedButton>{t("yes")}</RedButton>
            </div>
            <div onClick={toggleWantDelete}>
              <RedButton>{t("no")}</RedButton>
            </div>
          </div>
        </PortalModal>
      )}
      {showImageUploader && (
        <PortalModal onclose={checkdefault}>
          <ImageUploader getbackimage={tellme} sendDefault={sendDefault} />
        </PortalModal>
      )}
    </Fragment>
  );
};

export default Profile;
