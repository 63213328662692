import React, { Fragment, useEffect, useState } from "react";
import classes from "./cookiemodal.module.css";
import ReactDOM from "react-dom";

const CookieModal = (props) => {
  // const Backdrop = (props) => {
  //   return <div className={classes.backdrop} onClick={closeme}></div>;
  // };
 const [isTop, setIsTop] = useState(props.isTop);
 useEffect(() => {
   setIsTop(props.isTop);

 }, [props]);
  const ModalOverlay = (props) => {

   
      return (
        <div
          className={
            isTop
              ? `${classes.modaltop} ${classes.extramodal}`
              : `${classes.modal} ${classes.extramodal}`
          }
          id="portalModalContainer"
        >
          <button
            className={classes.closeBtn}
            id="closePortalModal"
            onClick={closeme}
          >
            <img
              src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
              alt="close modal"
              className={classes.closex}
            />
          </button>
          <div className={classes.content}>{props.children}</div>
        </div>
      );
  };
  function closeme() {
    props.onclose();
  }
  const portalElement = document.getElementById("overlay");
  return (
    <Fragment>
      {/* {ReactDOM.createPortal(<Backdrop />, portalElement)}; */}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
      
    </Fragment>
  );
};

export default CookieModal;
