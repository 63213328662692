import React, { useContext, useRef, useState } from 'react'
import AuthContext from '../../../context/authcontext';
import { useTranslation } from 'react-i18next';
import classes from "./sendVoucherCode.module.css"
import SuccessMessage from '../specialcomponents/successmessage';
import PortalModal from '../../Cards/WrapperComponents/portalmodal';
import RedButton from '../../Cards/WrapperComponents/redbutton';
import { checkstatus } from '../../../hooks/checkstatus';
import { useNavigate, useNavigation } from 'react-router-dom';
import { useHasUsedFreeCoins } from '../../../hooks/usePreflight';



function SendVoucherCode(props) {
    const voucherInputRef=useRef();
    const codeInputRef = useRef();
    const authCtx=useContext(AuthContext);
    const {t}=useTranslation();
    const [error,setError]=useState(false);
const [enterCode,setEnterCode]=useState(false);
const [success,setSuccess]=useState(false);
const changehasUsed = useHasUsedFreeCoins((state) => state.changehasUsed);
const changeSuccess=useHasUsedFreeCoins((state)=>state.changeSuccess);

    async function submitvouchdercode(e){
        e.preventDefault();
       if(!authCtx.isLoggedIn){setError(t("pleaseloginfirst"))}
          if(!enterCode){
            let code = voucherInputRef.current.value;
            if (code.length < 1) {
              return;
            }
            console.log(code);
            if (props.phone && !enterCode) {
              console.log(code);
              if (authCtx.country === "DE" && code.search("0049") !== 0) {
                console.log("hotte", code.search("0049"));
                code = code.slice(1);
                code = "0049" + code;
                console.log(code);
              }
              if (authCtx.country === "AT" && code.search("0043") !== 0) {
                code = code.slice(1);
                code = "0043" + code;
              }
              if (authCtx.country === "CH" && code.search("0041") !== 0) {
                code = code.slice(1);
                code = "0041" + code;
              }
              const answer = await fetch(
                `https://api.deine.fans/api/freeaccount?userID=${encodeURIComponent(
                  authCtx.userID
                )}&authToken=${encodeURIComponent(
                  authCtx.jwtAuthToken
                )}&phonenumber=${encodeURIComponent(code)}`,
                {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              const readableAnswer = await answer.json();
              console.log(readableAnswer);
              if (readableAnswer.hatSchonEinen) {
                setError(t("alreadygotonetext"));
              }
              if (readableAnswer.error) {
                if (readableAnswer.error==="Invalid PhoneNumber"){setError(t("invalidnumber"));}else{
                setError(readableAnswer.error);}
              }
              else if (readableAnswer.codesent) {
                
                setEnterCode(true);
              
              document.getElementById("form")[0].value="";
              }
              else{
                setError(t("pleasetrylater"));
              }
            }}
        if(enterCode){
          let thecode = codeInputRef.current.value;
           const answer = await fetch(
             `https://api.deine.fans/api/freeaccountcode?userID=${encodeURIComponent(
               authCtx.userID
             )}&authToken=${encodeURIComponent(
               authCtx.jwtAuthToken
             )}&code=${encodeURIComponent(thecode)}`,
             {
               method: "PUT",
               headers: {
                 "Content-Type": "application/json",
               },
             }
           );
           const readableAnswer = await answer.json();
           console.log(readableAnswer);
           if(readableAnswer.error){setError(readableAnswer.error)}
           if(readableAnswer.coinsadded){changeSuccess(true);setSuccess(t("yougotfreecoins"));checkstatus(authCtx);changehasUsed(true);}
        }
        // const answer= await fetch();
        // const readableAnswer=await answer.json();
        // console.log(readableAnswer);
    }
    const relocate=useNavigate();
  return (
    <div>
      <form className={classes.form} onSubmit={submitvouchdercode} id="form">
        {!props.phone ? (
          <>
            <label htmlFor="voucher" className={classes.label}>
              {t("voucher")}
            </label>
            <input
              type="text"
              id="voucher"
              onSubmit={submitvouchdercode}
              ref={voucherInputRef}
              className={classes.input}
              placeholder="Dein Gutscheincode"
            />
          </>
        ) : enterCode ? (
          <>
            <div className={classes.flexit}>
              <img
                src="https://d39j9qa9t0x6xj.cloudfront.net/dekor/mobil.jpg"
                alt="enternumber"
                className={classes.thegirlimage}
              />
              <label htmlFor="code" className={classes.label}>
                <h5>{t("codehead")}</h5>
                <p>{t("code")}</p>
              </label>
            </div>
            <input
              id="code"
              onSubmit={submitvouchdercode}
              ref={codeInputRef}
              className={classes.input}
              placeholder=""
              initialvalue=""
              type="number"
            />
          </>
        ) : (
          <>
            <div className={classes.flexit}>
              <img
                src="https://d39j9qa9t0x6xj.cloudfront.net/dekor/mobil.jpg"
                alt="enternumber"
                className={classes.thegirlimage}
              />
              <label htmlFor="number" className={classes.label}>
                <h5>{t("handynummer")}</h5>
                <p>{t("handynummerdesc")}</p>
              </label>
            </div>
            <input
              id="number"
              onSubmit={submitvouchdercode}
              ref={voucherInputRef}
              className={classes.input}
              placeholder={t("yournumber")}
              type="tel"
              autoComplete="tel-international"
            />
          </>
        )}
        <div className={classes.flexbuttons}>
          <RedButton
            className={classes.totheright}
            onClick={submitvouchdercode}
          >
            {t("send")}
          </RedButton>
          {enterCode ? (
            <RedButton
              onClick={() => {
                setEnterCode(false);
              }}
            >
              {t("sendnumberagain")}
            </RedButton>
          ) : (
            <RedButton
              onClick={() => {
                setEnterCode(true);
              }}
            >
              {t("alreadyhavesms")}
            </RedButton>
          )}
        </div>
      </form>

      {error ? (
        <PortalModal onclose={() => setError(null)}>
          <SuccessMessage
            type="message"
            body={error}
            head="Das hat nicht geklappt"
            onclose={() => setError(null)}
          />
        </PortalModal>
      ) : null}
      {success ? (
        <PortalModal
          onclose={() => {
            setSuccess(null);
            relocate("/");
          }}
        >
          <SuccessMessage
            type="message"
            image="success"
            body={success}
            head="Das hat geklappt"
            onclose={() => {
              setSuccess(null);
              relocate("/");
            }}
          />
        </PortalModal>
      ) : null}
    </div>
  );
}

export default SendVoucherCode
