import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import classes from "./dynafilters.module.css";
import AuthContext from "../../context/authcontext";
import { fastsignup } from "../../hooks/fastsignup";
import { useTranslation } from "react-i18next";
import { realloggout } from "../../hooks/logallout";
import SimplePaymentBaseModal from "../payment/simplepayment/simplepaymentBaseModal";
import { getstatus } from "../../hooks/checkstatus";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import { useIsApple } from "../../hooks/useIsApple";
import PortalModal from "../Cards/WrapperComponents/portalmodal";
import Login from "../../pages/login";
import { useMessages } from "../../hooks/usePreflight";

const DynaFilters = () => {
  const params = useParams();
  const userCtx = useContext(AuthContext);
  const isLoggedIn = userCtx.isLoggedIn;
  const [isPremium, setisPremium] = useState(userCtx.isPremium);
  const { t } = useTranslation();
  const [fanDollar, setFanDollar] = useState(userCtx.fanDollar);
  const [showFanDollar, setShowFanDollar] = useState(true);
  const [mailNumber, setmailNumber] = useState(0);
  const checkMessagesNow = useMessages((state) => state.checkedmessegas);
  useEffect(() => {
    var dol = Number(userCtx.fanDollar).toFixed(2);
    setFanDollar(dol);
    if (fanDollar > 0) {
      setShowFanDollar(true);
    }
    if (!isPremium) {
      setisPremium(userCtx.isPremium);
    }
  }, [userCtx]);

  const [amIontheBottom, setAmIOnTheBotom] = useState(false);
  function scrolldown() {
    const element = document.getElementById("footer");
    let pos = element.offsetTop;
    window.scrollTo(0, pos);
    setAmIOnTheBotom(true);
  }
  function scrollup() {
    window.scrollTo(0, 0);
    setAmIOnTheBotom(false);
  }
  const [buyFanDollars, setBuyFanDollars] = useState(false);
  function togglebuyFanDollar() {
    setBuyFanDollars(!buyFanDollars);
  }
  const [isDarkmode, setDarkMode] = useState(userCtx.darkMode);
  useEffect(() => {
    setDarkMode(userCtx.darkMode);
  }, [userCtx]);

  useEffect(() => {
    setAmIOnTheBotom(false);
  }, [params]);
  const [onTestModal, setOnTestModal] = useState(false);
  function toggleTestModal() {
    setOnTestModal(!onTestModal);
  }
  const [showcustomlogo, setShowCustomLogo] = useState(false);

  useEffect(() => {
    setShowCustomLogo(userCtx.profilePic);
    if (userCtx.profilePic === "undefined") {
      setShowCustomLogo(false);
    }
    if (userCtx.profilePic === "null") {
      setShowCustomLogo(false);
    }
  }, [userCtx]);

const MINUTE_MS = 50000;
const themessages = useMessages((state) =>state.messages);
const updateMessages = useMessages((state) => state.updateMessages);
const checkMessages = useMessages((state) => state.checkMessages);
useEffect(() => {
  if (userCtx.isLoggedIn) {
    checkformessage();
    const interval = setInterval(async () => {
      checkformessage();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }
}, [userCtx.isLoggedIn]);
useEffect(()=>{
  setTimeout(() => {
    checkformessage();
  }, 800)
  

},[checkMessagesNow])
async function checkformessage(){
const info = await fetch(
  `https://api.deine.fans/api/userstatus?userID=${userCtx.userID}&authToken=${userCtx.jwtAuthToken}&StatusScope=MAILS_ONLY`
);
const realinfo = await info.json();

updateMessages(realinfo.unreadMessages);

}

const isApple=useIsApple();

const [showLoginModal,setShowloginModal]=useState(false);
const [wantsignup,setwantsignup]=useState(false);
function toggleloginModal(val){setShowloginModal(!showLoginModal);setwantsignup(val)}
const changereload = useMessages((state) => state.changereload);
const reloadPage = useMessages((state) => state.reloadPage);
  return (
    <Fragment>
      <div className={isDarkmode ? classes.leftmenuedm : classes.leftmenue}>
        <ul className={classes.icon}>
          <Link to="/">
            <li>
              {showcustomlogo ? (
                <div className={classes.logowrapper}>
                  <img
                    className={classes.profilelogo}
                    src={showcustomlogo}
                    alt="mylogo"
                    id={"profilelogo"}
                  />
                  <img
                    id={"profilelog-nocustom"}
                    src={
                      window.location.hostname.search(/Live-Strip-Fans/i) > -1
                        ? `https://d39j9qa9t0x6xj.cloudfront.net/logos/livestrip_fans.png`
                        : `https://d39j9qa9t0x6xj.cloudfront.net/logos/livestrip_fans.png`
                    }
                    alt="Logo"
                    className={classes.logo}
                  />
                </div>
              ) : (
                <img
                  src={
                    window.location.hostname.search(/Live-Strip-Fans/i) > -1
                      ? `https://d39j9qa9t0x6xj.cloudfront.net/logos/livestrip_fans.png`
                      : `https://d39j9qa9t0x6xj.cloudfront.net/logos/livestrip_fans.png`
                  }
                  alt="Logo"
                  className={classes.logo}
                />
              )}
            </li>
          </Link>

          {!isLoggedIn && (
            <div
              className={classes.leftmenue}
              onClick={() => {
                toggleloginModal(false);
              }}
            >
              <li className={isDarkmode ? classes.icon3dm : classes.icon3}></li>
              <p className={`d-none d-md-block ${classes.bignav}`}>
                {t("login")}
              </p>
              <p className={`d-block d-md-none ${classes.smallnav}`}>
                {t("login")}
              </p>
            </div>
          )}

          {!isLoggedIn && (
            <div
              className={classes.leftmenue}
              onClick={() => {
                toggleloginModal(true);
              }}
            >
              <li className={isDarkmode ? classes.icon3dm : classes.icon3}></li>
              <p className={`d-none d-md-block ${classes.bignav}`}>
                {t("signup")}
              </p>
              <p className={`d-block d-md-none ${classes.smallnav}`}>
                {t("signup")}
              </p>
            </div>
          )}
          <Link className={classes.leftmenue} to="/">
            <li className={isDarkmode ? classes.icon1dm : classes.icon1}></li>
            <p className={`d-none d-md-block ${classes.bignav}`}>
              {t("timeline")}
            </p>
            <p className={`d-block d-md-none ${classes.smallnav}`}>
              {t("timeline")}
            </p>
          </Link>

          <Link className={classes.leftmenue} to="/models">
            <li className={isDarkmode ? classes.icon8dm : classes.icon8}></li>
            <p className={`d-none d-md-block ${classes.bignav}`}>
              {t("channels")}
            </p>
            <p className={`d-block d-md-none ${classes.smallnav}`}>
              {t("channels")}
            </p>
          </Link>

          {isLoggedIn && !isPremium && (
            <Link className={classes.leftmenue} to="/profile">
              <li className={isDarkmode ? classes.icon4dm : classes.icon4}></li>
              <p className={`d-none d-md-block ${classes.bignav}`}>
                {t("addpay")}
              </p>
              <p className={`d-block d-md-none ${classes.smallnav}`}>
                {t("addpay")}
              </p>
            </Link>
          )}

          {!isLoggedIn && (
            <a className={classes.leftmenue} href="https://signup.livestrip.Fans/">
              <li className={isDarkmode ? classes.icon9dm : classes.icon9}></li>
              <p className={`d-none d-md-block ${classes.bignav}`}>
                {t("jetztcreatorwerden")}
              </p>
              <p className={`d-block d-md-none ${classes.smallnav}`}>
                {t("jetztcreatorwerden")}
              </p>
            </a>
          )}

          {isLoggedIn && (
            <Link className={classes.leftmenue} to="/boughtitems">
              <li
                className={isDarkmode ? classes.icon10dm : classes.icon10}
              ></li>
              <p className={`d-none d-md-block ${classes.bignav}`}>
                {t("boughtitems")}
              </p>
              <p className={`d-block d-md-none ${classes.smallnav}`}>
                {t("boughtitems")}
              </p>
            </Link>
          )}

          {isLoggedIn && (
            <Link
              key={themessages}
              className={`${classes.leftmenue} ${classes.leftmenueextra}`}
              to="/messages"
              onClick={() => {
                changereload(!reloadPage);
                console.log(reloadPage);
              }}
            >
              <li className={isDarkmode ? classes.icon5dm : classes.icon5}></li>
              <p className={`d-none d-md-block ${classes.bignav}`}>
                {t("inbox")}
              </p>
              <p className={`d-block d-md-none ${classes.smallnav}`}>
                {t("inbox")}
              </p>

              {themessages > 0 && (
                <p key={updateMessages} className={classes.extraMail}>
                  {themessages}
                </p>
              )}
            </Link>
          )}
          {isLoggedIn && (
            <Link className={classes.leftmenue} to="/favs">
              <li className={isDarkmode ? classes.icon2dm : classes.icon2}></li>
              <p className={`d-none d-md-block ${classes.bignav}`}>
                {t("favs")}
              </p>
              <p className={`d-block d-md-none ${classes.smallnav}`}>
                {t("favs")}
              </p>
            </Link>
          )}

          {isLoggedIn && isPremium && (
            <Link className={classes.leftmenue} to="/collection">
              <li className={isDarkmode ? classes.icon7dm : classes.icon7}></li>
              <p className={`d-none d-md-block ${classes.bignav}`}>
                {t("mycollection")}
              </p>
              <p className={`d-block d-md-none ${classes.smallnav}`}>
                {t("mycollection")}
              </p>
            </Link>
          )}
        </ul>
        {!amIontheBottom && (
          <img
            src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/icons/pfeil_runter.${
              isApple ? "png" : "webp"
            }`}
            alt="PFEIL runter"
            onClick={scrolldown}
            className={classes.updownarrow}
          />
        )}
        {amIontheBottom && (
          <img
            src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/icons/pfeil_hoch.${
              isApple ? "png" : "webp"
            }`}
            alt="PFEIL rauf"
            className={classes.updownarrow}
            onClick={scrollup}
          />
        )}
      </div>
      {/* {buyFanDollars && <Otp getbackclose={togglebuyFanDollar} />} */}
      {buyFanDollars && (
        <SimplePaymentBaseModal onclose={togglebuyFanDollar} currency="€" />
      )}
      {showLoginModal ? (
        <PortalModal onclose={toggleloginModal}>
          <Login
            relocate={window.location.pathname}
            isSignup={wantsignup}
            onback={toggleloginModal}
          />
        </PortalModal>
      ) : null}
    </Fragment>
  );
};

export default React.memo(DynaFilters);
