import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import classes from "./ersterPost.module.css";
import PortalFeedbackModal from '../../WrapperComponents/portalfeedbackmodal';

function AddFavs(props) {
    const {t}=useTranslation();
    const [showModal,setShowModal]=useState(false);
    function togglemodal(){
        setShowModal(!showModal);
    }
  return (
    <Fragment>
      <div
        className={props.isdone ? classes.borderisDone : classes.border}
        onClick={togglemodal}
      >
        {t("addfavorites")}
        <img
          src="/images/icons_m/like.png"
          alt="likestar"
          className={classes.icon}
        />
        {t("addfavorites2")}
      </div>
      {showModal ? (
        <PortalFeedbackModal onclose={togglemodal}>
          <h5>{t("dontwannamissathing")}</h5>
          <hr />
          <div>
            <p>{t("checkoutthegirlsandfollowthe")}</p>
            <img
              src="/images/icons_m/like.png"
              alt="likestar"
              className={classes.icon2}
            />
          </div>
        </PortalFeedbackModal>
      ) : null}
    </Fragment>
  );
}

export default AddFavs
