import React from "react";
import classes from './footer.module.css';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () =>{
    
const {t}=useTranslation()
    return (
      <div className={classes.footer} id="footer">
        <div className={classes.footerlinks}>
          <Link className={classes.footertext} to="/privacy">
            {t("privacy")}
          </Link>
          <Link className={classes.footertext} to="/agb">
            {t("agb")}
          </Link>
          <Link className={classes.footertext} to="/impressum">
            {t("impressum")}
          </Link>
          <Link className={classes.footertext} to="/2257">
            {t("recordkeeping")}
          </Link>
          <Link className={classes.footertext} to="/support">
            {t("support")}
          </Link>
          <a
            className={classes.footertext}
            href="https://www.epoch.com/"
            target="_new"
          >
            Epoch Billing Support
          </a>
        </div>
        <p className={classes.center}>
          GEONDA GmbH Zimbagasse 1 1140 Vienna Austria | NET-LINE Online-Dienste
          GmbH, Wannenäckerstr. 25, D-74078 Heilbronn
        </p>
        <Link to="/complaints" className={classes.footertext}>
          {t("complaintsButton")}
        </Link>
      </div>
    );
};
export default Footer;