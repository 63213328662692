import classes from "./desktopheader.module.css";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import AuthContext from "../context/authcontext";
import { useTranslation } from "react-i18next";
import SimplePaymentBaseModal from "../components/payment/simplepayment/simplepaymentBaseModal";
import { realloggout } from "../hooks/logallout";
import RedButton from "../components/Cards/WrapperComponents/redbutton";
import Login from "../pages/login";
import PortalModal from "../components/Cards/WrapperComponents/portalmodal";
import { Auth,Analytics } from 'aws-amplify';

const DesktopHeader = () => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  const [fanDollar, setFanDollar] = useState(authCtx.fanDollar);
  const [showFanDollar, setShowFanDollar] = useState(true);
  const [showDreiStriche, setShowDreiStriche] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [buyFanDollars, setBuyFanDollars] = useState(false);
  const [isPremium, setisPremium] = useState(authCtx.isPremium);

  useEffect(() => {
    var dol = Number(authCtx.fanDollar).toFixed(2);
    setFanDollar(dol);
    if (fanDollar > 0) {
      setShowFanDollar(true);
    }
    if (!isPremium) {
      setisPremium(authCtx.isPremium);
    }
  }, [authCtx]);

  function togglebuyFanDollar() {
    setBuyFanDollars(!buyFanDollars);
  }
  
  function togglemenu() {
    setShowDreiStriche(!showDreiStriche);
  }

  const toggle = () => setDropdownOpen(!dropdownOpen);

  async function logmeoutfast() {
    await Auth.signOut();
    authCtx.logout();
    realloggout(authCtx);
    window.location.replace("/");
  }

 
const { i18n } = useTranslation();
const [isGerman, setisGerman] = useState(authCtx.myLanguage);
   function makeGerman() {
     setisGerman(true);
     i18n.changeLanguage("de");
     authCtx.switchlanguage("de");
     Analytics.record({
      name: "languageChange",
      attributes: {
        language: "de",
        userID: authCtx.userID
      }
    });
   }
   function makeEnglish() {
     setisGerman(false);
     i18n.changeLanguage("en");
     authCtx.switchlanguage("en");
     Analytics.record({
      name: "languageChange",
      attributes: {
        language: "de",
        userID: authCtx.userID
      }
    });
   }
   const [loginModal,setLoginModal] = useState(false);
   function showloginmodal() {setLoginModal(!loginModal);}
   const [isDarkmode,setIsDarkMode] = useState(false)
    function switchdm() {
      setIsDarkMode(!isDarkmode);
      authCtx.switchDarkMode();
    }
    function makeSwiss(){
      authCtx.changeCountry("CH");
    }
    function makeUserGerman(){
      authCtx.changeCountry("DE");
    }
  return (
    <div>
      {!authCtx.isLoggedIn ? null : (
        <div
          className={authCtx.isLoggedIn ? classes.language : classes.fandollar}
        >
          <div className={classes.distancerdm}>
            <a className={classes.distancer}>
              <input
                className={classes.toggle}
                type="checkbox"
                onChange={switchdm}
                checked={authCtx.darkMode}
              />
            </a>
          </div>
          <span
            className={isGerman ? classes.active : classes.inactive}
            onClick={makeGerman}
          >
            DE
          </span>
          {" | "}
          <span
            className={!isGerman ? classes.active : classes.inactive}
            onClick={makeEnglish}
          >
            EN
          </span>
        </div>
      )}
      {window.location.hostname === "localhost" ||
      authCtx.pseudo === "apitest" ||
      authCtx.userID === "f9e967d845864b7a9db90f3c9d705f43" ? (
        authCtx.country !== "CH" ? (
          <p onClick={makeSwiss} className={classes.countrymaker}>
            MakeSwiss
          </p>
        ) : (
          <p onClick={makeUserGerman} className={classes.countrymaker}>
            UnmakeSwiss
          </p>
        )
      ) : null}
      {authCtx.isLoggedIn && showFanDollar && (
        <li className={classes.fandollar} onClick={togglebuyFanDollar}>
          {fanDollar}
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/f_dollar_02.png"
            alt="FanDollar"
            className={classes.fandollarimg}
          />
        </li>
      )}
      {!authCtx.isLoggedIn && (
        <p className={classes.fandollar}>
          <span
            className={isGerman ? classes.active : classes.inactive}
            onClick={makeGerman}
          >
            DE
          </span>
          {" | "}
          <span
            className={!isGerman ? classes.active : classes.inactive}
            onClick={makeEnglish}
          >
            EN
          </span>
        </p>
      )}
      {authCtx.isLoggedIn && !authCtx.isAVS && !authCtx.isPremium ? (
        <Link to="/profile">
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/icons/achtung_2.png"
            className={classes.payAttention}
          />
        </Link>
      ) : null}
      {authCtx.isLoggedIn ? (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          direction="left"
          className={classes.dropdownmenu}
        >
          <DropdownToggle
            data-toggle="dropdown"
            tag="span"
            direction="down"
            className={classes.mymenu}
          >
            <img
              src="/images/icons/dreistricheicon_aktiv.png"
              alt="dreiStriche"
              className={`${classes.favicon} `}
            />
          </DropdownToggle>
          <DropdownMenu>
            <Link to="/profile" onClick={toggle} className={classes.dropdown}>
              <img
                src={"/images/icons/mobi_profil.png"}
                alt="Zum Profil"
            
              />
              {t("profile")}
            </Link>
            <Link
              to="/subscriptions"
              onClick={toggle}
              className={classes.dropdown}
            >
              <img
                src="/images/icons/abonnements.png"
                alt="subscriptions"
               
              />
              {t("abos")}
            </Link>
            <a
              href="https://signup.livestrip.fans/"
              target="__new"
              onClick={toggle}
              className={classes.dropdown}
            >
              <img
                src="/images/icons/beides.png"
                alt="Werde Creator"
               
              />
              {t("becomecreator")}
            </a>
            <div onClick={logmeoutfast} className={classes.dropdown}>
              <img
                src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
                alt="Logout"
                
              />
              {t("logout")}
            </div>
            <Link
              to="/impressum"
              onClick={toggle}
              className={classes.dropdownsmall}
            >
              {t("impressum")}
            </Link>
            <Link
              to="/privacy"
              onClick={toggle}
              className={classes.dropdownsmall}
            >
              {t("privacyshort")}
            </Link>
            <Link to="/agb" onClick={toggle} className={classes.dropdownsmall}>
              {t("agb")}
            </Link>
            <Link
              to="/support"
              onClick={toggle}
              className={classes.dropdownsmall}
            >
              {t("support")}
            </Link>
          </DropdownMenu>
        </Dropdown>
      ) : (
        <div className={classes.login}>
          <RedButton className={classes.loginbutton} onClick={showloginmodal}>
            {t("login")}
          </RedButton>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            direction="left"
            className={
              authCtx.isLoggedIn
                ? classes.dropdownmenu
                : classes.dropdownmenulogout
            }
          >
            <DropdownToggle
              data-toggle="dropdown"
              tag="span"
              direction="down"
              className={classes.mymenu}
            >
              <img
                src="/images/icons/dreistricheicon_aktiv.png"
                alt="dreiStriche"
                className={`${classes.favicon}`}
              />
            </DropdownToggle>
            <DropdownMenu>
              <Link
                to="/impressum"
                onClick={toggle}
                className={classes.dropdownsmall}
              >
                {t("impressum")}
              </Link>
              <Link
                to="/privacy"
                onClick={toggle}
                className={classes.dropdownsmall}
              >
                {t("privacyshort")}
              </Link>
              <Link
                to="/agb"
                onClick={toggle}
                className={classes.dropdownsmall}
              >
                {t("agb")}
              </Link>
              <Link
                to="/support"
                onClick={toggle}
                className={classes.dropdownsmall}
              >
                {t("support")}
              </Link>
            </DropdownMenu>
          </Dropdown>
        </div>
      )}
      {buyFanDollars && (
        <SimplePaymentBaseModal
          onclose={togglebuyFanDollar}
          isMinimum={true}
          currency="EUR"
        />
      )}
      {loginModal && (
        <PortalModal onclose={showloginmodal} bgnone="none">
          <Login onback={showloginmodal} />
        </PortalModal>
      )}
    </div>
  );
};

export default DesktopHeader;
