import { t } from "i18next";
import React, { useState } from "react";
import { useContext } from "react";
import { Col, Row } from "reactstrap";
import AuthContext from "../../../context/authcontext";
import PortalModal from "../../Cards/WrapperComponents/portalmodal";
import RedButton from "../../Cards/WrapperComponents/redbutton";
import WrapModal from "../../Cards/WrapperComponents/wrapmodal";
import AVSGetData from "./avsgetdata";
import classes from "./avsoverview.module.css";


const AVSOverview = (props) =>{
const authCtx=useContext(AuthContext);
const [openModal,setOpenModal] = useState(false)
const [mymethod,setMyMethod]=useState(null)
function makeavs(method){
    setMyMethod(method)
    setOpenModal(true)
    if(props.modal){props.sendback(method);}
}
function gotback(){
  props.sendback();
}
    return (
      <>
        {!openModal ? (
          <PortalModal onclose={gotback}>
            <div className={classes.container}>
              <div className={classes.flexouter}>
                <div className={classes.avsimage}>
                  <img
                    src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/avs_girl_2.png"
                    alt="theavsimage"
                    className={classes.avsimage}
                  />
                </div>
                <div>
                  <h1>{t("areyou18")}</h1>
                  <p>{t("areyou18text")}</p>
                </div>
              </div>
              <Row className={classes.thebuttons}>
                <RedButton
                  className={classes.addapayment}
                  onClick={() => {
                    makeavs("avsondato");
                  }}
                >
                  <div className={classes.flexit}>
                    <div>
                      <img
                        src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/blitz_avs.png"
                        alt=""
                        className={classes.icon}
                      />
                    </div>
                    <div className={classes.text}>
                      <h5>Blitz-AVS</h5>
                      <p>Sekundenschnelle Freischaltung ohne Ausweis</p>
                    </div>
                  </div>
                </RedButton>

                <RedButton
                  onClick={() => {
                    makeavs("SofortIdent");
                  }}
                  className={classes.addapayment}
                >
                  <div className={classes.flexit}>
                    <div>
                      <img
                        src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/sofortident.png"
                        alt=""
                        className={classes.icon}
                      />
                    </div>
                    <div className={classes.text}>
                      <h5>SofortIdent</h5>
                      <p>Sekundenschnelle Freischaltung mit Bankkonto</p>
                    </div>
                  </div>
                </RedButton>
                
              </Row>
            </div>
          </PortalModal>
        ) : (
          <>
            <h5 className={classes.head}>{t("sendinngyouaway")}</h5>
            <AVSGetData
              sendback={gotback}
              method={mymethod}
              context={authCtx}
            />
          </>
        )}
      </>
    );
}
export default AVSOverview;