import React, { useRef} from "react";
import classes from "./searchfortags.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RedButton from "../Cards/WrapperComponents/redbutton";
import { Analytics } from "aws-amplify";

const SearchForTags = () => {

 const navigate = useNavigate();
  const questionref = useRef();




  function submitHandler(event) {
    event.preventDefault();

    Analytics.record({
      name: 'searchedForTags',
      attributes: {
        text: questionref.current.value
      }
    });
    var target = questionref.current.value;
    if (questionref.current.value.toLocaleLowerCase() === "micaelaschaefer") {
      target = "micaelaschäfer";
    }
    navigate(`/models/${target}`);
  }
const {t}=useTranslation();
  return (
    <div id="searchit">
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <input
            type="text"
            id="suchtext"
            placeholder={t("searchchannels")}
            required
            ref={questionref}
            className={classes.input}
          />
          <RedButton onClick={submitHandler}>{t("abschicken")}</RedButton>
        </div>
      </form>
    </div>
  );
};

export default SearchForTags;
