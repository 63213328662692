import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/authcontext";
import { checkforpremium } from "../../hooks/checkforpremium";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import { checkstatus } from "../../hooks/checkstatus";
import { useAddProfile } from "../../hooks/useProfile";
import PortalModal from "../Cards/WrapperComponents/portalmodal";
import { Auth, Analytics } from "aws-amplify";
import { ServiceWorker } from "aws-amplify";
import classes from "./signup.module.css";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import LoadingSpinner from "../Cards/Extras/loadingspinner";
import FacebookLogin, {
  FacebookLoginClient,
} from "@greatsumini/react-facebook-login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { useADR, useIsAnonLogin, useLoginStatus } from "../../hooks/usePreflight";

function AuthForm(props) {
  const [loginfailed, setLoginFailed] = useState(false);
  const [loginsuccess, setLoginSuccess] = useState(false);
  const { t } = useTranslation();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const pseudoInputRef = useRef();
  const voucherInputRef = useRef();
  const authctx = useContext(AuthContext);
  const params = useParams();
  const [isLogin, setIsLogin] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [shallIReload, setShallIReload] = useState(false);
  const [waitingforPW, setWaitingforPW] = useState(false);
  const [gotPW, setGotPW] = useState(false);
  const [statusGoogleChrome, setStatusGoogleChrome] = useState(
    isGoogleChrome()
  );
  const [getnewpass, setgetnewpass] = useState(
    props.passwordreset ? props.passwordreset : false
  );
  function tryagain() {
    setLoginFailed(false);
  }
  const { i18n } = useTranslation();
  const thelanguage = i18n.language;
  const [relocateURL, setRelocateURL] = useState(null);
  const [isVoucher, setIsVoucher] = useState(true);
  const changeZustandlogin = useLoginStatus((state) => state.changeisLogin);
  function sendback() {
    if (props.onback) {
      props.onback();
    }
  }
  const testit = (
    <img
      alt="facebook"
      src="https://d39j9qa9t0x6xj.cloudfront.net/x45/f_logo_RGB-Blue_100.png"
      className={classes.facebookbutton}
    />
  );
  const theADR = useADR((state) => state.adr);
  
  const changeAnon = useIsAnonLogin((state) => state.changeIsAnon);
  useEffect(() => {
    var target;
    if (props.relocate) {
      target = props.relocate;
      setShallIReload(true);
    } else {
      target = window.location.pathname;
    }
    setRelocateURL(target);
    console.log(props.reloacte, "relocating to", relocateURL);
    console.log(theADR);
  
    if (theADR === "test") {
   
      setIsVoucher(true);
    }
  }, []);
  useEffect(() => {
    if (props.isSignup === true) {
      setIsLogin(false);
    } else {
      setIsLogin(true);
    }
  }, [params]);

  function switchAuthModeHandler() {
    setIsLogin((prevState) => !prevState);
  }
  // useEffect(() => {
  //   console.log("starting login", authctx);
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId:
  //         window.location.hostname.search(/Live-Strip-Fans/i) === -1
  //           ? "1294003607866528"
  //           : "645068591017916",
  //       xfbml: true,
  //       version: "v17.0",
  //     });
  //     window.FB.AppEvents.logPageView();
  //   };
  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "facebook-jssdk");
  // }, []);
  const navigate = useNavigate();

  async function submitHandler(event) {
    setLoginFailed(false);
    event.preventDefault();
    setIsLoading(true);

    const token = await executeRecaptcha("login").then((token) => {
      fetch("https://api.deine.fans/api/userlogin/preflight", {
        method: "POST",
        body: JSON.stringify({
          authToken: localStorage.getItem("jwtAuthToken"),
          captcha_action: "login",
          captcha_challenge: token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            submitHandlerthen(data.preflightToken);
          });
        } else {
          setIsLoading(false);
          throw new Error("Ein Captcha-Problem");
        }
      });
    });
  }
  async function loginwithgoogle(token) {
    const response = await fetch(
      `https://api.deine.fans/api/userlogin/socialmedia/google`,
      {
        method: "POST",
        body: JSON.stringify({
          token: token,
          adr: authctx.adr ? authctx.adr : "empty",
          loginDomain: window.location.hostname,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const answer = await response.json();
    if (!answer.error) {
      checkforpremium(answer.userID, answer.authToken, authctx);

      authctx.login(
        answer.authToken,
        answer.login,
        answer.pseudo,
        answer.userID,
        answer.refreshToken,
        answer.fanBalance,
        answer.lastLogin,
        answer.unreadMessages,
        answer.jwtAuthToken,
        answer.adr | null,
        answer.profile && answer.profile.image
          ? answer.profile.image.imageURL
          : null,
        answer.preferredLanguage ? answer.preferredLanguage : null,
        answer.country ? answer.country : null
      );
      changeZustandlogin(true);
      sendback();
      changeAnon(true);
      setLoginSuccess(true);
      if (shallIReload) {
        navigate(relocateURL);
      }
    } else {
      throw answer.error;
    }
  }

  function isGoogleChrome() {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
      // is Google Chrome on IOS
    } else if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  async function submitHandlerthen(preflight) {
    const enteredPseudo = pseudoInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    var reWhiteSpace = new RegExp("\\s+");

    // Check for white space
    if (reWhiteSpace.test(enteredPseudo)) {
      alert("Please Check Your Fields For Spaces");
      return;
    }

    if (isLogin) {
      let mypassword = enteredPassword;
      let endpointId = null;
      let myadr = "";

      try {
        Analytics._pluggables.forEach((pluggable) => {
          if (pluggable.getProviderName() === "AWSPinpoint") {
            console.log("endpointId: " + pluggable._config.endpointId + "");
            endpointId = pluggable._config.endpointId;
          }
        });
      } catch (err) {
        console.log("Error in getEndpointID: " + err.toString());
      }

      // console.log("hallo")
      // fastsignup(enteredPseudo, enteredPassword, authctx);
      fetch("https://api.deine.fans/api/userlogin", {
        method: "POST",
        body: JSON.stringify({
          pseudo: enteredPseudo,
          password: enteredPassword,
          adr: authctx.adr ? authctx.adr : "empty",
          endpointId: endpointId,
          preflightToken: preflight,
          loginDomain: window.location.hostname,
          }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.ok) {
            res.json().then(async (data) => {
              if (data.login === "OK") {
                checkforpremium(data.userID, data.authToken, authctx);

                authctx.login(
                  data.authToken,
                  data.login,
                  data.pseudo,
                  data.userID,
                  data.refreshToken,
                  data.fanBalance,
                  data.lastLogin,
                  data.unreadMessages,
                  data.jwtAuthToken,
                  data.adr | null,
                  data.profile.image ? data.profile.image.imageURL : null,
                  data.preferredLanguage ? data.preferredLanguage : null,
                  data.countryCode ? data.countryCode : null
                );
                 changeZustandlogin(true);
                sendback();
                let mypseudo = data.pseudo ? data.pseudo : "anonym";
                let myadr = data.adr ? data.adr : "empty";
                let myuserid = data.userID ? data.userID : "unknown";
                let myemail = data.email ? data.email : "noemail@Live-Strip-Fans";
                let thelanguage = data.preferredLanguage
                  ? data.preferredLanguage
                  : "de";

                try {
                  console.log("trying to sign into AWS Cognito " + mypseudo);
                  await Auth.signIn(mypseudo, mypassword);
                } catch (error) {
                  console.log("error signing into AWS Cognito", error);

                  if (error) {
                    let errorMessage = error.toString();
                    if (errorMessage) {
                      if (
                        errorMessage.indexOf("Incorrect username or password") >
                        -1
                      ) {
                        try {
                          console.log(
                            "trying to sign up to AWS Cognito as " +
                              mypseudo +
                              "|" +
                              myemail +
                              "|" +
                              myuserid +
                              "|" +
                              myadr +
                              "|" +
                              thelanguage
                          );
                          await Auth.signUp({
                            username: mypseudo,
                            password: mypassword,
                            attributes: {
                              email: myemail,
                              "custom:userid": myuserid,
                              "custom:adr": myadr,
                              "custom:preferredLanguage": thelanguage,
                            },
                            autoSignIn: {
                              // optional - enables auto sign in after user is confirmed
                              enabled: true,
                            },
                          });
                        } catch (error) {
                          console.log(
                            "error signing up for AWS Cognito",
                            error
                          );
                        }
                      }
                    }
                  }
                }

                checkstatus(authctx);

                setupPush();

                await Analytics.record({
                  name: "login",
                  attributes: {
                    userID: data.userID,
                    pseudo: data.pseudo,
                  },
                });

                setIsLoading(false);
                setLoginSuccess(true);
                if (shallIReload) {
                  navigate(relocateURL);
                }
                if (props.frompwreset) {
                  navigate("/");
                }
              } else {
                if (data.login === null) {
                  setIsLoading(false);
                  setLoginFailed(`${t("loginnull")}`);
                } else if (data.login === "Falsche Zugangsdaten(2)") {
                  setIsLoading(false);
                  setLoginFailed(`${t("wrongpassword")}`);
                } else {
                  setIsLoading(false);
                  setLoginFailed(data.login);
                }
              }
            });
          } else {
            setIsLoading(false);
            throw new Error("Fehler");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      const enteredEmail = emailInputRef.current.value;

      let endpointId = null;

      try {
        Analytics._pluggables.forEach((pluggable) => {
          if (pluggable.getProviderName() === "AWSPinpoint") {
            console.log("endpointId: " + pluggable._config.endpointId + "");
            endpointId = pluggable._config.endpointId;
          }
        });
      } catch (err) {
        console.log("Error in getEndpointID: " + err.toString());
      }
      console.log(authctx.adr);
      fetch("https://api.deine.fans/api/userlogin", {
        method: "put",
        body: JSON.stringify({
          pseudo: enteredPseudo,
          password: enteredPassword,
          passwordrepeat: enteredPassword,
          email: enteredEmail,
          adr: authctx.adr ? authctx.adr : "empty",
          couponcode: isVoucher ? voucherInputRef.current.value : "",
          endpointId: endpointId,
          preflightToken: preflight,
          loginDomain: window.location.hostname,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            if (data.login === "OK") {
              authctx.login(
                data.authToken,
                data.login,
                data.pseudo,
                data.userID,
                data.refreshToken,
                data.fanBalance,
                data.lastLogin,
                data.unreadMessages,
                data.jwtAuthToken,
                data.adr | "",
                null,
                null,
                data.countryCode ? data.countryCode : null
              );
               changeZustandlogin(true);
              sendback();
              authctx.switchlanguage(thelanguage);
              let myadr = data.adr ? data.adr : "";
              let myuserid = data.userID;

              checkstatus(authctx);

              var extra = JSON.stringify({
                userID: data.userID,
                authToken: data.jwtAuthToken,
                preferredLanguage: thelanguage,
              });

              setupPush();

              Analytics.record({
                name: "signup",
                attributes: {
                  userID: data.userID,
                  pseudo: data.pseudo,
                },
              });

              Auth.signUp({
                username: enteredPseudo,
                password: enteredPassword,
                attributes: {
                  email: enteredEmail,
                  "custom:userid": myuserid,
                  "custom:adr": myadr,
                  "custom:preferredLanguage": thelanguage,
                },
                autoSignIn: {
                  // optional - enables auto sign in after user is confirmed
                  enabled: true,
                },
              });

              useAddProfile(authctx, extra);
              setIsLoading(false);
              navigate("/");
            } else {
              if (data.login === null) {
                setIsLoading(false);
                setLoginFailed(`${t("loginnull")}`);
              } else {
                setIsLoading(false);
                setLoginFailed(data.login);
              }
            }
          });
        } else {
          res
            .json()
            .then((data) => {
              //FehlerAnzeige
              console.log(data);
              setIsLoading(false);
              setLoginFailed("Error");
            })
            .error((err) => {
              console.log(err);
              setIsLoading(false);
              setLoginFailed("Error");
            });
        }
      });
    }
  }

  function togglepassreset() {
    setgetnewpass(!getnewpass);
  }
  async function setupPush() {
    const serviceWorker = new ServiceWorker();

    try {
      const registeredServiceWorker = await serviceWorker.register(
        "/firebase-messaging-sw.js",
        "/"
      );
      serviceWorker.enablePush(
        "BPHWQ9EVz9gSk9knXj3LLvTAyqB0s1N2ONMCVzNr9WAhwrA9_Y2Fp_-Phr7VzAGeOzioKJVdKS3IXyhHBmNUiZU"
      );
      /*try {
        console.log('Updating pinpoint endpoint');
        Analytics.updateEndpoint({
          channelType: "GCM",
          optOut: "NONE"
        });
        console.log('Done updating pinpoint endpoint');
      } catch(err) {
        console.log("Error updating pinpoint endpoint: " + err.toString());
      }*/
    } catch (err) {
      console.log("Error on serviceWorker.enablePush: " + err.toString());
    }

    const firebaseConfig = {
      apiKey: "AIzaSyAJDcYtt_0XVIKmc25dgZV0LOVj0rK83TA",
      authDomain: "Live-Strip-Fans-f79b9.firebaseapp.com",
      projectId: "Live-Strip-Fans-f79b9",
      storageBucket: "Live-Strip-Fans-f79b9.appspot.com",
      messagingSenderId: "220559797435",
      appId: "1:220559797435:web:a262367664c493e541cd9e",
      measurementId: "G-R9K5GE61ZQ",
    };

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    getToken(messaging, {
      vapidKey:
        "BPHWQ9EVz9gSk9knXj3LLvTAyqB0s1N2ONMCVzNr9WAhwrA9_Y2Fp_-Phr7VzAGeOzioKJVdKS3IXyhHBmNUiZU",
    })
      .then((currentToken) => {
        if (currentToken) {
          if (currentToken !== "") {
            console.log("got Firebase token: " + currentToken);
            try {
              console.log("Updating userEndpoint: " + currentToken);
              Analytics.updateEndpoint({
                address: currentToken,
                channelType: "GCM",
                optOut: "NONE",
              });
              console.log("Done updating userEndpoint: " + currentToken);
            } catch (err) {
              console.log("Error updating endpoint: " + err.toString());
            }
          }
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          if (typeof window.Notification === "undefined") {
            console.log("Notifications are not supported in this browser.");
          } else {
            if (typeof window.Notification.requestPermission === "function") {
              window.Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  console.log("Notification permission granted.");
                } else {
                  console.log("Unable to get permission to notify.");
                }
              });
            } else {
              console.log(
                "Notifications are not supported in this browser(requestPermission is not a function)."
              );
            }
          }
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }
  function getnewpassword() {
    setWaitingforPW(true);
    const sendenteredmail = emailInputRef.current.value;
    const sendeneredpseudo = pseudoInputRef.current.value;
    console.log(
      `https://api.deine.fans/api/userpasswordlost?username=${encodeURIComponent(
        sendeneredpseudo
      )}&email=${encodeURIComponent(
        sendenteredmail
      )}&domain=${encodeURIComponent(window.location.origin.split("//")[1])}`
    );
    try {
      fetch(
        `https://api.deine.fans/api/userpasswordlost?username=${encodeURIComponent(
          sendeneredpseudo
        )}&email=${encodeURIComponent(
          sendenteredmail
        )}&domain=${encodeURIComponent(window.location.origin.split("//")[1])}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => {
        res.json().then((data) => {
          checkreceivedData(data, authctx, "AuthForm");
          if (data.success) {
            //alert(t("youvegotamail"));
            setGotPW(t("youvegotamail"));
          }
          if (data.error) {
            setGotPW(data.error);
          }
        });
      });
    } catch (err) {
      console.error(err);
    }
  }
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      //console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("login").then((token) => {
      fetch("https://api.deine.fans/api/userlogin/preflight", {
        method: "POST",
        body: JSON.stringify({
          authToken: localStorage.getItem("jwtAuthToken"),
          captcha_action: "login",
          captcha_challenge: token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            return data;
          });
        } else {
          throw new Error("Ein Captcha-Problem");
        }
      });
    });
  }, [executeRecaptcha]);

  async function responseFacebook(input) {
    const response = await fetch(
      `https://api.deine.fans/api/userlogin/socialmedia/facebook`,
      {
        method: "POST",
        body: JSON.stringify({
          token: Buffer.from(JSON.stringify(input)).toString("base64"),
          adr: authctx.adr ? authctx.adr : "empty",
          loginDomain: window.location.hostname,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const answer = await response.json();
    if (!answer.error) {
      checkforpremium(answer.userID, answer.authToken, authctx);

      authctx.login(
        answer.authToken,
        answer.login,
        answer.pseudo,
        answer.userID,
        answer.refreshToken,
        answer.fanBalance,
        answer.lastLogin,
        answer.unreadMessages,
        answer.jwtAuthToken,
        answer.adr | null,
        answer.profile && answer.profile.image
          ? answer.profile.image.imageURL
          : null,
        answer.preferredLanguage ? answer.preferredLanguage : null,
        answer.countryCode ? answer.countryCode : null
        
      );
       changeZustandlogin(true);
      sendback();
      changeAnon(true);
      setLoginSuccess(true);
      if (shallIReload) {
        navigate(relocateURL);
      }
    } else {
      throw answer.error;
    }
  }
  async function statusChangeCallback(response) {
    console.log(response, response.authResponse.accessToken);
    window.FB.api(
      response.authResponse.userID,
      { fields: "email, public_profile" },
      function (response) {
        responseFacebook(response);
      }
    );
  }
  function loginfacebook() {
    window.FB.login(
      function (response) {
        if (!response || response.error) {
          console.log("Error occured");
        } else {
          if (response.status === "connected") {
            statusChangeCallback(response);
          }
        }
      },
      { scope: "public_profile,email" }
    );
  }
  const componentClicked = () => {};
  const twitterAuthHandler = (err, data) => {
    console.log(err, data);
  };
  return (
    <section
      className={classes.auth}
      style={{
        backgroundColor: isloading ? "#C8323" : "#C8323",
        minHeight: isloading ? "300px" : "unset",
      }}
    >
      {isloading && <LoadingSpinner />}
      {getnewpass && !waitingforPW ? (
        <div style={{ display: isloading ? "none" : "block" }}>
          <h1>{t("getnewpassword")}</h1>
          <p>{t("tohowtogetnewpasswordtext")}</p>
          <form onSubmit={getnewpassword}>
            <div className={classes.control}>
              <label htmlFor="text">{t("yourpseudo")}</label>
              <input
                type="text"
                id="text"
                autoComplete="username"
                required
                ref={pseudoInputRef}
              />
            </div>
            <div className={classes.control}>
              <label htmlFor="email">{t("yourmail")}</label>
              <input
                type="email"
                id="email"
                autoComplete="email"
                required
                ref={emailInputRef}
              />
            </div>
          </form>
          <div className={classes.actions}>
            <button
              type="button"
              className={classes.toggle}
              onClick={togglepassreset}
            >
              {t("Tothelogin")}
            </button>
            <button onClick={getnewpassword}>{t("send")}</button>
          </div>
        </div>
      ) : !gotPW && getnewpass ? (
        <LoadingSpinner />
      ) : getnewpass ? (
        <h5>{gotPW}</h5>
      ) : null}
      {!getnewpass && (
        <div style={{ display: isloading ? "none" : "block" }}>
          <h1>{isLogin ? `${t("login")}` : `${t("signup")}`}</h1>

          <div className={classes.flexit}>
            {/* <button
              style={{
                backgroundColor: "#3578E5",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "bold",
                padding: "3px 16px",
                border: "2px solid white",
                borderRadius: "4px",
              }}
              onClick={() => {
                loginfacebook();
              }}
            >
              f
            </button> */}

            {console.log(window.location.hostname)}
            <GoogleOAuthProvider
              clientId={
                window.location.hostname.search("livestrip.fans") === -1
                  ? window.location.hostname.search("livestripfans") === -1
                    ? "444746902693-fkg45u94gb0sql2ndeuf1lia0nb33akf.apps.googleusercontent.com"
                    : "837967393166-s8pu2441uoi7indoml0gqcm973rpaoqa.apps.googleusercontent.com"
                  : "837967393166-s8pu2441uoi7indoml0gqcm973rpaoqa.apps.googleusercontent.com"
              }
            >
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse.credential);

                  loginwithgoogle(credentialResponse.credential);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                text="G"
                type="icon"
                logo_alignment="center"
                shape="rectangular"
              />
            </GoogleOAuthProvider>
          </div>

          {loginfailed && (
            <p className={classes.white}>{t("failedtryagain")}</p>
          )}
          <form onSubmit={submitHandler}>
            <div className={classes.control}>
              <label htmlFor="text">{t("yourpseudo")}</label>
              <input
                type="text"
                id="text"
                autoComplete="nickname"
                required
                ref={pseudoInputRef}
              />
            </div>
            <div className={classes.control}>
              <label htmlFor="password">{t("yourpassword")}</label>
              <input
                type="password"
                id="password"
                required
                ref={passwordInputRef}
                autoComplete={isLogin ? "current-password" : "new-password"}
              />
            </div>
            {!isLogin ? (
              <div className={classes.control}>
                <label htmlFor="email">{t("yourmail")}</label>
                <input
                  type="email"
                  id="email"
                  autoComplete={statusGoogleChrome ? "username" : "email"}
                  required
                  ref={emailInputRef}
                />
              </div>
            ) : null}
            {!isLogin && isVoucher ? (
              <div className={classes.control} key={isVoucher}>
                <label htmlFor="voucher">{t("voucher")}</label>
                <input type="text" id="voucher" ref={voucherInputRef} />
              </div>
            ) : null}
            <div className={classes.actions2}>
              {isLogin && (
                <button
                  aria-label="Passwort reset"
                  type="button"
                  className={classes.toggle}
                  onClick={togglepassreset}
                >
                  {t("forgotpassword")}
                </button>
              )}
            </div>
            {loginsuccess && (
              <div className={classes.actions}>
                <p>Login Success</p>
                <button
                  onClick={(document.location.href = "/profile")}
                  aria-label="Login Success"
                >
                  `${t("continue")}`
                </button>
              </div>
            )}
            <div className={classes.actions}>
              <button aria-label="Create account">
                {isLogin ? `${t("login")}` : `${t("createaccount")}`}
              </button>

              <button
                type="button"
                aria-label="Switch create"
                className={classes.toggle}
                onClick={switchAuthModeHandler}
              >
                {isLogin ? `${t("createaccount")}` : `${t("tologin")}`}
              </button>
            </div>
            {loginfailed && (
              <PortalModal onclose={tryagain}>
                <div>
                  <h1></h1>
                  <p>{loginfailed}</p>
                </div>
              </PortalModal>
            )}
          </form>
        </div>
      )}
    </section>
  );
}

export default AuthForm;
