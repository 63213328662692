import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import { checkstatus } from "../../hooks/checkstatus";
import { fastsignup } from "../../hooks/fastsignup";
import useMyHttp from "../../hooks/myhttp";
import RedButton from "../Cards/WrapperComponents/redbutton";
import classes from "./newmailpw.module.css";
import { Auth,Analytics } from 'aws-amplify';
import PortalModal from "../Cards/WrapperComponents/portalmodal";
import AuthForm from "./signup";
import PortalFeedbackModal from "../Cards/WrapperComponents/portalfeedbackmodal";

const NewMailPW = (props) =>{
const authCtx=useContext(AuthContext);
const {t}=useTranslation();
const firstPasswordRef=useRef()
const secondPasswordRef = useRef();
const oldPasswordRef = useRef();
const EmailRef = useRef();
const [usedPw,setUsedPw] =useState(null)
const [extraError,setExtraError]=useState(false)
const [modal,setModal] =useState(false)

const { isLoading, data,error,sendRequest} = useMyHttp();
 function togglemodal(){
  setModal(!modal)
 }
async function setnewpassword(oldPassword,enteredPassword,enteredMail) {
    // const cognitoUser = await Auth.currentAuthenticatedUser();
    // if(cognitoUser) {
    //   await Auth.changePassword(cognitoUser, enteredPassword, enteredPassword);
    //   const result = await Auth.updateUserAttributes(cognitoUser, {
    //     email: enteredMail
    //   });
    // }

    const body = JSON.stringify({
      userID: props.data.userID ? props.data.userID : authCtx.userID,
      oldPassword: oldPassword,
      newPassword: enteredPassword,
      newEMail: enteredMail,
      authToken: props.data.authToken ? props.data.authToken : authCtx.token,
    });

    sendRequest(
      "https://api.deine.fans/api/useraccount",
      "POST",
      body,
      null,
      null,
      null,
      null
    );
}

async function submitHandler(e){
    e.preventDefault();
    const oldPassword = !props.pwreset ? oldPasswordRef.current.value : "";
    const enteredPassword = firstPasswordRef.current.value;
    const enteredsecondpassword = secondPasswordRef.current.value;
    const enteredMail = EmailRef.current.value;

    if (enteredPassword!==enteredsecondpassword){setExtraError("Passwwörter stimmen nicht überein");return;}

    setUsedPw(enteredPassword);
togglemodal();
    setnewpassword(oldPassword,enteredPassword,enteredMail);
   

    if (!props.pwreset) {props.sendback();}

}
// useEffect(() => {
//   if (data) {
//     console.log("logging in")
//     fastsignup(props.myuserID, usedPw, authCtx);
//   }
// }, [data]);

    return (
      <div className={classes.boxit}>
        <div className={classes.form}>
          <h5 className={classes.theheader}>{t("changepassword")}</h5>
          <form onSubmit={submitHandler}>
            <div className={classes.control}>
              <label htmlFor="email">{t("newmail")}</label>
              <input
                type="email"
                id="email"
                required
                ref={EmailRef}
                autoComplete="email"
              />
            </div>

            {props.pwreset ? null : (
              <div className={classes.control}>
                <label htmlFor="oldpassword">{t("oldpassword")}</label>
                <input
                  type="password"
                  id="oldpassword"
                  required
                  ref={oldPasswordRef}
                  autoComplete="current-password"
                />
              </div>
            )}

            <div className={classes.control}>
              <label htmlFor="password">{t("newpassword")}</label>
              <input
                type="password"
                id="password"
                required
                ref={firstPasswordRef}
                autoComplete="new-password"
              />
            </div>

            <div className={classes.control}>
              <label htmlFor="password">{t("repeatpassword")}</label>
              <input
                type="password"
                id="repeatpassword"
                required
                ref={secondPasswordRef}
                autoComplete="new-password"
              />
            </div>

            <div className={classes.actions} onClick={submitHandler}>
              <RedButton>{t("send")}</RedButton>
            </div>
          </form>
        </div>
        {isLoading && <h5>{t("loading")}</h5>}
        {error && <h5>{t("error")}</h5>}
        {data && modal && props.pwreset ? (
          <h5>

            <PortalModal onclose={togglemodal}>
              {data.passwordChanged === true && data.emailChanged === true ? (
                <div className={classes.logininfo}>
                  <h5>
                    {t("passwordandmailreset")} {t("pleaseloginnowtoproceed")}
                  </h5>
                </div>
              ) : data.passwordChanged === true &&
                data.emailChanged === false ? (
                <div className={classes.logininfo}>
                  <h5>
                    {t("passwordreset")} {t("pleaseloginnowtoproceed")}
                  </h5>
                </div>
              ) : data.passwordChanged === false &&
                data.emailChanged === true ? (
                <div className={classes.logininfo}>
                  <h5>{t("mailreset")}</h5>
                </div>
              ) : data.passwordChanged === false &&
                data.emailChanged === false ? (
                <div className={classes.logininfo}>
                  <h5>{t("noreset")}</h5>
                </div>
              ) : null}
              <AuthForm frompwreset={true}/>
            </PortalModal>
          </h5>
        ) : null}
        {data && modal && !props.pwreset ? (
          <PortalFeedbackModal onclose={togglemodal}>
            {" "}
            {data.passwordChanged === true && data.emailChanged === true ? (
              <h5>{t("passwordandmailreset")}</h5>
            ) : data.passwordChanged === true && data.emailChanged === false ? (
              <h5>{t("passwordreset")}</h5>
            ) : data.passwordChanged === false && data.emailChanged === true ? (
              <h5>{t("mailreset")}</h5>
            ) : data.passwordChanged === false &&
              data.emailChanged === false ? (
              <h5>{t("noreset")}</h5>
            ) : null}{" "}
          </PortalFeedbackModal>
        ) : null}
        {extraError && <h5>{t("passwortstimmtnichtüberein")}</h5>}
      </div>
    );
}
export default NewMailPW;