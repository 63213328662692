import { checkreceivedData } from "./checkReceivedData";

export async function useLikes(guid,context) {

  fetch(`https://api.deine.fans/api/timeline/like/${encodeURIComponent(guid)}`, {
    method: "post",
    body: JSON.stringify({
      userID: context.userID,
      authToken: context.jwtAuthToken,
    }),

    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => {
    res.json().then((data) => {
      checkreceivedData(data);
    
      if (data.error) {
        console.log(data.error);
      }
      if (data.success) {
        console.log(data.success);
      }
    });
  });
}
