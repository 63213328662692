import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RedButton from "../Cards/WrapperComponents/redbutton";
import classes from "./firsttime.module.css";
import { Col, Row } from "reactstrap";
import AuthContext from "../../context/authcontext";
import ErsterPost from "../Cards/Extras/ErsterPost/ErsterPost";
import BasicTimeline from "../../pages/basicTimeline/basicTimeline";
import { useIsAnonLogin } from "../../hooks/usePreflight";

const Firsttime = () => {
  const { t } = useTranslation();

  const [width, setWidth] = useState(Number(window.innerWidth));
  const [isitMobile, setisItMobile] = useState(false);
  const isAnon=useIsAnonLogin(state=>state.isAnon)
console.log("isAnon",isAnon)
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    if (width <= 800) {
      setisItMobile(true);
    }
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    setIsDarkMode(authCtx.darkMode);
  }, [authCtx]);
  return (
    <Fragment>
      {/* <Row className={classes.isDarkMode ? classes.boxdm : classes.box}>
        {!isitMobile ? (
          <>
            <Col xs="6">
              <img
                src="/images/avsgirl.png"
                className={classes.avsimage}
                alt="avsimage"
              />
            </Col>
            <Col xs="6">
              <h2 className={classes.header}>{t("welcome1")}</h2>
              <p className={classes.text}>{t("welcome2")}</p>
              <p className={classes.text}>{t("welcome3")}</p>
              <Link to="/profile">
                <RedButton>{t("makeavs")}</RedButton>
              </Link>
            </Col>
          </>
        ) : (
          <div>
            <h2 className={classes.header}>{t("welcome1")}</h2>
            <img
              src="/images/avsgirl.png"
              className={classes.avsimage}
              alt="avsimagemobile"
            />

            <p className={classes.text}>{t("welcome3")}</p>
            <Link to="/profile">
              <RedButton>{t("makeavs")}</RedButton>
            </Link>
          </div>
        )}
      </Row> */}
     
      <ErsterPost showinfonofollows={false} isAnon={isAnon}/>
      <BasicTimeline dontshow={true} />
    </Fragment>
  );
};

export default Firsttime;
