import React, { useState } from "react";
import useCheckMobileScreen from "../../hooks/useCheckMobile";
import classes from "./timelinefilterbar.module.css";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

function Timelinefilterbar(props) {
  const [showToggle, setShowToggle] = useState(false);
  const [input, setInput] = useState(props.input);
  const [askForExtras, setAskForExtra] = useState(props.givemeextra);


  function setMyInput(type) {
    setInput(type);
    props.checkback(type);
    setShowToggle(!showToggle);
  }

  function setMyExtra(value){
    if (props.extrasorts) {
      props.extrasorts(value);
    }
  }
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  return (
    <div
      className={
        isMobile
          ? classes.wrappermobile
          : props.main
          ? classes.wrappermain
          : classes.wrapper
      }
    >
      {!showToggle ? (
        <div
          className={classes.bar}
          onClick={() => {
            setShowToggle(!showToggle);
          }}
        >
          <img src="/images/icons/pfeil_runter.png" />
        </div>
      ) : (
        <div
          className={
            askForExtras
              ? classes.fullbar2
              : isMobile
              ? classes.fullbarmobile
              : classes.fullbar
          }
        >
          <Row>
            <Col xs="1">
              <img
                src="/images/icons/pfeil_hoch.png"
                onClick={() => {
                  setShowToggle(!showToggle);
                }}
              />
            </Col>
            <Col xs="11">
              <div className={classes.fullwidth}>
                <div className={classes.innerflex}>
                  <form>
                    <input
                      type="radio"
                      onChange={() => setMyInput("publishTime")}
                    />
                    <label>{t("nachDatum")}</label>
                    <input type="radio" onChange={() => setMyInput("likes")} />
                    <label>{t("nachLikes")}</label>
                    <input
                      type="radio"
                      onChange={() => setMyInput("ranking")}
                    />
                    <label>{t("nachRating")}</label>
                  </form>
                </div>
              </div>
              {askForExtras ? (
                <div className={classes.innerflex}>
                  <form>
                    <input type="radio" onChange={() => setMyExtra("video")} />
                    <label>{t("videos")}</label>
                    <input type="radio" onChange={() => setMyInput("image")} />
                    <label>{t("images")}</label>
                    <input type="radio" onChange={() => setMyInput("none")} />
                    <label>{t("beides")}</label>
                  </form>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default Timelinefilterbar;
