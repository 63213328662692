import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/authcontext";
import classes from "./wrapmodal.module.css";



const WrapModal = (props) =>{

const [amifullscreen,setAmIFullscreen]=useState(false);
useEffect(()=>{
  if(props.fullscreen===true){setAmIFullscreen(true)}
})

    function closeme(){
        setOnshow(false)
        props.onclose();
    }
const [onshow,setOnshow]=useState(true)

useEffect(()=>{
    setOnshow(true)
},[])
const authCtx=useContext(AuthContext);
const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
useEffect(() => {
  setDarkMode(authCtx.darkMode);
}, [authCtx]);
    return (
      <>
        {onshow && (
          <>
            <div className={classes.darkBG} onClick={closeme} />
            <div
              className={
                !isDarkmode
                  ? !amifullscreen
                    ? classes.centered
                    : classes.centeredfull
                  : !amifullscreen
                  ? classes.centereddm
                  : classes.centeredfulldm
              }
            >
              <div
                className={
                  !isDarkmode
                    ? !amifullscreen
                      ? classes.modal
                      : classes.modalfull
                    : !amifullscreen
                    ? classes.modaldm
                    : classes.modalfulldm
                }
              >
                <button className={classes.closeBtn} onClick={closeme}>
                  <img
                    src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
                    alt="close modal"
                    className={classes.closex}
                  />
                </button>
                {props.children}
              </div>
            </div>
          </>
        )}
      </>
    );
   
}

export default WrapModal;