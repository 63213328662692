import React, { Fragment, useContext, useEffect, useState } from "react";

import AllContent from "../../components/lists/displays/allcontent";
import PleaseLogin from "../../components/sonstige/pleaselogin";
import AuthContext from "../../context/authcontext";
import TimeLineTest from "../../components/lists/Timeline/timelintest";
import Timelinefilterbar from "../../components/filterbars/timelinefilterbar";
import CollectionTimeline from "../../components/lists/displays/collectiontimeline";

const Collection = () => {

   const authCtx=useContext(AuthContext);

      useEffect(() => {
      
        window.scrollTo(0, 0);
      }, []);
        const [sortType, setSortType] = useState(null);
        function getback(info) {
          setSortType(info);
        }
        const [extravideo,setextravideo]=useState(false);
         const [extraimage,setextraimage]=useState(false);
  function getextrasorts(info){
if(info==="video"){setextravideo(true);setextraimage(false)}
if (info === "image") {
  setextravideo(false);
  setextraimage(true);
}
if (info==="none"){setextravideo(false);
  setextraimage(false);}
  }
    return (
      <Fragment>
        {authCtx.isLoggedIn ? (
          <Fragment>
            {/* <AllContent /> */}
            <CollectionTimeline />
            {/* <Timelinefilterbar sort={sortType} checkback={getback} extrasorts={getextrasorts} givemeextra={true}/> */}
            {/* <TimeLineTest isFavList={true} showvideos={extravideo} showimages={extraimage}/> */}
          </Fragment>
        ) : (
          <PleaseLogin />
        )}
      </Fragment>
    );

};

export default Collection;