import React, { Fragment, memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import AuthContext from "../../../../context/authcontext";
import useCheckMobileScreen from "../../../../hooks/useCheckMobile";
import RedButton from "../../WrapperComponents/redbutton";
import classes from "./displaySub.module.css";
import ManageSubs from "./managesubs";
import PortalFeedbackModal from "../../WrapperComponents/portalfeedbackmodal";
import { Link } from "react-router-dom";
import SubscriptionManager from "./subscriptionManager";
import Login from "../../../../pages/login";
import PortalModal from "../../WrapperComponents/portalmodal";

const DisplaySub = memo((props) => {
  const [tiers, setTiers] = useState(props.tiers);
  const girl = props.name;
  const girlid = props.producerID;
  const { t } = useTranslation();
  const authCtx = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [payLoad, setPayLoad] = useState(false);
  const [tochange, setToChange] = useState(props.change | false);
  const [breite, setBreite] = useState(6);
  const MyRuntime = (props) => {
    var a = Number(props.val1.replace(/\D/g, ""));
    var b = Number(props.val2);
    const [thenumber, setTheNumber] = useState(null);
    var c = a * b;

    return <span>{c}</span>;
  };
 
  const MyLaufzeit = (props) => {
    var value = props.value;
    var other = value.replace(/\D/g, "");
    var justthetime = value.replace(other, "");
    if (props.plural === true) {
      other = 2;
    }
    const [showisLogin, setIsLogin] = useState(false);
    function toggleIsLogin() {
      setIsLogin(!showisLogin);
    }
    return (
      <Fragment>
        {other <= 1 && !props.novalue && !props.normalruntime && (
          <span>
            {value.search("d") > 0 && value.replace("1d", `${t("tag")}`)}
            {value.search("m") > 0 && value.replace("1m", `${t("monat")}`)}
            {value.search("w") > 0 && value.replace("1w", `${t("woche")}`)}
          </span>
        )}
        {other <= 1 && !props.novalue && props.normalruntime && (
          <span>
            {value.search("d") > 0 && value.replace("d", `${t("tag")}`)}
            {value.search("m") > 0 && value.replace("m", `${t("monat")}`)}
            {value.search("w") > 0 && value.replace("w", `${t("woche")}`)}
          </span>
        )}
        {other > 1 && !props.novalue && (
          <span>
            {value.search("d") > 0 && value.replace("d", `${t("tage")}`)}
            {value.search("m") > 0 && value.replace("m", `${t("monate")}`)}
            {value.search("w") > 0 && value.replace("w", `${t("wochen")}`)}
          </span>
        )}
        {other <= 1 && !props.normalruntime && (
          <span>
            {value.search("d") > 0 && justthetime.replace("1d", `${t("tag")}`)}
            {value.search("m") > 0 &&
              justthetime.replace("1m", `${t("monat")}`)}
            {value.search("w") > 0 &&
              justthetime.replace("1w", `${t("woche")}`)}
          </span>
        )}
        {other > 1 && !props.normalruntime && (
          <span>
            {value.search("d") > 0 && justthetime.replace("d", `${t("tage")}`)}
            {value.search("m") > 0 &&
              justthetime.replace("m", `${t("monate")}`)}
            {value.search("w") > 0 &&
              justthetime.replace("w", `${t("wochen")}`)}
          </span>
        )}
      </Fragment>
    );
  };

  useEffect(() => {
    if (props.fullsize) {
      setBreite(12);
    }
  }, []);

  let payload;

  function buythis(id, tier) {
    if (tier.initialPrice === tier.price) {
      setPayLoad({
        tier: tier,
        kosten: tier.price,
        userID: authCtx.userID,
        producerID: girlid,
        tierID: id,
        authToken: authCtx.token,
        type: "buy",
        billingCycle: tier.billingPeriod,
        currency: tier.currency ? tier.currency : authCtx.country==="CH"?"CHF":"EUR",
      });
    } else {
      setPayLoad({
        tier: tier,
        kosten: tier.price,
        userID: authCtx.userID,
        producerID: girlid,
        tierID: id,
        authToken: authCtx.token,
        type: "buy",
        billingCycle: tier.billingPeriod,
        newpaymentcycle: tier.initialRuntime,
        firstPrice: tier.initialPrice,
        currency: tier.currency
          ? tier.currency
          : authCtx.country === "CH"
          ? "CHF"
          : "EUR",
      });
    }
    {
      setShowModal(!showModal);
    }
  }
  function changethis(id, tier) {
    if (tier.initialPrice === tier.price) {
      setPayLoad({
        tier: tier,
        kosten: tier.price,
        userID: authCtx.userID,
        producerID: girlid,
        tierID: id,
        authToken: authCtx.token,
        type: "change",
        changeSubscriptionID: props.subid,
        billingCycle: tier.billingPeriod,
      });
    } else {
      setPayLoad({
        tier: tier,
        kosten: tier.price,
        userID: authCtx.userID,
        producerID: girlid,
        tierID: id,
        authToken: authCtx.token,
        type: "change",
        changeSubscriptionID: props.subid,
        newpaymentcycle: tier.initialRuntime,
        firstPrice: tier.initialPrice,
        billingCycle: tier.billingPeriod,
      });
    }

    {
      setShowModal(!showModal);
    }
  }
  function sendback(info) {
    setShowModal(!showModal);
console.log(info);
    if (info && info.paid === true) {
      console.log(info)
      props.sendchange(info);
    }
  }
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    setIsDarkMode(authCtx.darkMode);
  }, [authCtx]);

  const isMobile = useCheckMobileScreen();
  const [showLoginInfo, setShowLoginInfo] = useState(false);
  function toggleLoginInfo(id) {
    
    if (id) {
      localStorage.setItem("wantedtoBuc", [
        id.id,
        props.name,
        window.location.pathname,
      ]);
    }
    setShowLoginInfo(!showLoginInfo);
  }

  return (
    <Fragment>
      <div
        className={props.extrahead ? classes.containertrans : classes.container}
      >
        {props.extrahead ? (
          <h5 className={isDarkMode ? classes.extraheaddm : classes.extrahead}>
            {t("subsandbenefits")}
          </h5>
        ) : null}
        <Row>
          {tiers &&
            tiers.length > 0 &&
            tiers.map((tier) => (
              <Col xs={isMobile ? "12" : breite} key={tier.tierName}>
                <div key={`a${tier.tierName}`} className={classes.wrapper}>
                  <li
                    key={tier.tierName}
                    className={isDarkMode ? classes.thelistdm : classes.thelist}
                  >
                    <div className={classes.innerWrapper}>
                      <p className={classes.header}>{tier.tierName}</p>
                      {tier.discountFormula &&
                        tier.discountFormula.search("%") > 0 && (
                          <p className={classes.content}>
                            {tier.discountFormula} {t("rabattaufallepaidposts")}
                          </p>
                        )}
                      {tier.discountFormula &&
                        tier.discountFormula.search("ABS") > 0 && (
                          <p className={classes.content}>
                            {(
                              Number(
                                tier.discountFormula &&
                                  tier.discountFormula.replace("ABS", "")
                              ) / 100
                            ).toFixed(2)}{" "}
                            {t("eurrabattaufallepaidposts")}
                          </p>
                        )}
                      <div>
                        <p
                          className={classes.secondline}
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {tier.tierDescription}
                        </p>

                        {tier.initialPrice === tier.price ||
                        tier.initialRuntime === 0 ? (
                          <p className={classes.priceline}>
                            {t("Preis")}
                            {" : "}
                            {Number(tier.price / 100).toFixed(2)}{" "}
                            {tier.currency
                              ? tier.currency
                              : authCtx.country === "CH"
                              ? "CHF"
                              : "EUR"}
                            {" pro "}
                            {tier.billingPeriod.replace(/\D/g, "") <= 1 && (
                              <span>
                                {tier.billingPeriod.search("d") > 0 &&
                                  tier.billingPeriod.replace(
                                    "1d",
                                    `${t("tag")}`
                                  )}
                                {tier.billingPeriod.search("m") > 0 &&
                                  tier.billingPeriod.replace(
                                    "1m",
                                    `${t("monat")}`
                                  )}
                                {tier.billingPeriod.search("w") > 0 &&
                                  tier.billingPeriod.replace(
                                    "1w",
                                    `${t("woche")}`
                                  )}
                              </span>
                            )}
                            {tier.billingPeriod.replace(/\D/g, "") > 1 && (
                              <span>
                                {tier.billingPeriod.search("d") > 0 &&
                                  tier.billingPeriod.replace(
                                    "d",
                                    `${t("tage")}`
                                  )}
                                {tier.billingPeriod.search("m") > 0 &&
                                  tier.billingPeriod.replace(
                                    "m",
                                    `${t("monate")}`
                                  )}
                                {tier.billingPeriod.search("w") > 0 &&
                                  tier.billingPeriod.replace(
                                    "w",
                                    `${t("wochen")}`
                                  )}
                                .
                              </span>
                            )}
                          </p>
                        ) : (
                          <div className={classes.specialcontainer}>
                            <p className={classes.specialofferheader}>
                              {t("superrabatt")}
                            </p>
                            <p className={classes.specialoffer}>
                              {t("thefirst")}
                              <span className={classes.specialprice}>
                                {Number(tier.billingPeriod.replace(/\D/g, "")) *
                                  Number(tier.initialRuntime)}
                              </span>
                              <span className={classes.specialprice}>
                                {tier.billingPeriod.search("d") > 0 &&
                                  t("tage")}
                                {tier.billingPeriod.search("m") > 0 &&
                                  t("monate")}
                                {tier.billingPeriod.search("w") > 0 &&
                                  t("wochen")}
                              </span>
                              {t("foronly")}{" "}
                              <span className={classes.specialprice}>
                                {Number(tier.initialPrice / 100).toFixed(2)}{" "}
                                {tier.currency
                                  ? tier.currency
                                  : authCtx.country === "CH"
                                  ? "CHF"
                                  : "EUR"}
                                {" pro "}
                              </span>
                              <span className={classes.specialprice}>
                                <MyLaufzeit
                                  value={tier.billingPeriod}
                                  normalruntime={true}
                                />
                              </span>
                              <br />
                              <span className={classes.smallprice}>
                                {t("danach")}
                                {" : "}
                                {Number(tier.price / 100).toFixed(2)}{" "}
                                {tier.currency
                                  ? tier.currency
                                  : authCtx.country === "CH"
                                  ? "CHF"
                                  : "EUR"}
                                {" pro "}
                                {tier.billingPeriod.replace(/\D/g, "") <= 1 && (
                                  <span>
                                    {tier.billingPeriod.search("d") > 0 &&
                                      tier.billingPeriod.replace(
                                        "1d",
                                        `${t("tag")}`
                                      )}
                                    {tier.billingPeriod.search("m") > 0 &&
                                      tier.billingPeriod.replace(
                                        "1m",
                                        `${t("monat")}`
                                      )}
                                    {tier.billingPeriod.search("w") > 0 &&
                                      tier.billingPeriod.replace(
                                        "1w",
                                        `${t("woche")}`
                                      )}
                                  </span>
                                )}
                                {tier.billingPeriod.replace(/\D/g, "") > 1 && (
                                  <span>
                                    {tier.billingPeriod.search("d") > 0 &&
                                      tier.billingPeriod.replace(
                                        "d",
                                        `${t("tage")}`
                                      )}
                                    {tier.billingPeriod.search("m") > 0 &&
                                      tier.billingPeriod.replace(
                                        "m",
                                        `${t("monate")}`
                                      )}
                                    {tier.billingPeriod.search("w") > 0 &&
                                      tier.billingPeriod.replace(
                                        "w",
                                        `${t("wochen")}`
                                      )}
                                  </span>
                                )}
                              </span>
                            </p>
                            <h5 className={classes.initialPrice}>
                              {t("Preis")}:
                              {tier.initialPrice === tier.price ? (
                                <span>
                                  {Number(tier.price / 100).toFixed(2)}{" "}
                                  {tier.currency
                                    ? tier.currency
                                    : authCtx.country === "CH"
                                    ? "CHF"
                                    : "EUR"}{" "}
                                  {" / "}
                                </span>
                              ) : (
                                <span>
                                  {" "}
                                  {Number(tier.initialPrice / 100).toFixed(
                                    2
                                  )}{" "}
                                  {tier.currency
                                    ? tier.currency
                                    : authCtx.country === "CH"
                                    ? "CHF"
                                    : "EUR"}{" "}
                                  {" / "}
                                </span>
                              )}
                              {tier.billingPeriod.replace(/\D/g, "") <= 1 && (
                                <span>
                                  {tier.billingPeriod.search("d") > 0 &&
                                    tier.billingPeriod.replace(
                                      "1d",
                                      `${t("tag")}`
                                    )}
                                  {tier.billingPeriod.search("m") > 0 &&
                                    tier.billingPeriod.replace(
                                      "1m",
                                      `${t("monat")}`
                                    )}
                                  {tier.billingPeriod.search("w") > 0 &&
                                    tier.billingPeriod.replace(
                                      "1w",
                                      `${t("woche")}`
                                    )}
                                </span>
                              )}
                              {tier.billingPeriod.replace(/\D/g, "") > 1 && (
                                <span>
                                  {tier.billingPeriod.search("d") > 0 &&
                                    tier.billingPeriod.replace(
                                      "d",
                                      `${t("tage")}`
                                    )}
                                  {tier.billingPeriod.search("m") > 0 &&
                                    tier.billingPeriod.replace(
                                      "m",
                                      `${t("monate")}`
                                    )}
                                  {tier.billingPeriod.search("w") > 0 &&
                                    tier.billingPeriod.replace(
                                      "w",
                                      `${t("wochen")}`
                                    )}
                                </span>
                              )}
                            </h5>
                          </div>
                        )}
                      </div>
                      {tier.initialRuntime <= 1 ? (
                        <p className={classes.kuendigung}>
                          {t("laufzeit")}:{" "}
                          <MyLaufzeit
                            value={tier.billingPeriod}
                            normalruntime={true}
                          />
                          . {t("kündigungmöglichnach")}
                        </p>
                      ) : (
                        <p className={classes.kuendigung}>
                          {t("erstelaufzeit")}:{" "}
                          <MyRuntime
                            val1={tier.billingPeriod}
                            val2={tier.initialRuntime}
                          />{" "}
                          <MyLaufzeit
                            value={tier.billingPeriod}
                            plural={true}
                            novalue={true}
                          />
                          . {t("danachkündbar")}
                        </p>
                      )}
                    </div>
                    {!tochange ? (
                      <div
                        className={classes.catoAc}
                        onClick={() => {
                          authCtx.isLoggedIn
                            ? buythis(tier.id, tier)
                            : toggleLoginInfo(tier);
                        }}
                      >
                        {t("subscribe")}
                      </div>
                    ) : (
                      <div
                        className={classes.catoAc}
                        onClick={() => {
                          changethis(tier.id, tier);
                        }}
                      >
                        {t("changeabo")}
                      </div>
                    )}
                  </li>
                </div>
              </Col>
            ))}
          {showModal ? (
            <ManageSubs
              payload={payLoad}
              sendback={sendback}
              image={props.secondimage}
              pseudo={props.name}
            />
          ) : // <SubscriptionManager
          //   payload={payLoad}
          //   sendback={sendback}
          //   image={props.secondimage}
          //   pseudo={props.name}
          // />
          null}

          {showLoginInfo && !authCtx.isLoggedIn ? (
            <PortalModal onclose={toggleLoginInfo} time="infinite">
              <div>
                <h5>{t("pleaseLoginfirsthead")}</h5>
                <p>{t("pleaseLoginfirsttext")}</p>
                <Login />
              </div>
            </PortalModal>
          ) : null}
        </Row>
      </div>
    </Fragment>
  );
});

export default DisplaySub;
