import React, { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../context/authcontext";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import LoadingSpinner from "../../Cards/Extras/loadingspinner";
import classes from "./getpaymentauthorization.module.css";

const GetPaymentAuthorization = memo((props) => {
  const [myPaymentMethods, setMyPaymentMethods] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const [chosenMethod, setChosenMethod] = useState(-1);
  const isFanDollar = true;
  const hasFanDollar = authCtx.fanDollar;
  const [checkforDuplicates, setCheckForDuplicates] = useState([]);
  const [rememberClicked, setRememberClicked] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [noAuthorizations, setNoAuthorizations] = useState(false);
  const [theFicPrice,setTheFixPrice]=useState(null);
const [vat,setVat]=useState(props.vat||1.19);

  useEffect(() => {
  
    if(props.fixprice){setTheFixPrice(props.fixprice/100)}
    fetchAuths();
  }, []);

  function filterlist(list) {
    setFilteredList([]);
    let array = [];
    let paymentMethods = [
      "CreditCardPayabl",
      "DirectDebit",
      "Epoch",
      "SofortUeberweisungGateway",
      "OnlineUeberweisen",
      "Twint",
      "CreditCardTrustPay",
    ];
    for (var i = 0; i < paymentMethods.length; i++) {
      var check = list.filter((el) => el.paymentMethod === paymentMethods[i]);
     
      if (check.length >= 1) {
        array.push(check[0]);
      }
    }
    return array;
  }

  const fetchAuths = () => {
    setIsLoading(true);
    console.log("checking",authCtx.userID,authCtx.token)
    fetch(
      `https://api.deine.fans/api/payment/authorizations/${encodeURIComponent(authCtx.userID)}?authToken=${encodeURIComponent(authCtx.token)}&all=false`
    )
      .then((res) => res.json())
      .then((data) => {
        checkreceivedData(data, authCtx,"getpaymentauth");
        setIsLoading(false);
        console.log("authdata",data.authorizations, data.authorizations.filter(el=>el.id!==-3))
        if (data.error) {
          setIsError(data.error);
        } else {
          var checksofort=data.authorizations.findIndex(el=>el.paymentMethod==="Sofort");
        if(data.authorizations.filter(el=>el.id!==-3).length<1){if(props.sendbacknone){props.sendbacknone(true)}}
          if (checksofort>=0){if(props.getbacksofort){props.getbacksofort();}}
            if (data.uStSatz) {
              console.log("yes we have ", data.uStSatz);
              setVat(data.uStSatz);
              if (props.sendUst) {
                props.sendUst(data.uStSatz);
              }
            }
          setMyPaymentMethods(data.authorizations);
          var thingie = filterlist(data.authorizations);
          setFilteredList(thingie);
        
          if (data.authorizations.filter((el) => el.id !== -3).length === 0) {
            setNoAuthorizations(true);
            if (props.sendback) {
              props.sendback("noneAvailable", "FanDollar");
            }
          }
          if (data.authorizations.filter((el) => el.id !== -3).length === 1) {
            if (props.sendback) {
              props.sendback(
                data.authorizations.id,
                data.authorizations.paymentMethod
              );
            }
          }
          //   if (data.authorizations.length > 0)
          //     setChosenMethod(data.authorizations[0].id);
          //      if (props.sendback) {
          //        props.sendback(data.authorizations[0].id);
          //      }
        }
      });
  };
  function takethis(id, method) {
    var duplicity = myPaymentMethods.filter(
      (el) => el.paymentMethod === method
    );

    if (duplicity.length === 1 || id === -1) {
      if (rememberClicked && rememberClicked !== id) {
        document
          .getElementById(rememberClicked)
          .classList.remove(classes.clicked);
      }
      if (rememberClicked !== id) {
        document.getElementById(id).classList.add(classes.clicked);
   
        setRememberClicked(id);
      }
      setChosenMethod(id, method);
      if (props.sendback) {
        props.sendback(id, method);
      }
    } else {
      setCheckForDuplicates(duplicity);
    }
  }
  function takethat(id, method) {
    setChosenMethod(id);
    if (props.sendback) {
      props.sendback(id, method);
    }
  }
  const { t } = useTranslation();

  return (
    <div className={classes.centerme}>
      <p>{t("chooseyouroption")}</p>
      <div className={classes.grid} key={filteredList}>
        {isError ? <p>{isError}</p> : null}
        {isLoading ? <LoadingSpinner /> : null}

        {theFicPrice && hasFanDollar >= theFicPrice && isFanDollar ? (
          <div className={classes.elements} id="-1">
            {" "}
            <img
              src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/fan_dollar.png`}
              alt={"Pay with Fandollar"}
              className={classes.icon}
              onClick={() => takethis(-1, "fandollar")}
            />
          </div>
        ) : null}
        {checkforDuplicates.length <= 1 &&
          filteredList &&
          filteredList.map((method, index) => (
            <div key={index} className={classes.elements} id={method.id}>
              <img
                src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/logo_${method.paymentMethod.toLowerCase()}.png`}
                alt={method.paymentMethod}
                className={classes.icon}
                onClick={() => takethis(method.id, method.paymentMethod)}
              />
            </div>
          ))}
        {console.log(filteredList)}
        {checkforDuplicates.length > 1 &&
          checkforDuplicates.map((method, index) => (
            <div key={index} className={classes.elements}>
              <img
                src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo__${method.paymentMethod.toLowerCase()}.png`}
                alt={method.paymentMethod}
                className={classes.icon}
                onClick={() => takethat(method.id, method.paymentMethod)}
              />
              {method.authKey}
            </div>
          ))}
        {checkforDuplicates.length > 0 && (
          <div className={classes.elements}>
            <img
              src={`/images/icons/paymentback.png`}
              alt={"back"}
              className={classes.icon}
              onClick={() => {
                setCheckForDuplicates([]);
                if (rememberClicked) {
                  document
                    .getElementById(rememberClicked)
                    .classList.remove(classes.clicked);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default GetPaymentAuthorization;
