import React, { Fragment, useEffect, useRef, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import SmallImageElement from "../smallimageElement";
import { PostCardHeader } from "./postCardHeader";
import classes from "./testpostcard.module.css";
import {
  SnapList,
  SnapItem,
  useVisibleElements,
  useScroll,
  useDragToScroll,
  isTouchDevice,
} from "react-snaplist-carousel";
import Videopreview from "../videos/videopreview";
import PostCardFunctionList from "./postcardfunctionlist";
import PostCardReport from "./postcardReport";
import SimplePaymentBaseModal from "../../payment/simplepayment/simplepaymentBaseModal";
import RedButton from "../WrapperComponents/redbutton";
import CommentList from "../../lists/comments/commentlist";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import LoadingSpinner from "../Extras/loadingspinner";

const PayedPostCard = memo((props) => {

  const [test, settest] = useState(props.post);
  const [isAvailable, setIsAvailable] = useState(false);
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  let { freeMedia, payMedia, guid, producerID, pseudo, creationTime } =
    test || null;
  const [freeImages, setFreeImages] = useState(
    freeMedia.filter((el) => el.type === "image") || null
  );
  const [freeVideos, setFreeVideos] = useState(
    freeMedia.filter((el) => el.type === "video") || null
  );
  const [payImages, setPayImages] = useState(
    payMedia.filter((el) => el.type === "image") || null
  );
  const [payVideos, setPayVideos] = useState(
    payMedia.filter((el) => el.type === "video") || null
  );
  const [freeContent, setFreeContent] = useState(freeImages.concat(freeVideos));
  const [payContent, setPayContent] = useState(payImages.concat(payVideos));
  const [allContent, setAllContent] = useState(freeContent.concat(payContent));
  const [extramessage, setExtraMessage] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const curDate = new Date(props.context.lastLogin);
  const createDate = new Date(creationTime);
  const [isPayPost, setIsPayPost] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [payData, setPayData] = useState([]);
   const [stillLoading, setStillLoading] = useState(true);

  useEffect(() => {
    if (test.activeDiscountFormula === "BOUGHT") {
      setIsPayPost(true);
      setStillLoading(false);
    }
    if (test.activeDiscountFormula === "POST_PRICE_OVERRIDE_FREE") {
      setIsPayPost(true);
       setStillLoading(false);
    }
    if (test.activeDiscountFormula === "WATCH_FOR_FREE") {
      setIsPayPost(true);
       setStillLoading(false);
    }
    setShowHeader(true);
  }, []);

  useEffect(() => {
    var checkDateDif = curDate - createDate;
    if (checkDateDif <= 0) {
      setIsNew(true);
    }
    if (test.bought === true) {
      setIsAvailable(true);
    } else if (test.activeDiscountFormula === "BOUGHT") {
      setIsAvailable(true);
       setStillLoading(false);
    } else if (payContent.length === 0) {
      setIsAvailable(true);
       setStillLoading(false);
    } else if (test.activeDiscountFormula === "POST_PRICE_OVERRIDE_FREE") {
      setIsAvailable(true);
      setExtraMessage(t("freewithtier"));
       setStillLoading(false);
    } else if (test.activeDiscountFormula === "WATCH_FOR_FREE") {
      setIsAvailable(true);
      setExtraMessage(t("freewithtier"));
       setStillLoading(false);
    } else {
      setAllContent(freeContent);
    }
  }, [test]);

  useEffect(() => {}, [test]);

  const snapList = useRef(null);
  const visible = useVisibleElements(
    { debounce: 10, ref: snapList },
    ([element]) => element
  );

  const selected = useVisibleElements(
    { ref: snapList, debounce: 10 },
    (elements) => elements[0]
  );
  const goToElement = useScroll({ ref: snapList });

  useEffect(() => {
    if (isMobile) {
      if (allContent.length > 1) {
        for (var i = 0; i < allContent.length; i++) {
          if (document.getElementById(`${guid}${i}`)) {
            document
              .getElementById(`${guid}${i}`)
              .classList.remove(classes.active);
          }
        }

        if (document.getElementById(`${guid}${visible}`)) {
          document
            .getElementById(`${guid}${visible}`)
            .classList.add(classes.active);
        }
      }
    }
  }, [selected, goToElement]);

  const [gotabg, setgotabg] = useState([]);
  const [showModal, setShowModal] = useState(false);
  function toggleModal() {
    setShowModal(!showModal);
  }
  const [showPostForFree, setShowPostForFree] = useState();
  useEffect(() => {
    if (test.temporaryAccess === true) {
      setShowPostForFree(true);
    }
  });
  function gotit(props) {
    toggleModal();

    if (props) {
      sendit();
    }
  }
  const [showComment, setShowComment] = useState(false);
  function toggleComment() {
    setShowComment(!showComment);
  }
  function sendit() {
    fetch(
      `https://api.deine.fans/api/timeline/post/${encodeURIComponent(
        guid
      )}?userID=${encodeURIComponent(props.context.userID)}&authToken=${
        encodeURIComponent(props.context.token)
      }`
    ).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          checkreceivedData(data, props.context);

          if (data.success) {
            setStillLoading(false);
            settest(data.post);
          }
        });
      }
    });
    if (props.sendbackbuy) {
      props.sendbackbuy();
    }
  }
  var saved = ((test.orgPrice - test.price) / 100).toFixed(2);
  const [saveprice, setSavePrice] = useState(null);
  const [useSingula, setuseSingular] = useState(false);
  const [freeforyou, setfreeforyou] = useState(false);
  useEffect(() => {
    if (saved > 0) {
      setSavePrice(saved);
    }
    if (saved === "1.00") {
      setuseSingular(true);
    }

    if (test.price === 0) {
      setfreeforyou(true);
    }
  });

  function igotanUrl(info) {
    gotabg.push(info);
  }
  useEffect(() => {
   if (isMobile && document.getElementById(`id${guid}`)) {
     document.getElementById(
       `id${guid}`
     ).style.backgroundImage = `url(${gotabg[visible]})`;
   }
  }, [visible]);

  useEffect(() => {
    setTimeout(() => {
      if (isMobile && document.getElementById(`id${guid}`)) {
        document.getElementById(
          `id${guid}`
        ).style.backgroundImage = `url(${gotabg[0]})`;
      }
    }, 400);
  }, []);
  const [isDarkmode, setDarkMode] = useState(props.context.darkMode);
  useEffect(() => {
    setDarkMode(props.context.darkMode);
  }, [props.context]);

  const rows = [];
  for (let i = 0; i < allContent.length; i++) {
    rows.push(i);
  }

   function sendlike(guid) {
   
     setDoIChangeLike(true);

     setTimeout(() => {
       setChangeValue(!changeValue);
     }, 100);
   }
     const [changeValue, setChangeValue] = useState(false);
     const [doIChangeLike, setDoIChangeLike] = useState(false);

       const [pinchange, setpinchange] = useState(false);
       function sendpin() {
         setpinchange(true);
         setTimeout(() => {
           setChangeValue(!changeValue);
         }, 100);
       }
      
  return (
    <Fragment>
      {!stillLoading ? (
        <div
          index={test}
          id={props.id}
          className={
            isNew
              ? isDarkmode
                ? classes.maincontentcontainernewdm
                : classes.maincontentcontainernew
              : isDarkmode
              ? classes.maincontentcontainerdm
              : classes.maincontentcontainer
          }
        >
          {isNew ? (
            <div className={classes.newcontainer}>
              <p className={isDarkmode ? classes.newdm : classes.new}>
                +++NEW+++
              </p>
            </div>
          ) : null}

          {showHeader && (
            <PostCardHeader
              key={props}
              pseudo={pseudo}
              date={creationTime}
              context={props.context}
              guid={guid}
              msgText={test.msgText}
              msgTitle={test.msgTitle}
              tags={test.tags}
              numofPosts={freeContent.length + payContent.length}
              numofPics={freeImages.length + payImages.length}
              numofVideos={freeVideos.length + payVideos.length}
              purchasestatus={isPayPost}
              paytext={test.payText}
              isDarkmode={isDarkmode}
              ranking={test.ranking}
              post={test}
              sendlike={sendlike}
              sendpin={sendpin}
            />
          )}

          <PostCardFunctionList
            post={test}
            context={props.context}
            changeLike={doIChangeLike}
            key={changeValue}
            changePin={pinchange}
          />

          {extramessage ? (
            <p className={classes.extramessage}>{extramessage}</p>
          ) : null}

          <div
            id={`id${guid}`}
            className={
              isMobile ? classes.mobelementouter : classes.elementouter
            }
          >
            <div
              className={isMobile ? classes.mobelement : classes.element}
              key={allContent}
            >
              {(!isMobile || allContent.length <= 1) &&
                allContent.map((image, index) => (
                  <div
                    key={`${creationTime} ${index}`}
                    className={
                      isMobile ? classes.mobelementinner : classes.elementinner
                    }
                  >
                    {image.type === "image" ? (
                      <SmallImageElement
                        guid={guid}
                        imgid={image.mediaID}
                        producerID={producerID}
                        token={props.context.token}
                        sendmetheurl={igotanUrl}
                      />
                    ) : (
                      <Videopreview
                        guid={guid}
                        mediaID={image.mediaID}
                        producerID={producerID}
                        authToken={props.context.token}
                        context={props.context}
                        sendmetheurl={igotanUrl}
                      />
                    )}
                  </div>
                ))}
              {isMobile && allContent.length > 1 && (
                <SnapList ref={snapList} width="100vw">
                  {allContent.map((image, index) => (
                    <SnapItem
                      width={"100%"}
                      margin={"auto"}
                      snapAlign="start"
                      key={`snap${index}`}
                      id={`snapme${index}`}
                    >
                      <div
                        visible={index}
                        key={`${creationTime} ${index}`}
                        className={
                          isMobile
                            ? classes.mobelementinner
                            : classes.elementinner
                        }
                      >
                        {allContent.length > 1 ? (
                          <p className={classes.showpos}>
                            {index + 1}/{allContent.length}
                          </p>
                        ) : null}
                        {image.type === "image" ? (
                          <SmallImageElement
                            guid={guid}
                            imgid={image.mediaID}
                            producerID={producerID}
                            token={props.context.token}
                            sendmetheurl={igotanUrl}
                          />
                        ) : (
                          <Videopreview
                            guid={guid}
                            mediaID={image.mediaID}
                            producerID={producerID}
                            authToken={props.context.token}
                            context={props.context}
                            sendmetheurl={igotanUrl}
                          />
                        )}
                      </div>
                    </SnapItem>
                  ))}
                </SnapList>
              )}
            </div>

            {!isAvailable && (
              <div className={classes.topaywrapper}>
                <div className={classes.topaybg}>
                  <img
                    src={
                      isMobile
                        ? "/images/fallback_schloss.jpg"
                        : "/images/fallback_schloss_quer.jpg"
                    }
                    className={classes.fallbackimage}
                  />
                </div>

                {!freeforyou ? (
                  <div className={classes.topaytext}>
                    <h5>
                      {t("getthisforonly")}{" "}
                      {test.orgPrice > test.price && (
                        <span className={classes.killit}>
                          {(test.orgPrice / 100).toFixed(2)} EUR!{" "}
                        </span>
                      )}{" "}
                      {(test.price / 100).toFixed(2)} EUR!
                      {saveprice && !useSingula && (
                        <p>
                          {t("yousave")} {saveprice} {t("thanksytotier")}
                        </p>
                      )}
                      {saveprice && useSingula && (
                        <p>
                          {t("yousave")} {saveprice} {t("thanksytotier")}
                        </p>
                      )}
                    </h5>
                    <RedButton
                      className={classes.bigbutton}
                      key={`bb${guid}`}
                      onClick={() => {
                        toggleModal(guid);
                      }}
                    >
                      {t("freischalten")}
                    </RedButton>
                  </div>
                ) : (
                  <div className={classes.topaytext}>
                    <h5 className={classes.distancer}>{t("payzero")}</h5>{" "}
                    <RedButton
                      className={classes.bigbutton}
                      key={`bb${guid}`}
                      onClick={() => {
                        toggleModal(guid);
                      }}
                    >
                      {t("freischalten")}
                    </RedButton>
                  </div>
                )}

                {showModal && (
                  <SimplePaymentBaseModal
                    postid={guid}
                    girlname={test.pseudo}
                    onclose={gotit}
                    fixpreis={test.price}
                    girlid={test.producerID}
                    canfandollar={true}
                  />
                )}
              </div>
            )}
          </div>
          {isMobile && rows.length > 1 ? (
            <div className={classes.pagination}>
              {rows.map((böbbl, index) => (
                <p
                  id={`${guid}${index}`}
                  onClick={() => {
                    goToElement(index);
                  }}
                  className={classes.böpl}
                  key={`böbl${index}`}
                >
                  &#x2022;
                </p>
              ))}
            </div>
          ) : null}
          <div className={classes.maxwidth}>
            <button
              onClick={toggleComment}
              className={
                !isDarkmode ? classes.showcommentbtn : classes.showcommentbtndm
              }
            >
              {!showComment ? t("showcomments") : t("showcomments")}
            </button>
          </div>

          {showComment && <CommentList post={test.guid} dm={isDarkmode} />}
          <PostCardReport guid={guid} context={props.context} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </Fragment>
  );
});

export default PayedPostCard;
