import React, { useContext, useEffect, useState } from "react";
import classes from "./favbox.module.css";
import { Link } from "react-router-dom";
import useMyHttp from "../../hooks/myhttp";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import OtpSpende from "../payment/otpSpende";
import WrapModal from "./WrapperComponents/wrapmodal";
import LoadingSpinner from "./Extras/loadingspinner";
import useCheckMobileScreen from "../../hooks/useCheckMobile";
import SimplePaymentBaseModal from "../payment/simplepayment/simplepaymentBaseModal";
import MyImage from "../sonstige/specialcomponents/images";
import { checkstatus } from "../../hooks/checkstatus";

const FavBox = (props) => {
  const { t } = useTranslation();
  const { isLoading, data, error, sendRequest } = useMyHttp();
  const authCtx = useContext(AuthContext);
  const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
  useEffect(() => {
    setDarkMode(authCtx.darkMode);
  }, [authCtx]);
  function fetchGirlsHandler() {
    sendRequest(
      `https://api.deine.fans/api/girls/${encodeURIComponent(props.mygirl)}`,
      "GET",
      null,
      null,
      null,
      authCtx
    );
  }
  useEffect(() => {
    fetchGirlsHandler();
  }, []);

  function deleteGirl(id) {
    fetch(`https://api.deine.fans/api/favs`, {
      method: "DELETE",
      body: JSON.stringify({
        userID: authCtx.userID,
        producerID: id,
        authToken: authCtx.jwtAuthToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      checkreceivedData(res.json(), authCtx,"FavBox");
      props.senddelete(id);
    });
  }

  const [showModal, setShowModal] = useState(false);
  function toggleModal() {
    setShowModal(!showModal);
  }

  function makeotp() {
    toggleModal();
  }
  const [message, setMessage] = useState(null);
  function gotbackdata(props) {
  
    if (props && props.preis) {
      //  setMessage(`${t("youhave")} ${data.girl.pseudo} ${props.preis} ${t("truefan")}`);
      generateHeart();
    }
    toggleModal();
    checkstatus(authCtx);
  }
  const [onheart, setonheart] = useState();
  function generateHeart() {
    setTimeout(() => setMessage(null), 1500);
    setTimeout(() => setonheart(true), 1500);

    setTimeout(() => setonheart(false), 2500);
  }
  const isMobile = useCheckMobileScreen();
const [prevImage,setPrevImage] = useState(null)

useEffect(()=>{
if(data){var theimage = data.girl?data.girl.smallImageIDURLs.urlMedium:"";
theimage=theimage.replace("/300x150/","/300x/")
setPrevImage(theimage)}
},[data])
  return (
    <div
      className={isDarkmode ? classes.userinfodm : classes.userinfo}
      id="box"
    >
      {isLoading && <LoadingSpinner />}
      {error && <h5>{t("encounterederror")}</h5>}
      {data && data.girl && (
        <div>
          <div className={classes.userinfoheader}>
            <Link to={`../${data.girl.pseudo}`}>
              <MyImage
                src={prevImage}
                alt={data.girl.pseudo}
                className={classes.girlimage}
              />
            </Link>
          </div>
          <div
            className={
              isDarkmode ? classes.userinfobodydm : classes.userinfobody
            }
          >
            <Link
              className={`${isDarkmode ? classes.btndm : classes.btn} ${
                classes.btnblock
              }`}
              to={`../${data.girl.pseudo}`}
            >
              {t("toprofile")}
              {data.girl.pseudo}
            </Link>

            <hr />

            <nav className="text-center">
              {/* <Link className={classes.btn} to="#">
                  Nachricht
                </Link> */}

              <div
                className={isDarkmode ? classes.btndm : classes.btn}
                onClick={makeotp}
                id="paymebox"
              >
                {t("paymoney")}
                {onheart && (
                  <div id="heart">
                    <span className={classes.heart}></span>
                    <span className={classes.heart}></span>
                  </div>
                )}
              </div>

              <div
                className={isDarkmode ? classes.btnabodm : classes.btnabo}
                onClick={() => {
                  deleteGirl(data.girl.producerID);
                }}
              >
                {t("unfav")}
              </div>
            </nav>
          </div>
        </div>
      )}
      {message && (
        <WrapModal onclose={toggleModal}>
          <div className={classes.thankyoudiv}>
            <h5 className={classes.thankyouheader}>{t("thankyou")}</h5>
            <div className={classes.thankyouimagetext}>
              <MyImage
                src={prevImage}
                alt="thank you"
                className={classes.thankyouimage}
              />
              <p className={classes.thankyoutext}>{message}</p>
            </div>
          </div>
        </WrapModal>
      )}

      {showModal && (
        <SimplePaymentBaseModal
          canfandollar={true}
          girlname={data.girl.pseudo}
          girlid={data.girl.producerID}
          onclose={gotbackdata}
          girlimage={data.girl.smallImageIDURLs.urlMedium}
        />
      )}
    </div>
  );
};

export default FavBox;
