import React, { useState } from "react";
import useCheckMobileScreen from "../../hooks/useCheckMobile";
import classes from "./timelinefilterbar.module.css";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

function TimelinefilterbarAllways(props) {
  const [showToggle, setShowToggle] = useState(true);
  const [input, setInput] = useState(props.input);
  const [askForExtras, setAskForExtra] = useState(props.givemeextra);
  const [date,setDate]=useState(true);
  const [likes,setLikes]=useState(false);
  const [rank,setRank]=useState(false)

  function setMyInput(type) {
    if (type === "publishTime") {
      setDate(true);
    } else {
      setDate(false);
    }
    if (type === "likes") {
      setLikes(true);
    } else {
      setLikes(false);
    }
    if (type === "ranking") {
      setRank(true);
    } else {
      setRank(false);
    }
    

    setInput(type);
    props.checkback(type);
    setShowToggle(!showToggle);
  }

  function setMyExtra(value) {
    if (props.extrasorts) {
      props.extrasorts(value);
    }
  }
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  return (
    <div className={`${classes.allwrapper} ${props.higher?classes.liftitup:null}`}>
      <div className={classes.allfullbar}>
        <Row>
          <Col xs="12">
            <div className={classes.fullwidth}>
              <div className={classes.innerflex}>
                <form>
                  <input
                    type="radio"
                    onChange={() => setMyInput("publishTime")}
                    checked={date}
                  />
                  <label>{t("nachDatum")}</label>
                  <input
                    type="radio"
                    onChange={() => setMyInput("likes")}
                    checked={likes}
                  />
                  <label>{t("nachLikes")}</label>
                  <input
                    type="radio"
                    onChange={() => setMyInput("ranking")}
                    checked={rank}
                  />
                  <label>{t("nachRating")}</label>
                </form>
              </div>
            </div>
            {askForExtras ? (
              <div className={classes.innerflex}>
                <form>
                  <input type="radio" onChange={() => setMyExtra("video")} />
                  <label>{t("videos")}</label>
                  <input type="radio" onChange={() => setMyInput("image")} />
                  <label>{t("images")}</label>
                  <input type="radio" onChange={() => setMyInput("none")} />
                  <label>{t("beides")}</label>
                </form>
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TimelinefilterbarAllways;
