import { useEffect, useState, useRef } from "react";

export function useFullOnScreen(options) {
  const [isOnScreen, setIsOnScreen] = useState(false);

  const observerRef = useRef(null);

const callbackFunction = (entries)=>{
    const [entry]=entries
    setIsOnScreen(entry.isIntersecting);
}

  useEffect(() => {
   const observer = new IntersectionObserver(callbackFunction,options) 
   if(observerRef.current)observer.observe(observerRef.current);
      

    return () => {
      if(observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [observerRef,options]);

  return [observerRef,isOnScreen];
}
