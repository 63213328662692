import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import RedButton from "../WrapperComponents/redbutton";
import classes from "./cookie.module.css";

const Cookies=(props)=>{

    function setAllCookies (){
         localStorage.setItem("cookies", "Cookies");
        props.sendBack();
    }
    function DeclineCookies(){
        localStorage.setItem("cookies", "No Cookies");
        props.sendBack();
    }
    const {t}=useTranslation();
    const [explain,setexplain] = useState(false)
    return (
      <Fragment>
        <div className={classes.heads}>{t("cookies")}</div>
        <p
          onClick={() => {
            setexplain(true);
          }}
          className={classes.text}
        >
          {t("cookiesdesc")}
        </p>
        {explain && <p className={classes.text2}>{t("cookiesdescextra")}</p>}
        <div className={classes.buttons}>
          <div onClick={setAllCookies}>
            <RedButton>{t("allesklar")}</RedButton>
          </div>
          <div onClick={DeclineCookies}>
            <RedButton>{t("willichnicht")}</RedButton>
          </div>
        </div>
      </Fragment>
    );
};

export default Cookies;