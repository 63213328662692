import React, { useState } from "react";
import MyImage from "./images";
import { useTranslation } from "react-i18next";
import classes from "./SuccessMessage.module.css";
import RedButton from "../../Cards/WrapperComponents/redbutton";
import { Link } from "react-router-dom";
import Login from "../../../pages/login";
import PortalModal from "../../Cards/WrapperComponents/portalmodal";
import AuthForm from "../../auth/signup";

const SuccessMessage = ({ type, onclose, transid, error, head, body,image }) => {
  const { t } = useTranslation();
  const [showLogin, setShowLogin] = useState(null);
  function toggleshowlogin() {
    setShowLogin(!showLogin);
  }
  return (
    <div className={classes.successContainer}>
      <h5>
        {type === "success"
          ? t("thatworked")
          : type === "failed"
          ? t("somethingwrong")
          : type === "already_authorized"
          ? t("isalreadyauthorized")
          : type === "pwfailed"
          ? t("somethingwrong")
          : type === "error"
          ? t("anerror")
          : type === "message"
          ? head
          : null}
      </h5>
      <p>
        {type === "success"
          ? t("weproceedyouback")
          : type === "failed"
          ? t("pleasecheckortryagain")
          : type === "already_authorized"
          ? t("pleasecheckortryagain")
          : type === "pwfailed"
          ? t("dieserlinkistleiderabgelaufen")
          : type === "error"
          ? t("anerrorhasoccured")
          : type === "message"
          ? body
          : null}
        {type === "success" ? null : type === "message" ? null : (
          <Link to="/support">{t("support")}</Link>
        )}
      </p>
      {error ? <p>{error}</p> : null}
      {type === "success" ? null : type === "pwfailed" ? null : type ===
        "message" ? null : type === "error" ? null : (
        <p>
          {" "}
          {t("yourtransid")}: {transid && transid.key ? transid.key : transid}
        </p>
      )}
      {console.log(transid)}

      {type === "success" ? (
        <MyImage
          src="https://d39j9qa9t0x6xj.cloudfront.net/geschafftheader.jpg"
          className={classes.mainimage}
          alt="Das hat geklappt"
        />
      ) : type === "failed" ? (
        <MyImage
          src="https://d39j9qa9t0x6xj.cloudfront.net/sorryheader.jpg"
          className={classes.mainimage}
          alt="Das hat leider nicht geklappt"
        />
      ) : type === "already_authorized" ? (
        <MyImage
          src="https://d39j9qa9t0x6xj.cloudfront.net/sorryheader.jpg"
          className={classes.mainimage}
          alt="Das hat leider nicht geklappt"
        />
      ) : type === "pwfailed" ? (
        <MyImage
          src="https://d39j9qa9t0x6xj.cloudfront.net/sorryheader.jpg"
          className={classes.mainimage}
          alt="Leider abgelaufen"
        />
      ) : type === "error" ? (
        <MyImage
          src="https://d39j9qa9t0x6xj.cloudfront.net/sorryheader.jpg"
          className={classes.mainimage}
          alt="Das hat leider nicht geklappt"
        />
      ) : type === "message" ? (
        image === "success" ? (
          <MyImage
            src="https://d39j9qa9t0x6xj.cloudfront.net/geschafftheader.jpg"
            className={classes.mainimage}
            alt="So nicht"
          />
        ) : (
          <MyImage
            src="https://d39j9qa9t0x6xj.cloudfront.net/sorryheader.jpg"
            className={classes.mainimage}
            alt="So nicht"
          />
        )
      ) : null}

      <RedButton
        className={classes.distancer}
        onClick={
          type === "pwfailed"
            ? () => {
                toggleshowlogin();
              }
            : () => {
                onclose();
              }
        }
      >
        {type === "pwfailed" ? t("resetpassword") : t("backtoyourcontent")}
      </RedButton>
      {showLogin ? (
        <PortalModal onclose={toggleshowlogin}>
          <AuthForm passwordreset={true} />
          {/* <Login passwordreset={true} /> */}
        </PortalModal>
      ) : null}
    </div>
  );
};

export default SuccessMessage;
