import React, { memo, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import classes from "./testpostcard.module.css"
import PortalFeedbackModal from '../WrapperComponents/portalfeedbackmodal';
import { checkreceivedData } from '../../../hooks/checkReceivedData';
import { useLikes } from '../../../hooks/useLikes';
import { Analytics } from 'aws-amplify';
import PortalModal from '../WrapperComponents/portalmodal';
import SimplePaymentBaseModal from '../../payment/simplepayment/simplepaymentBaseModal';
import WriteMessage from '../messenger/writeMessage';
import MyImage from '../../sonstige/specialcomponents/images';
import AuthContext from '../../../context/authcontext';


export const PostCardHeader = memo((props) => {
const [isPayPost, setIsPayPost] = useState(props.purchasestatus);

function NewlineText(props) {
  
  const text = props.text;
  let newText
  if(text&&text.length>0){
  newText = text.split("\n").map((str, index) => (
    <span key={index}>
      <br />
      {str}
    </span>
  ));
  }

  return newText;
}
const [dropdownOpen, setDropdownOpen] = useState(false);
function toggle (){ setTimeout(() => {
   setDropdownOpen(!dropdownOpen);
}, 50)
}

const {t}=useTranslation();
const {date}=props;

const realdate = new Date(date).toLocaleDateString(2);
const curDate = new Date().toLocaleDateString(2);
var linktarget=`/${props.pseudo}`



 const [isItLiked, setIsItLiked] = useState(props.post.liked||false);
 
 const [sendMail, setSendMail] = useState(false);
 function togglemail() {
   setSendMail(!sendMail);
 }
 const [openotp, setopenotp] = useState(false);
 function toggleotp() {
   setopenotp(!openotp);
 }
 function gotbackdata(info) {
   toggleotp();
 }

 const [isPinned, setIsPinned] = useState(props.post.starred);
 const [didIPin, setDidIPin] = useState(false);
 function closeinfo() {
   setDidIPin(false);
 }
 function pinthis(guid) {
   fetch(
     `https://api.deine.fans/api/timelinefavs/${encodeURIComponent(
       props.context.userID
     )}`,
     {
       method: "PUT",
       body: JSON.stringify({
         guid: guid,
         authToken: props.context.jwtAuthToken,
       }),
       headers: {
         "Content-Type": "application/json",
       },
     }
   ).then((res) => {
     res.json().then((data) => {
       checkreceivedData(data, props.context, "postcard,pin");
     });
   });
   setIsPinned(!isPinned);
   setDidIPin(true);
    props.sendpin();
 }
 function unpinthis(guid) {
   fetch(
     `https://api.deine.fans/api/timelinefavs/${encodeURIComponent(
       props.context.userID
     )}`,
     {
       method: "DELETE",
       body: JSON.stringify({
         guid: guid,
         authToken: props.context.jwtAuthToken,
       }),
       headers: {
         "Content-Type": "application/json",
       },
     }
   ).then((res) => {
     res.json().then((data) => {
       checkreceivedData(data, props.context, "postcard,unpin");
     });
   });
   setIsPinned(!isPinned);
   if (props.isdelete) {
     props.senddelete(props.post.guid);
   }
   props.sendpin();
 }


 function likeme(guid) {

   props.sendlike(guid)
   useLikes(guid,props.context)
 }

const authCtx=useContext(AuthContext)

    return (
      <div className={classes.maxwidth}>
        <Row className={classes.maxwidth}>
          <Col xs="4" md="3" className={classes.profileWrapper}>
            <span className={classes.profilePicWrapper}>
              <Link to={linktarget}>
                <MyImage
                  src={
                    props &&
                    props.post &&
                    props.post.smallImageIDURLs &&
                    props.post.smallImageIDURLs.urlSmall
                      ? authCtx.isAVS
                        ? props.post.smallImageIDURLs.urlSmall.search(
                            "/.webp" === -1
                          )
                          ? props.post.softSmallImageIDURLs.urlSmall
                          : props.post.smallImageIDURLs.urlSmall
                        : props.post.softSmallImageIDURLs
                        ? props.post.softSmallImageIDURLs.urlSmall
                        : props.image
                      : props.image
                  }
                  className={classes.profilePic}
                />
              </Link>
            </span>
            <span
              className={
                props.isDarkmode ? classes.profileTextdm : classes.profileText
              }
            >
              <Link to={linktarget}>
                <p
                  className={
                    props.isDarkmode ? classes.pseudonamedm : classes.pseudoname
                  }
                >
                  {props.pseudo}
                </p>
              </Link>
            </span>
          </Col>
          <Col xs="6" md="7" className={classes.flexitsm}>
            {/* {props.context.userID === "_a_apitest" && (
              <p>
                {props.guid}, {props.ranking}
              </p>
            )} */}
            <p
              className={
                !props.msgTitle
                  ? ""
                  : props.msgTitle.length < 20
                  ? classes.heading
                  : props.msgTitle.length < 40
                  ? classes.headingshort
                  : props.msgTitle.length < 60
                  ? classes.headingmedium
                  : classes.headinglong
              }
            >
              {props.msgTitle}
            </p>
          </Col>
          <Col xs="2" className={classes.texttoright}>
            <div>{props.post.publishTime === "CurDate" ? null : realdate}</div>
            {props.context && props.context.isLoggedIn ? (
              <div>
                {props.post.publishTime === "CurDate" ? null : (
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    className={classes.dropdown}
                  >
                    <DropdownToggle
                      data-toggle="dropdown"
                      tag="span"
                      direction="down"
                    >
                      <img
                        src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/x35/optionen_grau.png"
                        className={classes.iconmed}
                      />
                    </DropdownToggle>

                    <DropdownMenu className={classes.dropdown}>
                      <DropdownItem
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${window.location.host}/post/${props.guid}`
                          );
                          alert("copied");
                        }}
                        className={classes.dropdowntext}
                      >
                        {t("pc_head_copytoclipboard")}
                      </DropdownItem>
                      {!isItLiked ? (
                        <DropdownItem
                          onClick={() => {
                            likeme(props.guid);
                          }}
                        >
                          {t("pc_head_like")}
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          onClick={() => {
                            likeme(props.guid);
                          }}
                        >
                          {t("pc_head_removelike")}
                        </DropdownItem>
                      )}
                      <DropdownItem
                        onClick={() => {
                          setSendMail(true);
                        }}
                      >
                        {t("pc_head_schreibe")}
                        {props.pseudo}
                        {t("pc_head_nachricht")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          setopenotp(true);
                        }}
                      >
                        {t("pc_head_sende")}
                        {props.pseudo}
                        {t("pc_head_trinkgeld")}
                      </DropdownItem>
                      {isPinned ? (
                        <DropdownItem
                          onClick={() => {
                            unpinthis(props.guid);
                          }}
                        >
                          {t("pc_head_unpinpost")}
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          onClick={() => {
                            pinthis(props.guid);
                          }}
                        >
                          {t("pc_head_pinpost")}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
            ) : null}
          </Col>

          <Col xs="12">
            {!isPayPost ? (
              <p className={classes.subheading}>{props.msgText}</p>
            ) : (
              <p className={classes.subheading}>
                {props.paytext ? props.paytext : props.msgText}
              </p>
            )}
            <p>{props.tags}</p>
          </Col>
        </Row>
        {sendMail && (
          <PortalModal onclose={togglemail}>
            <WriteMessage onclose={togglemail} frompost={props.post} />
          </PortalModal>
        )}
        {openotp && (
          <SimplePaymentBaseModal
            girlname={props.post.pseudo}
            girlid={props.post.producerID}
            onclose={gotbackdata}
            canfandollar={true}
          />
        )}
      </div>
    );
  
});

