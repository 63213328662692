import React, { memo } from 'react'
import { useTranslation } from 'react-i18next';
import classes from "./getpaymentauthorization.module.css";
import { Col, Row } from 'reactstrap';

const InfoText = memo((props) => {
  
const {t}=useTranslation();
const { girlid ,postid,msgid,girlname,payPrice,girlimage,vat} = props;

    return (
      <div className={classes.topdown}>
        <Row>
          {girlimage ? (
            <Col xs="3">
              <img src={girlimage} className={classes.profilePic} />
            </Col>
          ) : null}
          <Col xs={girlimage ? 9 : 12}>
            {!girlid && !postid && <h1>{t("buyfandollar")}</h1>}
            {girlid && !postid && !msgid && <h1>{t("asmallgift")}</h1>}
            {postid && <h1>{props.title ? props.title : t("buypost")}</h1>}
            {msgid && <h4>{t("buymessage")}</h4>}

            {!girlid && !postid && !msgid && (
              <p>
                {t("bookfanollars")}
                <br />
              </p>
            )}

            {msgid && (
              <p>
                {t("buymessagefrom")} {girlname} {t("for")}{" "}
                {payPrice.toFixed(2)} FanDollar {t("buy")}
              </p>
            )}
          </Col>
          <Col xs="12">
            {girlid && !postid && !msgid && (
              <p className={classes.trinkgeldtext}>
                {t("paysmallgift1")}{" "}
                <span className={classes.profilename}>{girlname}</span>
                {t("paysmallgift2")}
              </p>
            )}
            {postid && (
              <p>
                {t("buypostfrom")} <span className={classes.girlname}>{girlname}</span> {t("nowfor")}{" "}
                {payPrice.toFixed(2)} FanDollar {t("buy")}
              </p>
            )}
            <hr />
          </Col>
        </Row>
      </div>
    );
});

export default InfoText
