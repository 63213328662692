import React, { Fragment, memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AuthContext from "../../../../context/authcontext";
import { checkreceivedData } from "../../../../hooks/checkReceivedData";
import PortalModal from "../../WrapperComponents/portalmodal";
import RedButton from "../../WrapperComponents/redbutton";
import classes from "./managesubs.module.css";
import ChoosepaymentOption from "../../../sonstige/profileElements/choosepaymentOption";
import { Col, Row } from "reactstrap";
import SubscriptionManager from "./subscriptionManager";
import GetPaymentAuthorization from "../../../payment/getPaymentAuthorization/getpaymentauthorization";
import { checkstatus } from "../../../../hooks/checkstatus";
import useCheckMobileScreen from "../../../../hooks/useCheckMobile";

const ManageSubs = memo((props) => {
  const [infoModal, setInfoModal] = useState();
  const [methods, setmethods] = useState();
  const [myValue, setMyValue] = useState("");
  const [allData, setAllData] = useState(null);
  const { t } = useTranslation();
  const [usedPayment, setUsedPayment] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [ErrorMod, setErrorMod] = useState(false);
  const authCtx = useContext(AuthContext);
  const [isBuy, setIsBuy] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [isConsent, setIsConsent] = useState(false);
  const [initialPrice, setInitialPrice] = useState(null);
  const [currency, setCurrency] = useState("EUR");
  const [isUsedMethodEpoch, setIsUsedMethodEpoch] = useState(false);
  const [firstbilling, setFirstBilling] = useState(
    props && props.payload && props.payload.newpaymentcycle
      ? props && props.payload && props.payload.newpaymentcycle
      : null
  );
  const [billingcycle, setBillingCycle] = useState(
    props && props.payload && props.payload.billingCycle
      ? props.payload.billingCycle.replace(/\D/g, "")
      : null
  );
  const [itsfree, setItsFree] = useState(false);
  const [billingcycleTime, setBillingCycleTime] = useState(false);
  const [kosten, setKosten] = useState(
    props && props.payload && props.payload.kosten ? props.payload.kosten : null
  );
  const [vat, setvat] = useState(1.19);
  const [errorModal, setErrorModal] = useState(false);
  const [thetransid, setthetransid] = useState(false);
  const [epochEnd, setEpochEnd] = useState(false);
  const [redirectTarget, setRedirectTarget] = useState(null);
  const [epochModal, setEpochModal] = useState(false);

  useEffect(() => {
    if (props && props.payload && props.payload.firstPrice) {
      setInitialPrice(props.payload.firstPrice);
    }
    if (
      props &&
      props.payload &&
      props.payload.billingCycle &&
      props.payload.billingCycle.search("w") > 0
    ) {
      if (props.payload.billingCycle.split("w")[0] == 1) {
        setBillingCycleTime(t("woche"));
      } else {
        setBillingCycleTime(t("wochen"));
      }
    }
    if (
      props &&
      props.payload &&
      props.payload.billingCycle &&
      props.payload.billingCycle.search("d") > 0
    ) {
      if (props.payload.billingCycle.split("d")[0] == 1) {
        setBillingCycleTime(t("tag"));
      } else {
        setBillingCycleTime(t("tagen"));
      }
    }
    if (
      props &&
      props.payload &&
      props.payload.billingCycle &&
      props.payload.billingCycle.search("m") > 0
    ) {
      if (props.payload.billingCycle.split("m")[0] == 1) {
        setBillingCycleTime(t("monat"));
      } else {
        setBillingCycleTime(t("monaten"));
      }
    }
    if (props.payload.kosten == 0) {
      setItsFree(true);
    }
    if (authCtx.country === "CH") {
      setCurrency("CHF");
    }
  }, []);

  function gotinfo(info, infos) {}

  function handleChange(event) {
    event.preventDefault();
    setIsUsedMethodEpoch(false);
    if (methods[event.target.value].paymentMethod === "Epoch") {
      setIsUsedMethodEpoch(true);
    }

    setMyValue(methods[event.target.value].paymentMethod);
    setUsedPayment(event.target.value);

    gotinfo(
      methods[event.target.value].id,
      methods[event.target.value].paymentMethod
    );
  }
  function makebuy() {
    if (props.payload.type === "buy") {
      var payload = {
        producerID: props.payload.producerID,
        tierID: props.payload.tierID,
        paymentAuthorizationID:
          methods === "noneAvailable" && !kosten ? -1 : methods,
      };

      managesub("add", authCtx, payload);
    } else if (props.payload.type === "change") {
      var payload = {
        producerID: props.payload.producerID,
        tierID: props.payload.tierID,
        paymentAuthorizationID:
          methods === "noneAvailable" && !kosten ? -1 : methods,
        changeSubscriptionID: props.payload.changeSubscriptionID,
      };
      managesub("change", authCtx, payload);
    }
  }
  function singlepayment(dates) {
    setIsUsedMethodEpoch(false);
    if (dates.paymentMethod === "Epoch") {
      setIsUsedMethodEpoch(true);
    }
    setMyValue(dates.paymentMethod);
    gotinfo(dates.id, dates.paymentMethod);
  }
  function sendbacksuccess(suc) {
    if (props.sendback) {
      props.sendback(suc);
    }
  }
  function sendback(datas) {
    if (props.sendback) {
      props.sendback(infoModal);
    }
  }
  // function getauths() {
  //   fetch(
  //     `https://api.deine.fans/api/payment/authorizations/${
  //       encodeURIComponent(authCtx.userID)
  //     }?authToken=${encodeURIComponent(authCtx.token)}&all=${encodeURIComponent(false)}`
  //   ).then((res) => {
  //     res.json().then((data) => {
  //       checkreceivedData(data, authCtx,"manageSubs");
  //       if (data.isAuthorized) {
  //         setmethods(data.authorizations);
  //         if (data.authorizations.length === 1) {
  //           singlepayment(data.authorizations[0]);
  //         }
  //       } else {
  //         if(authCtx.country==="CH" && props.payload.kosten==0){
  //           setmethods([{id:-2}]);
  //           singlepayment({ id: -2 });
  //         }
  //         else
  //         {setInfoModal("No Authorizations Set");
  //         setErrorMod(
  //           <div>
  //             <h5>{t("nopaymenthead")}</h5>
  //             <p>{t("nopaymenttext")}</p>
  //             <ChoosepaymentOption />
  //             {/* <Link to="/profile">
  //               <RedButton>{t("totheprofile")}</RedButton>
  //             </Link> */}
  //           </div>
  //         );}
  //       }
  //     });
  //   });
  // }

  useEffect(() => {
    if (props.payload && props.payload.type === "buy") {
      setIsBuy(true);
    } else if (props.payload && props.payload.type === "change") {
      setIsChange(true);
    }
  }, []);

  function managesub(funct, context, payload) {
    let body;
    let method;
    let url;

    if (funct === "add") {
      body = JSON.stringify({
        userID: context.userID,
        producerID: payload.producerID,
        tierID: payload.tierID,
        paymentAuthorizationID:
          payload.paymentAuthorizationID === "noneAvailable"
            ? "-1"
            : payload.paymentAuthorizationID,
        authToken: context.token,
        redirectTransID: payload.redirectTransID,
      });
      method = "PUT";
      url = "https://api.deine.fans/api/subscriptions/user/create";
    } else if (funct === "change") {
      body = JSON.stringify({
        userID: context.userID,
        producerID: payload.producerID,
        tierID: payload.tierID,
        paymentAuthorizationID: payload.paymentAuthorizationID==="noneAvailable"?"-1":payload.paymentAuthorizationID,
        authToken: context.token,
        doChange: true,
        changeSubscriptionID: payload.changeSubscriptionID,
      });
      method = "PUT";
      url = "https://api.deine.fans/api/subscriptions/user/create";
    } else if (funct === "cancel") {
      body = JSON.stringify({
        userID: context.userID,
        subscriptionID: payload.subscriptionID,
        authToken: context.authToken,
      });
      method = "DELETE";
      url = "https://api.deine.fans/api/subscriptions/user/cancel";
    } else if (funct === "delete") {
      body = JSON.stringify({
        userID: context.userID,
        producerID: payload.producerID,
        authToken: context.authToken,
      });
      method = "DELETE";
      url = "https://api.deine.fans/api/subscriptions/user/delete";
    } else if (funct === "changeauth") {
      body = JSON.stringify({
        userID: context.userID,
        newPaymentAuthorizationID: payload.paymentAuthorizationID,
        subscriptionID: payload.subscriptionID,
        authToken: context.authToken,
      });
      method = "POST";
      url = "https://api.deine.fans/api/subscriptions/user/delete";
    }

    fetch(url, {
      method: method,
      body: body,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, context, "managesubs");
        if (data.redirectinfo) {
          setRedirectTarget(data.redirectinfo.redirectURI);
          CheckApi(data.redirectinfo.transID);

          window.open(
            data.redirectinfo.redirectURI,
            "popup",
            "location=0,width=750,height=650,left=500,top=55"
          );
        } else if (data.success === false) {
          setInfoModal(data.message);
          setErrorMod(
            <div>
              <h5>{t("anerroroccured")}</h5>
              <p>
                {t("anerroroccuredtext")} <Link to="/support">Support</Link>
              </p>
              <p>{data.message}</p> <p>{data.error}</p>
              <div onClick={sendback}>
                <RedButton>{t("tryagain")}</RedButton>
              </div>
            </div>
          );
        }
        if (data.status > 300) {
          setErrorMod(
            <div>
              <h5>{t("anerroroccured")}</h5>
              <p>{t("anerroroccuredtext")}</p>
              <p>{data.title}</p>
              <div onClick={sendback}>
                <RedButton>{t("tryagain")}</RedButton>
              </div>
            </div>
          );
        }
        if (data.success) {
          sendbacksuccess({ paid: true, message: data.message });

          authCtx.checkFanDollar(data.newBalance / 100);
        }
      });
    });
  }
  function callApi(transid) {
    fetch(
      `https://api.deine.fans/api/payment/transcheck/${encodeURIComponent(
        transid
      )}?authToken=${encodeURIComponent(
        authCtx.token
      )}&userID=${encodeURIComponent(authCtx.userID)}`
    ).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, authCtx, "simplePaymentCallApi");
        if (data.success) {
        }
        if (data.returned) {
          setEpochEnd(true);
          setEpochModal(false);
          var payload = {
            producerID: props.payload.producerID,
            tierID: props.payload.tierID,
            paymentAuthorizationID: methods==="noneAvailable"?-2:methods,
          };
          var thepayload = {
            userID: authCtx.userID,
            producerID: payload.producerID,
            tierID: payload.tierID,
            paymentAuthorizationID:
              payload.paymentAuthorizationID === "noneAvailable"
                ? -2
                : payload.paymentAuthorizationID,
            authToken: authCtx.token,
            redirectTransID: thetransid,
          };
          managesub("add", authCtx, thepayload);
          setErrorModal("Das hat geklappt");
        }
      });
    });
  }
  function CheckApi(transid) {
    setthetransid(transid);
  }
  useEffect(() => {
    if (thetransid && !epochEnd) {
      let interval = setInterval(() => {
        callApi(thetransid);
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [thetransid, epochEnd]);
  function setUsedMethods(val) {
    setmethods(val);
  }
  const [chosenMethod, setChosenMethod] = useState(null);
  function sendUst(val) {
    setvat(val);
  }
  function getbacksofort(val) {
    console.log(val);
  }
  const [methodName, setMethodName] = useState(null);
  function gotinfo(authorization, methodName) {
    setIsUsedMethodEpoch(false);
    if (methodName === "Epoch") {
      setIsUsedMethodEpoch(true);
    }
    
    setmethods(authorization);
    setMethodName(methodName);
    setChosenMethod(authorization);
  }
  const [nopayments, setnopayments] = useState(false);
  function sendbacknone(bool) {
  
    if (
      bool === true && authCtx.country === "CH" && initialPrice
        ? initialPrice
        : kosten === null
    ) {
      setmethods(-2);
    } else {
      
      if (authCtx.fanDollar < (initialPrice ? initialPrice : kosten/100)) {
       
        setnopayments(bool);

      }
      else {setPaywithFandollarsor(true)}
    }
  }
  const isMobile = useCheckMobileScreen();
  const [paywithFandollars,setPaywithFandollarsor]=useState(false)
  const [showChoose, setShowChoose] = useState(false);
  return (
    <PortalModal onclose={sendback}>
      {ErrorMod && ErrorMod}
      {!ErrorMod ? (
        !nopayments ? (
          <div className={classes.selector} key={allData}>
            {isBuy && !initialPrice && (
              <Row>
                <Col xs="4" md="3">
                  <img
                    src={props.image}
                    className={
                      isMobile ? classes.profilepicmob : classes.profilepic
                    }
                    alt={props.pseudo}
                  />
                </Col>
                <Col xs="8" md="9">
                  <div className={classes.textblock1}>
                    <h5 className={classes.modaltitle}>
                      {t("youwantbuytitle")}
                    </h5>

                    <h4>{props.payload.tier.tierName}</h4>
                    {isMobile ? null : (
                      <p>{props.payload.tier.tierDescription}</p>
                    )}
                  </div>
                </Col>
                <Col xs="12">
                  {!isMobile ? null : (
                    <p>{props.payload.tier.tierDescription}</p>
                  )}
                </Col>
                <Col>
                  <div className={classes.textblock2}>
                    <hr />
                    <h5>
                      {props.payload.tier.discountFormula ===
                      "WATCH_FOR_FREE" ? (
                        <span>
                          {t("yougettowatchallforfree")}
                          <span className={classes.pseudo}>{props.pseudo}</span>
                          {t("yougettowatchallforfree2")}
                        </span>
                      ) : props.payload.tier.discountFormula.search("%") !==
                        -1 ? (
                        <span>
                          {t("mitdiesemabosparstdu")}
                          {props.payload.tier.discountFormula.split("%")[0]}
                          {" %"}
                          {t("aufallepaidposts")}
                          <span className={classes.pseudo}>{props.pseudo}</span>
                        </span>
                      ) : props.payload.tier.discountFormula.search("EUR") !==
                        -1 ? (
                        <span>
                          {t("mitdiesemabosparstdu")}
                          {
                            props.payload.tier.discountFormula.split("EUR")[0]
                          }{" "}
                          {props.payload.currency}
                          {t("aufallepaidposts")}{" "}
                          <span className={classes.pseudo}>{props.pseudo}</span>
                        </span>
                      ) : null}
                    </h5>
                    <div className={classes.flexitwrapper}>
                      <div className={classes.flexit}>
                        <p>
                          {t("kosten")}{" "}
                          <span className={classes.bold}>
                            {(kosten / 100).toFixed(2)} {props.payload.currency}
                            {" / "}
                            {billingcycle === "1" ? null : billingcycle}
                            {billingcycleTime}
                            {billingcycle === "1" ? null : "e"}
                          </span>
                        </p>
                        <p>
                          {t("mindestlaufzeit")}{" "}
                          <span className={classes.bold}>
                            {billingcycle} {billingcycleTime}
                            {billingcycle === "1" ? null : "e"}
                          </span>
                        </p>
                      </div>
                      <p>
                        {t("costafterminimumruntime")}{" "}
                        <span className={classes.bold}>
                          {(kosten / 100).toFixed(2)} {props.payload.currency}
                          {"/ "}
                          {billingcycle === "1" ? null : billingcycle}
                          {billingcycleTime}
                        </span>
                      </p>
                    </div>
                    <br />
                    {isUsedMethodEpoch ? (
                      <p className={classes.epochalert}>
                        {t("duetoepochwebill")}{" "}
                        {(initialPrice / 100).toFixed(2) *
                          props.payload.newpaymentcycle}
                        {authCtx.country === "CH" ? "CHF" : "EUR"}
                        {t("duetoepochwebill2")} {billingcycle}
                        {billingcycleTime}.
                      </p>
                    ) : null}
                  </div>
                </Col>
              </Row>
            )}
            {isChange && !initialPrice && (
              <Row>
                <Col xs="3">
                  <img
                    src={props.image}
                    className={classes.profilepic}
                    alt={props.pseudo}
                  />
                </Col>
                <Col xs="9">
                  <div className={classes.textblock1}>
                    <h5 className={classes.modaltitle}>
                      {t("youwantchangetitle")}
                    </h5>
                  </div>
                </Col>

                <Col>
                  <div className={classes.textblock2}>
                    {t("youwantchange")}
                    {(kosten / 100).toFixed(2)}
                    {t("youwantchange2")}
                    {(kosten / 100).toFixed(2)} {props.payload.currency}{" "}
                    {t("proweiterem")}
                    {billingcycle} {billingcycleTime}
                  </div>
                </Col>
              </Row>
            )}
            {isBuy && initialPrice && (
              <Row>
                <Col xs="4" md="3">
                  <img
                    src={props.image}
                    className={
                      isMobile ? classes.profilepicmob : classes.profilepic
                    }
                    alt={props.pseudo}
                  />
                </Col>
                <Col xs="8" md="9">
                  <div className={classes.textblock1}>
                    <h5 className={classes.modaltitle}>
                      {t("youwantbuytitle")}
                    </h5>
                    <h4>{props.payload.tier.tierName}</h4>
                    {isMobile ? null : (
                      <p>{props.payload.tier.tierDescription}</p>
                    )}
                  </div>
                </Col>
                <Col xs="12">
                  {!isMobile ? null : (
                    <p>{props.payload.tier.tierDescription}</p>
                  )}
                </Col>
                <Col>
                  <div className={classes.textblock2}>
                    <hr />
                    <h5>
                      {props.payload.tier.discountFormula ===
                      "WATCH_FOR_FREE" ? (
                        <span>
                          {t("yougettowatchallforfree")}
                          <span className={classes.pseudo}>{props.pseudo}</span>
                          {t("yougettowatchallforfree2")}
                        </span>
                      ) : props.payload.tier.discountFormula.search("%") !==
                        -1 ? (
                        <span>
                          {t("mitdiesemabosparstdu")}
                          {
                            props.payload.tier.discountFormula.split("%")[0]
                          }{" "}
                          {t("aufallepaidposts")}{" "}
                          <span className={classes.pseudo}>{props.pseudo}</span>
                        </span>
                      ) : props.payload.tier.discountFormula.search("EUR") !==
                        -1 ? (
                        <span>
                          {t("mitdiesemabosparstdu")}
                          {
                            props.payload.tier.discountFormula.split("EUR")[0]
                          }{" "}
                          {props.payload.currency}
                          {t("aufallepaidposts")}
                          <span className={classes.pseudo}>{props.pseudo}</span>
                        </span>
                      ) : null}
                    </h5>
                    <div className={classes.flexitwrapper}>
                      <div className={classes.flexit}>
                        <p>
                          {t("kosten")}{" "}
                          <span className={classes.bold}>
                            {(initialPrice / 100).toFixed(2)}{" "}
                            {props.payload.currency}
                            {" / "}
                            {billingcycle === "1" ? null : billingcycle}
                            {billingcycleTime}
                            {billingcycle === "1" ? null : "e"}
                          </span>
                        </p>
                        <p>
                          {t("mindestlaufzeit")}{" "}
                          <span className={classes.bold}>
                            {" "}
                            {props.payload.newpaymentcycle} {billingcycleTime}
                            {props.payload.newpaymentcycle === "1"
                              ? "null"
                              : "e"}
                          </span>
                        </p>
                      </div>
                      <p>
                        {t("costafterminimumruntime")}{" "}
                        <span className={classes.bold}>
                          {(kosten / 100).toFixed(2)} {props.payload.currency}
                          {" / "}
                          {billingcycle === "1" ? null : billingcycle}
                          {billingcycleTime}
                          {billingcycle === "1" ? null : "e"}
                        </span>
                      </p>
                    </div>
                    <br />
                    {isUsedMethodEpoch ? (
                      <p className={classes.epochalert}>
                        {t("duetoepochwebill")}{" "}
                        {(initialPrice / 100).toFixed(2) *
                          props.payload.newpaymentcycle}
                        {authCtx.country === "CH" ? "CHF" : "EUR"}
                        {t("duetoepochwebill2")} {billingcycle}
                        {billingcycleTime}.
                      </p>
                    ) : null}
                  </div>
                </Col>
              </Row>
            )}
            {isChange && initialPrice && (
              <Row>
                <Col xs="3">
                  <img
                    src={props.image}
                    className={classes.profilepic}
                    alt={props.pseudo}
                  />
                </Col>
                <Col xs="9">
                  <div className={classes.textblock1}>
                    <h5 className={classes.modaltitle}>
                      {t("youwantchangetitle")}
                    </h5>
                  </div>
                </Col>
                <Col>
                  <div className={classes.textblock2}>
                    {t("youwantchange")}
                    {(initialPrice / 100).toFixed(2)}{" "}
                    {t("youwantchangespecial")}
                    {billingcycle} {billingcycleTime}
                    {t("youwantchangespecial2")}
                    {(kosten / 100).toFixed(2)} {t("youwantchangespecial3")}
                    {billingcycleTime}
                    {t("youwantchangespecial4")}
                  </div>
                </Col>
              </Row>
            )}

            <div>
              {!chosenMethod ? (
                <GetPaymentAuthorization
                  sendback={gotinfo}
                  fixprice={kosten}
                  getbacksofort={getbacksofort}
                  sendUst={sendUst}
                  sendbacknone={sendbacknone}
                />
              ) : chosenMethod !== "noneAvailable" ? (
                <div className={classes.flexit}>
                  <img
                    src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/logo_${methodName.toLowerCase()}.png`}
                    alt="mymethod"
                    className={`${classes.icon} ${classes.glow}`}
                  />
                  <img
                    src={`/images/icons/paymentback.png`}
                    alt={"back"}
                    className={classes.icon}
                    onClick={() => {
                      setChosenMethod(false);
                    }}
                  />
                </div>
              ) : null}
              {paywithFandollars ? (
                !showChoose ? (
                  <p>
                    Du kannst dieses Abo mit FanDollar bezahlen oder{" "}
                    <RedButton
                      onClick={() => {
                        setShowChoose(true);
                      }}
                    >
                      Füge eine Zahlungsoption hinzu
                    </RedButton>
                  </p>
                ) : (
                  <RedButton
                    onClick={() => {
                      setShowChoose(false);
                    }}
                  >
                    Zahle mit FanDollar
                  </RedButton>
                )
              ) : null}
              {showChoose ? <ChoosepaymentOption /> : null}
            </div>
            <hr />
            {isBuy && initialPrice ? (
              <p>
                {t("kündigungmöglich")} {t("jederzeitmöglich")}
              </p>
            ) : null}

            {methods ? (
              methods === -1 || methods === "noneAvailable" ? null : (
                <p>
                  {t("abovattext1")}
                  {((initialPrice ? initialPrice : kosten) / 100).toFixed(
                    2
                  )}{" "}
                  {currency}
                  {t("abovattext2")}
                  {(
                    ((initialPrice ? initialPrice : kosten) / 100) *
                    (vat - 1)
                  ).toFixed(2)}{" "}
                  {currency}
                  {t("abovattext3")}
                </p>
              )
            ) : null}
            {!isConsent && (
              <>
                <div className={classes.centerme}>
                  <RedButton
                    className={classes.greybutton}
                    onClick={() => {
                      setIsConsent(true);
                    }}
                  >
                    {t("acceptagb")}
                  </RedButton>
                </div>
                <div className={classes.consentElement}>
                  <p>
                    <label className={classes.checkboxtext}>
                      <input
                        className={classes.checkbox}
                        type="checkbox"
                        onClick={() => {
                          setIsConsent(true);
                        }}
                      />
                      {"  "}
                      {t("acceptagb1")}
                      <Link to="/AGB" className={classes.otpagb}>
                        {" "}
                        {t("agb")}
                      </Link>
                      . {t("acceptagb2")}
                    </label>
                  </p>
                </div>
              </>
            )}

            {isBuy && isConsent ? (
              methods ? (
                <div onClick={makebuy} className={classes.buybtn}>
                  <RedButton>
                    {itsfree ? t("aboabschließenfree") : t("aboabschließenbuy")}
                  </RedButton>
                </div>
              ) : (
                <div className={classes.centerme}>
                  <RedButton className={classes.infoButton}>
                    {t("chosepaymentoption")}
                  </RedButton>
                </div>
              )
            ) : null}
            {isChange && isConsent ? (
              methods ? (
                <div onClick={makebuy} className={classes.buybtn}>
                  <RedButton>{t("changeabobuy")}</RedButton>
                </div>
              ) : (
                <div className={classes.centerme}>
                  <RedButton className={classes.infoButton}>
                    {t("chosepaymentoption")}
                  </RedButton>
                </div>
              )
            ) : null}
          </div>
        ) : authCtx.country === "CH" ? (
          kosten ? (
            <ChoosepaymentOption abo={true} />
          ) : null
        ) : (
          <ChoosepaymentOption abo={true} />
        )
      ) : null}

      {/* {myValue && myValue } */}
    </PortalModal>
  );
});

export default ManageSubs;
