import React from "react";

import classes from "./showimagemodal.module.css";
import PortalModal from "./WrapperComponents/portalmodal";


const ShowImageModal = (props) => {
  function closeme() {
    props.getback();
  }
 const image=props.image.replace("/320x160","")

  return (
    <PortalModal onclose={closeme} big={true} bg="black">
      <div className={classes.wrapme}>
        <img
          src={image}
          alt="It's big"
          onContextMenu={()=>{return false;}}
          className={classes.bigimage}
          onClick={closeme}
        />
      </div>
    </PortalModal>
  );
};

export default ShowImageModal;
