import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import classes from "./languageswitch.module.css";
import { Analytics } from 'aws-amplify';

function LanguageSwitcher(props) {
  const authCtx=useContext(AuthContext);
  const { i18n } = useTranslation();
  const [isGerman,setIsGerman]=useState(true)
  
  function makegerman(){
    props.onChange("de");
    setIsGerman(true)
    i18n.changeLanguage("de");
    authCtx.switchlanguage("de");
    Analytics.record({
      name: "languageChange",
      attributes: {
        language: "de",
        userID: authCtx.userID
      }
    });
  }
  function makeenglish() {
    props.onChange("en");
    setIsGerman(false);
    i18n.changeLanguage("en");
    authCtx.switchlanguage("en");
    Analytics.record({
      name: "languageChange",
      attributes: {
        language: "en",
        userID: authCtx.userID
      }
    });
  }
  useEffect(()=>{
    var currentLanguage =i18n.language;
    if(currentLanguage!=="de"){setIsGerman(false);} 
  },[])
  return (
    <div>
     
      <div className={classes.flexit}>
        <img
          src="images/icons/flagge_de.png"
          alt="German"
          onClick={makegerman}
          className={`${
            isGerman ? classes.flagicon : classes.flagiconinactive
          }`}
        />
        <img
          src="images/icons/flagge_gb.png"
          alt="English"
          onClick={makeenglish}
          className={`${
            !isGerman ? classes.flagicon : classes.flagiconinactive
          }`}
        />
      </div>
    </div>
  );
}
export default LanguageSwitcher;
