import React from "react";

class MyImage extends React.Component {
  state = {
    hasError: false,
  };

  handleError = (e) => {
    this.setState({
      hasError: true,
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <img
          {...this.props}
          onContextMenu={(e) => e.preventDefault()}
          alt=""
          src="/images/achtung_3.jpg"
        />
      );
    }

    return (
      <img
        {...this.props}
        onContextMenu={(e) => e.preventDefault()}
        alt=""
        onError={this.handleError}
      />
    );
  }
}
export default MyImage;
