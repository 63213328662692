import React from 'react';
import classes from "./showPrice.module.css";
import { useTranslation } from 'react-i18next';

function ShowPrice(props) {
    const {type, price,context,currency,extra}=props;
    const {t}=useTranslation();
    const vat=context.vat?context.vat:context.country==="DE"?0.19:context.country==="AT"?0.2:context.country==="CH"?0.07:0.19
  return (
    <div className={classes.wrapper}>
      <p className={classes.content}>
        {type === "post" ? t("youbuypostfor") : null}
        <span>
          {(price / 100).toFixed(2)} {currency}
          {t("including")}
          {((price / 100) * vat).toFixed(2)} {currency}
          {t("tax")}
        </span>
        {type === "post" ? t("youbuypostfor2") : null}
        {/* {extra ? (
          <span>
            {t("yougetfandollarback")}
            {(extra / 100).toFixed(2)}
            {t("yougetfandollarback2")}
          </span>
        ) : null} */}
      </p>
    </div>
  );
}

export default ShowPrice
