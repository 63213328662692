import React, { Fragment, useEffect, useState } from "react";
import classes from "./portalmodal.module.css";
import ReactDOM from "react-dom";
import RedButton from "./redbutton";

const PortalFeedbackModal = (props) => {
  const [time,setTime]=useState("infinite")
  useEffect(()=>{
    if(props.time){setTime(props.time)}
  })
  const ThisBackdrop = (props) => {
    return <div className={classes.backdroptwo} onClick={closeme}></div>;
  };

  const ThisModalOverlay = (props) => {
    return (
      <div
        className={`${classes.smallmodal} ${classes.extramodal}`}
        id="portalModalContainer"
      >
        <button
          className={classes.closeBtn}
          id="closePortalModal"
          onClick={closeme}
        >
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
            alt="close modal"
            className={classes.closex}
          />
        </button>
        <div className={classes.content}>{props.children}</div>
      </div>
    );
  };
  function closeme() {
    props.onclose();
  }
  useEffect(() => {
    if(time!=="infinite"){
    setTimeout(() => {
      closeme();
    }, time);
  }
  });
  const portalElement = document.getElementById("overlay");
  return (
    <Fragment>
      {ReactDOM.createPortal(<ThisBackdrop />, portalElement)}
      {ReactDOM.createPortal(
        <ThisModalOverlay>
          <>
            <div className={classes.distancer}>{props.children}</div>
            <div className={classes.okbutton} onClick={closeme}>
              <RedButton>OK</RedButton>
            </div>
          </>
        </ThisModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default PortalFeedbackModal;
