import React from 'react'

function SubInfoText(props) {
  console.log(props)
  return (
    <div>
      {props.content.tierID}
    </div>
  )
}

export default SubInfoText
