//import React, { useContext, useEffect, useState } from "react";
import React, { useEffect, useState } from "react";
import classes from "./subbox.module.css";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import RedButton from "../WrapperComponents/redbutton";
import { endabo, redoabo, removeabo } from "../../../hooks/endabo";
import Subscriptionmodal from "../../payment/subscriptionmodal";
import OtpSpende from "../../payment/otpSpende";
import ManageTiers from "./managetiers";
import { useContext } from "react";
import AuthContext from "../../../context/authcontext";
import PortalModal from "../WrapperComponents/portalmodal";
import SimplePaymentBaseModal from "../../payment/simplepayment/simplepaymentBaseModal";
import DisplaySub from "./subs_2ed/displaySub";
import PortalFeedbackModal from "../WrapperComponents/portalfeedbackmodal";

const SubBox = (props) => {
  const [girl, setGirl] = useState(null);
  useEffect(() => {
    doit();
  }, []);

  function doit() {
    fetch(`https://api.deine.fans/api/girls/${encodeURIComponent(props.thegirl.producerID)}`).then(
      (res) => {
        res.json().then((data) => {
          checkreceivedData(data, props.context,"subBox");

          setGirl(data);
        });
      }
    );
  }
  const startdate = new Date(props.thegirl.timestamp).toLocaleDateString(2);
  const nextbilling = new Date(
    props.thegirl.nextBillingCycle
  ).toLocaleDateString(2);
  const [showSubModal, setShowSubModal] = useState(false);

    const [setExtraMessage,setSetMessage]=useState(false)
  function cancelAbomodal(){
   console.log(props.thegirl);
    if (
      props.thegirl.authorization&&props.thegirl.authorization.paymentMethod ===
      "Epoch"
    ) {
      setSetMessage(
        <div>
          <h5>{t("wanttocancel")}</h5>
          <p>{t("thencancelhere")}</p>
          <div onClick={cancelAboEpoch}>
            <RedButton>{t("cancelabo")}</RedButton>
          </div>
        </div>
      );
    } else {
      setSetMessage(
        <div>
          <h5>{t("wanttocancel")}</h5>
          <p>{t("thencancelhere")}</p>
          <div onClick={cancelAbo}>
            <RedButton>{t("cancelabo")}</RedButton>
          </div>
        </div>
      );
    }
  }
  function cancelAbo() {
    endabo(props.thegirl.id, props.context);

    props.sendback();
  }
   function cancelAboEpoch() {
    // endabo(props.thegirl.id, props.context);
 fetch(
   `https://api.deine.fans/api/payment/methods/epoch/cancel/${encodeURIComponent(props.thegirl.authorizationID)}?authToken=${encodeURIComponent(props.context.token)}&userID=${encodeURIComponent(props.context.userID)}`,
   {
     method: "DELETE",

     headers: {
       "Content-Type": "application/json",
     },
   }
 ).then((res) => {
   res.json().then((data) => {
     checkreceivedData(data, props.context, "endabo,cancelabo");
    
     if (data.redirectinfo) {
       if (data.redirectinfo.needsRedirect) {
         setRedirectTarget(data.redirectinfo.redirectURI);
         CheckApi(data.redirectinfo.transID);
         setthetransid(data.redirectinfo.transID);

         window.open(
           data.redirectinfo.redirectURI,
           "popup",
           "location=0,width=750,height=650,left=500,top=55"
         );
       }
     }
   });
 });
   
   }
  function finishAbo() {
    removeabo(props.thegirl.producerID, props.context);
    props.sendback();
  }
  function renewabo() {
    redoabo(props.context, props.thegirl.id, props.thegirl.authorizationID);
    setGirl(null);
    props.sendback();
  }
  function toggleModal() {
    setShowSubModal(!showSubModal);
  }
  function closemodal() {
    toggleModal();
  }
const [epochEnd,setEpochEnd]=useState(false);
const [thetransid,setthetransid]=useState(false);
const [epochModal,setEpochModal]=useState(false);
const [redirectTraget,setRedirectTarget]=useState(null);

 function callApi(transid) {
   fetch(
     `https://api.deine.fans/api/payment/transcheck/${encodeURIComponent(transid)}?authToken=${encodeURIComponent(props.context.token)}&userID=${encodeURIComponent(props.context.userID)}`
   ).then((res) => {
     res.json().then((data) => {
       checkreceivedData(data, props.context, "simplePaymentCallApi");
      
       if (data.success) {
       
       }
       if (data.returned) {
         setEpochEnd(true);
         setEpochModal(false);
         cancelAboEpoch();
          
       }
     });
   });
 }
 function CheckApi(transid) {
   setthetransid(transid);
 }
 useEffect(() => {
   if (thetransid && !epochEnd) {
     let interval = setInterval(() => {
     
       callApi(thetransid);
     }, 2000);
     return () => {
       clearInterval(interval);
     };
   }
 }, [thetransid, epochEnd]);


  const { t } = useTranslation();
  const [openotp, setopenotp] = useState(false);
  const [message, setMessage] = useState(null);
  function showotp() {
    setopenotp(!openotp);
  }
  const [onheart, setonheart] = useState();
  function generateHeart() {
    setTimeout(() => setMessage(null), 1500);
    setTimeout(() => setonheart(true), 1500);

    setTimeout(() => setonheart(false), 2500);
  }
  function gotbackdata(props) {
    showotp();
    if (props && props.preis) {
      setMessage(
        `${t("youhave")} ${girl.girl.pseudo} ${props.preis} ${t("truefan")}`
      );
      generateHeart();
    }
  }
  const [showTiers, setShowTiers] = useState(false);
  function toggleTiers() {
    setShowTiers(!showTiers);
  }
  const authCtx = useContext(AuthContext);
  const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
  useEffect(() => {
    setDarkMode(authCtx.darkMode);
  }, [authCtx]);
  const [backmessage, setBackMessage] = useState(false);
  function checkchange(backinfo) {
   

    if (backinfo && backinfo.paid === true) {
      setBackMessage(
        <div>
          <h5>{t("yohaveboughtsubscription")}</h5>
          <p>{t("boughtsubscriptiontext")}</p>
        </div>
      );
    }
  }
  return (
    <div className={!isDarkmode ? classes.aboinfo : classes.aboinfodm} id="box">
      {!girl && <h5>{t("loading")}</h5>}
      {girl && girl.girl && props.thegirl.tierSubscription && (
        <div>
          {props.nopicture ? null : (
            <div className={classes.aboinfoheader}>
              <Link to={`../${girl.girl.pseudo}`}>
                <img
                  src={girl.girl.bigImageIDURLs.urlSmall}
                  alt={girl.girl.pseudo}
                />
              </Link>
            </div>
          )}
          <div className={classes.aboinfobody}>
            <h4 className={classes.abopseudo}>
              {t("deinabobei")}{" "}
              <Link to={`../${girl.girl.pseudo}`}>
                <span className={classes.abopseudo}>{girl.girl.pseudo}</span>
              </Link>
            </h4>
            <hr />
            <div>
              <p>
                {/* {t("yourtier")}
                {props.thegirl.tierSubscription.tierLogo && (
                  <img
                    alt="tierlogo"
                    src={props.thegirl.tierSubscription.tierLogo}
                  />
                )} */}
                {props.thegirl.tierSubscription.tierName}
              </p>
              <p> {props.thegirl.tierSubscription.tierDescription}</p>
            </div>
            <ul>
              <li>
                {t("abostatus")}
                {": "}
                <b>{props.thegirl.subscriptionStatus}</b>
              </li>
              {props.thegirl.subscriptionStatus === "CANCELLED" ? (
                <li>
                  {t("runsuntil")}
                  {": "}
                  <b>{nextbilling}</b>
                </li>
              ) : (
                <li>
                  {t("nextbilling")}
                  {": "}
                  <b>{nextbilling}</b>
                </li>
              )}
              <li>
                {t("discountformula")}
                {": "}
                <b>
                  {props.thegirl.tierSubscription.discountFormula &&
                  props.thegirl.tierSubscription.discountFormula.search("ABS") >
                    0
                    ? props.thegirl.tierSubscription.discountFormula &&
                      props.thegirl.tierSubscription.discountFormula.replace(
                        "ABS",
                        " ,-EUR Rabatt"
                      )
                    : props.thegirl.tierSubscription.discountFormula &&
                      props.thegirl.tierSubscription.discountFormula.replace(
                        "%",
                        "% Rabatt"
                      )}
                </b>
              </li>
              <li>
                {t("subscribedon")}
                {": "}
                <b>{startdate}</b>
              </li>
              <li>
                {t("runtime")}
                {": "}
                <b>
                  {Number(props.thegirl.tierSubscription.price / 100)}
                  {" EUR / "}
                  {props.thegirl.billingPeriod.replace("d", " Tage")}
                </b>
              </li>
              {console.log(props, props.thegirl.tierSubscription)}
              {girl.girl.tiers && showTiers && (
                <DisplaySub
                  change={true}
                  tiers={girl.girl.tiers}
                  name={girl.girl.pseudo}
                  producerID={girl.girl.producerID}
                  context={girl.girl.context}
                  altesttiername={props.thegirl.tierSubscription.tierName}
                  subid={props.thegirl.tierSubscription.id}
                  sendchange={checkchange}
                  fullsize={true}
                  secondimage={
                    girl.girl.bigImageIDURLs.urlBig.search("/.") < 0
                      ? girl.girl.bigImageIDURLs.urlBig
                      : girl.girl.softBigImageIDURLs.urlBig
                  }
                  imagesoft={girl.girl.softSmallImageIDURLs.urlSmall}
                  secondimagesoft={girl.girl.softBigImageIDURLs.urlBig}
                />
              )}
            </ul>

            <div className={classes.flexbuttons}>
              <span onClick={toggleTiers} className={classes.thebtns}>
                <RedButton>{t("tiers")}</RedButton>
              </span>
              {props.thegirl.subscriptionStatus !== "CANCELLED" && (
                <span className={classes.thebtns} onClick={cancelAbomodal}>
                  <RedButton> {t("cancelabo")}</RedButton>
                </span>
              )}
              {!props.thegirl.isBilled &&
                props.thegirl.subscriptionStatus === "CANCELLED" && (
                  <span className={classes.thebtns} onClick={toggleModal}>
                    <RedButton>{t("readdabo")}</RedButton>
                  </span>
                )}

              {props.thegirl.isBilled &&
                props.thegirl.subscriptionStatus === "CANCELLED" && (
                  <span className={classes.thebtns} onClick={renewabo}>
                    <RedButton className={classes.thebtns}>
                      {t("renewabo")}
                    </RedButton>
                  </span>
                )}
              <span className={classes.thebtns} onClick={showotp}>
                <RedButton>{t("sendmoney")}</RedButton>
                {onheart && (
                  <div id="heart">
                    <span className={classes.heart}></span>
                    <span className={classes.heart}></span>
                  </div>
                )}
              </span>
              {!props.thegirl.isBilled && (
                <span onClick={finishAbo}>
                  <RedButton className={classes.thebtns}>
                    {t("delete")}
                  </RedButton>
                </span>
              )}
            </div>
          </div>
          {showSubModal && (
            <Subscriptionmodal
              tiers={props.thegirl.tierSubscription}
              price="15"
              context={props.context}
              getbackclose={closemodal}
              girlname={girl.pseudo}
              girlid={props.thegirl.producerID}
              zahlungsmethode="Kreditkarte"
              fullsize={true}
            />
          )}
          {openotp && (
            // <OtpSpende
            //   name={girl.girl.pseudo}
            //   id={props.thegirl.producerID}
            //   getbackclose={gotbackdata}
            // />
            <SimplePaymentBaseModal
              girlname={girl.pseudo}
              girlid={props.thegirl.producerID}
              onclose={gotbackdata}
              canfandollar={true}
            />
          )}
          {message && (
            <PortalModal
              onlose={() => {
                setMessage(false);
              }}
            >
              <div className={classes.thankyoudiv}>
                <h5 className={classes.thankyouheader}>{t("thankyou")}</h5>
                <div className={classes.thankyouimagetext}>
                  <img
                    src={girl.girl.previewImageIDURLS.urlMedium}
                    alt="thank you"
                    className={classes.thankyouimage}
                  />
                  <p className={classes.thankyoutext}>{message}</p>
                </div>
              </div>
            </PortalModal>
          )}
          {backmessage && (
            <PortalFeedbackModal
              onclose={() => {
                setBackMessage(false);
              }}
            >
              {backmessage}
            </PortalFeedbackModal>
          )}
          {setExtraMessage && (
            <PortalModal
              onclose={() => {
                setSetMessage(false);
              }}
            >
              {setExtraMessage}
            </PortalModal>
          )}
        </div>
      )}
    </div>
  );
};

export default SubBox;
