import React from "react";
import classes from "./dropdownbutton.module.css";

const DropDownButton = ({ type = "button", onClick, children, ...rest }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      {...rest}
      className={classes.MyButton}
    >
      {children}
    </button>
  );
};
export default DropDownButton;
