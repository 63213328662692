import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../context/authcontext";
import classes from './mybutton.module.css';

const MyButton = (props) => {
  const authCtx=useContext(AuthContext)
  
 const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
 useEffect(() => {
   setDarkMode(authCtx.darkMode);
 }, [authCtx]);

  return <button className={isDarkmode?classes.btndm:classes.btn}>{props.children}</button>;
};

export default MyButton;