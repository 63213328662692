import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/authcontext";
import "./i18n";
import { PremiumContextProvider } from "./context/premiumcontext";
import LoadingSpinner from "./components/Cards/Extras/loadingspinner";
import { Amplify, Auth, Analytics } from "aws-amplify";
import { ServiceWorker } from "aws-amplify";
import { Hub } from "aws-amplify";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PreApp from "./PreApp";

class FanHubListener {
  constructor() {
    Hub.listen("analytics", (data) => {
      const { payload } = data;
      if (payload != null) {
        console.log("Analytics event: ", data.payload.event);
        if (data.payload.event === "pinpointProvider_configured") {
          this.getEndpointID();
        }
      }
    });
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload != null) {
        if (payload.event === "signIn") {
          if (payload.data != null) {
            console.log(
              "Auth event: ",
              data.payload.data.username + " has " + data.payload.event
            );
          } else {
            console.log("Auth event: ", data.payload.event);
          }
        }
        if (payload.event === "signOut") {
          if (payload.data != null) {
            console.log(
              "Auth event: ",
              data.payload.data.username + " has " + data.payload.event
            );
          } else {
            console.log("Auth event: ", data.payload.event);
          }
        }
      }
    });
  }

  getEndpointID = () => {
    try {
      Analytics._pluggables.forEach((pluggable) => {
        if (pluggable.getProviderName() === "AWSPinpoint") {
          console.log("endpointId: " + pluggable._config.endpointId + "");
        }
      });
    } catch (err) {
      console.log("Error in getEndpointID: " + err.toString());
    }
  };

  updateEndpointID = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();

      const USER_ID = cognitoUser.attributes.sub;

      Analytics._pluggables.forEach((pluggable) => {
        if (pluggable.getProviderName() === "AWSPinpoint") {
          const endpointId = `email-endpoint-${USER_ID}`;
          console.log("endpointId: " + endpointId + "");
          pluggable._config.endpointId = endpointId;
          this.setCachePinpointEndpointId(endpointId);
        }
      });
    } catch (err) {
      console.log("Error in updateEndpointID: " + err.toString());
    }
  };
}

const myHubListener = new FanHubListener();

/*const serviceWorker = new ServiceWorker();

async function doServiceWorker() {
  try {
    const registeredServiceWorker = await serviceWorker.register('/service-worker.js', '/');
  } catch(err) {
    console.log("Error on serviceWorker.register: " + err.toString());
  }
}

doServiceWorker();*/

const root = ReactDOM.createRoot(document.getElementById("root"));
const MyApp = lazy(() => import("./App"));

const awsconfig = {
  aws_project_region: "eu-west-1",
  aws_cognito_identity_pool_id:
    "eu-west-1:b3bd46d4-f364-4587-bdc7-b854675e2366",
  aws_cognito_region: "eu-west-1",
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: [],
  aws_mobile_analytics_app_id: "076119012f3f4859b742f1770b098a98",
  aws_mobile_analytics_app_region: "eu-west-1",
  Analytics: {
    AWSPinpoint: {
      appId: "076119012f3f4859b742f1770b098a98",
      region: "eu-west-1",
    },
  },
};

Amplify.configure(awsconfig);

Amplify.configure({
  Auth: {
    identityPoolId: "eu-west-1:b3bd46d4-f364-4587-bdc7-b854675e2366",
    region: "eu-west-1",
    userPoolId: "eu-west-1_34oa4QCk9",
    userPoolWebClientId: "5a1rfehp2l62fa5bpg49d7qmb5",
    mandatorySignIn: false,
    signUpVerificationMethod: "code",
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
});

Analytics.autoTrack("session", {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes
  attributes: {},
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: "AWSPinpoint",
});

Analytics.autoTrack("pageView", {
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // OPTIONAL, the event name, by default is 'pageView'
  eventName: "pageView",
  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes
  attributes: {},
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  type: "SPA",
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: "AWSPinpoint",
  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  },
});

root.render(
  <AuthContextProvider>
    <PremiumContextProvider>
      <React.StrictMode>
        <Suspense fallback={<LoadingSpinner />}>
          <GoogleReCaptchaProvider
            reCaptchaKey="6LcHqSQlAAAAAAWJcOwFgOWTMJ4CWGUBwpPVaVTa"
            scriptProps={{
              async: true,
              defer: true,
            }}
          >
            <BrowserRouter>
              <PreApp />
            </BrowserRouter>
          </GoogleReCaptchaProvider>
        </Suspense>
      </React.StrictMode>
    </PremiumContextProvider>
  </AuthContextProvider>
);
