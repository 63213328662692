import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import RedButton from '../../Cards/WrapperComponents/redbutton';
import SearchForTags from '../../sonstige/searchfortags';
import Allapigirls from '../allapigirls';
import classes from "./showInfoNoFollows.module.css";
const ShowInfoNoFollows = () => {

    const{t}=useTranslation();

  return (
    <>
      <div className={classes.wrapper}>
        <h5 className={classes.header}>{t("currentlynofollos")}</h5>
        <p>
          {t("likecreators")}
          <img
            src="/images/icons_m/like.png"
            alt="likestar"
            className={classes.icon}
          />
          {t("likecreators2")}
        </p>
        <p>{t("checkouthetcreators")}</p>
      </div> 
      <SearchForTags />
      <Allapigirls />
    </>
  );
};

export default ShowInfoNoFollows
