import React, { Fragment, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import RedButton from "../WrapperComponents/redbutton";
import AuthContext from "../../../context/authcontext";
import classes from "./deletemessage.module.css";
//import { Alert } from "bootstrap";
//import { faL } from "@fortawesome/free-solid-svg-icons";

const DeleteMessage=(props)=>{
  
    const authCtx = useContext(AuthContext);
    const userToken= authCtx.token;
    const userID= authCtx.userID;
    const [infomsg,setInfomsg] = useState("");
    const [errmsg,setErrmsg] = useState("");
    const [working,setWorking] = useState(false);

    async function deleteMessage(e) {
      e.preventDefault();

      setWorking(true);

      let url = `https://api.deine.fans/api/inboxuser?userID=${encodeURIComponent(userID)}&authToken=${encodeURIComponent(userToken)}&msgID=${encodeURIComponent(props.frommessage.msgID)}`;

      const response = await fetch(url,{
        method: "DELETE"
      });

      const result = await response.json();

      if(typeof(result.title) !== "undefined") {
        let errormsg = result.title;
        for(let ei = 0;ei < result.errors.length;ei++) {
          errormsg += " " + result.errors[ei].error;
        }
        setWorking(false);
        alert(errormsg);
      } else {
        setWorking(false);
        if(result.error !== null && result.error !== "") {
          setErrmsg("Error: " + result.error);
        } else {
          setInfomsg(result.message);
        }
      }      

      setTimeout(props.onclose,2000);
    }

    // function onclose(){
    //   props.onclose();
    // }

    const [name,setName]=useState(null);

   useEffect(()=>{
    if (props.frompost) {
      setName(props.frompost.pseudo);
    }
    if (props.frommessage) {
      setName(props.frommessage.sender);
    }
   },[])

   const {t} = useTranslation();
    return (
      <Fragment>
        <div className={classes.size}>
          <h5>
            {props.frommessage && !working && (
              <div>
                {t("reallydeletemessageby")} {name}{" "}
                {t("reallydeletemessageby2")}?
              </div>
            )}
            {working ? <div>{t("pleasewait")}</div> : null}
            {infomsg && infomsg !== "" ? (
              <div className="infomsg">{infomsg}</div>
            ) : null}
            {errmsg && errmsg !== "" ? (
              <div className="errmsg">{errmsg}</div>
            ) : null}
          </h5>
          {infomsg === "" && errmsg === "" && !working ? (
            <div onClick={deleteMessage}>
              <RedButton>{t("delete")}</RedButton>
            </div>
          ) : (
            <div onClick={deleteMessage}>
              <RedButton>{t("continue")}</RedButton>
            </div>
          )}
        </div>
      </Fragment>
    );
}

export default DeleteMessage;