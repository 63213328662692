import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import classes from "./agb.module.css";
const AGB=()=>{
    
    const {t}=useTranslation()
    

 const params=useParams()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);
  
  return (
    <div className={classes.content}>
      <h5 className={classes.header}>{t("agb")}</h5>
      <p className={classes.bold}>{t("agb1")}</p>
      <p>{t("agb2")}</p>
      <p>{t("agb3")}</p>
      <p>{t("agb4")}</p>
      <p>{t("agb5")}</p>
      <p>{t("agb6")}</p>
      <p>{t("agb7")}</p>
      <p>{t("agb8")}</p>
      <p className={classes.bold}>{t("agb9")}</p>
      <p>{t("agb10")}</p>
      <p className={classes.bold}>{t("agb11")}</p>
      <p>{t("agb12")}</p>
      <p className={classes.bold}>{t("agb13")}</p>
      <p>{t("agb14")}</p>
      <p className={classes.bold}>{t("agb15")}</p>
      <p className={classes.bold}>{t("agb16")}</p>
      <p>{t("agb17")}</p>
      <p className={classes.bold}>{t("agb18")}</p>
      <p>{t("agb19")}</p>
      <p className={classes.bold}>{t("agb20")}</p>
      <p>{t("agb21")}</p>
      <p>{t("agb22")}</p>
      <p>{t("agb23")}</p>
      <p>{t("agb24")}</p>
      <p>{t("agb25")}</p>
      <p>{t("agb26")}</p>
      <p>{t("agb27")}</p>
      <p>{t("agb28")}</p>
      <p>{t("agb29")}</p>
      <p>{t("agb30")}</p>
      <p className={classes.bold}>{t("agb31")}</p>
      <p>{t("agb32")}</p>
      <p>{t("agb33")}</p>
      <p>{t("agb34")}</p>
      <p>{t("agb35")}</p>
      <p>{t("agb36")}</p>
      <p>{t("agb37")}</p>
    </div>
  );
};

export default AGB;