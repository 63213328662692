import React, { Fragment, useContext, useTransition } from "react";
import { useState } from "react";
//import { useState } from "react";

import classes from "./imageUploader.module.css";

import RedButton from "../../Cards/WrapperComponents/redbutton";
import AuthContext from "../../../context/authcontext";
import UserImages from "./userimages";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../Cards/Extras/loadingspinner";

const ImageUploader = (props) => {
  const authCtx = useContext(AuthContext);
  const [uploaded, setUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [choseimage,setChoseImage]=useState(false);
  function handleChange(e) {
    console.log(e);
    if (e.target.files[0] !== "undefined") {
      setImageUrl(URL.createObjectURL(e.target.files[0]));

      setShowUpload(true);
    }
  }

  const url = "https://api.deine.fans/api/userupload?userID=";

  const [imageUrl, setImageUrl] = useState(
    "http://res.cloudinary.com/dvnb8tps5/image/upload/v1666267114/clouds/iydy7qev6ynoaly0en5n.png"
  );
  function submitForm(e) {
    e.preventDefault();
    setIsLoading(true);
    const files = document.querySelector("[type=file]").files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append("file", file);
      formData.append("name", file);

      fetch(`${url}${encodeURIComponent(authCtx.userID)}`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          //document.getElementById("data").innerHTML += data;
          setIsLoading(false);
          setImageUrl(data.url);
          setShowUpload(false);
          setUploaded(true);
        });
    }
  }

  const [showUpload, setShowUpload] = useState(false);
  function getback(info) {
    props.getbackimage(info);
  }
  const [defaultImage, setDefaultImage] = useState(false);
  function sendDefault(payload) {
    setDefaultImage(payload);

    if (props.sendDefault) {
      props.sendDefault(payload);
    }
  }
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={classes.upload}>
        <h5 className={classes.headerupload}>{t("chooseimageoruploadnew")}</h5>
        <div>
          <form
            method="post"
            encType="multipart/form-data"
            id="theform"
            onSubmit={submitForm}
            className={classes.form}
          >
            <>
              {!showUpload ? (
                
                  <label
                    alt="UploadPicture"
                    className={classes.button}
                    htmlFor="uploadbtn"
                    //style={{ backgroundImage: `url("${imageUrl}"` }}
                    onChange={handleChange}
                  >
                    Bild Auswählen
                  </label>
                  
              ) : null}
              <input
                type="file"
                name="files[]"
                accept="image/*"
                hidden
                id="uploadbtn"
                onChange={handleChange}
                //
                //className={classes.profilepic}
              />
            </>
            {isLoading ? <LoadingSpinner /> : null}
            {showUpload ? (
              <div className={classes.uploadedPicture}>
              <img src={imageUrl} alt="Test" className={classes.profilepic} />
              <img
                    src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
                    className={classes.igs}
                    onClick={()=>{setShowUpload(false)}}
                  />
                </div>
            ) : null}
            {showUpload && (
              <RedButton onClick={submitForm}>
                <input type="submit" value="Hochladen" name="submit" hidden />
                Bild Hochladen
              </RedButton>
            )}
          </form>
          {/* {choseimage && <RedButton>Upload</RedButton>} */}
        </div>
      </div>
      <hr className={classes.hr} />
      <div key={showUpload}>
        <h5 className={classes.header}>{t("yourcollection")}</h5>
        <UserImages
          key={showUpload}
          sendback={getback}
          sendDefault={sendDefault}
        />
      </div>
    </Fragment>
  );
};
export default ImageUploader;
