import React, { Fragment, useContext, useState } from "react";

import { useEffect} from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import useMyHttp from "../../hooks/myhttp";
import { useFullOnScreen } from "../../hooks/useCheckFullinViewPort";
import LoadingSpinner from "../Cards/Extras/loadingspinner";
import Modeladvertorial from "../Cards/CreatorDetails/modeladvertorial";
import RedButton from "../Cards/WrapperComponents/redbutton";
import { useGirlStore } from "../../hooks/useStoreData";

const AllApiGirls = (props) => {
 const thegirls=useGirlStore(state=>state.thegirls);
 const setTheGirls = useGirlStore((state) => state.setGirlsList);

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
const box = document.querySelector("#loadbtn");


  const authCtx=useContext(AuthContext);
  const [showGirls,setShowGirl]=useState(5);

  const { isLoading, data,error,sendRequest} = useMyHttp();
  function fetchGirlsHandler() {
    sendRequest("https://api.deine.fans/api/girls", "GET",null,null,null,null,authCtx);
  }
  const {i18n}=useTranslation();
  const{t}=useTranslation();
  useEffect(() => {
  
    fetchGirlsHandler();
  }, []);
  useEffect(()=>{
    if(data&&data.girls){setTheGirls(Array.from(data.girls))}
  
  },[data])

const [mybuttonref, isVisible] = useFullOnScreen({
  root: null,
  rootMargin: `0px`,
  threshold: 1.0,
});
useEffect(()=>{
  if(isVisible===true){setShowGirl(showGirls + 1);}
})

function sortlist(data) {
  var list = [];
  if (data && data.girls) {
    list = Array.from(data.girls);
  }
  var test = list.sort((a, b) => b.posts - a.posts);  
  return test;
}
const [sortedList,setSortedList]=useState(false);
useEffect(()=>{
 var a= sortlist(data);
 a = a.filter((el) => el.bigImageID!==null);
 
 setSortedList(a)
},[data])


  return (
    <div>
      <div>
        
        {sortedList &&
          sortedList.map((girl, index) => (
            <Fragment key={`girls${index}`}>
              {!!(index < showGirls) && (
                !authCtx.isAVS&&!girl.softSmallImageID?null:<Modeladvertorial
                  producerID={girl.producerID}
                  name={girl.pseudo}
                  image={
                    girl.smallImageID
                      ? girl.smallImageIDURLs.urlSmall
                      : girl.softSmallImageIDURLs.urlSmall
                  }
                  secondimage={
                    girl.smallImageID
                      ? girl.bigImageIDURLs.urlBig
                      : girl.softBigImageIDURLs.urlBig
                  }
                  imagesoft={
                    girl.softSmallImageID
                      ? girl.softSmallImageIDURLs.urlSmall
                      : authCtx.isAVS
                      ? girl.smallImageIDURLs.urlSmall
                      : null
                  }
                  secondimagesoft={
                    girl.softBigImageID
                      ? girl.softBigImageIDURLs.urlBig
                      : authCtx.isAVS
                      ? girl.bigImageIDURLs.urlBig
                      : null
                  }
                  age={girl.age}
                  numberofitems="2"
                  key={girl.pseudo}
                  isFav="unimportant"
                  hasSubscription="unimportant"
                  id={girl.producerID}
                  mymotto={
                    i18n.language === "de"
                      ? girl.descriptionDE
                      : girl.descriptionEN
                  }
                  mymottosoft={
                    i18n.language === "de"
                      ? girl.descriptionSoftDE || girl.steckbriefSoftText
                      : girl.descriptionSoftEN || girl.steckbriefSoftText
                  }
                  hashtag={girl.hashTags}
                  extra={girl}
                  isCurOnline={girl.isOnline}
                  data={girl}
                />
              )}
            </Fragment>
          ))}
        {sortedList && sortedList.length >= showGirls && (
          <div
            id="loadbtn"
            ref={mybuttonref}
            onClick={() => {
              setShowGirl(showGirls + 10);
            }}
          >
            <RedButton>{t("moregirls")}</RedButton>
          </div>
        )}
        {isLoading && <LoadingSpinner />}
        {error && (
          <div>
            <p>{t("trylater")}</p>
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(AllApiGirls);
