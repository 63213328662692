import React, { Fragment, useState } from "react";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import RedButton from "../WrapperComponents/redbutton";
import WrapModal from "../WrapperComponents/wrapmodal";
import classes from "./displayResponseMessage.module.css";
import WriteMessage from "./writeMessage";
import DeleteMessage from "./deleteMessage";
import DisplayMessage from "./displayMessage";

const DisplayResponseMessage = (props) =>{
  const oldDate = new Date(props.cont.sendTime);
  const isMobile = useCheckMobileScreen();

  const [showContent,setShowContent]=useState(false);

  function toggleShow(){
     
      setShowContent(!showContent);
  }
  const [sendReply, setSendReply]=useState(false);

  function toggleReply(){
      console.log("Replyfunktion");
      setSendReply(!sendReply);
  }

  const [deleteConfirm, setDeleteConfirm]=useState(false);

  function toggleDelete(){
    console.log("Nachricht löschen");
    setDeleteConfirm(!deleteConfirm);
}

  return (
    <Fragment>
      <div className={!isMobile?classes.card:classes.mobileCard} onClick={toggleShow}>
        <div className={showContent?classes.headerfield:classes.headersmall}>
          <img src={props.cont.girlimg} alt={props.cont.sender} className={!isMobile?classes.msgimg:classes.msgimgmobile} onContextMenu="return false;" />
          <h5 className={!isMobile?null:classes.headline}>Nachricht von {props.cont.sender}</h5>
          <p>{oldDate.toLocaleDateString()}</p>
        </div>
        {showContent && (
          <div className={!isMobile?classes.contentfield:classes.contentfieldmobile}>
            <div className={classes.textfield}>{props.cont.msgtext}</div>
            <div className={classes.buttonfield}>
              <div onClick={toggleReply}>
                <RedButton>Antworten</RedButton>
              </div>
              <div onClick={toggleDelete}>
                <RedButton>Löschen</RedButton>
              </div>
            </div>
              {props.cont.responses.map((mes, index) => (
                <div key={`responsemessage ${index}`}>
                  <DisplayMessage cont={mes} />
                </div>
              ))}
            <div>
            </div>
          </div>
        )}
      </div>

      {sendReply && (
        <WrapModal onclose={()=>{}}>
          {" "}
          <WriteMessage frommessage={props.cont} onclose={toggleReply} />
        </WrapModal>
      )}

      {deleteConfirm && (
        <WrapModal onclose={()=>{}}>
          {" "}
          <DeleteMessage frommessage={props.cont} onclose={toggleDelete} />
        </WrapModal>
      )}
    </Fragment>
  );
}
export default DisplayResponseMessage;