import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import classes from "./basicsites.module.css";
import { Link, useParams } from "react-router-dom";

const Impressum =()=>{
const {t} =useTranslation()
const params=useParams()
useEffect(() => {
  window.scrollTo(0, 0);
}, [params]);
    return (
      <div className={classes.content}>
        <h5 className={classes.header}>{t("impressum")}</h5>
        <p>{t("impressuml1")}</p>
        <p>{t("impressuml2")}</p>
        <p>{t("impressuml3")}</p>
        <p>{t("impressuml4")}</p>
        <br />
        <p className={classes.link}>
          {t("impressuml5")} <Link to="/privacy">{t("privacy0")}</Link>
        </p>
        <p>
          {t("plattform")}
          <a href="https://www.ec.europa.eu/consumers/odr" target="_blank">
            https://www.ec.europa.eu/consumers/odr
          </a>
        </p>
        <p></p>
      </div>
    );
}
export default Impressum;