import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./basicsites.module.css";
import { useParams } from "react-router-dom";
import RedButton from "../Cards/WrapperComponents/redbutton";
import { Tawk } from "./tawk";
import AuthContext from "../../context/authcontext";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Support = () => {
    const mailref=useRef();
    const questionref=useRef();

  const { t } = useTranslation();
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);
const [sentquestion,setsentquestion] =useState(false);
const authCtx = useContext(AuthContext);
const { executeRecaptcha } = useGoogleReCaptcha();

  async function sendquestion(e){
    e.preventDefault()
    	const thequestion = questionref.current.value;
        const themail = mailref.current.value;

        const token = await executeRecaptcha("support").then((token)=>{
      
         fetch("https://api.deine.fans/api/userlogin/preflight", {
           method: "POST",
           body: JSON.stringify({
             authToken: localStorage.getItem("jwtAuthToken"),
             captcha_action: "support",
             captcha_challenge: token,
           }),
           headers: {
             "Content-Type": "application/json",
           },
         }).then((res) => {
          if (res.ok) {
             res.json().then((data) => {  
    	
               sentitAway(thequestion, themail, data.preflightToken);
             });
          } else {
           
            throw new Error("Ein Captcha-Problem");
          }
        });
    })

}

function sentitAway(question,mail,preflight){
  var url="https://api.deine.fans/api/upload/ticket/user"
  //if (authCtx.isLoggedIn){url=`${url}${authCtx.userID}`}else{url = `${url}anonym`;}
        fetch(url, {
          method: "PUT",
          body: JSON.stringify({
            roleID:authCtx.isLoggedIn?authCtx.userID:"anonym",
            authToken: authCtx.jwtAuthToken,
            preflightToken: preflight,
            messageTitle: `Message from ${
              authCtx.isLoggedIn ? authCtx.pseudo : "unknownUser"
            }`,
            messageBody: question,
            email: mail,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((res) => {
          res.json().then((data) => {
            
          });
        });

setsentquestion(!sentquestion);

}




 
  return (
    <div className={classes.content}>
      <h5 className={classes.header}>{t("support")}</h5>
      <p>{t("support1")}</p>
      {!sentquestion && (
        <form className={classes.form} onSubmit={sendquestion}>
          <textarea
            rows="5"
            cols="60"
            name="text"
            id="suchtext"
            placeholder={`${t("yourquestion")}`}
            required
            ref={questionref}
            className={classes.inputquestion}
          ></textarea>
          <br />
          
          
          <input
            type="email"
            id="mail"
            placeholder={`${t("yourmail")}`}
            required
            ref={mailref}
            className={classes.inputmail}
          />
          <RedButton onClick={sendquestion}>{t("abschicken")}</RedButton>
        </form>
      )}
      {sentquestion && <h5>{t("thankyourforasking")}</h5>}
      {authCtx.isLoggedIn ? <Tawk /> : null}
    </div>
  );
};
export default Support;
