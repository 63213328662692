import React, { Fragment, useState } from "react";
import classes from "./ersterPost.module.css";
import AVSOverview from "../../../lists/avs/avsoverview";
import PortalModal from "../../WrapperComponents/portalmodal";
import AVSGetData from "../../../lists/avs/avsgetdata";
import { useTranslation } from "react-i18next";

function AddAvs(props) {
  const [showAVS, setShowAVS] = useState(false);
  const [getAVS, setGetAVS] = useState(false);

  function getback(info) {
    setShowAVS(false);
    if (info) {
      setGetAVS(info);
    }
    console.log(info);
  }

  function toggleShow() {
    setShowAVS(!showAVS);
  }
  function getback2() {
    setGetAVS(false);
  }
const {t}=useTranslation();
  return (
    <Fragment>
      <div
        className={props.isdone ? classes.borderisDone : classes.border}
        onClick={toggleShow}
      >
       {t("from18verifynow")}
      </div>
      {showAVS ? <AVSOverview sendback={getback} modal={true} /> : null}
      {getAVS ? (
        <PortalModal onclose={getback2}>
          <AVSGetData
            sendback={getback2}
            method={getAVS}
            context={props.context}
          />
        </PortalModal>
      ) : null}
    </Fragment>
  );
}

export default AddAvs;
