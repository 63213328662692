import React, { Fragment, useEffect, useRef, useState } from "react";
import classes from "./testpostcard.module.css";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import { SnapItem, SnapList, useScroll, useVisibleElements } from "react-snaplist-carousel";
import SmallImageElement from "../smallimageElement";
import Videopreview from "../videos/videopreview";

function PostCardContent(props) {
  const { thePost, context,available,children }=props;
  const [freeImages, setFreeImages] = useState(
    thePost.freeMedia?thePost.freeMedia.filter((el) => el.type === "image") :
      []
  );
  const [freeVideos, setFreeVideos] = useState(
    thePost.freeMedia?thePost.freeMedia.filter((el) => el.type === "video") :
      []
  );
  const [payImages, setPayImages] = useState(
    thePost.payMedia? thePost.payMedia.filter((el) => el.type === "image") : []
  );
  const [payVideos, setPayVideos] = useState(
    thePost.payMedia?thePost.payMedia.filter((el) => el.type === "video") : []
  );
  const [freeContent, setFreeContent] = useState(freeImages.concat(freeVideos));
  const [payContent, setPayContent] = useState(payImages.concat(payVideos));
  const [allContent, setAllContent] = useState(freeContent.concat(payContent));
  const [gotabg, setgotabg] = useState([]);
  function igotanUrl(info) {
    gotabg.push(info);
  }
  const snapList = useRef(null);
  const visible = useVisibleElements(
    { debounce: 10, ref: snapList },
    ([element]) => element
  );

  const selected = useVisibleElements(
    { ref: snapList, debounce: 10 },
    (elements) => elements[0]
  );
  const goToElement = useScroll({ ref: snapList });
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (isMobile) {
      if (allContent.length > 1) {
        for (var i = 0; i < allContent.length; i++) {
          if (document.getElementById(`${thePost.guid}${i}`)) {
            document
              .getElementById(`${thePost.guid}${i}`)
              .classList.remove(classes.active);
          }
        }

        if (document.getElementById(`${thePost.guid}${visible}`)) {
          document
            .getElementById(`${thePost.guid}${visible}`)
            .classList.add(classes.active);
        }
      }
    }
  }, [selected, goToElement]);

   const rows = [];
   var a =0
   console.log("thisisthepost",thePost, thePost.freeMedia)
   if(thePost.payMedia){a = Number(thePost.payMedia.length) + Number(thePost&&thePost.freeMedia?thePost.freeMedia.length:0);}
   for (
     let i = 0;
     i < a;
     i++
   ) {
     rows.push(i);
   }
  const lastSnapItem = useRef(null);

  return (
    <Fragment>
      <div
        id={`id${thePost.guid}`}
        className={isMobile ? classes.mobelementouter : classes.elementouter}
      >
        <div
          className={isMobile ? classes.mobelement : classes.element}
          key={`${allContent}${thePost}`}
        >
          {(!isMobile || allContent.length <= 1) &&
            allContent.map((image, index) => (
              <div
                key={`${thePost.creationTime} ${index}`}
                className={
                  isMobile ? classes.mobelementinner : classes.elementinner
                }
              >
                {image.type === "image" ? (
                  <SmallImageElement
                    guid={thePost.guid}
                    imgid={image.mediaID}
                    producerID={thePost.producerID}
                    token={context.token}
                    sendmetheurl={igotanUrl}
                    showError={available}
                  />
                ) : (
                  <Videopreview
                    guid={thePost.guid}
                    mediaID={image.mediaID}
                    producerID={thePost.producerID}
                    authToken={context.token}
                    context={context}
                    sendmetheurl={igotanUrl}
                    showError={available}
                  />
                )}
              </div>
              
            ))}

          {isMobile && allContent.length > 1 && (
            <SnapList ref={snapList} width="100vw">
              {allContent.map((image, index) => (
                <SnapItem
                  width={"100%"}
                  margin={"auto"}
                  snapAlign="start"
                  key={`snap${index}`}
                  id={`snapme${index}`}
                >
                  <div
                    visible={index}
                    key={`${thePost.creationTime} ${index}`}
                    className={
                      isMobile ? classes.mobelementinner : classes.elementinner
                    }
                  >
                    {allContent.length > 1 ? (
                      <p className={classes.showpos}>
                        {index + 1}/{allContent.length}
                      </p>
                    ) : null}
                    {image.type === "image" ? (
                      <SmallImageElement
                        guid={thePost.guid}
                        imgid={image.mediaID}
                        producerID={thePost.producerID}
                        token={context.token}
                        sendmetheurl={igotanUrl}
                        showError={available}
                      />
                    ) : (
                      <Videopreview
                        guid={thePost.guid}
                        mediaID={image.mediaID}
                        producerID={thePost.producerID}
                        authToken={context.token}
                        context={context}
                        sendmetheurl={igotanUrl}
                        showError={available}
                      />
                    )}
                  </div>
                </SnapItem>
              ))}
            </SnapList>
          )}
        </div>
{children}
        {isMobile && available && rows.length > 1 ? (
          <div className={classes.pagination}>
            {rows.map((böbbl, index) => (
              <p
                id={`${thePost.guid}${index}`}
                onClick={() => {
                  goToElement(index);
                }}
                className={classes.böpl}
                key={`böbl${index}`}
              >
                &#x2022;
              </p>
            ))}
          </div>
        ) : null}
      </div>
     
    </Fragment>
  );
}

export default PostCardContent;
