
import React from "react";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import RedButton from "../WrapperComponents/redbutton"
import classes from "./makeavs.module.css";

const MakeAVS = () =>{

    const {t}=useTranslation()
    return (
      <div className={classes.container}>
        <p>{t("toseemakeavs")}</p>
        <Link to="/profile">
          <RedButton>Jetzt AVS machen</RedButton>
        </Link>
      </div>
    );
};

export default MakeAVS;