import React, {useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useMyHttp from "../../../hooks/myhttp";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import LoadingSpinner from "../../Cards/Extras/loadingspinner";
import MyButton from "../../Cards/WrapperComponents/myButton";
import PortalModal from "../../Cards/WrapperComponents/portalmodal";
import classes from "./avsoverview.module.css";


const AVSGetData=(props)=>{
  const firstNameInputRef =useRef();
  const lastNameInputRef =useRef();
  const mailInputRef = useRef();
  const phoneInputRef = useRef();
  const birthdayInputRef = useRef();
  const streetInputRef = useRef();
  const plzInputRef = useRef();
  const cityInputRef = useRef();
  const countryInputRef = useRef();
  const storage = window.localStorage;

  const curUrl = encodeURIComponent(window.location);
  const {t}=useTranslation();
  const authCtx=props.context;
  const [countryCode,setcountrycode]=useState("")

  function gotback (){
    props.sendback();
  }

  useEffect(()=>{
    var check = navigator.language.slice(-2);
    if(check) {
      setcountrycode(check)
    }
  },[])

  const [datamissing,setdatamissing]=useState(false);

  function submitHandler (event){
    event.preventDefault();

    if (firstNameInputRef.current.value.length<1){
      setdatamissing(true);
      return;
    }
    if (lastNameInputRef.current.value.length < 1) {
      setdatamissing(true);
      return;
    }
    if (mailInputRef.current.value.length < 1) {
      setdatamissing(true);
      return;
    }

    if (phoneInputRef.current.value.length < 1) {
      setdatamissing(true);
      return;
    }
    if (birthdayInputRef.current.value.length < 1) {
      setdatamissing(true);
      return;
    }
    // if (streetInputRef.current.value.length < 1) {
    //   setdatamissing(true);
    //   return;
    // }
    // if (plzInputRef.current.value.length < 1) {
    //   setdatamissing(true);
    //   return;
    // }
    // if (cityInputRef.current.value.length < 1) {
    //   setdatamissing(true);
    //   return;
    // }
    if (countryInputRef.current.value.length < 1) {
      setdatamissing(true);
      return;
    }

    var mybody = {
      firstName: firstNameInputRef.current.value,
      lastName: lastNameInputRef.current.value,
      email: mailInputRef.current.value,
      phoneNumber: phoneInputRef.current.value,
      method: props.method,
      birthday: birthdayInputRef.current.value,
      street: "",
      plz: "",
      city: "",
      countryCode: countryInputRef.current.value,
      backLink: curUrl,
    };

    var jsonobject=JSON.stringify(mybody)
      fetchSession(jsonobject);
    }

    const { isLoading, data, error, sendRequest } = useMyHttp();
    
    function fetchSession(body) {
      sendRequest(
        `https://api.deine.fans/api/avssession?userID=${encodeURIComponent(authCtx.userID)}&authToken=${encodeURIComponent(authCtx.token)}`,
        "POST",
        body,
        null,
        null,
        authCtx
      );
    }
  
    function relocate(destination) {
      window.location.replace(destination);
    }

    const [hasMadeAvs,setHasMadeAvs] = useState(false)

    function makeavs () {
      setHasMadeAvs(true);
    console.log(props.method)
      if (props.method === "SofortIdent") {
        fetch(
          `https://api.deine.fans/api/${encodeURIComponent(
            props.method
          )}?userID=${encodeURIComponent(
            authCtx.userID
          )}&authToken=${encodeURIComponent(
            authCtx.token
          )}&avsSessionID=${encodeURIComponent(data.newsessionid)}`
        ).then((res) => {
          res.json().then((data) => {
            if (data.url) {
              relocate(data.url);
            } else {
              console.log(data.error);
            }
          });
        });
      } else if (props.method === "AVSHandyUpload") {
        fetch(
          `https://api.deine.fans/api/avshandyupload?userID=${encodeURIComponent(
            authCtx.userID
          )}&authToken=${encodeURIComponent(
            authCtx.token
          )}&avsSessionID=${encodeURIComponent(data.newsessionid)}`
        ).then((res) => {
          res.json().then((data) => {
            if (data.url) {
              relocate(data.url);
            } else {
              console.log(data.error);
            }
          });
        });
      } else if (props.method === "AVSWebcam") {
        fetch(
          `https://api.deine.fans/api/${encodeURIComponent(
            props.method
          )}?userID=${encodeURIComponent(
            authCtx.userID
          )}&authToken=${encodeURIComponent(
            authCtx.token
          )}&avsSessionID=${encodeURIComponent(data.newsessionid)}`
        ).then((res) => {
          res.json().then((data) => {
            if (data.url) {
              relocate(data.url);
            } else {
              console.log(data.error);
            }
          });
        });
      } else if (props.method === "avsondato") {
        fetch(
          `https://api.deine.fans/api/${encodeURIComponent(
            props.method
          )}?userID=${encodeURIComponent(
            authCtx.userID
          )}&authToken=${encodeURIComponent(
            authCtx.token
          )}&avsSessionID=${encodeURIComponent(data.newsessionid)}`
        ).then((res) => {
          res.json().then((data) => {
            if (data.url) {
              relocate(data.url);
            } else {
              console.log(data.error);
            }
          });
        });
      }
    }
const isMobile=useCheckMobileScreen();
    return (
      <PortalModal onclose={gotback} fullscreen={isMobile ? true : false}>
        <div className={classes.wrapper}>
          <div className={classes.form}>
            {!data && (
              <form onSubmit={submitHandler} key="form">
                <div>
                  <h5 className={classes.header}>
                    {t("avswith")}{" "}
                    {props.method === "avsondato" ? t("ondato") : props.method}
                  </h5>
                  {props.method === "SofortIdent" && (
                    <p className={classes.extrainfo}>
                      {t("infoszusofortident")}
                    </p>
                  )}
                  {props.method === "avsondato" && (
                    <p className={classes.extrainfo}>
                      {t("infoszuondato")}
                    </p>
                  )}
                  {props.method === "AVSHandyUpload" && (
                    <p className={classes.extrainfo}>{t("webcaminfo")}</p>
                  )}
                </div>
                <div className={classes.control}>
                  <span className={classes.controlphone}>
                    <label className={classes.begone} htmlFor="text">
                      {t("yourfirstname")}
                    </label>
                    <input
                      defaultValue={storage.getItem("username")}
                      onChange={(e) => {
                        storage.setItem("username", e.target.value);
                      }}
                      type="text"
                      id="firstname"
                      autoComplete="given-name"
                      placeholder={t("yourfirstname")}
                      required
                      ref={firstNameInputRef}
                      key="test"
                    />
                  </span>
                  <span className={classes.controlphone}>
                    <label className={classes.begone} htmlFor="text">
                      {t("yourlastname")}
                    </label>
                    <input
                      defaultValue={storage.getItem("userlastname")}
                      onChange={() => {
                        storage.setItem(
                          "userlastname",
                          lastNameInputRef.current.value
                        );
                      }}
                      type="text"
                      id="lastname"
                      placeholder={t("yourlastname")}
                      required
                      autoComplete="family-name"
                      ref={lastNameInputRef}
                    />
                  </span>
                </div>
                <div className={classes.control}>
                  <span className={classes.controlphone}>
                    <label className={classes.begone} htmlFor="mail">
                      {t("yourmail")}
                    </label>
                    <input
                      defaultValue={storage.getItem("usermail")}
                      onChange={() => {
                        storage.setItem("usermail", mailInputRef.current.value);
                      }}
                      type="email"
                      id="mail"
                      required
                      placeholder={t("yourmail")}
                      autoComplete="email"
                      ref={mailInputRef}
                    />
                  </span>

                  <span className={classes.controlphone}>
                    <label className={classes.begone} htmlFor="date">
                      {t("yourbirthday")}
                    </label>
                    <p className={classes.mobile}>{t("yourbirthday")}</p>
                    <input
                      defaultValue={storage.getItem("userbirthday")}
                      onChange={() => {
                        storage.setItem(
                          "userbirthday",
                          birthdayInputRef.current.value
                        );
                      }}
                      type="date"
                      id="birthday"
                      autoComplete="bday"
                      required
                      placeholder={t("yourbirthday")}
                      ref={birthdayInputRef}
                    />
                  </span>
                </div>
                <div className={classes.control}>
                  <span className={classes.controlphone}>
                    <label className={classes.begone} htmlFor="number">
                      {t("yourphone")}
                    </label>
                    <input
                      defaultValue={storage.getItem("usernumber")}
                      onChange={() => {
                        storage.setItem(
                          "usernumber",
                          phoneInputRef.current.value
                        );
                      }}
                      type="tel"
                      id="phonenumber"
                      required
                      placeholder={t("yourphone")}
                      ref={phoneInputRef}
                      autoComplete="tel-national"
                    />
                  </span>
                  <span className={classes.controlphone}>
                    <label htmlFor="text" className={classes.begone}>
                      {t("yourcountry")}
                    </label>
                    <input
                      defaultValue={countryCode}
                      type="text"
                      id="country"
                      required
                      placeholder={t("yourcountry")}
                      ref={countryInputRef}
                    />
                  </span>
                </div>
                {/* <div className={`${classes.control} ${classes.controllong}`}>
                  <span className={classes.controlstreet}>
                    <label htmlFor="text" className={classes.begone}>
                      {t("yourstreet")}
                    </label>
                    <input
                      defaultValue={storage.getItem("userstreet")}
                      onChange={() => {
                        storage.setItem(
                          "userstreet",
                          streetInputRef.current.value
                        );
                      }}
                      type="text"
                      id="street"
                      required
                      placeholder={t("yourstreet")}
                      ref={streetInputRef}
                      autoComplete="street-address"
                    />
                  </span>
                </div>
                <div className={classes.control}>
                  <span className={classes.controlzip}>
                    <label htmlFor="text" className={classes.begone}>
                      {t("yourplz")}
                    </label>
                    <input
                      defaultValue={storage.getItem("userzip")}
                      onChange={() => {
                        storage.setItem("userzip", plzInputRef.current.value);
                      }}
                      type="text"
                      id="zip"
                      required
                      placeholder={t("yourplz")}
                      ref={plzInputRef}
                      autoComplete="postal-code"
                    />
                  </span>
                  <span className={classes.controlcity}>
                    <label htmlFor="text" className={classes.begone}>
                      {t("yourcity")}
                    </label>
                    <input
                      defaultValue={storage.getItem("usercity")}
                      onChange={() => {
                        storage.setItem("usercity", cityInputRef.current.value);
                      }}
                      type="text"
                      id="city"
                      required
                      placeholder={t("yourcity")}
                      ref={cityInputRef}
                      autoComplete="address-level2"
                    />
                  </span>
                </div> */}

                <div className={classes.actions}>
                  {datamissing && (
                    <h5 className={classes.head}>{t("checkdata")}</h5>
                  )}
                  <button
                    type="button"
                    className={classes.button}
                    onClick={submitHandler}
                  >
                    {t("sendpersdata")}
                  </button>
                </div>
              </form>
            )}
            {isLoading && <h5>{t("loading")}</h5>}
            {error && <h5>{t("error")}</h5>}
            {data && (
              <div>
                <div>
                  <h5 className={classes.head}>
                    {props.method === "AVSHandyUpload"
                      ? t("webcaminfo")
                      : t("sendingyouaway")}
                  </h5>
                </div>
                {!hasMadeAvs && (
                  <div onClick={makeavs}>
                    <MyButton>{t("agreed")}</MyButton>
                  </div>
                )}
                {hasMadeAvs && (
                  <div>
                    <LoadingSpinner isSmall={true} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </PortalModal>
    );
};

export default AVSGetData;