import React, { Fragment } from "react";
import classes from "./cookiemodal.module.css";
import ReactDOM from "react-dom";

const FSK18Modal = (props) => {
  // const Backdrop = (props) => {
  //   return <div className={classes.backdrop} onClick={closeme}></div>;
  // };
  const isTop=props.double;

  const ModalOverlay = (props) => {
    return (
      <div
        className={
          isTop
            ? `${classes.modaltop} ${classes.extramodal}`
            : `${classes.modal} ${classes.extramodal}`
        }
        id="fsk18ModalContainer"
      >
        <button
          className={classes.closeBtn}
          id="closePortalModal2"
          onClick={closeme}
        >
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
            alt="close modal"
            className={classes.closex}
            id="anix"
          />
        </button>
        <div className={classes.content}>{props.children}</div>
      </div>
    );
  };
  function closeme() {
    props.onclose();
  }
  const portalElement = document.getElementById("overlay");
  return (
    <Fragment>
      {/* {ReactDOM.createPortal(<Backdrop />, portalElement)}; */}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
      
    </Fragment>
  );
};

export default FSK18Modal;
