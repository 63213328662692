import React, {
  Fragment,
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Models from "./pages/models";
import { Route, Routes, useLocation } from "react-router-dom";
import Privacy from "./pages/privacy";
import AGB from "./pages/agb";
import Detailsite from "./pages/detail";
import Favgirls from "./components/lists/favgirls";
import Login from "./pages/login";
import Collection from "./pages/collection";
import Profile from "./pages/profile";
import FilteredApiGirls from "./components/lists/filteredapigirls";
import AuthContext from "./context/authcontext";
import AllSubscriptions from "./components/lists/Subscriptions/allsubscriptions";
import SearchForTags from "./components/sonstige/searchfortags";
import classes from "./app.module.css";
import AuthResult from "./components/payment/authresult";
import Impressum from "./components/sonstige/impressum";
import RecordKeeping from "./components/sonstige/RecordKeeping";
import Support from "./components/sonstige/support";
import { useTranslation } from "react-i18next";
import Firsttime from "./components/sonstige/firsttime";
import MessagesforUser from "./components/lists/messenger/messagesforuser";
import { useState } from "react";
import { checkreceivedData } from "./hooks/checkReceivedData";
import PortalModal from "./components/Cards/WrapperComponents/portalmodal";
import CookieModal from "./components/Cards/WrapperComponents/cookieModal";
import Cookies from "./components/Cards/Extras/cookies";
import FSK18Modal from "./components/Cards/WrapperComponents/fsk18modal";
import FSK18 from "./components/Cards/Extras/fsk18";
import useCheckMobileScreen from "./hooks/useCheckMobile";
import SmallMainPageDesktop from "./layout/smallmainpagedesktop";
import PasswordReset from "./components/sonstige/passwordreset";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "./components/Cards/Extras/loadingspinner";

import uuid from "react-uuid";
import { Analytics } from "aws-amplify";
import BasicTimeline from "./pages/basicTimeline/basicTimeline";
import { refreshtoken } from "./hooks/refreshtoken";
import SinglePost from "./components/sonstige/SinglePost/SinglePost";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { useSessionCount } from "./hooks/useSessionCount";

import UploadPicture from "./components/Cards/Extras/profileinfo/uploadpicture";
import { checkstatus } from "./hooks/checkstatus";
import { useADR, useMessages } from "./hooks/usePreflight";
import UploadVideos from "./components/Cards/Extras/profileinfo/uploadVideos";
import SendVoucherCode from "./components/sonstige/formulare/sendVoucherCode";
import AddPayable from "./components/payment/apppayable";
import Aktionswrapper from "./components/Cards/userUIElements/aktionswrapper";
import BoughtItemsTimeline from "./components/lists/Timeline/boughtItemsTimeline";
import Complaints from "./components/sonstige/specialcomponents/Complaints";

const Mainpage = lazy(() => import("./layout/mainpage"));

const App = () => {
  const userCtx = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const newParams = new URLSearchParams(
    [...searchParams].map(([key, value]) => [key.toLowerCase(), value])
  );
  const adr = newParams.get("adr");
  const { i18n } = useTranslation();
  const location = useLocation();
  const [isCookieBanner, setIsCookieBanner] = useState(false);
  const [canSee, setCanSee] = useState(false);
  const [isDarkmode, setIsDarkMode] = useState(userCtx.darkMode);
  const isMobile = useCheckMobileScreen();
  const [isFSK18, setIsFSK18] = useState(false);
  const { t } = useTranslation();
  const [isThereAVS, setIsThereAVS] = useState(false);
  const [showSite, setShowSite] = useState(false);

  const [mytoken, setMyToken] = useState(null);
  const refToken = useRef(null);
  const theADR=useADR(state=>state.adr);
  const changeADR = useADR((state) => state.updateADR);
  const [firingUp,setFiringUp]=useState(true);
  // ON STARTUP
  useEffect(() => {
    setLanguageAndDark();
   
    setTimeout(() => {
      setCookieBanner();
      if (window.location.pathname.search("passwordlost") === -1) {
        refreshUser();
      }
    }, 0);
  
    setTimeout(() => {
      setFiringUp(false);
    }, 100)
    
  }, []);

  function setLanguageAndDark() {
  
    
    if (newParams.get("adr") && newParams.get("adr").length > 0) {
      userCtx.setMyAdr(adr);
      changeADR(adr);
    }
    if (
      localStorage.getItem("darkmode") === true ||
      localStorage.getItem("darkmode") === "true"
    ) {
      userCtx.setDark();
    }
    if (
      localStorage.getItem("darkmode") === false ||
      localStorage.getItem("darkmode") === "false"
    ) {
      userCtx.setBright();
    }
    if (
      localStorage.getItem("darkmode") === null ||
      localStorage.getItem("darkmode") === "null"
    ) {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        userCtx.setDark();
      } else {
        userCtx.setBright();
      }
    }
    var userLang = navigator.language || navigator.userLanguage;

    if (userLang.includes("de")) {
      i18n.changeLanguage("de");
    } else {
      i18n.changeLanguage("en");
    }

    var lang = localStorage.getItem("language");

    if (userCtx.myLanguage === "null") {
      userCtx.switchlanguage(userLang);
    }

    if (lang) {
      if (lang !== i18n.language) {
        Analytics.record({
          name: "languageChange",
          attributes: {
            language: lang,
            userID: userCtx.userID,
          },
        });

        i18n.changeLanguage(lang);
        userCtx.switchlanguage(lang);
      }
    }
  }

  function setCookieBanner() {
    if (!localStorage.getItem("cookies")) {
      setIsCookieBanner(true);
    }
    if (!localStorage.getItem("fsk")) {
      setIsFSK18(!isMobile);
    }
  }
  function refreshUser() {
    setShowSite(true);
    if (typeof localStorage.getItem("refreshToken") === "undefined") {
      return;
    }
    if (localStorage.getItem("refreshToken")) {
      refreshtoken(userCtx, localStorage.getItem("refreshToken"));
    }
  }
  useEffect(() => {
    // const onKeyDown = (e) => {
    //   if (e.ctrlKey && e.key === ThemeToggleKey) {
    //     toggleTheme();
    //   }
    // };
    const onClick = (e) => {
      const test = sendMetric({
        target: e.target.id ? e.target.id : "noId",
      });
      useSessionCount(userCtx, location.pathname);
    };
    document.addEventListener("click", onClick);
    // document.addEventListener("keydown", onKeyDown);

    return () => {
      // document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("click", onClick);
    };

    // eslint-disable-next-line
  }, []);

  function sendMetric(body) {
    fetch(`https://api.deine.fans/api/health/metrics`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userID: userCtx.userID,
        authToken: userCtx.jwtAuthToken,
        preflightToken: refToken.current,
        preflightAction: "login",
        metrics: [
          {
            id: uuid(),
            metricID: "click",
            metricKey: window.location.pathname,
            metricSubKey: body.target,
            time: new Date().toLocaleTimeString(),
          },
        ],
      }),
    });
  }
  // React.useEffect(() => {
  //   if (userCtx.isLoggedIn) {
  //     setTimeout(() => {
  //       useMetric(
  //         "pageHit",

  //         [
  //           {
  //             id: uuid(),
  //             metricID: "page-hit",
  //             metricSubKey: "",
  //             metricKey: location.pathname,
  //             userID: userCtx.userID,
  //             time: new Date().toLocaleString(),
  //           },
  //         ],
  //         userCtx
  //       );
  //     }, 0);
  //   }

  //   useSessionCount(userCtx, location.pathname);
  // }, [location]);

  function closeCookie() {
    setIsCookieBanner(false);
  }
  function closeFSK() {
    setIsFSK18(false);
  }

  useEffect(() => {
    if (userCtx.isLoggedIn) {
      try {
        fetch(
          `https://api.deine.fans/api/userstatus?userID=${encodeURIComponent(
            userCtx.userID
          )}&authToken=${encodeURIComponent(userCtx.token)}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((res) => {
          res.json().then((data) => {
            checkreceivedData(data, userCtx, "App");
            setShowSite(true);
            if (data.currentBalance) {
              userCtx.checkFanDollar(data.currentBalance / 100);
            }

            if (!data.hasPaymentData) {
              userCtx.dontBePremium();
            }
            if (data.hasPaymentData) {
              userCtx.bePremium("PREMIUMTOKEN");
            }

            if (data.avsStatus === 1) {
              userCtx.setAVS(true);
              setIsThereAVS(true);
            }
            if (data.avsStatus !== 1) {
              userCtx.setAVS(false);
              setIsThereAVS(false);
            }
            if (data.error) {
              return <PortalModal>{data.error}</PortalModal>;
            }
          });
        });
      } catch (err) {
        console.error("Error fetching userstatus: " + err);
      }
    }
  }, [userCtx.isLoggedIn]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsThereAVS(userCtx.isAVS);
  //   }, "100");
  // }, [userCtx]);

  useEffect(() => {
    setIsDarkMode(userCtx.darkMode);
    setIsThereAVS(userCtx.isAVS);
    if (userCtx.isPremium) {
      setCanSee(true);
    }
    if (userCtx.fanDollar > 0) {
      setCanSee(true);
    }
  }, [userCtx]);
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
    
      return;
    }

    const token = await executeRecaptcha("login");
    const mytoken = await fetch(
      "https://api.deine.fans/api/userlogin/preflight",
      {
        method: "POST",
        body: JSON.stringify({
          authToken: localStorage.getItem("jwtAuthToken"),
          captcha_action: "login",
          captcha_challenge: token,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const gettingcloser = await mytoken.json();
    return gettingcloser.preflightToken;
  }, [executeRecaptcha]);

  useEffect(() => {
    let hugo = handleReCaptchaVerify();
    let hotte = hugo.then(
      (res) => {
        setMyToken(res);
      },
      () => {}
    );
  }, [handleReCaptchaVerify]);
  useEffect(() => {
    refToken.current = mytoken;
  }, [mytoken]);
  return (
    <div className={!isDarkmode ? classes.main : classes.maindm}>
      {showSite ? (
        <Fragment>
          {isCookieBanner && (
            <CookieModal onclose={closeCookie} isTop={isFSK18 ? true : false}>
              <Cookies sendBack={closeCookie} />
            </CookieModal>
          )}
          {isFSK18 && (
            <FSK18Modal onclose={closeFSK}>
              <FSK18 sendBack={closeFSK} />
            </FSK18Modal>
          )}
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Mainpage>
                    {!userCtx.isLoggedIn && isMobile && <Login />}
                    {!userCtx.isLoggedIn && !isMobile && <Models />}

                    {userCtx.isLoggedIn && isThereAVS && canSee && (
                      <Fragment>
                        <Aktionswrapper>
                          <SendVoucherCode phone={true} />
                        </Aktionswrapper>
                        <BasicTimeline />
                      </Fragment>
                    )}
                    {userCtx.isLoggedIn && isThereAVS && !canSee && (
                      <div className={classes.isnapstart}>
                        {/* <h3 className={classes.head}>{t("addpaymenttosee0")}</h3>
                    <h3 className={classes.head}>{t("addpaymenttosee")}</h3> */}
                        {/* <Link to="/profile" className={classes.bigbutton}>
                      <RedButton className={classes.bigger}>
                        {t("addpay")}
                      </RedButton>
                    </Link> */}
                        <Fragment>
                          <Aktionswrapper>
                            <SendVoucherCode phone={true} />
                          </Aktionswrapper>
                        </Fragment>
                        <BasicTimeline extra="payment" />
                      </div>
                    )}
                    {userCtx.isLoggedIn && !isThereAVS && <Firsttime />}
                  </Mainpage>
                }
              />
              <Route
                path="/new"
                exact
                element={
                  <SmallMainPageDesktop>
                    <Models />
                  </SmallMainPageDesktop>
                }
              />
              <Route
                path="/models"
                exact
                element={
                  <Mainpage>
                    <Models />
                  </Mainpage>
                }
              />
              <Route
                path="/creator"
                exact
                element={
                  <Mainpage>
                    <Models />
                  </Mainpage>
                }
              />
              <Route
                path="/complaints"
                exact
                element={
                  <Mainpage>
                    <Complaints />
                  </Mainpage>
                }
              />
              <Route
                path="/boughtitems"
                exact
                element={
                  <Mainpage>
                    {userCtx.isLoggedIn ? <BoughtItemsTimeline /> : null}
                  </Mainpage>
                }
              />
              <Route
                path="/test"
                exact
                element={
                  <Mainpage>
                    {/* <CancelFormular /> */}
                    {/* <CollectionTimeline /> */}
                    {/* <HandCraftedFacebookLogin /> */}
                    {/* <WeAreTheBest />
                      <BoughtContent /> */}
                    {/* <UploadVideos width={400} height={300} /> */}
                    {userCtx.isLoggedIn ? (
                      <BoughtItemsTimeline />
                    ) : (
                      <p>PleaseLogin</p>
                    )}
                  </Mainpage>
                }
              />

              <Route
                path="/models/:filterID"
                element={
                  <Mainpage>
                    <SearchForTags />
                    <FilteredApiGirls />
                  </Mainpage>
                }
              />
              <Route
                path="/creator/:filterID"
                element={
                  <Mainpage>
                    <SearchForTags />
                    <FilteredApiGirls />
                  </Mainpage>
                }
              />

              <Route
                path="/passwordlost/:key"
                element={
                  <Mainpage>
                    <PasswordReset />
                  </Mainpage>
                }
              />

              <Route
                path="/profile"
                element={
                  <Mainpage>
                    <Profile />
                  </Mainpage>
                }
              />

              <Route
                path="/collection"
                exact
                element={
                  <Mainpage>
                    <Collection />
                  </Mainpage>
                }
              />
              <Route
                path="/login"
                element={
                  <Mainpage>
                    <Login />
                  </Mainpage>
                }
              />
              <Route
                path="/signup"
                element={
                  <Mainpage>
                    <Login isSignup={true} />
                  </Mainpage>
                }
              />
              <Route
                path="/favs"
                exact
                element={
                  <Mainpage>
                    <Favgirls />
                  </Mainpage>
                }
              />
              <Route
                path="/subscriptions"
                exact
                element={
                  <Mainpage>
                    <AllSubscriptions />
                  </Mainpage>
                }
              />

              <Route
                path="/messages"
                element={
                  <Mainpage>
                    <MessagesforUser />
                  </Mainpage>
                }
              />
              <Route
                path="/privacy"
                element={
                  <Mainpage>
                    <Privacy />
                  </Mainpage>
                }
              />
              <Route
                path="/impressum"
                element={
                  <Mainpage>
                    <Impressum />
                  </Mainpage>
                }
              />
              <Route
                path="/2257"
                element={
                  <Mainpage>
                    <RecordKeeping />
                  </Mainpage>
                }
              />
              <Route
                path="/support"
                element={
                  <Mainpage>
                    <Support />
                  </Mainpage>
                }
              />
              <Route
                path="/agb"
                element={
                  <Mainpage>
                    <AGB />
                  </Mainpage>
                }
              />
              <Route
                path="/post/:postId"
                element={
                  <Mainpage>
                    <SinglePost />
                  </Mainpage>
                }
              ></Route>

              <Route
                path="/:girlId"
                element={
                  <Mainpage detail={true}>
                    <Detailsite />
                  </Mainpage>
                }
              />
              <Route
                path="/payment/result/failed/:key"
                element={
                  <Mainpage>
                    <AuthResult result="failed" />
                  </Mainpage>
                }
              />
              <Route
                path="/payment/result/success/:key"
                element={
                  <Mainpage>
                    <AuthResult result="success" />
                  </Mainpage>
                }
              />
              <Route
                path="/payment/result/processing/:key"
                element={
                  <Mainpage>
                    <AuthResult result="processing" />
                  </Mainpage>
                }
              />

              <Route
                path="/payment/result/already_authorized/:key"
                element={
                  <Mainpage>
                    <AuthResult result="already_authorized" />
                  </Mainpage>
                }
              />
              <Route
                path="/payment/result/transsuccess/:key"
                element={
                  <Mainpage>
                    <AuthResult result="transuccess" />
                  </Mainpage>
                }
              />
            </Routes>
          </Suspense>
        </Fragment>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default React.memo(App);
