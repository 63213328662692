import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "./simplepaymentclasses.module.css"


const ConsentElement=memo((props)=>{
function togglewiderspruch(){

    props.setok();
};
const {t}=useTranslation();
    return (
      <p className={classes.consentblock}>
        <label>
          <input
            className={classes.checkbox}
            type="checkbox"
            onChange={togglewiderspruch}
          />
          {"  "}
          <span className={classes.consenttext}>
            {" "}
            {t("acceptagb1")}
            <Link
              to="/AGB"
              className={classes.otpagb}
              onClick={() => {
                props.clickedagb();
              }}
            >
              {" "}
              {t("agb")}
            </Link>
            . {t("acceptagb2")}
          </span>
        </label>
      </p>
    );
});

export default ConsentElement;