import React from 'react'
import Report from '../../Cards/Extras/reporting/report';

function Complaints() {
  return (
    <div>
      <h5>Content Removal Request / Antrag auf Entfernung von Inhalten</h5>
      <hr />
      <p>
        This form provides a method for you to report copyright violations as
        well as illegal or non-consensual content. We will strive to respond to
        your report as quickly as possible in a fair and reasonable manner. We
        intend to resolve all reports within seven (7) business days and provide
        clear guidelines to prevent issues from recurring. If a violation has
        occurred, we will determine the appropriate remedy and take the
        necessary action. Any content deemed to be illegal will be removed
        immediately. We will not act against other users for activity that
        happens on another platform or offline. In instances where disputes over
        consent cannot be resolved internally, such disputes can be referred to
        a neutral arbitration association. Once an action has been taken, or if
        more information is needed from you, we will contact you by email. Mit
        diesem Formular können Sie Urheberrechtsverletzungen sowie illegale oder
        unzulässige Inhalte melden. Wir werden uns bemühen, auf Ihre Meldung so
        schnell wie möglich, fair und angemessen zu reagieren. Wir
        beabsichtigen, alle Meldungen innerhalb von sieben (7) Werktagen zu
        lösen und klare Richtlinien zur Verfügung zu stellen, um ein erneutes
        Auftreten des Problems zu verhindern. Sollte ein Verstoß festgestellt
        werden, werden wir geeignete Abhilfemaßnahmen festlegen und die
        erforderlichen Schritte einleiten. Alle als rechtswidrig eingestuften
        Inhalte werden unverzüglich entfernt. Wir werden nicht gegen andere
        Nutzer für Aktivitäten vorgehen, die auf einer anderen Plattform oder
        offline stattfinden. In Fällen, in denen Streitigkeiten über die
        Zustimmung nicht intern gelöst werden können, können solche
        Streitigkeiten an eine neutrale Schlichtungsstelle weitergeleitet
        werden. Sobald eine Maßnahme ergriffen wurde oder wenn weitere
        Informationen von Ihnen benötigt werden, werden wir Sie per E-Mail
        kontaktieren.
      </p>
      <Report />
    </div>
  );
}

export default Complaints
