import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSendReport } from "../../../../hooks/useSendReport";
import RedButton from "../../WrapperComponents/redbutton";
import classes from "./report.module.css";

const Report =(props)=>{
 const [showReport, setShowReport] = useState(false);
 const [TOS, setTOS] = useState(false);
 const [RIGHT_OR_LEGAL, setRIGHT_OR_LEGAL] = useState(false);
 const [STOLEN_DMCA, setSTOLEN_DMCA] = useState(false);
 const [SPAM, setSPAM] = useState(false);
 const [ABUSIVE, setABUSIVE] = useState(false);
 const [INCLUDES_MINOR, setINCLUDES_MINOR] = useState(false);
 const toggleReport = () => setShowReport((prevState) => !prevState);
 const sendtextref = useRef();
 const {t}=useTranslation();
 const authCtx=props.context;
 

 function sendreport() {
   var a="";
   if (TOS) {
     a += "TOS";
   }
   if (RIGHT_OR_LEGAL) {
     a += "RIGHT_OR_LEGAL";
   }
   if (STOLEN_DMCA) {
     a += "STOLEN_DMCA";
   }
   if (SPAM) {
     a += "SPAM";
   }
   if (ABUSIVE) {
     a += "ABUSIVE";
   }
   if (INCLUDES_MINOR) {
     a += "INCLUDES_MINOR";
   }



   useSendReport(
     authCtx,
     props.guid,
     window.location.href,
     props.type,
     a,
     `${props.type}_CONTENT`,
     sendtextref.current.value
   );
   props.closewindow()
 }
 function makeallfalse(){
    setTOS(false);
    setABUSIVE(false);
    setINCLUDES_MINOR(false);
    setSPAM(false);
    setRIGHT_OR_LEGAL(false);
    setSTOLEN_DMCA(false);
 }
return (
  <div>
    <div>
      <h5>{t("reportproblem")}</h5>
      <div>
        <label>
          <input
            className={classes.checkbox}
            type="checkbox"
            onChange={() => {
            
              makeallfalse();
              setTOS(!TOS);
            }}
            checked={TOS}
          />
          {t("TOS")}
        </label>
      </div>
      <div>
        <label>
          <input
            className={classes.checkbox}
            type="checkbox"
            onChange={() => {
              makeallfalse();
              setRIGHT_OR_LEGAL(!RIGHT_OR_LEGAL);
            }}
            checked={RIGHT_OR_LEGAL}
          />
          {t("RIGHT_OR_LEGAL")}
        </label>
      </div>
      <div>
        <label>
          <input
            className={classes.checkbox}
            type="checkbox"
            onChange={() => {
                makeallfalse()
              setSTOLEN_DMCA(!STOLEN_DMCA);
            }}
            checked={STOLEN_DMCA}
          />
          {t("STOLEN_DMCA")}
        </label>
        <div></div>
        <label>
          <input
            className={classes.checkbox}
            type="checkbox"
            onChange={() => {
                makeallfalse();
              setSPAM(!SPAM);
            }}
            checked={SPAM}
          />
          {t("SPAM")}
        </label>
      </div>
      <div>
        <label>
          <input
            className={classes.checkbox}
            type="checkbox"
            onChange={() => {
                makeallfalse();
              setABUSIVE(!ABUSIVE);
            }}
            checked={ABUSIVE}
          />
          {t("ABUSIVE")}
        </label>
      </div>
      <div>
        <label>
          <input
            className={classes.checkbox}
            type="checkbox"
            onChange={() => {
                makeallfalse();
              setINCLUDES_MINOR(!INCLUDES_MINOR);
            }}
            checked={INCLUDES_MINOR}
          />
          {t("INCLUDES_MINOR")}
        </label>
      </div>
      <div>
        <textarea
          className={classes.textforGirl}
          type="text"
          placeholder={t("wannasendreport")}
          ref={sendtextref}
        />
      </div>
      <div onClick={sendreport}>
        <RedButton>{t("send")}</RedButton>
      </div>
    </div>
  </div>
);
};

export default Report;

