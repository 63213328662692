import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "./firsttime.module.css";
import { Col, Row } from "reactstrap";
import Login from "../../pages/login";

const PleaseLoginShort = (props) => {
  const { t } = useTranslation();
 
  const [curredirectURL, setCurredirrectURL] = useState(props.myUrl|null);
  const [width, setWidth] = useState(Number(window.innerWidth));
  const [isitMobile, setisItMobile] = useState(false);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

    useEffect(() => {
        if (props.myUrl) {
          setCurredirrectURL(props.myUrl);
        }else{setCurredirrectURL(window.location.pathname)}
      window.addEventListener("resize", handleWindowSizeChange);
      if (width <= 800) {
        setisItMobile(true);
      }
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }, []);
  
  return (
    <Row className={classes.box}>
      {!isitMobile && (
        <>
          <Col xs="12">
            <Link to="/login">
              <h2 className={classes.header}>{t("login1")}</h2>
            </Link>
            <p className={classes.text}>{t("login2")}</p>
            <p className={classes.text}>{t("login3")}</p>
           <Login relocate={curredirectURL} />
          </Col>
        </>
      )}
      {isitMobile && (
        <div>
          {/* <Link to="/login">
            {" "}
            <h2 className={classes.header}>{t("login1")}</h2>
          </Link>
          <img
            src="/images/kommreingirl.png"
            className={classes.avsimage}
            alt="avsimagemobile"
          /> */}

          {/* <p className={classes.text}>{t("welcome3")}</p> */}
          <Login relocate={curredirectURL} makeShort={props.makeShort} />
        </div>
      )}
    </Row>
  );
};

export default PleaseLoginShort;
