import React, { useContext, useEffect, useState } from "react";
import FavBox from "../Cards/favbox";

//import Modeladvertorial from "../Cards/modeladvertorial";
import { Col, Row } from "reactstrap";
import classes from "./favgirls.module.css";

import AuthContext from "../../context/authcontext";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import { t } from "i18next";
import PleaseLogin from "../sonstige/pleaselogin";
import ShowInfoNoFollows from "./Timeline/showInfoNoFollows";
import useCheckMobileScreen from "../../hooks/useCheckMobile";
import Login from "../../pages/login";

const Favgirls = () =>{

const authCtx = useContext(AuthContext);

const userToken= authCtx.token
const userID= authCtx.userID
const isLoggedIn = authCtx.isLoggedIn;
const isPremium = authCtx.isPremium;


const [hasFavs,setHasFavs]=useState(false);
const [favGirls, setFavGirls] = useState([]);
useEffect(()=>{
 window.scrollTo(0, 0);
 loadData();
},[])
function loadData() {
   fetch(
     `https://api.deine.fans/api/favs/${encodeURIComponent(userID)}?authToken=${encodeURIComponent(userToken)}`
   ).then((res) => {
     if (res.ok) {
       res.json().then((data) => {
         checkreceivedData(data,authCtx,"favgirls");
         if(data.favorites){
         if (data.favorites.length > 0) {
           setFavGirls(data.favorites);
           setHasFavs(true);
         }
        }
       });
     }
   });
          
}
function getdelete(id){

  var remove = favGirls.findIndex((el) => el.producerID === id);
  document.getElementById('favid'+remove).style.display="none";
}
const isMobile=useCheckMobileScreen();

    return (
      <div>
        {!isLoggedIn && !isPremium && !isMobile && <PleaseLogin />}
        {!isLoggedIn && !isPremium && isMobile && <Login />}
        

        {isLoggedIn && favGirls && favGirls.length === 0 && (
          <ShowInfoNoFollows />
        )}

        {hasFavs && favGirls && (
          <Row className={`${classes.content}`}>
            {favGirls.map((favgirl, index) => (
              <Col xs="12" lg="6" key={`col${index}`} id={`favid${index}`}>
                <FavBox
                  mygirl={favgirl.producerID}
                  key={index}
                  senddelete={getdelete}
                />
              </Col>
            ))}
          </Row>
        )}
      </div>
    );
};

export default Favgirls;