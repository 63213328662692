import { checkreceivedData } from "./checkReceivedData";

export async function useMetric(metric, guid, context) {
  if (metric === "pageHit") {
    fetch(`https://api.deine.fans/api/health/metrics`, {
    method: "PUT",
    body: JSON.stringify({
      userID: context.userID,
      authToken: context.jwtAuthToken,
      metrics:guid
    }),
    headers: {
      "Content-Type": "application/json",
    },
    }).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, context);
        if(data.ids_affected){
          var toRemove = Array.from(context.hitLog);
          for (var i=0;i<data.ids_affected.length;i++){
            var  theIndex=toRemove.findIndex(el=> el.id===data.ids_affected[i])
          toRemove=toRemove.splice(theIndex,1);
          }
          
          context.setHitLog(toRemove);
        }
      });
    });
  } else {
    fetch(`https://api.deine.fans/api/timeline/metric/${encodeURIComponent(metric)}/${encodeURIComponent(guid)}`, {
      method: "POST",
      body: JSON.stringify({
        userID: context.userID,
        authToken: context.jwtAuthToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, context);
      });
    });
  }
}
