import React, { useState } from "react";
import classes from "./testpostcard.module.css";
import PostCardReport from "./postcardReport";
import CommentList from "../../lists/comments/commentlist";
import { useTranslation } from "react-i18next";
import MyImage from "../../sonstige/specialcomponents/images";

function PostCardWrapper({
  id,
  onClick,
  children,
  className,
  darkMode,
  context,
  isNew,
  noextra,
  newborder,
  image,
  shorten,
  isPinned,
   ...rest
}) {
 
  
 
  const {t}=useTranslation();
const [showComment,setShowComment]=useState(false);
function toggleComment(){
    setShowComment(!showComment)
}
  return (
    <div
      id={id}
      className={
        isPinned
          ? darkMode
            ? classes.pinneddm
            : classes.pinned
          : isNew
          ? darkMode
            ? classes.maincontentcontainernewdm
            : classes.maincontentcontainernew
          : newborder
          ? darkMode
            ? classes.maincontentcontainernewdmduenn
            : classes.maincontentcontainernewduenn
          : darkMode
          ? classes.maincontentcontainerdm
          : classes.maincontentcontainer
      }
    >
      {noextra ? <MyImage className={classes.headerimage} src={image} /> : null}
      {isNew ? (
        <div className={classes.newcontainer}>
          <p className={darkMode ? classes.newdm : classes.new}>+++NEW+++</p>
        </div>
      ) : null}
      {children}
      {!shorten ? (
        noextra ? null : (
          <div className={classes.maxwidth}>
            <button
              onClick={toggleComment}
              className={
                !darkMode ? classes.showcommentbtn : classes.showcommentbtndm
              }
            >
              {!showComment ? t("showcomments") : t("showcomments")}
            </button>
          </div>
        )
      ) : null}

      {showComment && <CommentList post={id} dm={darkMode} />}
      {noextra ? null : <PostCardReport guid={id} context={context} />}
    </div>
  );
}

export default PostCardWrapper;
