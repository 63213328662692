import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AuthContext from "../../../context/authcontext";
import SearchForTags from "../searchfortags";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import LoadingSpinner from "../../Cards/Extras/loadingspinner";
import MainPostCard from "../../Cards/MainPostCard/mainPostCard";

function SinglePost(props) {
  const params = useParams();
  const authCtx = useContext(AuthContext);
  const isPremium = authCtx.isPremium;
  const thePostId = params.postId;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const location = useLocation();
  const [hasEntered, setHasEntered] = useState(null);
  
useEffect(()=>{
getPostData();
},[props])

  useEffect(() => {
    setHasEntered(null);
    if (location.state !== null) {
      setHasEntered(location.state.test);
    }
    window.scrollTo(0, 0);
  }, [location.state]);

  async function getPostData() {
    console.log("isitme")
    const getData = await fetch(
      `https://api.deine.fans/api/timeline/post/${encodeURIComponent(
        props.postId?props.postId:thePostId
      )}?userID=${encodeURIComponent(authCtx.userID)}&authToken=${
        authCtx.jwtAuthToken
      }`
    );
   
    const myInforaw = await getData.json();
    // const myInfo = await checkreceivedData(
    //   myInforaw,
    //   authCtx,
    //   `SinglePost: ${thePostId}`
    // );
    if (!myInforaw.error) {
      setData(myInforaw);
      setLoading(false);
    } else {
      setError(myInforaw.error);
      setLoading(false);
      console.log("dieserFehler?", error);
    }
  }
  return (
    <div>
     {props.postId?null: <SearchForTags />}
      {loading ? <LoadingSpinner /> : null}
         
          {data ? (
            <div>
              <MainPostCard
                post={data.post}
                id={thePostId}
                context={authCtx}
                sendbackby={() => {}}
              />
            </div>
          ) : (
            
            error
          )}
    
    </div>
  );
}

export default SinglePost;
