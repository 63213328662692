import React, { Fragment, useEffect, useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MyButton from "../WrapperComponents/myButton";
import PortalModal from "../WrapperComponents/portalmodal";
import RedButton from "../WrapperComponents/redbutton";
import AuthContext from "../../../context/authcontext";
import classes from "./writemessage.module.css";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import ImageUploader from "../../sonstige/imageUploader/imageUploader";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import SimplePaymentBaseModal from "../../payment/simplepayment/simplepaymentBaseModal";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import { checkstatus } from "../../../hooks/checkstatus";
import UploadVideos from "../Extras/profileinfo/uploadVideos";


const WriteMessage=(props)=>{

    const messageref = useRef();
    const [errmsg,setErrmsg]=useState("");
    const [modalMessage,setModalMessage]=useState("");
    const authCtx = useContext(AuthContext);
    const userToken= authCtx.token;
    const userID= authCtx.userID;
    const fanDollar = authCtx.fanDollar;
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [freeMedia,setFreeMedia] = useState([]);
    const [clickedMediaData,setClickedMediaData] = useState(null);
    const [smallPreviewDimension] = useState("80x80xinside");
    const [singleImagePreviewDimension] = useState("480x480xinside");
    const [showAttachmentModal,setShowAttachmentModal]=useState(false);
    //const [attchment,setAttachment]=useState(null);

    function attachImage() {

      setShowAttachmentModal(!showAttachmentModal);
    }
    function closeImage(){
      if(defaultSendImage){tellme(defaultSendImage)}else{attachImage()}
      setDefaultSendImage(false)
    }

    

    function tellme (info){
      //setAttachment(info);
      imageModalItemClicked(
        {
                mediaID: info.imageID,
                type: "image"
            }
        );
      attachImage();
    }
    function closeImageModal() {
      setImageModalOpen(false);
    }

    function imageModalItemClicked(clickedImage) {
   
      setImageModalOpen(false);
      setFreeMedia([...freeMedia,clickedImage]);
    }
    
    async function submitMessage(e) {
      e.preventDefault();

      let receiver = "";

      if(typeof(props.frommessage) !== "undefined") {
        if(props.frommessage !== null) {
          receiver = props.frommessage.sender;
        }
      }

      if(typeof(props.frompost) !== "undefined") {
        if(props.frompost !== null) {
          receiver = props.frompost.producerID;
        }
      }
 
      if(typeof(props.fromgirl) !== "undefined") {
        if (props.fromgirl !== null) {
          receiver = props.fromgirl;
         
        }
      }

      if(receiver === "") {
        receiver = name;
      }

      let url = `https://api.deine.fans/api/messengeruser?userID=${encodeURIComponent(userID)}&receiver=${encodeURIComponent(receiver)}&authToken=${encodeURIComponent(userToken)}`;

      if(typeof(fanDollar) !== "undefined") {
        if(fanDollar !== null) {
          if(fanDollar > 0) {
            url += `&forceUseFanbalance=true`;
          }
        }
      }

      const response = await fetch(url,{
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          msgText: messageref.current.value,
          freeMedia: freeMedia
        })
      });

      const result = await response.json();
     
      if(typeof(result.title) !== "undefined") {
        let errormsg = result.title;
        for(let ei = 0;ei < result.errors.length;ei++) {
          errormsg += " " + result.errors[ei].error;
        }
        setErrmsg(errormsg);
      } else {
        if(result.error !== "") {
          setErrmsg(result.error);
        } else {
          if(result.sendMessage === "Message Sent") {
           setTimeout(() => {checkstatus(authCtx); props.onclose()}, 2000); 
            setModalMessage(t("messagesent"));
          } else {
            setErrmsg(result.sendMessage);
          
          }
        }
      }      
    }

    function onclose(){
      checkstatus();
     props.onclose();
    }

    const [name,setName]=useState(null);
    const [title,setTitle] =useState(null) ;

   useEffect(()=>{
    if (props.frompost) {
      setName(props.frompost.pseudo);
      setTitle(props.frompost.msgTitle);
    }
    if (props.frommessage) {
      setName(props.frommessage.sender);
    }
   },[])

useEffect(() => {
  let receiver = "";

  if (typeof props.frommessage !== "undefined") {
    if (props.frommessage !== null) {
      receiver = props.frommessage.sender;
    }
  }

  if (typeof props.frompost !== "undefined") {
    if (props.frompost !== null) {
      receiver = props.frompost.producerID;
    }
  }

  if (typeof props.fromgirl !== "undefined") {
    if (props.fromgirl !== null) {
      receiver = props.fromgirl;
    }
  }

  if (receiver === "") {
    receiver = name;
  }
    let url = `https://api.deine.fans/api/girls/${encodeURIComponent(receiver)}`;

    if(userID !== null && userID !== "") {
      url += `/${encodeURIComponent(userID)}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, authCtx,"writeMessage");
        if(data && typeof(data.messengerPrice) !== "undefined" && data.messengerPrice !== null) {
          setMessengerPrice(data.messengerPrice);
        } else {
          if(data && data.girl && data.girl.messengerPrice > 0) {
            setMessengerPrice(data.girl.messengerPrice);
          }
        }
      });
    });
}, []);

const [messengerPrice,setMessengerPrice] = useState(null)

   function closeClickedMediaData() {
    setClickedMediaData(null);
   }

   function deleteClickedMediaData() {
        if(clickedMediaData !== null) {
            let myImages = [];
            
            myImages = freeMedia.filter((img) => { return img.mediaID !== clickedMediaData.mediaID });
            setFreeMedia(myImages);
            setClickedMediaData(null);
        }
    }

    function getSmallMediaPreviewUrl(item) {
     
      return "https://d2cq08zcv5hf9g.cloudfront.net/" + smallPreviewDimension + "/" + item.mediaID + ".jpg";
   }

   function getSingleImagePreviewUrl(item) {
 
      return "https://d2cq08zcv5hf9g.cloudfront.net/" + singleImagePreviewDimension + "/" + item.mediaID + ".jpg";
    }

    function freeMediaClicked(clickedMediaData) {
      if(clickedMediaData) {
          setClickedMediaData(clickedMediaData);
      } else {
          setErrmsg(t("invalidimageclicked"));
      }
    }
const [defaultSendImage,setDefaultSendImage]=useState(false);
function sendDefault(image){
setDefaultSendImage(image)
}
   const {t} = useTranslation();
   const [buyFanDollars,setBuyFanDollars]=useState(false);
   function togglebuyFanDollar(){setBuyFanDollars(!buyFanDollars);}
   const isMobile=useCheckMobileScreen();

const [videoattachment,setVideoAttachment]=useState(false);
function togglevideoattachment(){
  setVideoAttachment(!videoattachment);
}
function sendvideo(id){
  console.log(id)
 imageModalItemClicked({
   mediaID: id,
   type: "video",
 });
 
 togglevideoattachment();
}


    return (
      <Fragment>
        {modalMessage !== "" ? (
          <PortalModal big={isMobile ? true : false}>
            {modalMessage}
          </PortalModal>
        ) : null}
        {/* <ImageModal
          id="myImageModal"
          open={imageModalOpen}
          mediaAddMode="message"
          closeCallback={closeImageModal}
          itemClickCallback={imageModalItemClicked}
        ></ImageModal> */}
        {!videoattachment ? (
          <Modal
            isOpen={clickedMediaData !== null}
            size="md"
            onClosed={closeClickedMediaData}
          >
            <ModalHeader
              close={
                <button className="close" onClick={closeClickedMediaData}>
                  ×
                </button>
              }
            >
              {clickedMediaData ? clickedMediaData.type : null}
            </ModalHeader>
            <ModalBody>
              {clickedMediaData ? (
                <img
                  alt=""
                  src={getSingleImagePreviewUrl(clickedMediaData)}
                  className="w-100"
                />
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button onClick={deleteClickedMediaData}>
                {t("deleteimage")}
              </Button>
              <Button onClick={closeClickedMediaData}>{t("close")}</Button>
            </ModalFooter>
          </Modal>
        ) : null}
        {props.frompost ? (
          <Link to="/messages">
            <MyButton>{t("allmessages")}</MyButton>
          </Link>
        ) : null}
        {!videoattachment ? (
          <div className={classes.size}>
            <h5>
              {props.frompost && (
                <div>
                  {t("feedbackfor")} {name} {t("onpost")} "{title}":
                </div>
              )}
              {props.frommessage && (
                <div>
                  {t("messageto")} {name}:
                </div>
              )}
              {props.fromname && (
                <div>
                  {t("messageto")} {props.fromname}:
                </div>
              )}
            </h5>
            {messengerPrice ? (
              <p className={classes.messengerPrice}>
                {t("thiscosts")}
                {(messengerPrice / 100).toFixed(2)}
                {t("thiscosts2")}
              </p>
            ) : null}
            {messengerPrice && authCtx.fanDollar < messengerPrice / 100 ? (
              <p>{t("pleaseaddfandollarstosend")}</p>
            ) : null}
            {errmsg && errmsg !== "" && (
              <div className={classes.errmsg}>{errmsg}</div>
            )}
            <form onSubmit={submitMessage}>
              <textarea
                className={classes.textforGirl}
                type="text"
                placeholder={t("wannasendmessage")}
                ref={messageref}
              />
            </form>
            <div>
              <h6>{t("attachments")}:</h6>
              <div className="d-flex flex-wrap" style={{ gap: "4px" }}>
                {freeMedia.map((item, index) => (
                  <div className="smallMediaPreview" key={index}>
                    <div className="smallMediaPreviewIcon">
                      {item.type === "image" ? (
                        <img
                          alt=""
                          src="/images/icons/bild.png"
                          width="16"
                          height="16"
                        />
                      ) : item.type === "video" ? (
                        <img
                          src="/images/icons/video.png"
                          alt=""
                          width="30"
                          height="30"
                        />
                      ) : item.type === "audio" ? (
                        <img
                          alt=""
                          src="/images/icons/unmute.png"
                          width="16"
                          height="16"
                        />
                      ) : null}
                    </div>
                    <div className="w-100">
                      <img
                        src={getSmallMediaPreviewUrl(item)}
                        alt=""
                        className="w-100"
                        style={{ cursor: "pointer" }}
                        onClick={freeMediaClicked.bind(this, item)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.buttonfield}>
              {messengerPrice && authCtx.fanDollar < messengerPrice / 100 ? (
                <div>
                  {" "}
                  <RedButton onClick={togglebuyFanDollar}>
                    {t("addFandollar")}
                  </RedButton>
                </div>
              ) : (
                <div onClick={submitMessage}>
                  <RedButton>
                    {messengerPrice
                      ? `${t("sendfor")}${(messengerPrice / 100).toFixed(2)} FD`
                      : t("send")}
                  </RedButton>
                </div>
              )}
              <div onClick={attachImage}>
                <RedButton>
                  <img
                    src="/images/icons/klammer.png"
                    width="24"
                    height="24"
                    alt="Dateianhang"
                    style={{ display: "inline-block" }}
                  />{" "}
                  {t("attatchimage")}
                </RedButton>
              </div>
             <RedButton onClick={togglevideoattachment}>
                <img
                  src="/images/icons/klammer.png"
                  width="24"
                  height="24"
                  alt="Dateianhang"
                  style={{ display: "inline-block" }}
                />{" "}
                {t("attachvideo")}
              </RedButton>
            </div>
          </div>
        ) : null}
        {showAttachmentModal && (
          <PortalModal onclose={closeImage}>
            <ImageUploader getbackimage={tellme} sendDefault={sendDefault} />
          </PortalModal>
        )}
        {buyFanDollars && (
          <SimplePaymentBaseModal onclose={togglebuyFanDollar} />
        )}
        {videoattachment ? (
          <PortalModal onclose={togglevideoattachment}>
            <div className={classes.writemessage}>
              <UploadVideos onFinal={sendvideo} />
            </div>
          </PortalModal>
        ) : null}
      </Fragment>
    );
}

export default WriteMessage;