import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../context/authcontext'
import SinglePost from '../../sonstige/SinglePost/SinglePost';
import { Col, Row } from 'reactstrap';
import PortalModal from '../../Cards/WrapperComponents/portalmodal';
import { useDataStore, useGirlStore } from '../../../hooks/useStoreData';
import ShortBuyItem from './shortBuyItem';
import classes from "./boughtItemsTimeline.module.css";
import { useTranslation } from 'react-i18next';
import DisplayMessage from '../../Cards/messenger/displayMessage';
import BoughtContentGirlCard from '../../Cards/CardWrappers/BoughtContentGirlCard';

function BoughtItemsTimeline() {
const authCtx=useContext(AuthContext);
const [boughtData,setBoughtData]=useState(null);
const [currentID, setCurrentID] = useState(null);
const [showModal,setShowModal]=useState(false);

const boughtContent = useDataStore((state) => state.boughtContent);
const setBoughtContent = useDataStore((state) => state.setBoughtContent);
const getBoughtContent = useDataStore((state) => state.getBoughtContent);
const [isSingleGirlView,setIsSingleGirlView]=useState(false);
const [mes,setMes]=useState(null);
const thegirls = useGirlStore((state) => state.thegirls);
const setTheGirls = useGirlStore((state) => state.setGirlsList);
const getGirlImage = useGirlStore((state) => state.getGirlImage);
const getTheGirls=useGirlStore(state=>state.getTheGirls);

const setBoughtContentProducers = useDataStore(
  (state) => state.setBoughtContentProducers
);
const boughtContentProducers = useDataStore(
  (state) => state.boughtContentProducers
);

const [isMessage,setIsMessage] =useState(false)

useEffect(()=>{
  var a = getTheGirls();

if(a.length<1){setGirls();}

    var a=getBoughtContent();
    if(a.length<1){
   getData();
   
}else{
    setBoughtData(boughtContent);
    
}
},[]);
useEffect(() => {setBoughtContentProducers();}, [boughtContent]);
useEffect(()=> {setBoughtData(getBoughtContent)} , [setBoughtContent]);
async function getData(){
    console.log("running")
const data=await fetch(`https://api.deine.fans/api/boughtitems/?userID=${encodeURIComponent(authCtx.userID)}&authToken=${encodeURIComponent(authCtx.jwtAuthToken)}`);
const gotData=await data.json();
setBoughtContent(Array.from(gotData.boughtItems).reverse());
setBoughtData(
  Array.from(gotData.boughtItems).reverse()
);
}
 async function setGirls() {
  console.log("isruuninghere")
   const girls = await fetch("https://api.deine.fans/api/girls");
   const convgirls = await girls.json();
   setTheGirls(convgirls.girls);
 }

async function showMe(id){
    console.log("triggered")
console.log(id);
setShowModal(true);

if (id.buyType==="Timeline"){setCurrentID(id.contentID);}else{
    setIsMessage(true);
    setCurrentID(id.contentID);
    var message = await fetch(
      `https://api.deine.fans/api/message?msgID=${encodeURIComponent(
        id.contentID
      )}&userOrProducerID=${encodeURIComponent(authCtx.userID)}&role=user&authToken=${encodeURIComponent(authCtx.jwtAuthToken)}`);
      const myMes=await message.json();
      console.log(myMes)
     setMes(myMes); 
   
}
}
async function getNewList(pseudo){
  var a = boughtContent.findIndex((el) => el.girlPseudo === pseudo);
  console.log(boughtContent[a]);
  
  var b = boughtContent[a].producerID;
  const data = await fetch(
    `https://api.deine.fans/api/boughtitems/?userID=${encodeURIComponent(
      authCtx.userID
    )}&authToken=${encodeURIComponent(authCtx.jwtAuthToken)}&producerID=${encodeURIComponent(b)}`
  );
  const gotData = await data.json();

  setBoughtData(
    Array.from(
      gotData.boughtItems? gotData.boughtItems.filter((el) => el.contentType !== "message")
    :[]).reverse() 
  );
setIsSingleGirlView(true)
}
const{t}=useTranslation();
  const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
  useEffect(() => {
    setDarkMode(authCtx.darkMode);
  }, [authCtx]);
  return (
    <div>
      <div className={classes.listofgirls}>
        {isSingleGirlView
          ? null
          : thegirls.length>1?boughtContentProducers.map((producer) => (
              <div
                key={producer}
                onClick={() => {
                  getNewList(producer);
                }}
              >
                <BoughtContentGirlCard pseudo={producer} />
              </div>
            )):null}
      </div>
      {isSingleGirlView ? (
        <p
          onClick={() => {
            setBoughtData(boughtContent);
            setIsSingleGirlView(false);
          }}
          className={!isDarkmode ? classes.girl : classes.girl_dm}
        >
          {t("back")}
        </p>
      ) : null}
      <Row>
        {boughtData && boughtData.length > 0
          ? boughtData.map((postID, index) => (
              <Col
                xs={12}
                md={6}
                onClick={() => {
                  showMe(postID);
                }}
                key={index}
              >
                <ShortBuyItem item={postID} />
              </Col>
            ))
          : null}
      </Row>
      {showModal ? (
        <PortalModal
          onclose={() => {
            setCurrentID(null);
            setShowModal(false);
            setIsMessage(false);
          }}
          big={true}
          dm={isDarkmode}
        >
          {isMessage ? (
            mes ? (
              <DisplayMessage
                cont={mes.msg}
                lastOpenedMessage={mes.msg.msgID}
                onMessageBought={() => {}}
              />
            ) : null
          ) : (
            <SinglePost postId={currentID} />
          )}
        </PortalModal>
      ) : null}
    </div>
  );
}

export default BoughtItemsTimeline
