import React from 'react'
import RedButton from '../../Cards/WrapperComponents/redbutton';
import classes from "./choosepaymentoption.module.css";
import { useTranslation } from 'react-i18next';

function ChooseTwintorOther(props) {
  const {t}=useTranslation();
  return (
    <div className={classes.choosewrapper}>
      <p>{t("choostwintorotheroption")}</p>
      <div className={classes.flexit}>
        <div
          onClick={() => {
            props.sendtwint(true);
          }}
        >
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo_twint.png"
            className={classes.extraimage}
            alt="Pay with Twint"
          />
          <p className={classes.erklärbär}>{t("twintsofort")}</p>
        </div>
        <div>
          <RedButton
            onClick={() => {
              props.sendback(true);
            }}
          >
            {t("RegisterOtherPayment")}
          </RedButton>
        </div>
      </div>
    </div>
  );
}

export default ChooseTwintorOther
