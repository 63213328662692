import { useContext, useEffect } from "react";
import AuthContext from "../../context/authcontext";

export const tawkToLoadScripts = async () => {
  // var Tawk_API = Tawk_API || {};

  // var Tawk_LoadStart = new Date();

  await (function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = `https://embed.tawk.to/6414743b31ebfa0fe7f33260/1grnuc2kh`;
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();
};


export const Tawk=()=>{
useEffect(()=>{
tawkToLoadScripts();
},[])
    const authCtx=useContext(AuthContext)
window.Tawk_API = window.Tawk_API || {}
window.Tawk_LoadStart = new Date();
  
window.Tawk_API.onLoad = function () {
      //console.log("chat loaded");
      window.Tawk_API.setAttributes(
        {
         name:authCtx.pseudo,
         userID:authCtx.userID,
         fanDollar:authCtx.fanDollar,
         isPremium:authCtx.isPremium,
         issAVS:authCtx.isAVS,
         hitLog:authCtx.hitLog[0].id

        },
        function (error) {
          //console.log("window.Tawk_API errorr",error)
        }
      );
    };
    window.Tawk_API.onChatStarted = function () {
      //console.log("chat loaded");
      window.Tawk_API.setAttributes(
        {
         name:authCtx.pseudo,
         userID:authCtx.userID,
         fanDollar:authCtx.fanDollar,
         isPremium:authCtx.isPremium,
         issAVS:authCtx.isAVS,
         hitLog:authCtx.hitLog[0].id

        },
        function (error) {
          //console.log("window.Tawk_API errorr",error)
        }
      );
    };


};