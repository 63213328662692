export function logallout(context){
   
   if (context){
      context.logout();
      realloggout(context)
   }
  
    
}

export function realloggout(context){
   fetch(
     `https://api.deine.fans/api/userlogin/logout/${encodeURIComponent(context.userID)}/${encodeURIComponent(context.token)}`,
     {
       method: "DELETE",
     }
   ).then((res) => {
     
     res.json().then((data) => {
       console.log(data.success);
     });
   });
}