import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import useMyHttp from "../../hooks/myhttp";
import RedButton from "../Cards/WrapperComponents/redbutton";
import AddEpoch from "./addepoch";
import AddOnlineUeberweisen from "./addOnlineUeberweisen";
import AddPaymentModal from "./addpaymentmodal";
import AddSofort from "./addSofort";
import AddPayable from "./apppayable";
import classes from "./paymentmethods.module.css";
import AddPostfinance from "./addPostfinance";
import AddTwint from "./addTwint";
import AddTrustPay from "./addTrustPay";

const PaymentMethods = () => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  const { isLoading, data, error, sendRequest } = useMyHttp();
  function fetchMethods() {
    sendRequest(
      `https://apitest.guppy.live/api/payment/methods/${encodeURIComponent(
        authCtx.userID
      )}?authToken=${encodeURIComponent(authCtx.token)}`,
      "GET",
      null,
      null,
      null,
      authCtx
    );
  }
  useEffect(() => {
    fetchMethods();
  }, []);
  const [seeModal, setSeeModal] = useState(false);
  const [seeModal2, setSeeModal2] = useState(false);
  const [seeModal3, setSeeModal3] = useState(false);
  const [seeModal4, setSeeModal4] = useState(false);
  const [seeModal5, setSeeModal5] = useState(false);
  const [seeModal6, setSeeModal6] = useState(false);
  const [seeModal7, setSeeModal7] = useState(false);
  const [seeModal8, setSeeModal8] = useState(false);
  const [myMethod, setMyMethod] = useState(null);

  function showModal() {
    setMyMethod();
    setSeeModal(true);
  }
  function showModal2() {
    setMyMethod();
    setSeeModal2(true);
  }
  function showModal3() {
    setMyMethod();
    setSeeModal3(true);
  }
  function showModal4() {
    setMyMethod();
    setSeeModal4(true);
  }
  function showModal5() {
    setMyMethod();
    setSeeModal5(true);
  }
  function showModal6() {
    setMyMethod();
    setSeeModal6(true);
  }
  function showModal7() {
    setMyMethod();
    setSeeModal7(true);
  }
   function showModal8() {
     setMyMethod();
     setSeeModal8(true);
   }

  var a = "OnlineUeberweisen";
  function closeModal() {
    setSeeModal(false);
    setSeeModal2(false);
    setSeeModal3(false);
    setSeeModal4(false);
    setSeeModal5(false);
    setSeeModal6(false);
    setSeeModal7(false);
    setSeeModal8(false);
  }
  function getbackdata(props) {
    closeModal();
  }
  function removeauth(aut) {
    fetch(
      `https://apitest.guppy.live/api/payment/authorizations/${encodeURIComponent(
        authCtx.userID
      )}/${encodeURIComponent(aut)}?authToken=${encodeURIComponent(
        authCtx.token
      )}`,
      {
        method: "DELETE",

        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, authCtx, "paymentMethods");
        setTimeout(() => {
          fetchMethods();
        }, "500");
      });
    });
  }
  const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
  useEffect(() => {
    setDarkMode(authCtx.darkMode);
  }, [authCtx]);
  return (
    <div>
      {isLoading && <h1>{t("loading")}</h1>}
      {data && data.availablePaymentMethods && (
        <div className={classes.boxit}>
          <ul className={classes.thelist}>
            {data.availablePaymentMethods.map((method) => (
              <li
                key={method.paymentMethodName}
                className={`${
                  method.authorized ? classes.listtrue : classes.listfalse
                }`}
              >
                {method.paymentMethodName === "DirectDebit" ? (
                  <img
                    src={
                      isDarkmode
                        ? "images/icons/lastschrift_dm.png"
                        : "images/icons/lastschrift.png"
                    }
                    alt="directdebit"
                  />
                ) : method.paymentMethodName === "CreditCardPayabl" ? (
                  <img
                    src={
                      isDarkmode
                        ? "images/icons/kreditkarte_dm.png"
                        : "images/icons/kreditkarte.png"
                    }
                    alt="creditcard"
                  />
                ) : method.paymentMethodName === "Epoch" ? (
                  <img
                    src={
                      isDarkmode
                        ? "images/icons/kreditkarte_dm.png"
                        : "images/icons/kreditkarte.png"
                    }
                    alt="creditcard"
                  />
                ) : method.paymentMethodName === "OnlineUeberweisen" ? (
                  <img
                    src={
                      isDarkmode
                        ? "images/icons/kreditkarte_dm.png"
                        : "images/icons/kreditkarte.png"
                    }
                    alt="creditcard"
                  />
                ) : method.paymentMethodName === "SofortUeberweisungGateway" &&
                  authCtx.country === "DE" ? (
                  <img
                    src={
                      isDarkmode
                        ? "images/icons/lastschrift_dm.png"
                        : "images/icons/lastschrift.png"
                    }
                    alt="sofort"
                  />
                ) : method.paymentMethodName === "Twint" &&
                  authCtx.country === "CH" ? (
                  <img
                    src={
                      isDarkmode
                        ? "images/icons/lastschrift_dm.png"
                        : "images/icons/lastschrift.png"
                    }
                    alt="twint"
                  />
                ) : method.paymentMethodName === "Postfinance" &&
                  authCtx.country === "CH" ? (
                  <img
                    src={
                      isDarkmode
                        ? "images/icons/lastschrift_dm.png"
                        : "images/icons/lastschrift.png"
                    }
                    alt="Postfinance"
                  />
                ) : method.paymentMethodName === "CreditCardTrustPay"  ? (
                  <img
                    src={
                      isDarkmode
                        ? "images/icons/kreditkarte_dm.png"
                        : "images/icons/kreditkarte.png"
                    }
                    alt="Postfinance"
                  />
                ) : null}
                {console.log(method.paymentMethodName)}
                {method.paymentMethodName === "DirectDebit" ? (
                  <span>{t("lastschrift")}</span>
                ) : method.paymentMethodName === "CreditCardPayabl" ? (
                  <span>{t("creditcard")}</span>
                ) : method.paymentMethodName === "Epoch" ? (
                  <span>{t("epoch")}</span>
                ) : method.paymentMethodName === "OnlineUeberweisen" ? (
                  <span>{t("OnlineUeberweisen")}</span>
                ) : method.paymentMethodName === "Twint" &&
                  authCtx.country === "CH" ? (
                  <span>{t("Twint")}</span>
                ) : method.paymentMethodName === "SofortUeberweisungGateway" &&
                  authCtx.country === "DE" ? (
                  <span>{t("sofort")}</span>
                ) : method.paymentMethodName === "Postfinance" &&
                  authCtx.country === "CH" ? (
                  <span>{t("Postfinance")}</span>
                ) : method.paymentMethodName === "CreditCardTrustPay"  ? (
                  <span>{t("CreditCardTrustPay")}</span>
                ) : null}
                <ul className={classes.list}>
                  {method.authorizations.map((aut) => (
                    <div key={`div${aut.authKey}`}>
                      <li className={classes.listelement} key={aut.authKey}>
                        <img
                          src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/logo_${
                            method.paymentMethodName === "DirectDebit"
                              ? "lastschrift"
                              : method.paymentMethodName === "CreditCardPayabl"
                              ? "payabl"
                              : method.paymentMethodName === "Epoch"
                              ? "epoch"
                              : method.paymentMethodName ===
                                "SofortUeberweisungGateway"
                              ? "sofort"
                              : method.paymentMethodName ===
                                  "OnlineUeberweisen" &&
                                authCtx.country === "DE"
                              ? "onlineueberweisen"
                              : method.paymentMethodName === "Twint" &&
                                authCtx.country === "CH"
                              ? "twint"
                              : method.paymentMethodName === "Postfinance" &&
                                authCtx.country === "CH"
                              ? "Postfinance"
                              : method.paymentMethodName ===
                                "CreditCardTrustPay"
                              ? "CreditCardTrustPay"
                              : "none"
                          }.png`}
                          className={classes.thingieicon}
                        />
                        {method.paymentMethodName === "DirectDebit" ? (
                          <span>{t("ddwith")}: </span>
                        ) : method.paymentMethodName === "CreditCardPayabl" ? (
                          <span>{t("pywith")}: </span>
                        ) : method.paymentMethodName === "Epoch" ? (
                          <span>{t("pywith")}: </span>
                        ) : method.paymentMethodName === "OnlineUeberweisen" &&
                          authCtx.country === "DE" ? (
                          <span>{t("pywith")}: </span>
                        ) : method.paymentMethodName ===
                          "SofortUeberweisungGateway" ? (
                          <span>{t("ddwith")}: </span>
                        ) : method.paymentMethodName === "Twint" &&
                          authCtx.country === "CH" ? (
                          <span>{t("ddwith")}: </span>
                        ) : method.paymentMethodName === "Postfinance" &&
                          authCtx.country === "CH" ? (
                          <span>{t("ddwith")}: </span>
                        ) : method.paymentMethodName === "CreditCardTrustPay"  ? (
                          <span>{t("pywith")}: </span>
                        ) : null}
                        {aut.authKey}{" "}
                        <img
                          src="/images/icons/muelleimer.png"
                          className={classes.deleteIcon}
                          onClick={() => {
                            removeauth(aut.id);
                          }}
                        />
                      </li>
                    </div>
                  ))}
                </ul>
                <div>
                  {method.paymentMethodName === "DirectDebit" ? (
                    <div className={classes.addmore} onClick={showModal}>
                      <RedButton>{t("addauthorisationdd")}</RedButton>
                    </div>
                  ) : method.paymentMethodName === "CreditCardPayabl" ? (
                    <div className={classes.addmore} onClick={showModal2}>
                      <RedButton>{t("addauthorisationpy")}</RedButton>
                    </div>
                  ) : method.paymentMethodName === "Epoch" ? (
                    <div className={classes.addmore} onClick={showModal3}>
                      <RedButton>{t("addauthorisationepoch")}</RedButton>
                    </div>
                  ) : method.paymentMethodName === "OnlineUeberweisen" ? (
                    <div className={classes.addmore} onClick={showModal5}>
                      <RedButton>
                        {t("addauthorisationonlineueberweisen")}
                      </RedButton>
                    </div>
                  ) : method.paymentMethodName ===
                    "SofortUeberweisungGateway" ? (
                    <div className={classes.addmore} onClick={showModal4}>
                      <RedButton>{t("addauthorisationsofort")}</RedButton>
                    </div>
                  ) : method.paymentMethodName === "Twint" &&
                    authCtx.country === "CH" ? (
                    <div className={classes.addmore} onClick={showModal6}>
                      <RedButton>{t("addauthorisationtwint")}</RedButton>
                    </div>
                  ) : method.paymentMethodName === "Postfinance" &&
                    authCtx.country === "CH" ? (
                    <div className={classes.addmore} onClick={showModal7}>
                      <RedButton>{t("addauthorisationpostfinance")}</RedButton>
                    </div>
                  ) : method.paymentMethodName === "CreditCardTrustPay" && authCtx.userID==="_a_apitest" ? (
                    <div className={classes.addmore} onClick={showModal8}>
                      <RedButton>{t("addauthorisationtrustpay")}</RedButton>
                    </div>
                  ) : null}
                </div>

                {seeModal && (
                  <div>
                    <AddPaymentModal
                      getbackclose={getbackdata}
                      userID={authCtx.userID}
                      authToken={authCtx.token}
                      method={"DirectDebit"}
                    />
                  </div>
                )}
                {seeModal2 && (
                  <div>
                    <AddPayable
                      getbackclose={getbackdata}
                      userID={authCtx.userID}
                      authToken={authCtx.token}
                      method={"CreditCardPayabl"}
                    />
                  </div>
                )}
                {seeModal3 && (
                  <div>
                    <AddEpoch
                      getbackclose={getbackdata}
                      userID={authCtx.userID}
                      authToken={authCtx.token}
                      method={"Epoch"}
                    />
                  </div>
                )}
                {seeModal4 && (
                  <div>
                    <AddSofort
                      getbackclose={getbackdata}
                      userID={authCtx.userID}
                      authToken={authCtx.token}
                      method={"Sofort"}
                    />
                  </div>
                )}
                {seeModal5 && (
                  <div>
                    <AddOnlineUeberweisen
                      getbackclose={getbackdata}
                      userID={authCtx.userID}
                      authToken={authCtx.token}
                      method={"OnlineUeberweisen"}
                    />
                  </div>
                )}
                {seeModal6 && (
                  <div>
                    <AddTwint
                      getbackclose={getbackdata}
                      userID={authCtx.userID}
                      authToken={authCtx.token}
                      method={"Twint"}
                    />
                  </div>
                )}
                {seeModal7 && (
                  <div>
                    <AddPostfinance
                      getbackclose={getbackdata}
                      userID={authCtx.userID}
                      authToken={authCtx.token}
                      method={"Postfinance"}
                    />
                  </div>
                )}
                {seeModal8 && (
                  <div>
                    <AddTrustPay
                      getbackclose={getbackdata}
                      userID={authCtx.userID}
                      authToken={authCtx.token}
                      method={"CreditCardTrustPay"}
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      {error && { error }}
    </div>
  );
};

export default PaymentMethods;
