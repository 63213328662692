import { checkreceivedData } from "./checkReceivedData";

export async function addabo(userID, producerID, PaymentAuthtoken,tierID, authToken) {
  console.log(userID, producerID, PaymentAuthtoken, authToken);
   fetch("https://api.deine.fans/api/subscriptions/user/create", {
     method: "PUT",
     body: JSON.stringify({
       userID: userID,
       producerID: producerID,
       tierID: tierID,
       paymentAuthorizationID: PaymentAuthtoken,
       authToken: authToken,
     }),
     headers: {
       "Content-Type": "application/json",
     },
   }).then((res) => {
     res.json().then((data) => {
       checkreceivedData(data);
       console.log(data);
       if (data.error) {
         console.log(data.error);
       }
       if (data.success) {
         console.log(data.success);
       }
       return data;
     });
   });
}