import React, { useContext, useState } from "react";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import useMyHttp from "../../hooks/myhttp";
import Add from "../Cards/Extras/Commercials/add";
import ManageTiers from "../Cards/subscriptions/managetiers";
import DisplaySub from "../Cards/subscriptions/subs_2ed/displaySub";
import PortalFeedbackModal from "../Cards/WrapperComponents/portalfeedbackmodal";
import SingleGirlSubscriptions from "./Subscriptions/singleGirlSubscriptions";

//import Shortadvertorial from "../Cards/shortadvertorial";
import classes from "./allapishort.module.css";
import TimeLineTest from "./Timeline/timelintest";
import Allapigirls from "./allapigirls";
import AllApiShort from "./allapishort";

const DetailApiShort = (props) => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();
  const { isLoading, data, error, sendRequest } = useMyHttp();
  const [extraGirls, setExtraGirls] = useState([]);
  const [isSub, setIsSub] = useState(false);
  const [list,setList]=useState([])

 
  function fetchGirlHandler() {
    var girl=props.girl;
    if (girl.toLowerCase()==="micaelaschaefer"){girl="micaelaschäfer"}
    sendRequest(
      `https://api.deine.fans/api/girls/${encodeURIComponent(girl)}`,
      "GET",
      null,
      null,
      null,
      authCtx
    );
  }
  useEffect(() => {
    checkforSub();
    fetchGirlHandler();
    fetchlivegirls();
  }, []);

  function checkforSub() {
    fetch(
      `https://api.deine.fans/api/subscriptions/user/list/${encodeURIComponent(authCtx.userID)}?producerID=${encodeURIComponent(props.girl)}&authToken=${encodeURIComponent(authCtx.jwtAuthToken)}`
    )
      .then((response) => response.json())
      .then((data) => {
    
        if (data.hasSubscription === true) {
          setIsSub(true);
        } 
      });
  }

  function fetchlivegirls() {
    fetch(
      "https://guppy.site/camsinfo?systemid=pussyposer&apikey=B956528A36CBC27624B047A167B6980C"
    )
      .then((response) => response.json())
      .then((data) => {
        var z = Array.from(data.online);
        setExtraGirls(z);
      });
  }
 

  const [backmessage, setMessage] = useState(false);
  function checkchange(backinfo) {
    fetchGirlHandler();
    checkforSub();
    if (backinfo && backinfo.paid === true) {
      setMessage(
        <div>
          <h5>{t("yohaveboughtsubscription")}</h5>
          <p>{t("boughtsubscriptiontext")}</p>
        </div>
      );
      props.isbought()
    }
  }
 
  return (
    <div className={classes.contain2}>
      {!isSub ? (
        <div>
          {data &&
          data.girl &&
          data.girl.tiers &&
          data.girl.tiers.length > 0 ? (
            <div>
              <DisplaySub
                tiers={Array.from(data.girl.tiers).sort(
                  (a, b) => a.price - b.price
                )}
                name={data.girl.pseudo}
                producerID={data.girl.producerID}
                context={authCtx}
                sendchange={checkchange}
                fullsize={true}
                extrahead={props.extrahead}
                secondimage={
                  data.girl.bigImageIDURLs.urlBig.search("/.") < 0
                    ? data.girl.bigImageIDURLs.urlBig
                    : data.girl.softBigImageIDURLs.urlBig
                }
                imagesoft={data.girl.softSmallImageIDURLs.urlSmall}
                secondimagesoft={data.girl.softBigImageIDURLs.urlBig}
              />
            </div>
          ) : // <ManageTiers
          //   tiers={data.girl.tiers}
          //   name={data.girl.pseudo}
          //   producerID={data.girl.producerID}
          //   context={data.girl.context}
          // />
          authCtx.isLoggedIn && authCtx.isPremium ? (
            <div className={classes.wrappadds}>
              {extraGirls.map((girl, index) => (
                <div key={`a${girl.pseudo}`} className={classes.add}>
                  <div key={`b${girl.pseudo}`}>
                    {index < 6 && extraGirls.length > 0 && (
                      <Add
                        header={`${t("nowliveoncam")} ${
                          extraGirls[index].pseudo
                        }`}
                        image={extraGirls[index].previewimageurlsoft}
                        addcontent={extraGirls[index].vcardsoft}
                        link={extraGirls[index].sedcardurl}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <AllApiShort />
          )}
          <div className={classes.footerelement}></div>
          {isLoading && <h5>{t("loading")}</h5>}

          {error && <p>{error}</p>}
        </div>
      ) : (
        <div>
          <SingleGirlSubscriptions
            girlinfo={props.girl}
            fullsize={true}
            nopicture={true}
          />
        </div>
      )}
      {backmessage && (
        <PortalFeedbackModal
          onclose={() => {
            setMessage(false);
          }}
        >
          {backmessage}
        </PortalFeedbackModal>
      )}
      <div className={classes.bottombouncer}></div>
    </div>
  );
};

export default DetailApiShort;
