import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../context/authcontext";
import useMyHttp from "../../../hooks/myhttp";
import LoadingSpinner from "../../Cards/Extras/loadingspinner";
import classes from "./allcontent.module.css";
import MainPostCard from "../../Cards/MainPostCard/mainPostCard";

const CollectionTimeline = () => {
  const [isBig, setIsBig] = useState(false);
  function setbig() {
    setIsBig(!isBig);
  }
  const authCtx = useContext(AuthContext);

  const { isLoading, data, error, sendRequest } = useMyHttp();
  function fetchFavs() {
    sendRequest(
      `https://api.deine.fans/api/timelinefavs/${encodeURIComponent(
        authCtx.userID
      )}?authToken=${encodeURIComponent(
        authCtx.token
      )}&startTime=2012-09-21T11%3A22%3A27`,
      "GET",
      null,
      null,
      null,
      null,
      authCtx
    );
  }

  useEffect(() => {
    if (data && data.posts) {
      setMyList(
        data.posts.sort(
          (a, b) =>
            new Date(
              b.publishTime !== "0001-01-01T00:00:00"
                ? b.publishTime
                : b.creationTime
            ).getTime() -
            new Date(
              a.publishTime !== "0001-01-01T00:00:00"
                ? a.publishTime
                : a.creationTime
            ).getTime()
        )
      );

      filterlist(data.posts);
    }
  }, [data]);

  const [mylist, setMyList] = useState([]);
  useEffect(() => {
    fetchFavs();
  }, []);

  function gotbackbuy() {
    fetchFavs();
  }
  function removeit(props) {
    var g = mylist.filter((el) => el.guid !== props);
    setMyList(g);
  }
  const { t } = useTranslation();

  const [videos, setvideos] = useState(false);
  const [images, setimages] = useState(false);

  const [videolist, setVideolist] = useState([]);
  const [imagelist, setImageList] = useState([]);
  function filterlist(info) {
    var base = info;
    var videos = [];
    var images = [];
    for (var i = 0; i < base.length; i++) {
      var checkvorimages = false;
      var checkforvideos = false;
      for (var u = 0; u < base[i].freeMedia.length; u++) {
        if (base[i].freeMedia[u].type === "video") {
          checkforvideos = true;
        }
        if (base[i].freeMedia[u].type === "image") {
          checkvorimages = true;
        }
      }
      for (var u = 0; u < base[i].payMedia.length; u++) {
        if (base[i].payMedia[u].type === "video") {
          checkforvideos = true;
        }
        if (base[i].payMedia[u].type === "image") {
          checkvorimages = true;
        }
      }
      if (checkforvideos === true) {
        videos.push(base[i]);
      }
      if (checkvorimages === true) {
        images.push(base[i]);
      }
    }
    setImageList(images);
    setVideolist(videos);
  }

  function setshowvideos() {
    setimages(false);
    setvideos(!videos);
  }

  function setshowimages() {
    setvideos(false);
    setimages(!images);
  }
  function setshowall() {
    setvideos(false);
    setimages(false);
  }
  const [showList,setShowList]=useState(false)
  const [showListItem, setShowListItem] = useState(true);
function toggleList(){
    setShowList(!showList);
}
function toggleLisItem() {
  setShowListItem(!showListItem);
}
function removeit(index){

    if(data.posts){
        var checkarray = Array.from(mylist);
        checkarray.splice(index, 1);}
        setMyList(checkarray);
}
let testvalue=false;
  return (
    <div className={classes.wrapper}>
      <div className={classes.mainhead}>
        <h2>{t("collectionhead")}</h2>
        <p>{t("hereyoufinditall")}</p>
        <div className={classes.flexit}>
          <p className={classes.view} onClick={toggleList}>
            {showList ? t("showasList") : t("showaposts")}
          </p>
        </div>
        <hr className={classes.trenner} />
      </div>

      {isLoading && <LoadingSpinner />}
      {error && <p>{error}</p>}
      {data && data.posts && data.posts.length === 0 && (
        <div className={classes.centercontent}>
          <h5>{t("collectionheader")}</h5>
          <p>{t("thisishowthecollectionworks")}</p>
        </div>
      )}
      {data &&
        data.posts &&
        mylist.map((post, index) =>
          showList ? (
            <MainPostCard
              key={post.guid}
              post={post}
              id={index}
              context={authCtx}
              sendbackby={gotbackbuy}
            />
          ) : (
            <div
              key={index}
              onClick={() => {
                testvalue = true;
              }}
            >
              <MainPostCard
                post={post}
                id={index}
                context={authCtx}
                sendbackby={gotbackbuy}
                sendremoveback={() => removeit(index)}
                headeronly={testvalue ? false : true}
              />
            </div>
          )
        )}
    </div>
  );
};
export default CollectionTimeline;
