import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import useMyHttp from "../../hooks/myhttp";
//import MyButton from "../Cards/WrapperComponents/myCard.js/myButton";
import classes from "./addpaymentmethod.module.css";
//import { Link } from "react-router-dom";
import { Analytics } from "aws-amplify";

const AddPaymentModal = (props) => {

  const NameInputRef = useRef();
  const NumberInputRef = useRef();
  const CSVInputRef = useRef();

  function closeme() {
    props.getbackclose();
  }

  const [consent, setConsent] = useState(false);
  const [myerror, setmyerror] = useState(false);
  const authCtx = useContext(AuthContext);
  function submitHandler(event) {
    event.preventDefault();
    senddata(true);
  }
const curPath=window.location.pathname;
  const { isLoading, data, error, sendRequest } = useMyHttp();
  function fetchURL(authToken, userId, holder, iban, bic) {
  
    sendRequest(
      `https:///api.deine.fans/api/payment/methods/directdebit/getmandat?authToken=${encodeURIComponent(
        authToken
      )}&userID=${encodeURIComponent(userId)}&Holder=${encodeURIComponent(
        holder
      )}&IBAN=${encodeURIComponent(
        iban.toUpperCase()
      )}&BIC=${encodeURIComponent(bic.toUpperCase())}`,
      "GET",
      null,
      null,
      null,
      authCtx
    );
  }
  const { t } = useTranslation();

  function senddata() {
    const enterednameraw = NameInputRef.current.value;
    const enteredname = encodeURIComponent(enterednameraw);
    const enterednumber = NumberInputRef.current.value;
    var mynum = enterednumber.replaceAll(/ /g, "");
    const enteredcsv = CSVInputRef.current.value;
    localStorage.setItem("dd_name", NameInputRef.current.value);
    localStorage.setItem("dd_number", NumberInputRef.current.value);
    localStorage.setItem("dd_csv", CSVInputRef.current.value);

    fetchURL(props.authToken, props.userID, enteredname, mynum, enteredcsv);
  }

  function togglewiderspruch() {
    setConsent(!consent);
  }
  function relocate(destination) {
    window.location.replace(destination);
  }

  const [receiver, setReceiver] = useState(null);
  useEffect(() => {
    if (data && data.paymentReceiver) {
      var tu = data.paymentReceiver.replaceAll("<br />", " - ");

      setReceiver(tu);
    }
  }, [data]);
  function weitergehts(thisid) {
   localStorage.setItem("lastSite", curPath);
    fetch(
      `https://api.deine.fans/api/payment/methods/directdebit/authorize?authToken=${encodeURIComponent(props.authToken)}&userID=${encodeURIComponent(props.userID)}&MandatID=${encodeURIComponent(thisid)}&referer=${encodeURIComponent(curPath)}`
    )
      .then((response) => response.json())
      .then((data) => {
        checkreceivedData(data, authCtx,"addpayment");
       
        if (data.success === false) {
          setmyerror(t("trylater"))
        } else {
          Analytics.record({
            name: 'authorizePaymentDirectDebit',
            attributes: {
              userID: props.userID
            }
          });

          relocate(data.authUrl);
        }
      });
  }
  const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
  useEffect(() => {
    setDarkMode(authCtx.darkMode);
  }, [authCtx]);
  return (
    <>
      {error && error}
      <div className={classes.darkBG} onClick={closeme} />
      <div className={classes.centered}>
        <div className={!isDarkmode ? classes.modal : classes.modalbg}>
          <div
            className={
              !isDarkmode ? classes.modalHeader : classes.modalHeaderbg
            }
          >
            <h3>{t("enterbankdata")}</h3>
          </div>
          <button className={classes.closeBtn} onClick={closeme}>
            <img
              src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
              alt="close modal"
              className={classes.closex}
            />
          </button>
          <form onSubmit={submitHandler}>
            <div className={classes.control}>
              <input
                type="text"
                id="holder"
                required
                ref={NameInputRef}
                placeholder="Name des Kontoinhabers"
                defaultValue={
                  localStorage.getItem("dd_name")
                    ? localStorage.getItem("dd_name")
                    : null
                }
              />
            </div>
            <div className={classes.control}>
              <input
                type="text"
                id="iban"
                required
                ref={NumberInputRef}
                placeholder="IBAN"
                defaultValue={
                  localStorage.getItem("dd_number")
                    ? localStorage.getItem("dd_number")
                    : null
                }
              />
            </div>
            <div className={classes.control}>
              <input
                type="text"
                id="bic"
                required
                ref={CSVInputRef}
                placeholder="BIC"
                defaultValue={
                  localStorage.getItem("dd_csv")
                    ? localStorage.getItem("dd_csv")
                    : null
                }
              />
            </div>

            {!isLoading && !data && (
              <button className={classes.myButton}>{t("abschicken")}</button>
            )}
            {data && myerror}
            <h5>{myerror}</h5>
            {data && data.conversionMessage !== "SUCCESS" && (
              <button className={classes.myButton}>{t("abschicken")}</button>
            )}
          </form>
          {data && data.isValid === true && (
            <div
              className={
                isDarkmode ? classes.mandatskastendm : classes.mandatskasten
              }
            >
              <h5>SEPA Lastschriftsmandat</h5>
              <p>Mandatsreferenz: {data.mandatID}</p>
              <p>Datum: {data.mandatDate}</p>
              <p></p>
              <p>Gläubiger-Identifikationsnummer: {data.glaeubigerID}</p>
              <p>Zahlungsempfänger: {receiver}</p>

              {!consent && (
                <h5 className={classes.consent}>
                  <label>
                    <input
                      className={classes.checkbox}
                      type="checkbox"
                      onClick={togglewiderspruch}
                    />
                    Ich ermächtige den Zahlungsempfänger, Zahlungen von meinem
                    Konto auf der {data.bankName} mittels Lastschrift
                    einzuziehen. Zugleich weise ich mein Kreditinstitut an, die
                    von dem Zahlungsempfänger auf mein Konto gezogenen
                    Lastschriften einzulösen. <br />
                    Ich stimme ausdrücklich zu, dass Sie vor Ablauf der
                    Widerrufsfrist mit der Ausführung des Vertrages beginnen.
                    Mir ist bekannt, dass ich durch diese Zustimmung mit Beginn
                    der Ausführung des Vertrages mein Widerrufsrecht verliere.
                  </label>
                </h5>
              )}
              <p></p>
              {consent && (
                <button
                  className={classes.myButton}
                  onClick={() => weitergehts(data.mandatID)}
                >
                  Einverstanden
                </button>
              )}
            </div>
          )}
          {data && data.isValid === false && <h5>{data.conversionMessage}</h5>}

          {/* {consent && (
            <div>
              <h5>
                Du wirst nun weitergleitet, um Dein Konto zu verifizieren.
              </h5>
              <button onClick={senddata}>Okay, Machen wir es so!</button>
            </div>
          )} */}
          {isLoading && <h5>Verarbeite Daten</h5>}
          {error && <h5>{error}</h5>}
          {/* {data && <h5>{relocate(`${data.authUrl}`)}</h5>} */}
        </div>
      </div>
    </>
  );
};

export default AddPaymentModal;
