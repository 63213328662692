import React, { memo, useEffect, useState, useTransition } from 'react'
import classes from "./subscriptionammager.module.css";
import { useTranslation } from 'react-i18next';
import GetPaymentAuthorization from '../../../payment/getPaymentAuthorization/getpaymentauthorization';


const SubscriptionManager=memo((props)=> {
    const [isPaid,setIsPaid]=useState(false);
   
    const [kosten, setKosten] = useState(
      props.kosten
    );
    const [initialPrice,setInitialPrice]=useState(null);
    const [billingCycleTime,setBillingCycleTime]=useState(null);
    const [chosenMethod,setChosenMethod]=useState(null);
    const [methodName,setmethodName]=useState(null)
    useEffect(() => {
      if (props && props.payload && props.payload.firstPrice) {
        setInitialPrice(props.payload.firstPrice);
      }
      if (
        props &&
        props.payload &&
        props.payload.billingCycle &&
        props.payload.billingCycle.search("w") > 0
      ) {
        if (props.payload.billingCycle.split("w")[0] == 1) {
          setBillingCycleTime(t("woche"));
        } else {
          setBillingCycleTime(t("wochen"));
        }
      }
      if (
        props &&
        props.payload &&
        props.payload.billingCycle &&
        props.payload.billingCycle.search("d") > 0
      ) {
        if (props.payload.billingCycle.split("d")[0] == 1) {
          setBillingCycleTime(t("tag"));
        } else {
          setBillingCycleTime(t("tagen"));
        }
      }
      if (
        props &&
        props.payload &&
        props.payload.billingCycle &&
        props.payload.billingCycle.search("m") > 0
      ) {
        if (props.payload.billingCycle.split("m")[0] == 1) {
          setBillingCycleTime(t("monat"));
        } else {
          setBillingCycleTime(t("monaten"));
        }
      }
    }, []);
    function sendback(){
        props.sendback(false);
    }
    const {t}=useTranslation();
    function gotinfo(authorization, methodName){
      setChosenMethod(authorization);
      setmethodName(methodName);
      props.setMethods(authorization);
    }
    function getbacksofort(){
      console.log("hasSofort")
    }
    function sendUst(val){
      console.log("vat is", val)
    }
    console.log("initialprice",initialPrice,props)
  return (
    <div>
   
      {!chosenMethod ? (
        <GetPaymentAuthorization
          sendback={gotinfo}
          fixprice={kosten}
          getbacksofort={getbacksofort}
          sendUst={sendUst}
        />
      ) : chosenMethod !== "noneAvailable" ? (
        <div className={classes.flexit}>
          <img
            src={`https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/logo_${methodName.toLowerCase()}.png`}
            alt="mymethod"
            className={`${classes.icon} ${classes.glow}`}
          />
          <img
            src={`/images/icons/paymentback.png`}
            alt={"back"}
            className={classes.icon}
            onClick={() => {
              setChosenMethod(false);
            }}
          />
        </div>
      ) : null}
    </div>
    //<SimplePaymentBaseModal girlid={props.girlid} image={props.secondimage} type="abobuy" fixpreis={initialPrice} payload={props.payload}/>
  );
})

export default SubscriptionManager
