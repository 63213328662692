import { create } from "zustand";


export const useDataStore = create((set, get) => ({
  boughtContent: [],
  setBoughtContent: (value) => {
    set({ boughtContent: value });
  },
  getBoughtContent: () => {
    var a = get().boughtContent;
    return a;
  },
  boughtContentgetProducers: () => {
    let Arr = get().boughtContent;
    let outputArray = Array.from(new Set(Arr));
    return outputArray;
  },
  boughtContentProducers:[],
  setBoughtContentProducers:()=>{
    let Arr = get().boughtContent;
    let outputArray = [...new Set(Arr.map((element) => element.girlPseudo))];
   
    set({ boughtContentProducers: outputArray });
  },
}));

export const useGirlStore=create((set,get)=>({
  thegirls:[],
  setGirlsList:(list)=>{

    set({thegirls:list})
  },
  getTheGirls:()=>{
    return(get().thegirls)
  },
  getGirlImage:(name,bool)=>{
    let list=get().thegirls;
   
   var pos= list.findIndex(el=>el.pseudo===name);
   
  var image=null
  if(list[pos]){image=list[pos].smallImageIDURLs.urlMedium;
     if(bool){image=list[pos].softSmallImageIDURLs.urlMedium;}}
    return image 
  }
}))