import WrapModal from "../components/Cards/WrapperComponents/wrapmodal";
import { checkreceivedData } from "./checkReceivedData";
import React from "react";


export async function getstatus(context){
  if (context.userID === null) {
    return;
  }
  //console.log("checkstatus",context.userID,context.token);
  try {
    fetch(
      `https://api.deine.fans/api/userstatus?userID=${encodeURIComponent(context.userID)}&authToken=${encodeURIComponent(context.jwtAuthToken)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        //console.log("THEDATA", data);
        checkreceivedData(data, context, "checkstatus");

        
        if (data.error) {
          return <WrapModal>{data.error}</WrapModal>;
        }
        else return data
      });
    });
  } catch (err) {
    console.error("Error fetching userstatus: " + err);
  }
}


export async function checkstatus(context) {
  if(context.userID === null) {
    return;
  }
  //console.log("checkstatus",context.userID,context.token);
  try {
    fetch(
      `https://api.deine.fans/api/userstatus?userID=${encodeURIComponent(context.userID)}&authToken=${encodeURIComponent(context.jwtAuthToken)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        //console.log("THEDATA", data);
        checkreceivedData(data, context,"checkstatus");
       

        if (data.currentBalance) {
        
          if (data.currentBalance === 0) {
            context.checkFanDollar(0);
          } else {
            context.checkFanDollar(data.currentBalance / 100);
          }
        }
        if(data.adr){context.setMyAdr(data.adr);};
        if (data.avsStatus === 1) {
          context.setAVS(true);
        }
        if (data.unreadMessages >= 0) {
          context.checkUnreadMessages(data.unreadMessages);
        }
        
        if (data.error) {
          return <WrapModal>{data.error}</WrapModal>;
        }
      });
    });
  } catch (err) {
    console.error("Error fetching userstatus: " + err);
  }
}
