import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import RedButton from "../WrapperComponents/redbutton";
import classes from "./searchmessage.module.css"
import { Analytics } from "aws-amplify";

const SearchMessage = (props) =>{
  const {t}=useTranslation();
  const questionref=useRef();

  function submitHandler(e){
    e.preventDefault();
    
    Analytics.record({
      name: 'searchedForMessage',
      attributes: {
        text: questionref.current.value
      }
    });

    props.sendback(questionref.current.value.toLowerCase());
    questionref.current.value="";
  }

  return(
    <form onSubmit={submitHandler}>
      <div className={classes.control}>
        <input
          type="text"
          id="suchtext"
          placeholder={t("searchmessage")}
          required
          ref={questionref}
          className={classes.input}
        />
      
        <RedButton>{t("abschicken")}</RedButton>
      </div>
    </form>
  )
}
export default SearchMessage;