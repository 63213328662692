import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  memo,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import SmallImageElement from "../smallimageElement";
import { PostCardHeader } from "./postCardHeader";
import classes from "./testpostcard.module.css";
import {
  SnapList,
  SnapItem,
  useVisibleElements,
  useScroll,
  useDragToScroll,
  isTouchDevice,
} from "react-snaplist-carousel";
import Videopreview from "../videos/videopreview";
import PostCardFunctionList from "./postcardfunctionlist";
import PostCardReport from "./postcardReport";
import SimplePaymentBaseModal from "../../payment/simplepayment/simplepaymentBaseModal";
import RedButton from "../WrapperComponents/redbutton";
import CommentList from "../../lists/comments/commentlist";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import PayedPostCard from "./payedpostcard";
import LoadingSpinner from "../Extras/loadingspinner";
import PostCardWrapper from "./postCardWrapper";
import AuthContext from "../../../context/authcontext";
import PostCardContent from "./postCardContent";
import { Link } from "react-router-dom";
import BlueButton from "../WrapperComponents/bluebutton";
import PostCardNumofContent from "./postCardNumofContent";
import PortalModal from "../WrapperComponents/portalmodal";
import Login from "../../../pages/login";

const MainPostCard = memo((props) => {
  // PostcardElemente States
  const [thePost, setthePost] = useState(props.post);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isPayed, setIsPayed] = useState(false);
  const [extramessage, setExtraMessage] = useState(false);
  const curDate = new Date(props.context.lastLogin);
  const createDate = new Date(thePost.creationTime);
  const [isNew, setIsNew] = useState(false);
const [doIHideSome,setDoIHideSome]=useState(props.headeronly)
  // StartFunktion
  useEffect(() => {
    //setIsPayed(checkIfPayed(thePost));
    setIsAvailable(checkIfPayed(thePost));
    if (thePost.payMedia&&thePost.payMedia.length < 1) {
      setIsAvailable(true);
    }
    var checkDateDif = curDate - createDate;
    if (checkDateDif <= 0) {
      setIsNew(true);
    }
    if (
      thePost.activeDiscountFormula === "POST_PRICE_OVERRIDE_FREE" ||
      thePost.activeDiscountFormula === "WATCH_FOR_FREE"
    ) {
      setExtraMessage(t("freewithtier"));
    }
  }, []);

  // Funktionen Speziell für den Post

  function checkIfPayed(post) {
    if (!thePost.payMedia){return true}
      if (
        post.activeDiscountFormula === "BOUGHT" ||
        post.activeDiscountFormula === "POST_PRICE_OVERRIDE_FREE" ||
        post.activeDiscountFormula === "WATCH_FOR_FREE" ||
        post.bought === true ||
        thePost.payMedia.length < 1
      ) {
        return true;
      } else {
        return false;
      }
  }

  //   Allgemeine Websiten-Funktionen
  const [isDarkmode, setDarkMode] = useState(props.context.darkMode);
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    setDarkMode(props.context.darkMode);
  }, [props.context]);

  ///Funktionen speziell für diese Seite
  var saved = ((thePost.orgPrice - thePost.price) / 100).toFixed(2);
  const [saveprice, setSavePrice] = useState(null);
  const [useSingula, setuseSingular] = useState(false);
  const [freeforyou, setfreeforyou] = useState(false);
  useEffect(() => {
    if (saved > 0) {
      setSavePrice(saved);
    }
    if (saved === "1.00") {
      setuseSingular(true);
    }

    if (thePost.price === 0) {
      setfreeforyou(true);
    }
  }, []);

  // Funktionen um Updates zwischen den Komponenten zu regeln
  const [showModal, setShowModal] = useState(false);
  const [changeValue, setChangeValue] = useState(false);
  const [doIChangeLike, setDoIChangeLike] = useState(false);
  const [pinchange, setpinchange] = useState(false);
  const [isStillLoading, setIsStillLoading] = useState(true);
  const [boughtPost, setBoughtPost] = useState(null);
const [needsLogin,setNeedsLogin]=useState(false);
function toggleLogin(){
  setNeedsLogin(!needsLogin);
}
  function toggleModal() {
    setShowModal(!showModal);
  }
  function gotit(props) {
    toggleModal();
    if(props){setIsPayed(true)}else{setIsStillLoading(false)};

    if (props) {
      
      fetch(
        `https://api.deine.fans/api/timeline/post/${encodeURIComponent(
          thePost.guid
        )}?userID=${encodeURIComponent(
          authCtx.userID
        )}&authToken=${encodeURIComponent(authCtx.token)}`
      ).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            checkreceivedData(data, authCtx);
            if (data.success && data.post && data.post.bought) {
              setBoughtPost(data.post);
              setIsStillLoading(false);
              setIsAvailable(true);
            }
          });
        }
      });
    }
    
  }
  function sendlike(guid) {
   
    setDoIChangeLike(true);

    setTimeout(() => {
      setChangeValue(!changeValue);
    }, 100);
  }
  function sendpin() {
   
    setpinchange(true);
   
      setTimeout(() => {
        setChangeValue(!changeValue);
      }, 100);
  }
function sendpininfo(){

  if (props.sendremoveback) props.sendremoveback();
}

  return (
    <PostCardWrapper
      darkMode={isDarkmode}
      id={thePost.guid}
      key={isDarkmode}
      context={authCtx}
      isNew={authCtx.isLoggedIn ? isNew : false}
      shorten={doIHideSome ? true : false}
      isPinned={props.isPinned}
    >
      {console.log(thePost)}
      <PostCardHeader
        key={`a${thePost}`}
        pseudo={thePost.pseudo}
        date={
          thePost.publishTime === "0001-01-01T00:00:00"
            ? thePost.creationTime
            : thePost.publishTime
        }
        context={authCtx}
        guid={thePost.guid}
        msgText={doIHideSome ? "" : thePost.msgText}
        msgTitle={thePost.msgTitle}
        tags={thePost.tags}
        //    numofPosts={freeContent.length + payContent.length}
        //    numofPics={freeImages.length + payImages.length}
        //    numofVideos={freeVideos.length + payVideos.length}
        numofPosts="2"
        numofPics="2"
        numofVideos="2"
        purchasestatus={true}
        paytext={thePost.payText}
        isDarkmode={isDarkmode}
        ranking={thePost.ranking}
        post={thePost}
        sendlike={sendlike}
        sendpin={sendpin}
      />

      <PostCardNumofContent post={thePost} isDarkmode={isDarkmode} />
      {extramessage ? (
        <p className={classes.extramessage}>{extramessage}</p>
      ) : null}

      {!doIHideSome ? (
        isPayed ? (
          <div>
            {isStillLoading ? (
              <LoadingSpinner />
            ) : (
              <PostCardContent
                thePost={boughtPost}
                context={props.context}
                key={boughtPost}
                available={true}
              ></PostCardContent>
            )}
          </div>
        ) : (
          <PostCardContent
            thePost={thePost}
            context={props.context}
            available={isAvailable}
          >
           
            {!isAvailable && (
              <div className={classes.topaywrapper}>
                <div className={classes.topaybg}>
                  <img
                    src={
                      isMobile
                        ? "/images/fallback_schloss_quer.jpg"
                        : "/images/fallback_schloss_quer.jpg"
                    }
                    className={classes.fallbackimage}
                  />
                </div>

                {!freeforyou ? (
                  <div className={classes.topaytext}>
                    <h5>
                      {t("getthisforonly")}{" "}
                      {thePost.orgPrice > thePost.price && (
                        <span className={classes.killit}>
                          {(thePost.orgPrice / 100).toFixed(2)}{" "}
                          {authCtx.country === "CH" ? "CHF" : "EUR"}
                        </span>
                      )}{" "}
                      {(thePost.price / 100).toFixed(2)}{" "}
                      {authCtx.country === "CH" ? "CHF" : "EUR"}
                      {saveprice && !useSingula && (
                        <p>
                          {t("yousave")} {saveprice}{" "}
                          {authCtx.country === "CH" ? "CHF" : "EUR"}{" "}
                          {t("thanksytotier")}
                        </p>
                      )}
                      {saveprice && useSingula && (
                        <p>
                          {t("yousave")} {saveprice}{" "}
                          {authCtx.country === "CH" ? "CHF" : "EUR"}{" "}
                          {t("thanksytotier")}
                        </p>
                      )}
                    </h5>
                    <RedButton
                      className={classes.bigbutton}
                      key={`bb${thePost.guid}`}
                      onClick={() => {
                        authCtx.isLoggedIn
                          ? toggleModal(thePost.guid)
                          : toggleLogin();
                      }}
                    >
                      {t("freischalten")}
                    </RedButton>
                    {thePost.freeTierIDs.length > 0 ||
                    saveprice ||
                    props.hasSub ? null : (
                      <h5 className={classes.distancerdown}>
                        <Link
                          to={`/${thePost.pseudo}`}
                          className={classes.linkinner}
                        >
                          {t("orsubscribe")}
                        </Link>
                      </h5>
                    )}
                  </div>
                ) : (
                  <div className={classes.topaytext}>
                    <h5 className={classes.distancer}>{t("payzero")}</h5>{" "}
                    <RedButton
                      className={classes.bigbutton}
                      key={`bb${thePost.guid}`}
                      onClick={() => {
                        toggleModal(thePost.guid);
                      }}
                    >
                      {t("freischalten")}
                    </RedButton>
                  </div>
                )}
                {showModal && (
                  <SimplePaymentBaseModal
                    postid={thePost.guid}
                    title={thePost.msgTitle}
                    girlname={thePost.pseudo}
                    onclose={gotit}
                    fixpreis={thePost.price}
                    girlid={thePost.producerID}
                    canfandollar={true}
                    girlimg={thePost.smallImageIDURLs.urlSmall}
                    secondgirlimg={thePost.bigImageIDURLs.urlMedium}
                    currency={thePost.currency}
                  />
                )}
              </div>
            )}
          </PostCardContent>
        )
      ) : (
        <div
          onClick={() => {
            setDoIHideSome(false);
          }}
        >
          <BlueButton>Anzeigen</BlueButton>
        </div>
      )}

      {doIHideSome ? null : (
        <PostCardFunctionList
          post={thePost}
          context={authCtx}
          changeLike={doIChangeLike}
          key={changeValue}
          changePin={pinchange}
          sendpininfo={sendpininfo}
          messengerPrice={props.messengerPrice}
        />
      )}
      {needsLogin ? (
        <PortalModal onclose={toggleLogin}>
          <Login />
        </PortalModal>
      ) : null}
    </PostCardWrapper>
  );
});

export default MainPostCard;
