import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import MyImage from "../../sonstige/specialcomponents/images";
import BlueButton from "../WrapperComponents/bluebutton";
import classes from "./isLiveGirl.module.css";

const IsLiveGirl = (props) => {
  const {
    isOnline,
    girlname,
    image,
    targetType,
    targetUrl,
    targetName,
    isOnlineInLivecam,
    website,
  } = props;
  const [link, setLink] = useState(false);
  const [onlineLocation, setOnlineLocation] = useState(false);
  const [newtargetUrl, setNewTargetUrl] = useState(null);
  useEffect(() => {
    var mytarget;
    if (props.targetUrl === "null") {
      setNewTargetUrl(props.website);
      mytarget = props.website;
    } else {
      setNewTargetUrl(targetUrl);
      mytarget=props.targetUrl
    }

    if (targetType === "null") {
      setLink(`https://susi.live/cam/${girlname}`);
      setOnlineLocation("susi.live");
    }
    if (targetType === "cms") {
      var a = mytarget;
      if (a.search.ttp > 0) {
        a = `https://${a}`;
      }
      setLink(a);
      setOnlineLocation(targetName);
    }
    if (targetType === "custom") {
      var a = mytarget;
      if (a.search.ttp > 0) {
        a = `https://${a}`;
      }
      setLink(a);
    }
  }, []);

  const { t } = useTranslation();
const isMobile=useCheckMobileScreen();
  return (
    <Fragment>
      {isOnlineInLivecam ? (
        <div className={isMobile?classes.mobelement:classes.element}>
          <a href={link}>
            <Row className={classes.therow}>
              <Col xs="12" md="6">
                <MyImage src={image} className={classes.image} />
              </Col>
              <Col xs="12" md="6" className={classes.centerme}>
                <h5 className={classes.text}>
                  {t("currentlylive")}
                  {onlineLocation}
                </h5>

              
                  <BlueButton>{t("tothelivechat")}</BlueButton>
               
              </Col>
            </Row>
          </a>
        </div>
      ) : //   <div className={classes.element}>
      //     <a href={target}>
      //       <h5 className={classes.text}>{t("wannabecloser")}</h5>
      //     </a>
      //   </div>
      null}
    </Fragment>
  );
};

export default IsLiveGirl;
