import React from "react";

import classes from "./add.module.css"
const Add=(props)=>{
var reallink = props.link.replace("sys-pussyposer.livecam.support","susi.live");
return (

    <div
      className={classes.addcard}
      style={{ background: `url(${props.image}) top center no-repeat` }}
    >
        <a href={reallink}>
      <div className={classes.headerwrapper}>
        <p className={classes.anzeigetext}>Anzeige</p>
        <div className={classes.extrawrapper}>
          <h4 className={classes.header}>{props.header}</h4>
          <p className={classes.addcontent}>{props.addcontent}</p>
        </div>
      </div>
      {/* <img alt={props.header} src={props.image} className={classes.image} /> */}
        </a>
    </div>

);
}
export default Add;