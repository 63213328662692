import { checkreceivedData } from "./checkReceivedData";

export async function useSendReport(
         context,
         contextID,
         contextType,
         contextPage,
         reportType,
         reportTypeClass,
         comment
       ) {
       
         fetch(`https://api.deine.fans/api/compliance/report`, {
           method: "PUT",
           body: JSON.stringify({
             authToken: context.jwtAuthToken,
             userID: context.userID,
             contextID: contextID,
             contextPage:contextPage,
             contextType: contextType,             
             reportType: reportType,
             reportTypeClass: reportTypeClass,
             comment: comment,
             g_recaptcha_response: "true",
           }),
           headers: {
             "Content-Type": "application/json",
           },
         }).then((res) => {
           res.json().then((data) => {
             checkreceivedData(data, context);
           });
         });
       }
