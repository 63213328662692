import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AuthContext from "../../context/authcontext";
import NewMailPW from "../auth/newMailPW";
import classes from "./passwordreset.module.css";
import SuccessMessage from "./specialcomponents/successmessage";
import PortalModal from "../Cards/WrapperComponents/portalmodal";

const PasswordReset = () =>{
const params=useParams();
const uerel=window.location.href;
const datoken = uerel.split("token=")[1];
const context=useContext(AuthContext);
const [worked,setWorked]=useState(false);
const [myUser,setMyUser]=useState(null);
const [myError,setMyError]=useState(false);
const [gotData,setGotData]=useState(null)

useEffect(()=>{loghimin();},[]);

function loghimin(){
    fetch("https://api.deine.fans/api/token/refresh", {
      method: "POST",
      body: JSON.stringify({
        role: "user",
        userID: "pwreset",
        password_reset_id: params.key,
        authToken: datoken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
    
      res.json().then((data) => {
        if(data.success){
        // context.newToken(data.authToken);
        // context.setAFreshToken(data.refreshToken);
        // context.setJwtAuthToken(data.jwtAuthToken);
        // context.addUserID(data.userID);
       setGotData(data)
        setMyUser(data.userID);
        setWorked(true);
        }
        else{
          setMyError(data.error)
        }
       
      });
    });
}
const {t}=useTranslation();
    return (
      <div key={gotData}>
        {worked && gotData ? (
          <NewMailPW pwreset={true} myuserID={myUser} data={gotData}/>
        ) : myError === "Invalid AuthToken" ? (
          <PortalModal onclose={()=>{}}>
            <SuccessMessage type="pwfailed" error={myError} />
          </PortalModal>
        ) : (
          <p>{myError}</p>
        )}
      </div>
    );
};

export default PasswordReset;