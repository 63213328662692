import React, { memo, Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../context/authcontext";
import useMyHttp from "../../hooks/myhttp";
import useCheckMobileScreen from "../../hooks/useCheckMobile";
import classes from "./postCard.module.css";
import styles from "./smallimageElement.module.css";
import ShowImageModal from "./showimagemodal";
import ScrollImage from "./WrapperComponents/stylings/scrollimage";
import { useMetric } from "../../hooks/useMetric";
import LoadingSpinner from "./Extras/loadingspinner";
import { useRef } from "react";
import { useOnScreen } from "../../hooks/useCheckinDisplayPort";
import { useFullOnScreen } from "../../hooks/useCheckFullinViewPort";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SmallImageElement = memo((props) => {
  const { data, isLoading, sendRequest } = useMyHttp();
  const authCtx = useContext(AuthContext);
  const [imageUrl, setImageUrl] = useState("");

  function thishandler() {
    var media = encodeURIComponent(props.imgid);

    sendRequest(
      `https://api.deine.fans/api/media/${props.guid}?mediaID=${media}&userID=${authCtx.userID}&authToken=${authCtx.jwtAuthToken}`,
      "GET",
      null,
      null,
      null,
      authCtx
    );
  }
  const isMobile = useCheckMobileScreen();
  useEffect(() => {
    thishandler();
  }, []);
  const [showModal, setShowModal] = useState();
  function toggleModal() {
    setShowModal(!showModal);
    useMetric("image-view", props.guid, authCtx);
  }
  useEffect(() => {
    if (
      data &&
      data.media &&
      data.media.imageUrl &&
      data.media.imageUrl.urlMedium
    ) {
      var t = data.media.imageUrl.urlMedium;
      if (props && props.dimension) {
        var g = t.replace("720x540/", props.dimension);
        setImageUrl(g);
      } else {
        setImageUrl(data.media.imageUrl.urlBig);
      }
    }
  }, [data, thishandler]);
  useEffect(() => {
    if (props.sendmetheurl && data && data.media) {
      props.sendmetheurl(data.media.imageUrl.urlBig);
    }
  }, [data]);

  const [elementRef, isVisible] = useFullOnScreen({
    root: null,
    rootMargin: `0px`,
    threshold: 1.0,
  });
  const delay = 5;
  const [hasShown, setHasShown] = useState(false);
  let timer1;

  useEffect(() => {
    if (isVisible === true) {
      timer1 = setTimeout(() => {
        setHasShown(true);
      }, delay * 1000);
    } else {
      clearTimeout(timer1);
    }
  }, [isVisible]);
  const [hasSent, setHasSent] = useState(false);

  useEffect(() => {
    if (hasShown && isVisible && !hasSent) {
      useMetric("image-view", props.guid, authCtx);
      setHasSent(true);
    }
  }, [hasShown, isVisible]);

  function getrealsize(val, width, height) {
    const a = val.split("cloudfront.net/")[1];

    const b = a.split("/")[0];

    const num1 = b.split("x")[0];
    const num2 = b.split("x")[1];
    var test;
    if (Number(width) < Number(height)) {
      var bigger = Number(num1) * 2;
      if (isMobile) {
        test = val.replace(b, `${bigger}x`);
      } else {
        test = val.replace(b, `${bigger}x`);
      }
    } else {
      var bigger = Number(num1) * 2;
      if (isMobile) {
        test = val.replace(b, `x${bigger}`);
      } else {
        test = val.replace(b, `x${bigger}`);
      }
    }

    return test;
  }
  const { t } = useTranslation();

  return (
    <div ref={elementRef}>
      {isLoading && <LoadingSpinner />}
 
      {data && data.success === false && props.showError ? (
        <div className={styles.errorContainer}>
          <p className={styles.errormessage}>
            {t("contentnotdisplayble")}
            <Link to="/support">Support</Link> - {data.message}
          </p>
          {authCtx.pseudo === "apitest" ? <p>{data.debugMessage}</p> : null}
        </div>
      ) : null}
      {data && data.media && (
        <ScrollImage
          extraclass={styles.myimageelement}
          src={getrealsize(
            data.media.imageUrl.urlMediumInside,
            data.media.imageUrl.orgWidth,
            data.media.imageUrl.orgHeight
          )}
          id={props.imgid}
          alt={props.imgid}
          style={{ cursor: "pointer" }}
          sendback={() => toggleModal()}
        />
      )}

      {data && showModal && data.media && (
        <>
          <ShowImageModal
            image={
              data.media.imageUrl.urlBig
                ? getrealsize(
                    data.media.imageUrl.urlMedium,
                    data.media.imageUrl.orgWidth,
                    data.media.imageUrl.orgHeight
                  )
                : isMobile
                ? "/images/fall02.jpg"
                : "/images/fall01.jpg"
            }
            getback={toggleModal}
          />
        </>
      )}
    </div>
  );
});

export default SmallImageElement;
