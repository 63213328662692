import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import classes from "./showInfoNoFollows.module.css";
const ShowInfoAbos = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.wrapper}>
        <h5 className={classes.header}>{t("thiscreatorwantsabos")}</h5>
        <p>
          {t("creatorabos")}
          <span className={classes.girltext}>{props.girlinfo}</span>
          {props.hasFree ? t("creatorabos2free") : t("creatorabos2")}
        </p>
      </div>
    </>
  );
};

export default ShowInfoAbos;
