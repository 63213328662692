import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import { Analytics } from "aws-amplify";

//import MyButton from "../Cards/WrapperComponents/myCard.js/myButton";
import classes from "./addpaymentmethod.module.css";
import GetNumber from "./simplepayment/components/getNumber";
import GetFanDollarNumber from "./simplepayment/components/getFanDollarNumber";
import RedButton from "../Cards/WrapperComponents/redbutton";
import ShowPrice from "./showPrice";
//import { Link } from "react-router-dom";

const AddPayable = (props) => {
  const { i18n } = useTranslation();
  const [number, setNumber] = useState(0);

  function closeme() {
    props.getbackclose();
  }
  function sendback(info) {
    if (info) {
      setNumber(info);
    }
  }

  const [consent, setConsent] = useState(false);

  const authCtx = useContext(AuthContext);
  //   function submitHandler(event) {
  //     event.preventDefault();
  //     senddata(true);
  //   }

  const { t } = useTranslation();

  function togglewiderspruch() {
    setConsent(!consent);
  }
  function relocate(destination) {
    window.location.replace(destination);
  }
  const curPath = window.location.pathname;
  function weitergehts() {
    localStorage.setItem("lastSite", curPath);
    fetch(
      `https://api.deine.fans/api/payment/methods/creditcardpayable/authorize/${
        number > 0
          ? encodeURIComponent(number * 100)
          : props.initialPay
          ? props.initialPay > 999
            ? props.initialPay
            : 1000
          : ""
      }?authToken=${encodeURIComponent(
        authCtx.token
      )}&userID=${encodeURIComponent(
        authCtx.userID
      )}&UserLanguage=${encodeURIComponent(
        i18n.language
      )}&referer=${encodeURIComponent(props.postid)}&currency=${
        authCtx.currency ? authCtx.currency : "EUR"
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        checkreceivedData(data, authCtx, "addpayable");
        if (!data.success) {
          console.log(data.error);
        } else {
          Analytics.record({
            name: "authorizePaymentPayable",
            attributes: {
              userID: props.userID,
            },
          });

          relocate(data.authUrl);
        }
      });
  }
  return (
    <>
      <div className={classes.darkBG} onClick={closeme} />
      <div className={classes.centered}>
        <div className={classes.modal}>
          <div className={classes.modalHeader}>
            <h3>{t("addcreditcard")}</h3>
          </div>
          <button className={classes.closeBtn} onClick={closeme}>
            <h3>X</h3>
          </button>
          <p className={classes.consenttextsmall}>{t("consentpayabl")}</p>
          {props.initialPay ? null : (
            <p className={classes.consenttext}>{t("buchejetztfandollarauf")}</p>
          )}
          {props.initialPay && props.initialPay < 1000 ? (
            <p>
              {t("letssayten")} {t("yougetfandollarback")}
              {((1000 - props.initialPay) / 100).toFixed(2)}
              {t("yougetfandollarback2")}
            </p>
          ) : null}
          {props.initialPay ? (
            <ShowPrice
              price={props.initialPay < 1000 ? 1000 : props.initialPay}
              extra={props.initialPay < 1000 ? 1000 - props.initialPay : null}
              context={authCtx}
              type="post"
              currency={authCtx.currency ? authCtx.currency : "EUR"}
            />
          ) : (
            <GetFanDollarNumber
              includezero={props.pricefrombuyfandollar ? false : true}
              sendback={sendback}
              value={
                number?number:
                props.pricefrombuyfandollar
                  ? props.pricefrombuyfandollar
                  : number
              }
              key={number}
              firtTime={0}
            />
          )}
          
          <RedButton className={classes.myButton} onClick={() => weitergehts()}>
            {props.initialPay
              ? t("authorisierenformoney")
              : number > 0
              ? t("authorisierenformoney")
              : props.pricefrombuyfandollar
              ? t("authorisierenformoney")
              : t("authorisieren")}
          </RedButton>
        </div>
      </div>
    </>
  );
};

export default AddPayable;
