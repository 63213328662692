import React, { memo, useContext, useEffect, useState } from "react";
//import { useTranslation } from "react-i18next";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import { useMetric } from "../../../hooks/useMetric";
import MyImage from "../../sonstige/specialcomponents/images";
//import VideoPlayer2 from "./videoplayer2";
import classes from "./videopreview.module.css";
import ReactPlayer from "react-player";
import AuthContext from "../../../context/authcontext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Videopreview = memo((props) => {
  // const { t } = useTranslation();
  const [imageDimension, setImageDimension] = useState(false);
  const [isShaka, setIsShaka] = useState(false);
  const isMobile = useCheckMobileScreen();
  const [errormessage,setErrorMessage] = useState(false);
   const [debugmessage, setDebugMessage] = useState(false);
   const authCtx = useContext(AuthContext);
  useEffect(() => {
    setImageDimension(props.dimension);
  }, []);
  useEffect(() => {
    if (props.play === true) {
      playvideo();
    }
    getSingleVideo(props.mediaID);
  }, []);
  function checksize(image) {
    var extra = "";
    var element = document.getElementById("flexi");
    if (element) {
      var positionInfo = element.getBoundingClientRect();
      var height = Math.round(positionInfo.height);
      var width = Math.round(positionInfo.width);
      extra = `${width}x${height}/`;
    }
    var element2 = document.getElementById("flexib");
    if (element2) {
      var positionInfo = element2.getBoundingClientRect();
      var height = Math.round(positionInfo.height);
      var width = Math.round(positionInfo.width);
      extra = `${width}x${height}/`;
    }

    if (extra.length > 0) {
      var a = image.replace("640x480/", extra);
      setPreviewImage(a);
    } else {
      setPreviewImage(image);
    }
    if (props.sendmetheurl) {
      props.sendmetheurl(image);
    }
  }

  function getSingleVideo(mediaid, guid, context) {
    //var media = encodeURIComponent(mediaid, props.guid, props.context);

    fetch(
      `https://api.deine.fans/api/media/${encodeURIComponent(props.guid)}?mediaID=${encodeURIComponent(mediaid)}&userID=${encodeURIComponent(props.context.userID)}&authToken=${encodeURIComponent(props.context.jwtAuthToken)}`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, props.context, "videopreview");
        
                if(data && data.success===false){setErrorMessage(data.message);setDebugMessage(data.debugMessage)}
        if (data && data.media && data.media.videoUrl) {
          if (data.media.videoUrl.playerinfo === 4) {
            setIsShaka(true);
          }

          setMyInfo(data.media.videoUrl.url);

          if (
            data.media.videoUrl.previewImage &&
            data.media.videoUrl.previewImage.search("noPreview") <= 0
          ) {
            if (props.context.isAVS) {
              setTimeout(() => {
                checksize(data.media.videoUrl.previewImage);
              }, 50);
            } else {
              setTimeout(() => {
                checksize(data.media.videoUrl.previewImageSoft);
              }, 50);
            }
          } else {
            setPreviewImage(
              isMobile ? "/images/fall02.jpg" : "/images/fall01.jpg"
            );
          }
        } else {
          setPreviewImage(
            isMobile ? "/images/fall02.jpg" : "/images/fall01.jpg"
          );
          setErrorMessage(`PostID: ${props.guid}`);
        }
      });
    });
  }

  const [showModal, setShowModal] = useState(false);
  const [myInfo, setMyInfo] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  function countforstats() {
    useMetric("video-view", props.guid, props.context);
  }
  function playvideo() {
    countforstats();
    setShowModal(!showModal);
  }
  function toggleModal() {
    setShowModal(!showModal);
  }
const {t}=useTranslation();
  return (
    <div className={classes.container}>
      {previewImage && (
        <div>
          {errormessage && props.showError ? (
            <p className={classes.errormessage}>
              {t("contentnotdisplayble")}
              <Link to="/support">Support</Link> - {errormessage}
            </p>
          ) : null}
          {debugmessage && authCtx.pseudo === "apitest" && props.showError ? (
            <p>{debugmessage}</p>
          ) : null}
          <div className={classes.wrapvideoclass}>
            <ReactPlayer
              url={myInfo}
              controls={true}
              playing={true}
              width="100%"
              height="100%"
              light={
                <MyImage
                  src={previewImage}
                  alt={"mainpreview"}
                  key={previewImage}
                  className={classes.videoPrev}
                />
              }
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default Videopreview;
