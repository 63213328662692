
import { create } from "zustand";


export const usePreflight = create((set,get) => ({
  token: "",
  updateToken: (newToken) => {
    console.log(newToken)
    if (newToken) {set({ token: newToken })};
  },
  getToken:get(),
}));

export const useMessages = create((set,get)=>({
  messages:0,
  updateMessages:(newNum)=>{
    if (newNum) {set({messages:newNum})}else{set(({messages:0}))}
  },
  reloadPage:false,
  changereload:(bool)=>{
    set({reloadPage:bool})
  },
  checkedmessegas:false,
  checkmessages:()=>{
    var a=get().checkedmessegas
    set({ checkedmessegas:!a });
  }
}))

export const useADR = create((set, get) => ({
  adr: "",
  updateADR: (value) => {
    if (value) set({ adr: value });
  },
 
}));
export const useIsAnonLogin = create((set, get) => ({
  isAnon: false,
  changeIsAnon: (value) => {
    console.log(value)
    if (value) set({ isAnon: value });
  },
}));
export const useHasUsedFreeCoins = create((set, get) => ({
  hasUsed: false,
  changehasUsed: (value) => {
    
    if (value) set({ hasUsed: value });
  },
  hasSuccess:false,
  changeSuccess:(value)=>{
    if(value)set({hasSuccess:value})
  }
}));

export const useLoginStatus = create((set, get) => ({
  isLogin: false,
  changeisLogin: (value) => {
    
    if (value) set({ isLogin: value });
  },
}));