import React,{ memo, useEffect, useState } from "react";
import classes from "./simplepaymentclasses.module.css";

const WantFanDollar = memo((props) =>{

    const [wantoPayWithFanDollar,setWantToPayWithFanDollar]=useState(props.initial);
    const [PayPrice,setPayPrice]=useState(props.price);
    const [realpayprice,setrealpayprice]=useState(0);
    const [additionalcoinsneeded,setadditionalcoinneeded]=useState(false);
    const ctX=props.context;

    useEffect(()=>{
      var check = ctX.fanDollar || 0;
      var test = check - PayPrice;
      var test2 = PayPrice - check;
      setrealpayprice(test.toFixed(2));
      if(PayPrice-check<0)setadditionalcoinneeded(true);
    }, []);

    function togglewant(){

        props.doyouwant(!wantoPayWithFanDollar);
            
    }
    return (
      <div>
        <p>
          <label>
            <input
              className={classes.checkbox}
              checked={wantoPayWithFanDollar}
              type="checkbox"
              onChange={togglewant}
            />
            <span className={classes.consenttext}>
              {additionalcoinsneeded && (
                <span>
                  {" "}
                  Möchtest Du mit FanDollar bezahlen? {realpayprice} FanDollar
                  bleiben dann noch in Deinem Account.
                </span>
              )}
              {!additionalcoinsneeded && (
                <span>
                  {" "}
                  Willst Du mit FanDollars bezahlen?{" "}
                  {(PayPrice - ctX.fanDollar).toFixed(2)} FanDollar werden noch
                  von Deiner Bezahlmethode abgebucht.
                </span>
              )}
            </span>
          </label>
        </p>
      </div>
    );
});

export default WantFanDollar;