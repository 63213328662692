import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import classes from "./basicsites.module.css";
import { Link, useParams } from "react-router-dom";

const RecordKeeping = () => {
  const { t } = useTranslation();
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  return (
    <div className={classes.content}>
      <h5 className={classes.header}>{t("recordkeeping")}</h5>
      <p>{t("recordkeeping1")}</p>
      <p>{t("recordkeeping2")}</p>
      <p>{t("recordkeeping3")}</p>
      <p>{t("impressuml2")}</p>
      <p>{t("impressuml3")}</p>
      <p>{t("impressuml4")}</p>
      <p>{t("impressuml5")}</p>
      <p className={classes.link}>
        {t("recordkeeping5")} <Link to="/support">{t("support")}</Link>
      </p>
    </div>
  );
};
export default RecordKeeping;
