import React, { Fragment, useContext, useEffect, useState } from "react";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import RedButton from "../WrapperComponents/redbutton";
import WrapModal from "../WrapperComponents/wrapmodal";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import classes from "./displayMessages.module.css";
import WriteMessage from "./writeMessage";
import DeleteMessage from "./deleteMessage";
import DisplayResponseMessage from "./displayResponseMessage";
import DisplayMessageMedia from "./displayMessageMedia";
import AuthContext from "../../../context/authcontext";
import MyImage from "../../sonstige/specialcomponents/images";
import SimplePaymentBaseModal from "../../payment/simplepayment/simplepaymentBaseModal";
import { checkstatus } from "../../../hooks/checkstatus";
import { useTranslation } from "react-i18next";
import MakeAVS from "../Extras/makeavs";
import { useNavigate } from "react-router-dom";
import { useMessages } from "../../../hooks/usePreflight";

const DisplayMessage = (props) => {
  const oldDate = new Date(props.cont.sendTime);
  const isMobile = useCheckMobileScreen();
  const authContext = useContext(AuthContext);

  const [showContent, setShowContent] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isFavorit, setIsFavorit] = useState(props.cont.isFav);
  const currentDate = new Date().getTime();
  const clickDate = new Date(`${props.cont.clickTime}Z`).getTime();
  const [isalreadyClicked, setIsAlreadyClicked] = useState(false);
  const { t } = useTranslation();
  const { lastOpenedMessage } = props;
const triggerMessageCheck = useMessages((state) => state.checkmessages);


  useEffect(() => {
    if (props.cont.clickTime !== "0001-01-01T00:00:00") {
      if (props.cont.clickTime){
      setIsAlreadyClicked(true);
      }
    }
    if (lastOpenedMessage === props.cont.msgID) {
      setShowContent(true);
    }
   
  }, []);

  function toggleShow(e) {
    setShowContent(!showContent);
    setIsAlreadyClicked(true);
    fetch("https://api.deine.fans/api/messageclick", {
      method: "POST",
      body: JSON.stringify({
        authToken: authContext.jwtAuthToken,
        userID: authContext.userID,
        msgID: props.cont.msgID,
        page: window.location.href,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((data) => {
        console.log(data);
      });
      // do nothing, ignore result
      console.log("messageclick sent to server");
      //checkstatus(authContext);
    });
  }

  const [sendReply, setSendReply] = useState(false);

  function toggleReply() {
    console.log("Replyfunktion");
    setSendReply(!sendReply);
  }

  const [showPayModal, setShowPayModal] = useState(false);

  function togglePayModal() {
    console.log(
      "pay modal, msgid: " + props.cont.msgID + " | price: " + props.cont.price
    );

    if (props.cont.price === 0) {
      fetch("https://api.deine.fans/api/messagebuy", {
        method: "POST",
        body: JSON.stringify({
          authToken: authContext.jwtAuthToken,
          userID: authContext.userID,
          msgID: props.cont.msgID,
          authorizationID: -1,
          useFanBalance: true,
          page: window.location.href,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        res.json().then((data) => {
          checkreceivedData(data, authContext, "displayMessage");
          if (data.alreadyBought) {
            console.log("isbought");
          }
          if (data.success === true) {
            const payments = {
              preis: props.cont.price,
              payed: props.cont.price,
            };
            authContext.checkFanDollar(data.newBalance / 100);
            messageBoughtCallback();
          } else {
            alert(data.error);
          }
        });
      });
    } else {
      setShowPayModal(!showPayModal);
    }
  }

  function messageBoughtCallback(info) {
    console.log(info);
    setShowPayModal(false);
    props.onMessageBought(props.cont.msgID);
    console.log("messagebought")
  }

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  function toggleDelete() {
    if (deleteConfirm) {
      setDeleteConfirm(false);
      setIsDeleted(true);
    } else {
      console.log("Nachricht löschen");
      setDeleteConfirm(true);
    }
  }

  function makeFavorite(e) {
    e.preventDefault();
    console.log("Nachricht favorisieren");
    fetch(
      "https://api.deine.fans/api/inboxuser?msgID=" +
        encodeURIComponent(props.cont.msgID) +
        "&userID=" +
        encodeURIComponent(authContext.userID) +
        "&authToken=" +
        encodeURIComponent(authContext.jwtAuthToken),
      {
        method: "PUT",
      }
    ).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, authContext, "displayMessage");
        if (data.newStatus === true) {
          console.log("Nachricht favorisiert");
          setIsFavorit(true);
        } else {
          console.log("Nachrichten FavStatus entfernt");
          setIsFavorit(false);
        }
      });
    });
  }

  const authCtx = useContext(AuthContext);

  const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);

  useEffect(() => {
    setDarkMode(authCtx.darkMode);
  }, [authCtx]);
const navigate = useNavigate();

  return (
    <Fragment>
      {showPayModal ? (
        <SimplePaymentBaseModal
          msgid={props.cont.msgID}
          girlname={props.cont.sender}
          onclose={messageBoughtCallback}
          fixpreis={props.cont.price}
          girlid={props.cont.sender}
          canfandollar={true}
        />
      ) : null}
      {!isDeleted ? (
        <div
          className={!isMobile ? classes.card : classes.mobileCard}
          key={isFavorit}
        >
          <div
            onClick={(e) => {
              console.log(e.target.name);
              triggerMessageCheck();
              if (e.target.name === "favbutton") {
                makeFavorite(e);
              } else if (e.target.name === "content") {
                return;
              } else if (e.target.name === "profileimage") {
                navigate(`/${props.cont.sender}`);
              } else {
                toggleShow();
              }
            }}
            className={
              showContent
                ? isalreadyClicked
                  ? classes.headerfieldclicked
                  : classes.headerfield
                : isalreadyClicked
                ? classes.headersmallclicked
                : classes.headersmall
            }
          >
            <MyImage
              src={
                props.cont.sender === ""
                  ? "https://d39j9qa9t0x6xj.cloudfront.net/logos/x120/spinner01.png"
                  : props.cont.girlimg
              }
              alt={props.cont.sender}
              className={!isMobile ? classes.msgimg : classes.msgimgmobile}
              name="profileimage"
            />
            <h5 className={!isMobile ? null : classes.headline}>
              Nachricht{" "}
              {props.cont.folder === "inbox"
                ? "von " + props.cont.sender
                : "an " + props.cont.receiverPseudo}
            </h5>
            <div
              className={
                isMobile ? classes.messagedatemobile : classes.messagedate
              }
            >
              {oldDate.toLocaleString()}
              {props.cont.folder === "inbox" ? (
                <div className={classes.faviconDIV}>
                  <img
                    name="favbutton"
                    src="/images/icons/post_icon_herz.png"
                    className={
                      isFavorit ? classes.favicon : classes.favicongray
                    }
                    alt={isFavorit ? t("MakeFav") : t("UnmakeFav")}
                    title={isFavorit ? t("MakeFav") : t("UnmakeFav")}
                  />
                </div>
              ) : null}
              {props.cont.freeMedia.length + props.cont.payMedia.length > 0 ? (
                <label>
                  <img
                    width="24"
                    height="24"
                    src="/images/icons/klammer.png"
                    alt="Attachments"
                    style={{ display: "inline-block" }}
                  />
                  <span>
                    {props.cont.freeMedia.length + props.cont.payMedia.length}
                  </span>
                </label>
              ) : null}
            </div>
          </div>
          {showContent && (
            <div
              className={
                !isMobile ? classes.contentfield : classes.contentfieldmobile
              }
            >
              <div
                className={
                  !isDarkmode ? classes.textfield : classes.textfielddm
                }
              >
                {props.cont.needsAVS && !authCtx.isAVS ? (
                  <MakeAVS />
                ) : (
                  props.cont.msgtext
                )}
              </div>
              {console.log(props.cont)}
              {props.cont.needsAVS && !authCtx.isAVS ? null : (
                <div className={classes.mediafield}>
                  {!props.cont.bought && props.cont.payMedia.length > 0 ? (
                    <div onClick={togglePayModal}>
                      <RedButton>
                        {t("buythisfor")} {(props.cont.price / 100).toFixed(2)}{" "}
                        FanDollar
                      </RedButton>
                    </div>
                  ) : null}
                  {props.cont.payMedia !== null
                    ? props.cont.bought === true
                      ? props.cont.payMedia.map((media, index) => (
                          <div name="content" key={`usermsgPayMedia ${index}`}>
                            <DisplayMessageMedia
                              type={media.type}
                              mediaID={media.mediaID}
                              msgID={props.cont.msgID}
                            />
                          </div>
                        ))
                      : props.cont.payMedia.length > 0 && (
                          <p
                            className={
                              isDarkmode ? classes.textdark : classes.textbright
                            }
                          >
                            {t("thisposthas")} {props.cont.payMedia.length}{" "}
                            {t("mediaitemstobuy")}
                          </p>
                        )
                    : null}
                  {props.cont.freeMedia
                    ? props.cont.freeMedia.map((media, index) => (
                        <div key={`usermsgfreeMedia ${index}`}>
                          <DisplayMessageMedia
                            type={media.type}
                            mediaID={media.mediaID}
                            msgID={props.cont.msgID}
                          />
                        </div>
                      ))
                    : null}
                  {props.cont.folder === "inbox" ? (
                    <div className={classes.buttonfield}>
                      <div onClick={toggleReply}>
                        <RedButton>{t("Antworten")}</RedButton>
                      </div>
                      <div onClick={toggleDelete}>
                        <RedButton>{t("Delete")}</RedButton>
                      </div>
                    </div>
                  ) : null}
                  {props.cont.responses
                    ? props.cont.responses.map((mes, index) => (
                        <div key={`responsemessage ${index}`}>
                          <DisplayResponseMessage cont={mes} />
                        </div>
                      ))
                    : null}
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}

      {sendReply && (
        <WrapModal onclose={() => {}}>
          {" "}
          <WriteMessage frommessage={props.cont} onclose={toggleReply} />
        </WrapModal>
      )}

      {deleteConfirm && (
        <WrapModal onclose={() => {}}>
          {" "}
          <DeleteMessage frommessage={props.cont} onclose={toggleDelete} />
        </WrapModal>
      )}
    </Fragment>
  );
};
export default React.memo(DisplayMessage);
