import { useReducer, useCallback, useContext } from "react";
import AuthContext from "../context/authcontext";
import { checkreceivedData } from "./checkReceivedData";



const httpReducer = (curHttpState, action) => {
  switch (action.type) {
    case "SEND":
      return {
        loading: true,
        error: null,
        data: null,
        extra: null,
        identifier: action.identifier,
      };
    case "RESPONSE":
      return {
        ...curHttpState,
        loading: false,
        data: action.responseData,
        extra: action.extra,
      };
    case "ERROR":
      return { loading: false, error: action.errorMessage };
    case "CLEAR":
      return { ...curHttpState, error: null };
    default:
      throw new Error("Should not be reached!");
  }
};

const useMyHttp = () => {
  const authCtx=useContext(AuthContext)
  const [httpState, dispatchHttp] = useReducer(httpReducer, {
    loading: false,
    error: null,
    data: null,
    extra: null,
    identifier: null,
   
  });

  const sendRequest = useCallback(
    (url, method, body, reqExtra, reqIdentifer,context) => {
     
      dispatchHttp({ type: "SEND", identifier: reqIdentifer });

      fetch(url, {
        method: method,
        body: body,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
           
           
            return response.json();
         
        })
        .then((responseData) => {
          
          if (context) {
            checkreceivedData(responseData, context, "myhttp");
          }
                dispatchHttp({
                  type: "RESPONSE",
                  responseData: responseData,
                  extra: reqExtra,
                });
              })
        .catch((err) => {
          console.log(err)
          dispatchHttp({
            type: "ERROR",
            errorMessage: "Fehler",
          
          });
        });
    },
    []
  );

  return {
    isLoading: httpState.loading,
    data: httpState.data,
    error: httpState.error,
    sendRequest: sendRequest,
    reqExtra: httpState.extra,
    reqIdentifer: httpState.identifier,
   
  };
};

export default useMyHttp;
