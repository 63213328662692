import React, {  useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import AuthContext from "../../../context/authcontext";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import classes from "./allsubscriptions.module.css";
import SubBox from "../../Cards/subscriptions/subBox";
import PleaseLogin from "../../sonstige/pleaselogin";
import { t } from "i18next";
import { useTranslation } from "react-i18next";



const AllSubscriptions = () => {

  const authCtx = useContext(AuthContext);

  const userToken = authCtx.token;
  const userID = authCtx.userID;
  const isLoggedIn = authCtx.isLoggedIn;
  const isPremium = authCtx.isPremium;
 const {t}=useTranslation();

  const [hasSubs, setHasSubs] = useState(false);
  const [subGirls, setSubGirls] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  function loadData() {
    if (!isLoggedIn){return}
    fetch(
      `https://api.deine.fans/api/subscriptions/user/${encodeURIComponent(userID)}?authToken=${encodeURIComponent(userToken)}&includeUnBilled=false`
    ).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          
          checkreceivedData(data, authCtx,"allsubs");
          if(data.subscriptions){
          if (data.subscriptions.length > 0) {
            setSubGirls(data.subscriptions);
           
            setHasSubs(true);
          }
        }
        });
      }
    });
  }

  useEffect(() => {
    loadData();
   
  }, []);
function sendback(){
  setSubGirls(null);
   setTimeout(() => {
     loadData();
   }, "500");
}
  return (
    <div className={classes.outer}>
      {!isLoggedIn && !isPremium && <PleaseLogin />}
      {!hasSubs && <h5>{t("youhavenosubs")}</h5>}
      {hasSubs && subGirls && (
        <Row className={classes.mylist}>
          {subGirls.map((subgirl, index) => (
            <Col xs="12" lg="6" key={`col${index}`}>
             
              <SubBox
                
                thegirl={subgirl}
                context={authCtx}
                key={index}
                sendback={sendback}
                subid={subgirl.id}
              />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default AllSubscriptions;
