import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PortalModal from "../Cards/WrapperComponents/portalmodal";
import GetNumber from "./simplepayment/components/getNumber";
import classes from "./addpaymentmethod.module.css";
import Mandat from "./mandat";
import RedButton from "../Cards/WrapperComponents/redbutton";
import AuthContext from "../../context/authcontext";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import LoadingSpinner from "../Cards/Extras/loadingspinner";
import { Analytics } from "aws-amplify";

const AddOnlineUeberweisen = (props) => {
  const [initialPayment, setInitialPayment] = useState(1);
  const [isConsent, setIsConsent] = useState(false);
  const [mandat, setMandat] = useState(null);
  const [isMandat, setIsMandat] = useState(false);
  const authCtx = useContext(AuthContext);
  const [destination, setDestination] = useState("/error");

  function toggleconsent() {
    setIsConsent(true);
    getmandat();
  }

  function unnamed() {
    props.getbackclose();
  }
  function getbackNumber(value) {
    if (value) {
      setInitialPayment(value);
    }
  }
  function gotconsent(val) {
    if (val) {
      setIsMandat(true);
      sendaway();
    }
  }
  function sendaway() {
    window.location.replace(destination);
  }
  const curPath=window.location.pathname;
  function getmandat() {
    localStorage.setItem("lastSite", curPath);
    fetch(
      `https://api.deine.fans/api/payment/methods/onlineueberweisen/authorize/${
        initialPayment * 100
      }?authToken=${encodeURIComponent(
        authCtx.token
      )}&userID=${encodeURIComponent(authCtx.userID)}&referer=${encodeURIComponent(curPath)}`
    )
      .then((response) => response.json())
      .then((data) => {
        checkreceivedData(data, authCtx, "addOnline");
        if (!data.success) {
          console.log(data.error);
        } else {
          Analytics.record({
            name: "authorizePaymentOnlineUeberweisen",
            attributes: {
              userID: authCtx.userID,
            },
          });
          setMandat(data.mandatInfo);
          setDestination(data.authUrl);
        }
      });
  }

  const { t } = useTranslation();
  return (
    <PortalModal onclose={unnamed}>
      <div className={classes.topdown}>
        <div className={classes.flexit}>
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x150/logo_onlineueberweisen.png"
            className={classes.smalllogo}
            alt="Online Ueberweisen Payment"
          />
          <h5>{t("addonlineUeberweisen")}</h5>
        </div>
        <p>{t("addvalue_onlineueberweisen")}</p>
        <p>{t("addvalue2")}</p>
        {!isConsent && !isMandat ? (
          <div>
            <span className={classes.getNumber}>
              <GetNumber
                sendbackNumber={getbackNumber}
                value={initialPayment}
                currency={authCtx.country==="CH"?"CHF":"€"}
              />
            </span>
            <div className={classes.consent}>
              <label>
                <input
                  className={classes.checkbox}
                  type="checkbox"
                  onClick={toggleconsent}
                />
                {t("consentonlineueberweisen")}
              </label>
            </div>
          </div>
        ) : isConsent && !mandat ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : isConsent && mandat ? (
          <div>
            <Mandat
              mandat={mandat}
              getconsent={gotconsent}
              extra="OnlineUeberweisen"
              context={authCtx}
            />
          </div>
        ) : (
          <div>
            <RedButton onClick={sendaway}>
              {t("kostenplichtigbestellen")}
            </RedButton>
          </div>
        )}
      </div>
    </PortalModal>
  );
};
export default AddOnlineUeberweisen;
