import React from "react"
import classes from "./redbutton.module.css"

const RedButton = ({ type = "button", onClick, children, className,id, ...rest }) => {
  return (
    <button
    id={id}
      type={type}
      onClick={onClick}
      {...rest}
      className={`${className} ${classes.MyButton}`}
    >
      {children}
    </button>
  );
};
export default RedButton