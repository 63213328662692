import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "./choosepaymentoption.module.css";
import AddPayable from "../../payment/apppayable";
import AddPaymentModal from "../../payment/addpaymentmodal";
import AuthContext from "../../../context/authcontext";
import AddSofort from "../../payment/addSofort";
import RedButton from "../../Cards/WrapperComponents/redbutton";
import AddEpoch from "../../payment/addepoch";
import AddOnlineUeberweisen from "../../payment/addOnlineUeberweisen";
import AddTwint from "../../payment/addTwint";
import AddPostfinance from "../../payment/addPostfinance";

function ChoosepaymentOption(props) {
  const { t } = useTranslation();
  const [addCreditCard, setAddCreditCard] = useState(true);
  const [addDirectDebit, setAddDirectDebit] = useState(true);
  const [addSofort, setAddSofort] = useState(true);
  const [addEpoch, setAddEpoch] = useState(true);
  const [addOnlineUeberweisen, setAddOnlineUeberweisen] = useState(true);
  const [addTwint, setAddTwint] = useState(false);
    const [addPostfinance, setAddPostfinance] = useState(true);
  const country= (Intl.DateTimeFormat().resolvedOptions().timeZone);
  const isGermany=country.search("Berlin") >= 0;
  const isSwitzerland=country.search("Zürich") >= 0;
  const {initialPay}=props;

  const authCtx = useContext(AuthContext);
  const [isDarkmode,setIsDarkMode] = useState(false);

  useEffect(() => {setIsDarkMode(authCtx.darkMode)}, [authCtx]);

  return (
    <div
      className={isDarkmode ? classes.flexcontainerdm : classes.flexcontainer}
    >
      {props.abo ? (
        authCtx.country === "CH" ? (
          <p>
            {t("adpaymentoptiontoenjoy")} {t("donthavecc")}
          </p>
        ) : (
          <p>{t("adpaymentoptiontoenjoy")}</p>
        )
      ) : (
        <Fragment>
          {props.isTimeLine ? (
            <p>
              <span>{t("addpaymentoptiontl1")}</span>{" "}
              <span classname={classes.pseudo}>{props.isTimeLine}</span>
              <span>{t("addpaymentoptiontl2")}</span>
            </p>
          ) : (
            <p>{t("addapaymentoption")}</p>
          )}
        </Fragment>
      )}

      <RedButton
        className={`${classes.flexit} ${classes.addapayment}`}
        onClick={() => setAddCreditCard(false)}
      >
        <img
          src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/x70/logo_epoch.png"
          className={classes.image}
        />
        <p className={classes.text}>{t("addcreditCard")}</p>
      </RedButton>
      {addCreditCard ? null : (
        <AddPayable
          userID={authCtx.userID}
          authToken={authCtx.token}
          method={"CreditCardPayabl"}
          getbackclose={() => setAddCreditCard(true)}
          initialPay={initialPay}
          postid={props.postid}
          pricefrombuyfandollar={props.pricefrombuyfandollar}
        />
      )}
      <RedButton
        className={`${classes.flexit} ${classes.addapayment}`}
        onClick={() => setAddSofort(false)}
      >
        <img
          src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/x70/logo_sofort.png"
          className={classes.image}
        />
        <p className={classes.text}>{t("addsofort")}</p>
      </RedButton>
      {addSofort ? null : (
        <AddSofort
          userID={authCtx.userID}
          authToken={authCtx.token}
          method={"Sofort"}
          getbackclose={() => setAddSofort(true)}
          initialPay={initialPay}
          postid={props.postid}
          pricefrombuyfandollar={props.pricefrombuyfandollar}
        />
      )}
      {authCtx.country === "DE" ? (
        <RedButton
          className={`${classes.flexit} ${classes.addapayment}`}
          onClick={() => setAddDirectDebit(false)}
        >
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo_lastschrift.png"
            className={classes.image}
          />
          <p className={classes.text}>{t("addlastschrift")}</p>
        </RedButton>
      ) : null}
      {authCtx.country === "CH" ? (
        <RedButton
          className={`${classes.flexit} ${classes.addapayment}`}
          onClick={() => setAddTwint(true)}
        >
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo_twint.png"
            className={classes.image}
          />
          <p className={classes.text}>{t("addtwint")}</p>
        </RedButton>
      ) : null}
      {addDirectDebit ? null : (
        <AddPaymentModal
          getbackclose={() => setAddDirectDebit(true)}
          userID={authCtx.userID}
          authToken={authCtx.token}
          method={"DirectDebit"}
        />
      )}
      <p>{t("furtherpaymentoptions")}</p>
      <div className={`${classes.flexit2} ${classes.secondcontainer}`}>
        {authCtx.country !== "DE" ? (
          addDirectDebit ? (
            <div>
              {" "}
              <img
                src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo_lastschrift.png"
                onClick={() => {
                  setAddDirectDebit(false);
                }}
                className={classes.extraimage}
              />
              <p className={classes.paymentInfo}>{t("infodirectdebit")}</p>
            </div>
          ) : (
            <AddPaymentModal
              getbackclose={() => setAddDirectDebit(true)}
              userID={authCtx.userID}
              authToken={authCtx.token}
              method={"DirectDebit"}
            />
          )
        ) : null}
        {addEpoch ? (
          <div>
            {" "}
            <img
              src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo_epoch.png"
              onClick={() => {
                setAddEpoch(false);
              }}
              className={classes.extraimage}
            />
            <p className={classes.paymentInfo}>{t("infocredit")}</p>
          </div>
        ) : (
          <AddEpoch
            getbackclose={() => setAddEpoch(true)}
            userID={authCtx.userID}
            authToken={authCtx.token}
            method={"Epoch"}
          />
        )}
        {addTwint ? (
          <AddTwint
            getbackclose={() => {
              setAddTwint(false);
            }}
            userID={authCtx.userID}
            authToken={authCtx.token}
            method={"Twint"}
          />
        ) : null}
        {authCtx.country === "DE" ? (
          addOnlineUeberweisen ? (
            <div>
              {" "}
              <img
                src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo_onlineueberweisen.png"
                onClick={() => {
                  setAddOnlineUeberweisen(false);
                }}
                className={classes.extraimage}
              />
              <p className={classes.paymentInfo}>{t("infodebit")}</p>
            </div>
          ) : (
            <AddOnlineUeberweisen
              getbackclose={() => setAddOnlineUeberweisen(true)}
              userID={authCtx.userID}
              authToken={authCtx.token}
              method={"OnlineUeberweisen"}
            />
          )
        ) : null}

        {authCtx.country === "CH" ? (
          addPostfinance ? (
            <div>
              {" "}
              <img
                src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/paymenticons/x70/logo_postfinance.png"
                onClick={() => {
                  setAddPostfinance(false);
                }}
                className={classes.extraimage}
              />
              <p className={classes.paymentInfo}>{t("mobilepayment")}</p>
            </div>
          ) : (
            <AddPostfinance
              getbackclose={() => setAddPostfinance(true)}
              userID={authCtx.userID}
              authToken={authCtx.token}
              method={"Postfinance"}
            />
          )
        ) : null}
      </div>
    </div>
  );
}

export default ChoosepaymentOption;
