import React from "react";
import classes from "./modeladvertorial.module.css";

import { Link } from "react-router-dom";

import MyButtonRund from "../WrapperComponents/myButtonRund";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import AuthContext from "../../../context/authcontext";
import { useEffect } from "react";
import { useState } from "react";
import MyImage from "../../sonstige/specialcomponents/images";

const Shortadvertorial = React.memo((props) => {
 
  const modelname = props.name;

 const {t}=useTranslation();

 const authCtx = useContext(AuthContext);
 const [isDarkmode, setDarkMode] = useState(authCtx.darkMode);
 const [touseImage,setToUseImage]=useState(null)
 useEffect(() => {
   setDarkMode(authCtx.darkMode);
 }, [authCtx]);
useEffect(()=>{
let useSoft = props.image.search("/.webp");
if (useSoft===-1){useSoft=false}

  var newpic=props.secondimage;
  if(!authCtx.isAVS){newpic=props.secondimagesoft}
  if(useSoft){newpic = props.secondimagesoft;}
  newpic=newpic.replace("/300x150","/300x");
  setToUseImage(newpic)
},[])
  return (
    <div className={classes.padshort}>
      <div
        className={
          !isDarkmode
            ? `${classes.userinfo} ${classes.hoverme}`
            : `${classes.userinfodm} ${classes.hoverme}`
        }
      >
        <div className={classes.userinfoheadershort}>
          <Link to={`/${modelname}`} state={{ test: "1" }}>
            <MyImage src={touseImage} alt={modelname} className={classes.mysideimage} />
          </Link>
        </div>
        <div className={classes.userinfobody}>
          <p className={classes.infotext}>{authCtx.isAVS?props.mymotto:props.mottosoft}</p>

          <Link to={`/${modelname}`} state={{ test: "1" }}>
            <MyButtonRund className={classes.setback}>
              {t("toprofile")} {modelname}
            </MyButtonRund>
          </Link>
        </div>
      </div>
    </div>
  );
});

export default Shortadvertorial;
