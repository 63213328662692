import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap"
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import classes from "./comment.module.css";

const Comment = (props) => {
  const isMobile = useCheckMobileScreen();
 const oldDate = new Date(props.com.timestamp);
 const [liked,setLiked]=useState(null)
 const [hearted, setHearted] = useState(null);
 const [disliked, setDisliked] = useState(null);
 
  function likethis() {
    setLiked(true);
      setHearted(false);
       setDisliked(false);
  }
  function heartthis() {
     setLiked(false);
     setHearted(true);
     setDisliked(false);
  }
  function dislikethis() {
     setLiked(false);
     setHearted(false);
     setDisliked(true);
  }

  return (
    <Row className={classes.combody}>
      {!isMobile && (
        <Col xs="3">
          <Link to={`/${props.com.commenterPseudo}`}>
            {/* {!isMobile && (
            <img
              src={props.com.userimage}
              alt={props.com.commenterPseudo}
              className={classes.userimagesmall}
            />
          )} */}
            <p>{props.com.commenterPseudo}</p>
            <p className={classes.commentdate}>{oldDate.toLocaleDateString()}</p>
          </Link>
        </Col>
      )}
      <Col xs={!isMobile ? "9" : "12"}>
        {isMobile && (
          <div className={classes.mobilepad}>
            <p className={classes.mobileText}>
              {props.com.commenterPseudo} - {oldDate.toLocaleDateString()}
            </p>
          
          </div>
        )}
        <p className={!isMobile ? classes.text : classes.mobileText}>
          {props.com.comment}
        </p>
        {/* <div className={classes.reactions}>
          <img
            src="/images/icons/like.png"
            alt="like"
            className={hearted ? classes.reactiones : classes.grayscaled}
            onClick={heartthis}
          />
          <img
            src="/images/icons/like_2.png"
            alt="cool"
            className={liked ? classes.reactiones : classes.grayscaled}
            onClick={likethis}
          />
          <img
            src="/images/icons/dislike_2.png"
            alt="dislike"
            className={disliked ? classes.reactiones : classes.grayscaled}
            onClick={dislikethis}
          />
        </div> */}
      </Col>
    </Row>
  );
};

export default Comment;