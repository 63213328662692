import AuthForm from "../../components/auth/signup";
import React, { useEffect } from "react";
const Login = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function sendback() {
    if (props.onback) {
      props.onback();
    }
   
  }
  return (
    
   
        <AuthForm
          relocate={props.reloacate}
          isSignup={props.isSignup}
          onback={sendback}
          makeShort={props.makeShort}
        />
      
  );
};

export default Login;
