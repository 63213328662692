import React, { useContext, useState } from "react";

import { useEffect } from "react";
import useMyHttp from "../../hooks/myhttp";
import Modeladvertorial from "../Cards/CreatorDetails/modeladvertorial";
import { useParams } from "react-router-dom";
import AllApiGirls from "./allapigirls";
import { useTranslation } from "react-i18next";
import AuthContext from "../../context/authcontext";

const FilteredApiGirls = (props) => {

const params=useParams()
const authCtx=useContext(AuthContext)
const {t}=useTranslation();
const {i18n}=useTranslation();
  const { isLoading, data, error, sendRequest } = useMyHttp();
  const [didIRun, setDidIrun] = useState(false)
  function fetchGirlsHandler() {
  
    sendRequest(`https://api.deine.fans/api/girls/query/%23${encodeURIComponent(params.filterID)}`,"GET",null,null,null,authCtx);
    
  }
  function fetchGirlsHandler2() {
    setDidIrun(true);
    sendRequest(
      `https://api.deine.fans/api/girls/query/${encodeURIComponent(params.filterID)}`,
      "GET",
      null,
      null,
      null,
      authCtx
    );
  }
  useEffect(() => {
    fetchGirlsHandler();
    window.scrollTo(0, 0);
    setDidIrun(false);
  }, [params]);
 
  return (
    <div>
      <div>
        {data && data.girls.length < 1 && !didIRun && (
          <h5>{fetchGirlsHandler2()}</h5>
        )}
        {data && data.girls.length < 1 && didIRun && (
          <div>
            <h5>
              {t("notfound")} {t("tryoneofthese")}
            </h5>
            <AllApiGirls />
          </div>
        )}
        {data &&
          data.girls &&
          data.girls.map((girl) => (
            <Modeladvertorial
              name={girl.pseudo}
              image={
                girl.smallImageIDURLs.urlSmall.search("/.") < 0
                  ? girl.smallImageIDURLs.urlSmall
                  : girl.softSmallImageIDURLs.urlSmall
              }
              secondimage={
                girl.bigImageIDURLs.urlBig.search("/.") < 0
                  ? girl.bigImageIDURLs.urlBig
                  : girl.softBigImageIDURLs.urlBig
              }
              imagesoft={girl.softSmallImageIDURLs.urlSmall}
              secondimagesoft={girl.softBigImageIDURLs.urlBig}
              age={girl.age}
              numberofitems="2"
              key={girl.pseudo}
              isFav="unimportant"
              id={girl.producerID}
              mymotto={
                i18n.language === "de"
                  ? girl.descriptionSoftDE
                  : girl.descriptionSoftEN
              }
              mymottosoft={
                i18n.language === "de"
                  ? girl.descriptionSoftDE
                  : girl.descriptionSoftEN
              }
              hashtag={girl.hashTags}
              extra={girl.girl}
              data={girl}
            />
          ))}

        {isLoading && <h5>Lade Daten</h5>}

        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default FilteredApiGirls;
