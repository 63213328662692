

export async function checkforpremium(userID, authToken, context) {

  if(userID === null) {
    return;
  }
  //console.log("checkforpremium",userID, authToken);
  fetch(
    `https://api.deine.fans/api/payment/authorizations/${encodeURIComponent(userID)}?authToken=${encodeURIComponent(authToken)}&all=false`
  ).then((res) => {
    res.json().then((data) => {
    //  console.log("premiumCONTEXT", data);
      if (data.isAuthorized) {
        context.bePremium("PREMIUMTOKEN");
      } else {
        context.bePremium();
      }
    });
  })
  .catch((err) => {
    console.log("error fetch checkforpremium", err);
  });
}
