import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import classes from "./privacy.module.css";

const Privacy =() =>{
const {t}=useTranslation();
 const params=useParams()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);
  
  return (
    <div className={classes.content}>
      <h5 className={classes.header}>{t("privacy0")}</h5>
      <p className={classes.regular}>{t("privacy1")}</p>
      <p className={classes.headline}>{t("privacy2")}</p>
      <p className={classes.regular}>{t("privacy3")}</p>
      <p className={classes.headline}>{t("privacy4")}</p>
      <p className={classes.regular}>{t("privacy5")}</p>
      <p className={classes.headline}>{t("privacy6")}</p>
      <p className={classes.regular}>{t("privacy7")}</p>
      <p className={classes.headline}>{t("privacy8")}</p>
      <p className={classes.regular}>{t("privacy9")}</p>
      <p className={classes.headline}>{t("privacy10")}</p>
      <p className={classes.regular}>{t("privacy11")}</p>
      <p className={classes.headline}>{t("privacy12")}</p>
      <p className={classes.regular}>{t("privacy13")}</p>
      <p className={classes.headline}>{t("privacy14")}</p>
      <p className={classes.regular}>{t("privacy15")}</p>
      <p className={classes.headline}>{t("privacy16")}</p>
      <p className={classes.regular}>{t("privacy17")}</p>
      <p className={classes.headline}>{t("privacy18")}</p>
      <p className={classes.regular}>{t("privacy19")}</p>
      <p className={classes.headline}>{t("privacy20")}</p>
      <p className={classes.regular}>{t("privacy21")}</p>
      <p className={classes.headline}>{t("privacy22")}</p>
      <p className={classes.regular}>{t("privacy23")}</p>
      <p className={classes.headline}>{t("privacy24")}</p>
      <p className={classes.regular}>{t("privacy25")}</p>
      <p className={classes.headline}>{t("privacy26")}</p>
      <p className={classes.regular}>{t("privacy27")}</p>
      <p className={classes.headline}>{t("privacy28")}</p>
      <p className={classes.regular}>{t("privacy29")}</p>
    </div>
  );
};

export default Privacy;