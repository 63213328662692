import React, { useContext, useEffect, useState } from 'react';
import classes from "./boughtcontentgirlcard.module.css";
import { useTranslation } from 'react-i18next';
import { useGirlStore } from '../../../hooks/useStoreData';
import AuthContext from '../../../context/authcontext';

function BoughtContentGirlCard(props) {
    const{t}=useTranslation();
     const thegirls = useGirlStore((state) => state.thegirls);
     const setTheGirls = useGirlStore((state) => state.setGirlsList);
       const getGirlImage = useGirlStore((state) => state.getGirlImage);
const authCtx=useContext(AuthContext);
const [image,setImage]=useState(null);

useEffect(()=>{

var a = authCtx.isPremium
  ? getGirlImage(props.pseudo, true)
  : getGirlImage(props.pseudo, false);
  setImage(a);

},[])
   
     
  return (
    <div>
      <div className={classes.profile}>
        <div className={classes.profileImage}>
          <img src={image} alt="" />
        </div>
        <div className={classes.rightcontainer}>
          <div className={classes.profileUserSettings}>
            <h1 className={classes.profileUserName}>{props.pseudo}</h1>
          </div>
          <div className={classes.profileStats}>
            <button className={`${classes.btn} ${classes.profileEditBtn} `}>
              Alle Posts
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoughtContentGirlCard
