import React, { memo, useContext, useEffect, useState } from "react";
import DisplayMessage from "../../Cards/messenger/displayMessage";
import SearchMessage from "../../Cards/messenger/searchmessage";
import RedButton from "../../Cards/WrapperComponents/redbutton";
import AuthContext from "../../../context/authcontext";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import { useParams } from "react-router-dom";
import classes from "./messagesforuser.module.css";
import useCheckMobileScreen from "../../../hooks/useCheckMobile";
import LoadingSpinner from "../../Cards/Extras/loadingspinner";
import { useTranslation } from "react-i18next";
import Login from "../../../pages/login";
import { useMessages } from "../../../hooks/usePreflight";

const MessagesforUser = memo((props) => {
    const [myMessages, setMyMessages] = useState([]);
    const [currentFolder, setCurrentFolder] = useState("inbox");
    const [loading,setLoading] = useState(false);
    const authCtx = useContext(AuthContext);
    const userToken= authCtx.token;
    const userID= authCtx.userID;
   const [lastOpenedMessage,setLastOpenedMessage]=useState(null)

    // useEffect(() => {
    //   loadMessages();
    // }, [params]);
    const params = useParams();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [params]);

    useEffect(() => {
      loadMessages();
    }, [currentFolder]);

    function loadMessages() {
      setLoading(true);
      setMyMessages([]);
      fetch(
        `https://api.deine.fans/api/inboxuser?userID=${encodeURIComponent(userID)}&folder=${encodeURIComponent(currentFolder)}&authToken=${encodeURIComponent(userToken)}`
        ).then((res) => {
          if (res.ok) {
            res.json().then((data) => {
                 console.log(data);
              setLoading(false);
              checkreceivedData(data,authCtx,"messagesforUser");
              if(data.messages){
             
                if (data.messages.length > 0) {
                  setMyMessages(data.messages);
                }
              }
            });
          } else {
            setLoading(false);
          }
        });
    }
    const [savedMessages,setSavedMessages]=useState()
    function searchme(value){
      setSavedMessages(myMessages);
     
      var keep=[];
      for (var i=0;i<myMessages.length;i++){
          var check = myMessages[i].sender.toLowerCase();
          var a = check.search(value);
         
          if (a>=0){keep.unshift(myMessages[i])
          } else {
              var bcheck = myMessages[i].msgtext.toLowerCase();
              var b = bcheck.search(value);
              if(b>=0){
                keep.unshift(myMessages[i]);
              }
          }
      }
      setMyMessages(keep);
      setIsReset(true);
    }

    const [isreset,setIsReset]=useState(false);

    function reset () {
      setMyMessages(savedMessages);
      setIsReset(false);
    }

    function onMessageBought(id) {
      loadMessages();
      setLastOpenedMessage(id)
    }

    function changeToInbox() {
      setCurrentFolder("inbox");
    }

    function changeToSentMessages() {
      setCurrentFolder("sentmessages");
    }

    const isMobile=useCheckMobileScreen();
const {t}=useTranslation();

const reloadPage = useMessages((state) => state.reloadPage);

useEffect(()=>{
loadMessages()
},[reloadPage])
    return (
      authCtx.isLoggedIn?<div>
        <div className={classes.buttonfield}>
          <div
            className={currentFolder === "inbox" ? `active` : ""}
            onClick={changeToInbox}
          >
            <RedButton>{t("posteingang")}</RedButton>
          </div>
          <div
            className={currentFolder === "sentmessages" ? "active" : ""}
            onClick={changeToSentMessages}
          >
            <RedButton>{t("postausgang")}</RedButton>
          </div>
          <div onClick={loadMessages}>
            <RedButton>{t("reload")}</RedButton>
          </div>
        </div>
        <SearchMessage sendback={searchme} />
        {isreset && (
          <div onClick={reset}>
            <RedButton>{t("resetfilters")}</RedButton>
          </div>
        )}
        <h5 className={isMobile ? classes.spacer : null}>
          {currentFolder === "inbox" ? "Posteingang" : "Gesendete Nachrichten"}
        </h5>
        {/* {loading ? <p>Loading...<img src="/images/icons/loading.png" className={classes.spinning} alt="Loading..." /></p> : null} */}
        {loading ? <LoadingSpinner isSmall={true} /> : null}
        {myMessages && !loading ? (
          myMessages.length === 0 ? (
            <p className={isMobile ? classes.spacer : null}>
              {t("nomessages")}
            </p>
          ) : null
        ) : null}
        {myMessages.map((mes, index) => (
          <div key={`message ${index}`}>
            <DisplayMessage cont={mes} onMessageBought={onMessageBought} lastOpenedMessage={lastOpenedMessage} />
          </div>
        ))}
      </div>:<Login />
    );
});
export default React.memo(MessagesforUser);