import React, { Fragment, useEffect, useState } from "react";
import MyButton from "../Cards/WrapperComponents/myButton";
import classes from "./subscriptionmodal.module.css"
import { Link } from "react-router-dom";
import { addabo } from "../../hooks/addabo";
import { checkreceivedData } from "../../hooks/checkReceivedData";
import { useTranslation } from "react-i18next";
import PortalModal from "../Cards/WrapperComponents/portalmodal";
import RedButton from "../Cards/WrapperComponents/redbutton";



const Subscriptionmodal = (props) => {
const {t}=useTranslation();
function closeme (){
  props.getbackclose(false);
}
const [showFeedback,setShowFeedback] = useState(false);

function feedback(){

props.getbackclose(true);
 //setShowFeedback(true);
}

function closefeedback(){
  setShowFeedback(false);
}

const [usedPayment,setUsedPayment]=useState(0)

function closetrue() {
 addabo(
    props.context.userID,
    props.girlid,
    paymentmethodsmethods[usedPayment].id,
    props.tiers.id,
    props.context.token
  );
 
  feedback();
}
const [consent,setConsent]=useState(false);

function togglewiderspruch(){
  setConsent(!consent);
}
const [paymentmethodsmethods, setmethods] = useState(
 null
);

useEffect(() => {
 
 
 fetch(`https://api.deine.fans/api/payment/authorizations/${encodeURIComponent(props.context.userID)}?authToken=${encodeURIComponent(props.context.token)}&all=${encodeURIComponent(false)}`)
 .then((res) => {
     res.json().then((data) => {
      checkreceivedData(data, props.context,"subscriptionModal");
       if (data.isAuthorized){setmethods(data.authorizations)}
       else{
        closeme();
       
      }
     });
   });
}, []);
function handleChange(event){
event.preventDefault()

 setUsedPayment(event.target.value);
}
useEffect(() => {

}, [paymentmethodsmethods]);
  return (
    <Fragment>
      {!showFeedback && (
        <PortalModal onclose={closeme}>
          <div className={classes.modalHeader}>
            <h3> {t("getabo")}</h3>
          </div>

          <h5>
            {t("greeting")} {props.context.pseudo},
          </h5>
          {paymentmethodsmethods && (
            <p className={classes.maintext}>
              {t("getabo1")}
              {props.girlname} {t("getabo2")} {Number(props.tiers.price / 100)}{" "}
              {t("getabo3")}{" "}
              {paymentmethodsmethods.length === 1 ? (
                <span>{paymentmethodsmethods[usedPayment].paymentMethod}</span>
              ) : (
                <select onChange={handleChange} className={classes.theoption}>
                  {paymentmethodsmethods.map((option, index) => (
                    <option key={index} value={index}>
                      {option.paymentMethod} {t("endetin")}
                      {option.authKey}
                    </option>
                  ))}
                </select>
              )}{" "}
              {t("getabo4")}
            </p>
          )}
          <p>
            <label>
              <input
                className={classes.checkbox}
                type="checkbox"
                onChange={togglewiderspruch}
                checked={consent}
              />
              {t("acceptagb1")}
              <Link to="/AGB"> {t("agb")}</Link>. {t("acceptagb2")}
            </label>
          </p>
          {consent && paymentmethodsmethods && (
            <MyButton>
              <div onClick={closetrue}> {t("kostenpflichtigbestellen")}</div>
            </MyButton>
          )}
          {!consent && (
            <MyButton className={classes.inactive}>
              <div> {t("pleaseacceptagb")}</div>
            </MyButton>
          )}
        </PortalModal>
      )}
      {showFeedback && (
        <PortalModal onclose={closefeedback}>
          <div className={classes.wrapper}>
            <h3>{t("thatworked")}</h3>
            <hr />
            <p>{t("thatworkedtext")}</p>
            <div className={classes.buttons}>
              <span onClick={closefeedback} className={classes.innerbuttons}>
                <RedButton>{t("cool")}</RedButton>
              </span>
            </div>
          </div>
        </PortalModal>
      )}
    </Fragment>
  );
};

export default Subscriptionmodal;