import React, { Fragment, useState } from 'react'
import Timelinefilterbar from '../../components/filterbars/timelinefilterbar';
import TimeLineTest from '../../components/lists/Timeline/timelintest';
import ChoosepaymentOption from '../../components/sonstige/profileElements/choosepaymentOption';

function BasicTimeline(props) {

const [sortType,setSortType] =useState(null)
function getback(info){
    setSortType(info)
}
  return (
    <Fragment>
      <Timelinefilterbar checkback={getback} sort={sortType} main={true} higher={true}/>
      {props.extra === "payment" ? <ChoosepaymentOption /> : null}
      <TimeLineTest sort={sortType} dontshow={props.dontshow} />
    </Fragment>
  );
}

export default BasicTimeline;
