import React, { Fragment, useState } from "react";
import classes from "./ersterPost.module.css";
import DarkWrapModal from "../../WrapperComponents/darkwrapmodal";
import PortalModal from "../../WrapperComponents/portalmodal";
import ChoosepaymentOption from "../../../sonstige/profileElements/choosepaymentOption";
import { useTranslation } from "react-i18next";

function AddPremium(props) {
  const [showPayments, setShowPayments] = useState(false);
  function toggleShow() {
    setShowPayments(false);
  }
  function showShow(){
    setShowPayments(true);
  }
  const{t}=useTranslation();
  return (
    <Fragment>
      <div
        className={props.isdone ? classes.borderisDone : classes.border}
        onClick={props.isdone ? null : showShow}
      >
        {t("addcreitcardnow")}
      </div>
      {showPayments && (
        <PortalModal onclose={toggleShow}>
          <ChoosepaymentOption />
        </PortalModal>
      )}
    </Fragment>
  );
}

export default AddPremium;
