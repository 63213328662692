import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import AuthContext from "../../../context/authcontext";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import classes from "./allsubscriptions.module.css";
import SubBox from "../../Cards/subscriptions/subBox";

const SingleGirlSubscriptions = (props) => {

   
  const authCtx = useContext(AuthContext);
const [info, setInfo] = useState(props.girlinfo); 
 const userToken = authCtx.token;
  const userID = authCtx.userID;
  const isLoggedIn = authCtx.isLoggedIn;
  const isPremium = authCtx.isPremium;
  
  const [hasSubs, setHasSubs] = useState(false);
  const [subGirls, setSubGirls] = useState([]);
  
  function loadData() {
  setInfo(props.girlinfo)
    if (!isLoggedIn) {
      return;
    }
   fetch(
      `https://api.deine.fans/api/subscriptions/user/list/${encodeURIComponent(
        userID
      )}?producerID=${info}&authToken=${encodeURIComponent(userToken)}`
    ).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          checkreceivedData(data, authCtx,"singleGirlSub");
       
          if (data.subscription) {
            setHasSubs(true);
            setSubGirls(data.subscription);
            if (data.subscription.length > 0) {
              setSubGirls(data.subscription);
            
            }
          }
        });
      }
    });
  }

  useEffect(() => {
   setInfo(props.girlinfo);
   setTimeout(() => {
     loadData();
   }, "500");
  }, []);
  function sendback() {
    setSubGirls(null);
    setTimeout(() => {
      loadData();
    }, "500");
  }
  return (
    <div className={classes.outer}>
      {!isLoggedIn && !isPremium && <h1>Logge Dich zunächst ein</h1>}
      {!hasSubs && <h5>Du hast bislang noch keine Abonnements</h5>}

      {hasSubs && subGirls && (
        <Row className={classes.mylist}>
          <Col xs="12" lg={props.fullsize?"12":"6"} key={`thing`}>
            <SubBox
              thegirl={subGirls}
              context={authCtx}
              key={"index"}
              sendback={sendback}
              subid={props.girlinfo}
              nopicture={props.nopicture?true:false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SingleGirlSubscriptions;
