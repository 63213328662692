import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import { useLikes } from "../../../hooks/useLikes";
import WriteMessage from "../messenger/writeMessage";
import PortalFeedbackModal from "../WrapperComponents/portalfeedbackmodal";
import PortalModal from "../WrapperComponents/portalmodal";
import SimplePaymentBaseModal from "../../payment/simplepayment/simplepaymentBaseModal";
import classes from "./testpostcard.module.css";
import { Analytics } from "aws-amplify";
import ChoosepaymentOption from "../../sonstige/profileElements/choosepaymentOption";
import Login from "../../../pages/login";

const PostCardFunctionList =memo((props) => {
  const [isItLiked, setIsItLiked] = useState(props.post.liked);
  const [numofLikes, setNumOfLikes] = useState(props.post.likes);
  function toggleLiked() {
    setIsItLiked(!isItLiked);
  }
  const [sendMail, setSendMail] = useState(false);
  function togglemail() {
    setSendMail(!sendMail);
  }
  useEffect(() => {
   
    setTimeout(() => {
      if (props.changePin) {
        setIsPinned(!isPinned);
      }
    if (props.changeLike) {likeme(props.guid);}
     
    }, 100);
  }, []);

  const [openotp, setopenotp] = useState(false);
  function toggleotp() {
    setopenotp(!openotp);
  }
  function gotbackdata(info) {
    toggleotp();
  }

  const [isPinned, setIsPinned] = useState(props.post.starred);
  const [didIPin, setDidIPin] = useState(false);
  function closeinfo() {
    setDidIPin(false);
  }
  function pinthis(guid) {
    fetch(
      `https://api.deine.fans/api/timelinefavs/${encodeURIComponent(
        props.context.userID
      )}`,
      {
        method: "PUT",
        body: JSON.stringify({
          guid: guid,
          authToken: props.context.jwtAuthToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, props.context, "postcard,pin");
      });
    });
    setIsPinned(!isPinned);
    setDidIPin(true);
  }
  function unpinthis(guid) {
    fetch(
      `https://api.deine.fans/api/timelinefavs/${encodeURIComponent(
        props.context.userID
      )}`,
      {
        method: "DELETE",
        body: JSON.stringify({
          guid: guid,
          authToken: props.context.jwtAuthToken,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      res.json().then((data) => {
        checkreceivedData(data, props.context, "postcard,unpin");
      });
    });
    setIsPinned(!isPinned);
    if (props.isdelete) {
      props.senddelete(props.post.guid);
    }
    props.sendpininfo();
  }

  const { t } = useTranslation();

  function likeme(guid) {
    setIsItLiked(!isItLiked);
    useLikes(guid, props.context);

    Analytics.record({
      name: "likedPost",
      attributes: {
        guid: props.post.guid,
      },
    });

    if (!isItLiked) {
      setNumOfLikes(numofLikes + 1);
    } else {
      setNumOfLikes(numofLikes - 1);
    }
  }
  const [buyCoins,setBuyCoins]=useState(false);
  function togglebuycoins(){
    setBuyCoins(!buyCoins)
  }
   const [showLogin, setShowLogin] = useState(false);
   function makeLogin() {
     setShowLogin(!showLogin);
   }

  return (
    <div
      className={`${classes.flexit} ${classes.iconliste}`}
      id={`Post-Card-Funktionlist-Button ${props.post.guid}`}
    >
      <div
        id={`Post-Card-Mail-container ${props.post.guid}`}
        onClick={
          !props.context.isLoggedIn
            ? makeLogin
            : props.context.isPremium
            ? togglemail
            : props.context.canfandollar > props.context.messengerPrice
            ? props.context.messengerPrice
            : 1
            ? togglemail
            : props.messengerPrice && props.messengerPrice > 0
            ? togglebuycoins
            : togglemail
        }
      >
        <img
          src="/images/icons/post_icon_mail.png"
          id={`Post-Card-Mail-Button ${props.post.guid}`}
          className={classes.icon}
        />
      </div>
      <div
        onClick={
          !props.context.isLoggedIn
            ? makeLogin
            : props.context.isPremium
            ? toggleotp
            : togglebuycoins
        }
        id={`Post-Card-Trinkgeld-Container ${props.post.guid}`}
      >
        <img
          src="/images/icons/post_icon_trinkgeld.png"
          className={classes.icon}
          id={`Post-Card-Trinkgeld-Button ${props.post.guid}`}
        />
      </div>
      <div
        className={classes.likecontainer}
        id={`Post-Card-Like-Container ${props.post.guid}`}
        onClick={
          !props.context.isLoggedIn ? makeLogin : () => likeme(props.post.guid)
        }
      >
        <img
          src="/images/icons/post_icon_herz.png"
          className={isItLiked ? classes.icon : classes.icongray}
          id={`Post-Card-Like-Button ${props.post.guid}`}
        />
        <p
          className={
            numofLikes < 10
              ? classes.likenumber
              : numofLikes < 99
              ? classes.likenumbermiddle
              : classes.likenumberbig
          }
        >
          {numofLikes}
        </p>
      </div>
      <div>
        <img
          src="/images/icons/post_icon_pin.png"
          id={`Post-Card-PinButton ${props.post.guid}`}
          className={isPinned ? classes.icon : classes.icongray}
          onClick={
            !props.context.isLoggedIn
              ? makeLogin
              : !isPinned
              ? () => {
                  pinthis(props.post.guid);
                }
              : () => {
                  unpinthis(props.post.guid);
                }
          }
        />
      </div>
      {sendMail && (
        <PortalModal onclose={togglemail}>
          <WriteMessage onclose={togglemail} frompost={props.post} />
        </PortalModal>
      )}
      {openotp && (
        <SimplePaymentBaseModal
          girlname={props.post.pseudo}
          girlid={props.post.producerID}
          onclose={gotbackdata}
          canfandollar={true}
        />
      )}
      {didIPin && (
        <PortalFeedbackModal onclose={closeinfo}>
          {t("youhavepinned")}
        </PortalFeedbackModal>
      )}
      {buyCoins ? (
        <PortalModal onclose={togglebuycoins}>
          <ChoosepaymentOption />
        </PortalModal>
      ) : null}
      {showLogin ? (
        <PortalModal onclose={makeLogin}>
          <Login />
        </PortalModal>
      ) : null}
    </div>
  );
      });

export default PostCardFunctionList;
