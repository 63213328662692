import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  logindata: "",
  pseudo: "",
  userID: "",
  isLoggedIn: false,
  unreadMessages: 0,
  profilePic: "",
  jwtAuthToken: "",
refreshToken:"",
  login: (
    token,
    logindata,
    pseudo,
    userID,
    refreshToken,
    fanDollar,
    lastLogin,
    unreadMessages,
    jwtAuthToken,
    profilePic,
    language
  ) => {},
  logout: () => {},
  fanDollar: "",
  premiumtoken: "",
  isPremium: false,
  refreshToken: "",
  lastLogin: "",
  darkMode: false,
  isAVS: false,
  jwtAuthToken: "",
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("mytoken");
  const initialJwtAuthToken = localStorage.getItem("jwtAuthToken");
  const initialPseudo = localStorage.getItem("myuser");
  const initialID = localStorage.getItem("userID");
  const initiallogin = localStorage.getItem("logindata");
  const initialpremium = localStorage.getItem("premiumdata");
  const initialrefresh = localStorage.getItem("refreshToken");
  const initialfanDollar = localStorage.getItem("fanDollar");
  const initiallastLogin = localStorage.getItem("lastLogin");
  const initialUnreadMessages = localStorage.getItem("unreadMessages");
  const initialProfilePic = localStorage.getItem("profilePic");
  const initialLanguage = localStorage.getItem("language");
  const initialcountry=localStorage.getItem("country")

  const [refreshToken, setRefreshToken] = useState(initialrefresh);
  const [token, setToken] = useState(initialToken);
  const [logindata, setLogindata] = useState(initiallogin);
  const [pseudo, setPseudo] = useState(initialPseudo);
  const [userID, setUserID] = useState(initialID);
  const [premiumToken, setPremiumToken] = useState(initialpremium);
  const [fanDollar, setFanDollar] = useState(initialfanDollar);
  const userIsLoggedIn = !!token;
  const userIsPremium = !!premiumToken;
  const [userIsAVS, setUserIsAVS] = useState(null);
  const [lastLogin, setlastLogin] = useState(initiallastLogin);
  const [darkMode, setDarkMode] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState(initialUnreadMessages);
  const [myProfilePic, setMyProfilePic] = useState(initialProfilePic);
  const [myJwtAuthToken, setMyJwtAuthToken] = useState(initialJwtAuthToken);
  const [myLanguage, setMyLanguage] = useState(initialLanguage);
  const [adr, setAdr] = useState("");
  const [hitLog,setHitLog]=useState([]);
  const [country,setCountry]=useState(initialcountry)

  const loginHandler = (
    token,
    logindata,
    pseudo,
    userID,
    refreshToken,
    fanDollar,
    lastLogin,
    unreadMessages,
    jwtAuthToken,
    adr,
    profilePic,
    language,
    country,
    
  ) => {
    setToken(token);
    setLogindata(logindata);
    setPseudo(pseudo);
    setUserID(userID);
    setRefreshToken(refreshToken);
    setFanDollar(fanDollar / 100);
    setPremiumToken(premiumToken);
    setlastLogin(lastLogin);
    setUnreadMessages(unreadMessages);
    setMyJwtAuthToken(jwtAuthToken);
    setMyProfilePic(profilePic);
    setMyLanguage(language);
    setAdr(adr);
    setCountry(country);

    localStorage.setItem("mytoken", token);
    localStorage.setItem("myuser", pseudo);
    localStorage.setItem("userID", userID);
    localStorage.setItem("logindata", logindata);
    localStorage.setItem("premiumdata", premiumToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("lastLogin", lastLogin);
    localStorage.setItem("fanDollar", fanDollar / 100);
    localStorage.setItem("unreadMessages", unreadMessages);
    localStorage.setItem("jwtAuthToken", jwtAuthToken);
    localStorage.setItem("profilePic", profilePic);
    localStorage.setItem("language", language);
    localStorage.setItem("country",country);
  };

  const logoutHandler = () => {
    setToken(null);
    setLogindata(null);
    setPseudo(null);
    setUserID(null);
    setFanDollar(null);
    setPremiumToken(null);
    setRefreshToken(null);
    setlastLogin(null);
    setUnreadMessages(0);
    setMyJwtAuthToken(null);
    setMyProfilePic(null);
    setMyLanguage(null);

    localStorage.removeItem("mytoken");
    localStorage.removeItem("myuser");
    localStorage.removeItem("userID");
    localStorage.removeItem("logindata");
    localStorage.removeItem("premiumdata");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("lastLogin");
    localStorage.removeItem("fanDollar");
    localStorage.removeItem("refreshing");
    localStorage.removeItem("unreadMessages");
    localStorage.removeItem("profilePic");
    localStorage.removeItem("jwtAuthToken");
    localStorage.removeItem("language");
    localStorage.removeItem("country");
  };
  const setMyAdr = (value) => {
    setAdr(value);
  };

  const switchlanguage = (value) => {
    setMyLanguage(value);
    localStorage.setItem("language", value);
  };
  const setJwtAuthToken = (value) => {
    setMyJwtAuthToken(value);
    localStorage.setItem("setMyJwtAuthToken", value);
  };
  const saveProfilePic = (value) => {
    setMyProfilePic(value);
    localStorage.setItem("profilePic", value);
  };
  const checkUnreadMessages = (value) => {
    setUnreadMessages(value);
    localStorage.setItem("unreadMessages", value);
  };
  const bePremiumHandler = (value) => {
    setPremiumToken(value);
    localStorage.setItem("premiumdata", value);
  };
  const dontBePremiumHandler = () => {
    setPremiumToken(null);
    localStorage.removeItem("premiumdata");
  };
  const setCurrentFandollar = (value) => {
    setFanDollar(value);
  };
  const setAVSHandler = (value) => {
    setUserIsAVS(value);
  };
  const newRefreshToken = (value) => {
    setRefreshToken(value);
    localStorage.setItem("refreshToken", value);
  };
  const switchDarkMode = () => {
    localStorage.setItem("darkmode", !darkMode);
    setDarkMode(!darkMode);
  };
  const setDark = () => {
    setDarkMode(true);
    localStorage.setItem("darkmode", true);
  };
  const setBright = () => {
    setDarkMode(false);
    localStorage.setItem("darkmode", false);
  };
  const newToken = (value) => {
    setToken(value);

    localStorage.setItem("mytoken", value);
  };
  const addUserID = (value) => {
    setUserID(value);
    localStorage.setItem("myuser", value);
  };
  const changeCountry=(country)=>{
    setCountry(country);
    localStorage.setItem(country,country)
  }
  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    logindata: logindata,
    pseudo: pseudo,
    userID: userID,
    login: loginHandler,
    logout: logoutHandler,
    premiumToken: premiumToken,
    isPremium: userIsPremium,
    jwtAuthToken: myJwtAuthToken,
    setJwtAuthToken: setJwtAuthToken,
    bePremium: bePremiumHandler,
    dontBePremium: dontBePremiumHandler,
    fanDollar: fanDollar,
    checkFanDollar: setCurrentFandollar,
    isAVS: userIsAVS,
    setAVS: setAVSHandler,
    refreshToken: refreshToken,
    setAFreshToken: newRefreshToken,
    newToken: newToken,
    lastLogin: lastLogin,
    darkMode: darkMode,
    switchDarkMode: switchDarkMode,
    setDark: setDark,
    setBright: setBright,
    unreadMessages: unreadMessages,
    checkUnreadMessages: checkUnreadMessages,
    profilePic: myProfilePic,
    saveProfilePic: saveProfilePic,
    addUserID: addUserID,
    myLanguage: myLanguage,
    switchlanguage: switchlanguage,
    adr: adr,
    setMyAdr: setMyAdr,
    hitLog:hitLog,
    setHitLog:setHitLog,
    country:country,
    changeCountry:changeCountry,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
