import React, { Fragment, useState } from "react";
import classes from "./portalmodal.module.css";
import ReactDOM from "react-dom";

const PortalModal = (props) => {
  const [big] = useState(props.big);
  const [bg] = useState(props.bg);
  const fullscreen = props.fullscreen;
  const dm=props.dm

  const Backdrop = (props) => {
    return (
      <div
        className={props.bg ? classes.backdropbg : classes.backdrop}
        onClick={closeme}
      ></div>
    );
  };

  const ModalOverlay = (props) => {
    return (
      <div
        id={props.id ? props.id : "portalModalContainer"}
        className={`${props.className} ${
          fullscreen
            ? classes.modalfs
            : !big
            ? `${classes.modal} ${classes.extramodal}`
            : props.bg
            ? classes.modalbigblack
            : props.dm
            ? classes.modalbigdm
            : classes.modalbig
        }`}
      >
        <button
          className={classes.closeBtn}
          id="closePortalModal"
          onClick={closeme}
        >
          <img
            src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
            alt="close modal"
            className={classes.closex}
            id="closePortalModal-X"
          />
        </button>
        <div className={classes.content}>{props.children}</div>
      </div>
    );
  };
  function closeme() {
    if (props.onclose) {
      props.onclose();
    }
  }
  const portalElement = document.getElementById("overlay");
  return (
    <Fragment>
      {!fullscreen
        ? ReactDOM.createPortal(<Backdrop bg={bg}  />, portalElement)
        : null}
      {ReactDOM.createPortal(
        <ModalOverlay
          bg={bg}
          fullscreen={fullscreen}
          className={props.className}
          dm={dm}
        >
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default PortalModal;
