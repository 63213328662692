import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../context/authcontext";
import { checkreceivedData } from "../../../hooks/checkReceivedData";
import PortalModal from "../../Cards/WrapperComponents/portalmodal";
import RedButton from "../../Cards/WrapperComponents/redbutton";
import classes from "./imageUploader.module.css";

const UserImages = (props) => {
  const url =
    "https://api.deine.fans/api/userimages?dimension=" +
    encodeURIComponent("320x240xinside") +
    "&userID=";
  const authCtx = useContext(AuthContext);
  const [userImages, setUserImages] = useState([]);
  const [userVideos, setUserVideos] = useState([]);
  const [defaultImage, setDefaultImage] = useState(props.ichanged||null);

  useEffect(() => {
    getImages();
  }, []);

  function getImages() {
    fetch(
      `${url}${encodeURIComponent(
        authCtx.userID
      )}&authToken=${encodeURIComponent(authCtx.token)}`
    ).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          checkreceivedData(data, authCtx,"userImages,getImages");
console.log(data,"jetzte")
          if (data&&data.images) {
            console.log(data)
            setUserImages(data.images);
            if(data.videos)setUserVideos(data.videos);
         
            if(defaultImage===true){props.sendDefault(data.images[0])}
          }
        });
      }
    });
  }
  function sendit(content) {
    props.sendback(content);
  }
  
function deleteImage(){
  fetch(
    `https://api.deine.fans/api/userimage/Delete/${
      encodeURIComponent(toDelete.imageID)
    }?userID=${encodeURIComponent(
      authCtx.userID
    )}&authToken=${encodeURIComponent(authCtx.token)}`
  ).then((res) => {
    if (res.ok) {
      res.json().then((data) => {
        checkreceivedData(data, authCtx,"userImages,deleteImages");
        if (data.error !== null) {
          console.log(data.error);
        }
        if (data) {
          getImages();
        }
      });
    }
  });
 
}
const [askDelete,setAskDelete] = useState(false)
const [toDelete,setToDelete] = useState(null);
const toggleask=() =>{
  setAskDelete(!askDelete);
}
const {t}=useTranslation();
function sayno(){
  setAskDelete(false);
  
}
function sayyes(){
deleteImage();
setAskDelete(false);
}
function startdelete(info){
  setToDelete(info);
  toggleask();
}
  return (
    <div className={classes.imageholderholder}>
      <div className={classes.imageholder}>
        {userImages &&
          userImages.map((image) => (
            <div key={image.imageID} className={classes.wrapper}>
              <img
                src="https://d39j9qa9t0x6xj.cloudfront.net/funktionsicons/igsz.png"
                className={classes.ix}
                alt="delete"
                onClick={() => {
                  startdelete(image);
                }}
              />
              <img
                className={classes.userimages}
                alt="test"
                src={image.imageURL}
                onClick={() => {
                  sendit(image);
                }}
              />
            </div>
          ))}
      </div>
      {askDelete && (
        <PortalModal onclose={sayno}>
          <div className={classes.wrapper}>
            <h3>{t("wannadeletetitle")}</h3>
            <hr />
            <p>{t("wannadelete")}</p>
            <div className={classes.buttons}>
              <span onClick={sayyes} className={classes.innerbuttons}>
                <RedButton>{t("yes")}</RedButton>
              </span>

              <span onClick={sayno}>
                <RedButton>{t("no")}</RedButton>
              </span>
            </div>
          </div>
        </PortalModal>
      )}
    </div>
  );
};

export default UserImages;
